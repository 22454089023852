import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NotificationState } from "ReduxStore/Reducers/eijentNotification.reducer";
import { Actions } from "ReduxStore/Actions"; // Import your actions
import { Box, Typography, Card, CardContent, Modal, Button } from "@mui/material";

const SmartNudgesNotification = () => {
  const dispatch = useDispatch();

  const getSmartNudgesNotificationList = (page?: number) => {
    dispatch(Actions.smartNudgesNotificationListRequest());
  };

  useEffect(() => {
    getSmartNudgesNotificationList();
  }, []);

  const { smartNudgesNotification } = useSelector(
    (state: any) => state.eijentNotification
  ) as NotificationState;

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Box sx={{ textAlign: "center", mt: 3 }}>
      <Button variant="contained" onClick={handleOpen}>
        View Smart Nudges Notifications
      </Button>

      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 3,
            borderRadius: 2,
            maxHeight: "80vh",
            overflowY: "auto",
          }}
        >
          <Typography variant="h5" sx={{ mb: 2, fontWeight: "bold", textAlign: "center" }}>
            Smart Nudges Notifications
          </Typography>

          {smartNudgesNotification?.map((notification) => (
            <Card key={notification._id} sx={{ boxShadow: 2, borderRadius: 2, mb: 2 }}>
              <CardContent>
                <Typography variant="h6">{notification.title}</Typography>
                <Typography variant="body1" color="text.secondary">
                  {notification.message}
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  <strong>Priority:</strong> {notification.priority}
                </Typography>
                <Typography variant="body2">
                  <strong>Created At:</strong> {new Date(notification.createdAt).toLocaleString()}
                </Typography>
                <Typography variant="body2">
                  <strong>Updated At:</strong> {new Date(notification.updatedAt).toLocaleString()}
                </Typography>
                {notification.link && (
                  <Typography variant="body2">
                    <strong>Link:</strong>{" "}
                    <a href={notification.link} target="_blank" rel="noopener noreferrer">
                      {notification.link}
                    </a>
                  </Typography>
                )}
                <Typography variant="body2">
                  <strong>Name:</strong> {notification.name || "N/A"}
                </Typography>
              </CardContent>
            </Card>
          ))}

          <Button variant="contained" color="error" fullWidth onClick={handleClose}>
            Close
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default SmartNudgesNotification;
