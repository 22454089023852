import React, { useEffect, useState, useRef, useCallback } from "react";
import NotificationButton from "./NotificationButton";
import NotificationPopover from "./NotificationPopover";
import { Actions } from "ReduxStore/Actions";
import { useDispatch } from "react-redux";

export default function NotificationPopup(): JSX.Element {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [open, setOpen] = useState<boolean>(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
    setOpen(false);
    dispatch(Actions.eijentNotificationListReset({resetType: 'reset', }));
  };
  let params = {
    pageNo:1,  // Add pageNo as query parameter
    limit:20,
    sortBy:"updatedAt", // Add limit as query parameter
  };

  const getNotificationList = () => {
    dispatch(Actions.eijentNotificationListRequest(params));
  };

    useEffect(() => {
      getNotificationList();
    }, []);
  return (
    <div>
      <NotificationButton open={open} onClick={handleClick} />
      <NotificationPopover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
      />
    </div>
  );
}

