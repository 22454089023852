import { Stack, Box, Avatar, Typography } from '@mui/material';
import { Message } from '../common';

export default function SendersReceiversInfo({ mail }: { mail: Message }) {
  const getNameFromEmail = (fromValue: string) => {
    // Check if the email contains a name part (format: "Name <email@domain.com>")
    const nameMatch = fromValue.match(/(.*?)\s*<(.+?)>/);
    if (nameMatch && nameMatch[1]) {
      // Return the name part if it exists
      return nameMatch[1].trim();
    }

    // If no name part, extract from email (format: email@domain.com)
    const email = nameMatch ? nameMatch[2] : fromValue;
    const namePart = email.split('@')[0];
    const names = namePart.split('.');
    return names
      .map((name) => name.charAt(0).toUpperCase() + name.slice(1))
      .join(' ');
  };

  return (
    <Stack
      spacing={{ xs: 1, md: 8 }}
      direction={{ md: 'row' }}
      alignItems={'center'}
    >
      <Box display={'flex'} alignItems={'center'}>
        <Avatar
          sizes="small"
          alt={getNameFromEmail(
            mail.payload.headers.find((g: any) => g.name === 'From').value,
          )}
          sx={{
            width: 31,
            height: 31,
            fontSize: '12px',
            fontWeight: 400,
            mr: 1.5,
          }}
        >
          {getNameFromEmail(
            mail.payload.headers.find((g: any) => g.name === 'From').value,
          )
            .split(' ')
            .map((n) => n[0])
            .join('')}
        </Avatar>
        <Stack>
          <Box display={'flex'} alignItems={'center'}>
            <Typography variant="body1">
              {getNameFromEmail(
                mail.payload.headers.find((g: any) => g.name === 'From').value,
              )}{' '}
              &nbsp;
            </Typography>

            <Typography variant="body2">
              {mail.payload.headers.find((g: any) => g.name === 'From').value}
            </Typography>
          </Box>
          <Box display={'flex'} alignItems={'center'}>
            <Typography variant="body1">To: &nbsp;</Typography>
            <Typography variant="body2">
              {mail.payload.headers.find((g: any) => g.name === 'To').value}
            </Typography>
          </Box>
          {mail.payload.headers.find((g: any) => g.name === 'Cc') && (
            <Box display={'flex'} alignItems={'center'}>
              <Typography variant="body1">Cc: &nbsp;</Typography>
              <Typography variant="body2">
                {mail.payload.headers.find((g: any) => g.name === 'Cc').value}
              </Typography>
            </Box>
          )}
          {mail.payload.headers.find((g: any) => g.name === 'Bcc') && (
            <Box display={'flex'} alignItems={'center'}>
              <Typography variant="body1">Bcc: &nbsp;</Typography>
              <Typography variant="body2">
                {mail.payload.headers.find((g: any) => g.name === 'Bcc').value}
              </Typography>
            </Box>
          )}
        </Stack>
      </Box>
    </Stack>
  );
}
