import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Alert, AlertTitle, IconButton, Stack } from "@mui/material";
import { SxProps, Theme } from "@mui/system";

import {
    RedirectionIcon
} from "../components/CustomIcons";
// @ts-ignore
import EijentButtonLogo from "../assets/images/eijent-logo-compressed.gif";

const JourneySummary: React.FC = () => {
    return (
        <Stack flexGrow={1} py={2} display="flex" alignItems="center" justifyContent="center">
            {/* Eijent Logo & Title */}
            <Box display="flex" my={1} gap={1} justifyContent="center" alignItems="center">
                <img
                    src={EijentButtonLogo}
                    width={36}
                    height={36}
                    alt="Eijent logo"
                    style={{
                        mixBlendMode: "multiply",
                        filter: "contrast(1)",
                    }}
                />
                <Typography>Eijent</Typography>
            </Box>

            <Typography variant="body2" align="center">
                This is the summary of your &nbsp;
                <strong>New Journey</strong>
            </Typography>

            {/* Summary Card */}
            <Stack
                spacing={3}
                mt={4}
                sx={{
                    backgroundColor: "#ffffff",
                    boxShadow: "0px 15px 30px #00000022",
                    borderRadius: "16px",
                    p: 6,
                    width: "100%",
                    maxWidth: "50%",
                }}
            >
                {/* Journey Title */}
                <Box display="flex" alignItems="center" gap={1}>
                    <Typography variant="h4" color="primary">
                        F1 – Las Vegas
                    </Typography>
                    <IconButton
                        size="small"
                        variant="contained"
                        //@ts-ignore
                        color='transparent'
                    >
                        <RedirectionIcon />
                    </IconButton>
                </Box>

                {/* Journey Description */}
                <Typography variant="body2">
                    Hub for all the opportunities and operational tasks related to events from Formula 1 in Las Vegas.
                </Typography>

                {/* Impact Alert */}
                <Alert
                    severity="warning"
                    sx={{
                        "& .MuiAlert-message": { mt: 0 },
                        "& .MuiAlertTitle-root": { ml: 3 },
                    }}
                >
                    <AlertTitle>Impact of this Journey</AlertTitle>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.
                </Alert>
            </Stack>
        </Stack>
    );
};

export default JourneySummary;
