import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Actions } from 'ReduxStore/Actions';
import { createNotification } from '../../helpers';
import '../../sass/main.scss';
import Loader from '../../Components/Loader/loader';
import { Helmet } from 'react-helmet';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AuthLayout from '../../Layouts/AuthLayout';
import Card from '../../Layouts/AuthLayout/SignInCard';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { FormLabel, InputAdornment, Stack } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import SignInButtonIcon from '../../assets/icons/SignInButtonIcon.svg';
import { Alert, Collapse, IconButton } from '@mui/material';
import { CancelIcon } from '../../eijent/components/CustomIcons';

import withCustomRouter from '../../Common/withCustomRouter';
class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      confirmPassword: '',
      emailToken: '',
      errors: {},
      loading: false,
    };
  }

  componentDidMount() {
    const { token } = this.props.params; // Extract token from route params
    if (token) {
      this.setState({ emailToken: token });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const login = this.props.login;

    if (
      login &&
      login.resetSuccess &&
      this.props.login.resetMsg !== prevProps.login.resetMsg
    ) {
      let st = prevState.loading ? this.setState({ loading: false }) : null; 
      this.handleAlert('success',login.resetMsg);  
      setTimeout(() => {
        this.props.navigate('/');
      }, 2000); // 2-second delay 
    } else if (login && login.resetError && this.state.loading) { 
      this.handleAlert('error',login.resetMsg);  
      this.handleLoading();
    }
  }

  handleLoading = () => {

    let loading = this.state.loading;
    this.setState({
      loading: !loading,
    });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  isLoginValid = () => {
    const { confirmPassword, password } = this.state;
    let error = {};
    let formIsValid = true;
    // const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;//old way
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    if (password === '' || password.trim().length === 0) {
      formIsValid = false;
      error['password'] = '*Please enter password.';
    }
    if (password && passwordRegex.test(password) === false) {
      formIsValid = false;
      error['password'] = "Password must have at least 1 uppercase letter, 1 lowercase letter, 1 number, 1 special character, and a minimum length of 8.";
    }
    if (confirmPassword === '' || confirmPassword.trim().length === 0) {
      formIsValid = false;
      error['confirmPassword'] = '*Please enter confirm password.';
    }

    if (password !== confirmPassword) {
      formIsValid = false;
      error['confirmPassword'] =
        '*password and confirm password is not matched.';
    }
    this.setState({ errors: error });
    if (!formIsValid) {
      const errorMessages = Object.values(error).join('\n'); // Combine error messages with a newline
      this.handleAlert('error', errorMessages); // Trigger the alert
    }
    return formIsValid;
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    if (this.isLoginValid()) {
      const { emailToken, password } = this.state;

      const requestbody = { emailToken, password };
     await this.props.resetPassword(requestbody);
      this.handleLoading();
      // this.props.startLoading();
    }
  };

  handleAlert = (type, message) => {
    this.setState({
      alert: { type, message },
    });
  };


  render() {
    const { password, errors, loading, confirmPassword } = this.state;
    return (
      <AuthLayout>
        <Helmet>
          <title>Eijent | Reset Password</title>
        </Helmet>
        <Box component="form" onSubmit={this.handleSubmit}>
          <Card >
            <Typography
              component="h1"
              variant="h1"
              textAlign={'center'}
              sx={{ width: '100%', }}
            >
              Reset Password
              {loading ? <Loader /> : null}
            </Typography>

            {/* ---Form--- */}
            <Box
              sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 2 }}
            >
              <FormControl>
                <FormLabel htmlFor="password">Password</FormLabel>
                <TextField 
                  name="password"
                  id="password"
                  value={password}
                  placeholder="Password"
                  type="password"
                  autoComplete="password"
                  onChange={this.handleChange}
                  fullWidth
                  variant="outlined"
                  size='large'
                  color={errors.password ? 'error' : 'primary'}
                  slotProps={{
                    input: {
                      endAdornment: <InputAdornment position="end"  >
                        <Typography color='error'>
                          *
                        </Typography>
                      </InputAdornment>,
                    },
                  }}
                />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="confirmPassword">Confirm Password</FormLabel>
                <TextField
                  
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={this.handleChange}
                  placeholder="Confirm Password"
                  type="password"
                  autoComplete="confirmPassword"
                  fullWidth
                  variant="outlined"
                  size='large'
                  color={errors.confirmPassword ? 'error' : 'primary'}
                  slotProps={{
                    input: {
                      endAdornment: <InputAdornment position="end"  >
                        <Typography color='error'>
                          *
                        </Typography>
                      </InputAdornment>,
                    },
                  }}
                />
              </FormControl>
            </Box>

            <Collapse in={!!this.state.alert}>
              <Alert
                severity={this.state.alert?.type} // dynamic severity (error, success, etc.)
                action={
                  <IconButton
                    aria-label="close"
                    size="small"
                    onClick={() => this.setState({ alert: null })} // Close the alert
                  >
                    <CancelIcon />
                  </IconButton>
                }
              >
                {this.state.alert?.message.split('\n').map((msg, index) => (
                  <Box key={index}>{msg}</Box> // Render each message on a new line
                ))}
              </Alert>
            </Collapse>


            {/* ---Button--- */}
            <Stack spacing={2.5} alignItems={'center'}>
              <Box>
                <Button
                  size='large'
                  color='primary'
                  variant='contained'
                  type="submit"
                  endIcon={<img src={SignInButtonIcon} alt="arrow-icon" />}
                  sx={{
                    justifyContent: 'space-between',
                    marginTop: '16px',
                    minWidth: '222px',
                  }}>Reset Password</Button>
              </Box>
            </Stack>
          </Card>
        </Box>
      </AuthLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  login: state.Login,
  // loading: state.Loader.loaderOne,
});

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      userLogin: Actions.loginRequest,
      resetPassword: Actions.resetPasswordRequest,
      // startLoading: Actions.startLoaderOne,
    },
    dispatch,
  );
export default connect(mapStateToProps, mapActionsToProps)(withCustomRouter(ResetPassword));
