import React from "react";
import { Box, keyframes } from "@mui/material";

const loadingFade = keyframes`
  0% { opacity: 0; }
  50% { opacity: 0.8; }
  100% { opacity: 0; }
`;

const ListLoader: React.FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        p: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "5px",
          padding: "6px 8px",
          borderRadius: "20px",
        }}
      >
        {[0, 0.2, 0.4].map((delay: number, index: number) => (
          <Box
            key={index}
            sx={{
              width: "10px",
              height: "10px",
              borderRadius: "50%",
              backgroundColor: "#5578FA",
              animation: `${loadingFade} 1s infinite`,
              animationDelay: `${delay}s`,
            }}
          />
        ))}
      </Box>
    </Box>
  );
};

export default ListLoader;
