export type MailType = 'TEAM-INBOX' | 'INBOX' | 'DRAFT' | 'SENT' | 'TRASH' | 'STARRED' | '';

export type Message = {
    id: string;
    labelIds: string[];
    payload: {
      body: any;
      filename: string;
      headers: { name: string; value: string }[];
      mimeType: string;
      partId: string;
      parts: any[];
      originalContent?: any;
    };
    sizeEstimate: number;
    snippet: string;
    threadId: string;
    draftId?: string;
    recipients?: {
      to: string[];
      cc: string[];
    };
    summary?: string;
  };

  export type Gmail = Message[];

  export type GmailData = {
    success?: boolean;
    message?: string;
    data?: { _id: string; email: string; messages: Message[]; isPinned: boolean; isPriority: boolean; }[];
    totalMailCount?: number;
  };

export const setMailTypeFromUrl = () => {
    const currentPath = location.pathname.split('/')[2];
    const internalTab = location.pathname.split('/')[3] as typeof internalTab;
    let mailType: MailType = 'INBOX';
    switch (currentPath) {
      case 'my-inbox':
        mailType = 'INBOX';
        break;
      case 'drafts':
        mailType = 'DRAFT';
        break;
      case 'sent':
        mailType = 'SENT';
        break;
      case 'trash':
        mailType = 'TRASH';
        break;
      case 'bookmarks':
        mailType = 'STARRED';
        break;
      case 'all-mails':
        mailType = '';
        break;
      default:
        break;
    }

    return {mailType, currentPath, internalTab}
  };

export const sampleMessage = {
    id: '',
    labelIds: [],
    payload: {
      body: {},
      filename: '',
      headers: [{ name: '', value: '' }],
      mimeType: '',
      partId: '',
      parts: [],
    },
    sizeEstimate: 0,
    snippet: '',
    threadId: '',
    draftId: '',
  };

