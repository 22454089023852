import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Box, Button, Stack, Tooltip } from '@mui/material';
import Grid from '@mui/material/Grid2';

import IconButton from '@mui/material/IconButton';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import NavigateBeforeOutlinedIcon from '@mui/icons-material/NavigateBeforeOutlined';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';
import moment from 'moment';
//@ts-ignore
import DocIcon from '../../../src/assets/images/icons/Doc-icon.svg';
import ClearIcon from '@mui/icons-material/Clear';
//@ts-ignore
import AddFile from '../../../src/assets/images/icons/attach_file_add.svg';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {
  xpAccountIntegrationDeleteDraft,
  xpAccountIntegrationDownloadAllAttachment,
  xpAccountIntegrationDownloadAttachment,
  xpAccountIntegrationGetMessagesForThread,
  xpAccountIntegrationGmailArchiveEmail,
  xpAccountIntegrationGmailArchiveThread,
  xpAccountIntegrationGmailDeleteEmail,
  xpAccountIntegrationGmailDeleteThread,
  xpAccountIntegrationGmailGetGmailId,
  xpAccountIntegrationGmailGetList,
  xpAccountIntegrationGmailGetThreadMessages,
  xpAccountIntegrationGmailMarkEmailAsBookmarked,
  xpAccountIntegrationGmailMarkEmailAsRead,
  xpAccountIntegrationGmailMarkEmailAsUnread,
  xpAccountIntegrationGmailMarkThreadAsRead,
  xpAccountIntegrationGmailMarkThreadAsUnread,
  xpAccountIntegrationGmailUnBookmarkEmail,
  xpAccountIntegrationThreadCreateDraft,
  xpAccountIntegrationThreadSendDraft,
  xpAccountIntegrationThreadSendReply,
  xpAccountIntegrationThreadUpdateDraft,
} from 'ReduxStore/API';
import { createNotification } from 'helpers';
import MarkEmailUnreadOutlinedIcon from '@mui/icons-material/MarkEmailUnreadOutlined';
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import StarRateRounded from '@mui/icons-material/StarRateRounded';
import ReplyIcon from '@mui/icons-material/Reply';
import ReactQuill from 'react-quill';
import SendIcon from '@mui/icons-material/Send';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import Loader from 'Components/Loader/loader';
import DraftComposeInThread from './DraftComposeInThread';
import EmailsLayout, { GmailData } from '.';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';


declare global {
  interface Window {
    myFunction?: () => void;
  }
}
interface AutoResizeIframeProps {
  getHtmlOfContent: () => string;
}

export type Message = {
  id: string;
  labelIds: string[];
  payload: {
    body: any;
    filename: string;
    headers: { name: string; value: string }[];
    mimeType: string;
    partId: string;
    parts: any[];
  };
  sizeEstimate: number;
  snippet: string;
  threadId: string;
  draftId?: string;
};

const sampleMessage = {
  id: '',
  labelIds: [],
  payload: {
    body: {},
    filename: '',
    headers: [{ name: '', value: '' }],
    mimeType: '',
    partId: '',
    parts: [],
  },
  sizeEstimate: 0,
  snippet: '',
  threadId: '',
  draftId: '',
};

type MailType = 'INBOX' | 'DRAFT' | 'SENT' | 'TRASH' | 'STARRED' | ''
type  Gmail = Message[];

// type EditorText = { [key: string]: string };
// type DraftIds = { [key: string]: string };
type DraftIds = string[];

const GmailView = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams();
  //@ts-ignore
  const modules: QuillToolbarModules = {
    toolbar: {
      container: [
        [{ header: '1' }, { header: '2' }, { font: [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ align: [] }], // Text alignment
        ['link', 'image'], // Default image handler
        ['attachment'], // Custom attachment button
        ['clean'], // Removes formatting
      ],
    },
  };

  const params = useParams()

  const [replyToAll, setReplyToAll] = useState<boolean>(false);

  const [showMore, setShowMore] = useState(false);
  const [gmail, setGmail] = useState<Gmail>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [draftId, setDraftId] = useState<DraftIds>([]);
  const [removedAttachments, setRemovedAttachments] = useState<string[]>([]);
  const [mailType, setMailType] = useState<MailType>('');
  const [thread, setThread] = useState<Message[]>([]);
  const [gmailId, setGmailId] = useState("");
  const [tempStart, setTempStart] = useState<number>(0);
  const [page_no, setPage_no] = useState<number>(1);
  const [selectedEmailIndex, setSelectedEmailIndex] = useState<number | null>(null);
  const [gmailData, setGmailData] = useState<GmailData>({});
  const [totalCount, setTotalCount] = useState<number>(null as number);
  const [limit, setLimit] = useState<number>(10);

  // useEffect(() => {
  //     setGmail(thread);
  //     thread.some((message) => message.labelIds.includes('UNREAD')) &&
  //       markThreadAsRead();

  // }, [thread]);

  // useEffect(() => {
  //   // findSnippetForDraft(gmail, false, 'didUpdate');
  // }, [gmail]);

  useEffect(() => {
    const type = setMailTypeFromUrl();
    // getThreadMessages(type)
    getGmailDetails()
    getGmailData(true, type)

    const mailNo = searchParams.get('mailNo');
    const pageNo = searchParams.get('pageNo');
    const totalCount = searchParams.get('totalCount');
    const limit = searchParams.get('limit');

    setPage_no(parseInt(pageNo))
    
    setSelectedEmailIndex(parseInt(mailNo) - 1)
    setTempStart(parseInt(mailNo) + (parseInt(pageNo) - 1) * parseInt(limit))
    setTotalCount(parseInt(totalCount))
    setLimit(parseInt(limit))
  }, []);

  // }, [location.pathname]);
  console.log(">>===========params", params)
  
  const getGmailDetails = async () => {
    try {
      const result: any = await xpAccountIntegrationGmailGetGmailId();
      if (result.status === 200 && result.data.success) {
        setGmailId(result.data.details.appData.user.email);
      }
    } catch (error) {
      console.log("error");
      if (error instanceof Error) {
        createNotification("error", error.message);
      } else {
        createNotification("error", "An unexpected error occurred.");
      }
    }
  };

  const setMailTypeFromUrl = () => {
    const currentPath = location.pathname.split('/')[3];
    let type: MailType;
    switch (currentPath) {
      case 'inbox':
        type = 'INBOX';
        break;
      case 'drafts':
        type = 'DRAFT';
        break;
      case 'sent':
        type = 'SENT';
        break;
      case 'trash':
        type = 'TRASH';
        break;
      case 'bookmark':
        type = 'STARRED';
        break;
      default:
        break;
    }
    setMailType(type);
    return type
  };

  const getThreadMessages = async (type: MailType) => {
    try {
      const data = {
        type: type,
        threadId: params.threadId
      }
      const res  = await xpAccountIntegrationGmailGetThreadMessages(data)
      console.log("==============res", res)
      if(res.data.success){
        setGmail(res.data.data.messages);
        res?.data?.data?.messages?.some((message) => message.labelIds.includes('UNREAD')) &&
          markThreadAsRead();
      } else {
        createNotification("error", "Something went wrong")
      }
      
    } catch (error) {
      console.log(error)
      createNotification("error", error.message)
    }
  }

  const getGmailData = async (showLoader = true, type) => {
    const pageNo = searchParams.get('pageNo') || 1
    const limit = searchParams.get('limit') || 10

    if (showLoader) {
      setIsLoading(true);
    }
    const queryParams = {
      page_no: pageNo,
      limit: limit,
      type: type || mailType,
    };
    const gmailDataResult: any = await xpAccountIntegrationGmailGetList(
      queryParams,
    );
    setGmail(gmailDataResult.data.data.find(msg => msg._id === params.threadId)?.messages);
    gmailDataResult.data.data.find(msg => msg._id === params.threadId)?.messages.some(message => message.labelIds.includes('UNREAD')) &&
    markThreadAsRead();

    // gmailDataResult?.data?.data?.some((message) => (message._id === params.threadId) && message.labelIds.includes('UNREAD')) &&
    //       markThreadAsRead();

    setIsLoading(false);
    if (gmailDataResult?.data) {
      setGmailData(gmailDataResult.data);
    }
  };

  console.log("=========mailType", mailType)
  const handleNextMail = async () => {
    try {
      console.log("@@=============selectedEmailIndex", selectedEmailIndex)
      console.log("@@=============limit", limit)
      // console.log("@@=============gmailData.data", gmailData.data)
      // setGmail(gmailData.data.findByIndex(msg => msg._id === params.threadId).messages);
      
      if (selectedEmailIndex === (limit - 1) && totalCount > tempStart) { // If the mail reaches the last mail of the current list
        setIsLoading(true);
        
        const queryParams = {
          page_no: page_no + 1,
          limit: limit,
          type: mailType,
        };
        const gmailDataResult: any = await xpAccountIntegrationGmailGetList(
          queryParams,
        );
        // console.log("=============gmailDataResult", gmailDataResult)
        if (gmailDataResult?.data) {
          const threadId = gmailData.data[0]._id
          setPage_no((prev) => prev + 1);
          setSelectedEmailIndex(0);
          // viewGmailContent(gmailDataResult.data.data[0].messages, 0, page_no + 1);
          setGmailData(gmailDataResult.data);
          setTotalCount(gmailDataResult.data.totalMailCount);
          setGmail(gmailDataResult.data.data[0].messages);
          setIsLoading(false);
          setTempStart(prev => prev + 1)
          navigate(`/emails/inbox/gmailView/${threadId}?pageNo=${page_no + 1}&limit=${limit}&mailNo=${1}&totalCount=${gmailData?.totalMailCount}`)

        }
      } else if (
        selectedEmailIndex !== null &&
        gmailData.data &&
        selectedEmailIndex < gmailData.data.length
        && totalCount > tempStart
      ) {
        const nextIndex = selectedEmailIndex + 1;
        const nextThreadId = gmailData.data[nextIndex]._id
        setGmail(gmailData.data[nextIndex].messages);
        gmailData.data.find(msg => msg._id === nextThreadId).messages.some(message => message.labelIds.includes('UNREAD')) && markThreadAsRead();
        setSelectedEmailIndex(nextIndex);
        setTempStart(prev => prev + 1)
        console.log("==============nextIndex", nextIndex)
        navigate(`/emails/inbox/gmailView/${nextThreadId}?pageNo=${page_no}&limit=${limit}&mailNo=${parseInt(searchParams.get("mailNo")) + 1}&totalCount=${gmailData?.totalMailCount}`)
        // viewGmailContent(gmailData.data[nextIndex].messages, nextIndex);
      }
    } catch (error) {
      console.log(error)
      createNotification("error", error.message)
    }
  };

  const handlePreviousMail = async () => {
    try {
      // const prevThreadId = gmailData.data[selectedEmailIndex - 2]._id
      // navigate(`/emails/inbox/gmailView/${prevThreadId}?pageNo=${page_no}&limit=${limit}&mailNo=${selectedEmailIndex - 1}&totalCount=${gmailData?.totalMailCount}`, {state: {gmailData: gmailData}})

      if (selectedEmailIndex === 0 && page_no > 1) {
        setIsLoading(true); // If the mail reaches the first mail of the current list
        const queryParams = {
          page_no: page_no - 1,
          limit: limit,
          type: mailType,
        };
        const gmailDataResult: any = await xpAccountIntegrationGmailGetList(
          queryParams,
        );
        if (gmailDataResult?.data) {
          const threadId = gmailData.data[limit - 1]._id
          setPage_no((prev) => prev - 1);
          setSelectedEmailIndex(limit - 1);
          // viewGmailContent(
          //   gmailDataResult.data.data[limit - 1].messages,
          //   limit - 1,
          //   page_no - 1,
          // );
          setGmailData(gmailDataResult.data);
          setTotalCount(gmailDataResult.data.totalMailCount);
          setGmail(gmailDataResult.data.data[limit - 1].messages);
          setIsLoading(false);
          setTempStart(prev => prev - 1)
          navigate(`/emails/inbox/gmailView/${threadId}?pageNo=${page_no - 1}&limit=${limit}&mailNo=${limit - 1}&totalCount=${gmailData?.totalMailCount}`)

        }
      } else if (
        selectedEmailIndex !== null &&
        gmailData.data &&
        selectedEmailIndex > 0
      ) {
        const previousIndex = selectedEmailIndex - 1;
        const previousThreadId = gmailData.data[previousIndex]._id
        setGmail(gmailData.data[previousIndex].messages);
        gmailData.data.find(msg => msg._id === previousThreadId).messages.some(message => message.labelIds.includes('UNREAD')) && markThreadAsRead();
        setSelectedEmailIndex(previousIndex);
        setTempStart(prev => prev - 1)

        navigate(`/emails/inbox/gmailView/${previousThreadId}?pageNo=${page_no}&limit=${limit}&mailNo=${parseInt(searchParams.get("mailNo")) - 1}&totalCount=${gmailData?.totalMailCount}`)

        // viewGmailContent(gmailData.data[previousIndex].messages, previousIndex);
      }
    } catch (error) {
      console.log("=========error", error)
      createNotification('error', error.message);
    }
  };

  const setGmailDataAfterUnBookmarkingEmail = (messageId) => {
    setGmailData(prev => {
      let modifiedData = prev.data.map(obj => {
        // Iterate through the messages array to find the message with the given messageId
        let updatedMessages = obj.messages.map(message => {
          if (message.id === messageId && message.labelIds.includes("STARRED")) {
            // Create a new message object with the updated labelIds (removing "STARRED")
            return {
              ...message,
              labelIds: message.labelIds.filter(label => label !== "STARRED"),
            };
          }
          return message; // Return the original message if no changes are needed
        });
    
        // Return a new object with the updated messages array
        return {
          ...obj,
          messages: updatedMessages,
        };
      });
    
      // Return the updated state
      return {
        ...prev,
        data: modifiedData,
      };
    });
  }

  const setGmailDataAfterBookmarkingEmail = (messageId) => {
    setGmailData(prev => {
      let modifiedData = prev.data.map(obj => {
        // Iterate through the messages array to find the message with the given messageId
        let updatedMessages = obj.messages.map(message => {
          if (message.id === messageId && !message.labelIds.includes("STARRED")) {
            // Create a new message object with the updated labelIds
            return {
              ...message,
              labelIds: [...message.labelIds, "STARRED"],
            };
          }
          return message; // Return the original message if no changes are needed
        });
    
        // Return a new object with the updated messages array
        return {
          ...obj,
          messages: updatedMessages,
        };
      });
    
      // Return the updated state
      return {
        ...prev,
        data: modifiedData,
      };
    }); 
  }

  const setGmailDataAfterUnbookmarkingMultipleEmails = (messageIds) => {
    setGmailData(prev => {
      let modifiedData = prev.data.map(obj => {
        // Iterate through the messages array to find the message with the given messageId
        let updatedMessages = obj.messages.map(message => {
          if (messageIds.includes(message.id) && message.labelIds.includes("STARRED")) {
            // Create a new message object with the updated labelIds (removing "STARRED")
            return {
              ...message,
              labelIds: message.labelIds.filter(label => label !== "STARRED"),
            };
          }
          return message; // Return the original message if no changes are needed
        });
    
        // Return a new object with the updated messages array
        return {
          ...obj,
          messages: updatedMessages,
        };
      });
    
      // Return the updated state
      return {
        ...prev,
        data: modifiedData,
      };
    });
  }


  const markEmailAsRead = async (id) => {
    try {
      const data = {
        messageId: id,
        gmailId: gmailId,
      };
      const res = await xpAccountIntegrationGmailMarkEmailAsRead(data);
      if (res.data.success) {
        // getGmailData(true, undefined);
      }
    } catch (error) {
      console.log('error', error);
      createNotification('error', error.message);
    }
  };

  const markEmailAsUnread = async (id) => {
    try {
      const data = {
        messageId: id,
        gmailId: gmailId,
      };
      const res = await xpAccountIntegrationGmailMarkEmailAsUnread(data);
      if (res.data.success) {
        // getGmailData(true, undefined);
        navigate(`/emails/${mailType.toLowerCase()}`)
        // closeGmailContent();
      } else {
        createNotification('error', res.data.message);
      }
    } catch (error) {
      console.log('error', error);
      createNotification('error', error.message);
    }
  };

  const markThreadAsRead = async () => {
    try {
      const data = {
        threadId: params.threadId,
        gmailId: gmailId,
      };
      const res = await xpAccountIntegrationGmailMarkThreadAsRead(data);
      // if (res.data.success) {
      //   getGmailData(true, undefined);
      // }
    } catch (error) {
      console.log('error', error);
      createNotification('error', error.message);
    }
  };
  const markThreadAsUnread = async () => {
    try {
      const data = {
        threadId: params.threadId,
        gmailId: gmailId,
      };
      const res = await xpAccountIntegrationGmailMarkThreadAsUnread(data);
      // if (res.data.success) {
      //   getGmailData(true, undefined);
      // }
    } catch (error) {
      console.log('error', error);
      createNotification('error', error.message);
    }
  };

  const archiveThread = async () => {
    try {
      const data = {
        threadId: params.threadId,
        gmailId: gmailId,
      };
      const res = await xpAccountIntegrationGmailArchiveThread(data);
      if (res.data.success) {
        navigate(`/emails/${mailType.toLowerCase()}`)
        // getGmailData(true, undefined);
        // closeGmailContent();
      }
    } catch (error) {
      console.log('error', error);
      createNotification('error', error.message);
    }
  };

  const deleteEmail = async (id) => {
    try {
      const data = {
        messageId: id,
        gmailId: gmailId,
      };
      const res = await xpAccountIntegrationGmailDeleteEmail(data);
      if (res.data.success) {
        setGmail((prev) => {
          let filteredArr = prev.filter((obj) => obj.id !== id);
          if (filteredArr.length === 0) {
            navigate(`/emails/${mailType.toLowerCase()}`)
            // closeGmailContent();
          }
          return filteredArr;
        });
        // getGmailData(true, undefined);
      }
    } catch (error) {
      console.log('error', error);
      createNotification('error', error.message);
    }
  };

  const deleteThread = async () => {
    try {
      setIsLoading(true)
      const data = {
        threadId: params.threadId,
        gmailId: gmailId,
      };
      const res = await xpAccountIntegrationGmailDeleteThread(data);
      if (res.data.success) {
        navigate(`/emails/${mailType.toLowerCase()}`)
        // closeGmailContent();
        // getGmailData(true, undefined);
      }
    } catch (error) {
      console.log('error', error);
      createNotification('error', error.message);
    } finally {
      setIsLoading(false)
    }
  };

  const getHtmlOfContent = (mail) => {
    if (
      mail.payload.mimeType === 'multipart/mixed' ||
      mail.payload.mimeType === 'multipart/alternative'
    ) {
      if (mail?.payload?.parts.length > 1) {
        if (mail.payload.parts[0]?.mimeType === 'multipart/alternative') {
          if (mail?.payload?.parts[0]?.parts?.length > 1) {
            if (mail?.payload?.parts[0]?.parts[0]?.mimeType === 'text/html') {
              return Buffer.from(
                mail?.payload?.parts[0]?.parts[0]?.body?.data,
                'base64',
              ).toString('ascii');
            } else if (
              mail?.payload?.parts[0]?.parts[1]?.mimeType === 'text/html'
            ) {
              return Buffer.from(
                mail?.payload?.parts[0]?.parts[1]?.body?.data,
                'base64',
              ).toString('ascii');
            }
          }
        } else {
          if (mail.payload?.parts[0]?.mimeType === 'text/html') {
            return Buffer.from(
              mail.payload?.parts[0]?.body?.data,
              'base64',
            ).toString('ascii');
          } else if (mail?.payload?.parts[1]?.mimeType === 'text/html') {           
            return Buffer.from(
              mail?.payload?.parts[1]?.body?.data,
              'base64',
            ).toString('utf-8');
          }
        }
      } else if (mail?.payload?.parts.length) {
        return Buffer.from(
          mail?.payload?.parts[0]?.body?.data,
          'base64',
        ).toString('ascii');
      } else {
        return '';
      }
    } else if (mail.payload.mimeType === 'text/html') {
      return Buffer.from(mail.payload?.body?.data, 'base64').toString('ascii');
    }
  };

  const downloadAttachment = async (messageId, filename, attachmentId) => {
    try {
      const data = {
        messageId,
        gmailId,
        filename,
        attachmentId,
      };
      await xpAccountIntegrationDownloadAttachment(data);
    } catch (error) {
      createNotification('error', error.message);
    }
  };

  // const downloadAllAttachments = async () => {
  //   try {
  //     const data = {
  //       messageId: gmail[0]?.id,
  //       gmailId,
  //       attachmentIds,
  //     };
  //     await xpAccountIntegrationDownloadAllAttachment(data);
  //   } catch (error) {
  //     createNotification('error', error.message);
  //   }
  // };

  const renderAttachments = (
    gmail,
    showRemoveAttachmentIcon = false,
    indexFromGmail,
  ) => {
    const { payload } = gmail;
    const { parts } = payload;

    if (
      payload.mimeType === 'multipart/mixed' &&
      Array.isArray(parts) &&
      parts.length > 1
    ) {
      return parts.map((part, i) => {
        if (
          i > 0 &&
          !removedAttachments[indexFromGmail]?.includes(part.partId)
        ) {
          return (
            <Box className="attachment-file" key={part.partId}>
              <Box display={'flex'} alignItems={'center'}>
                <Box className="file-icon">
                  <img src={DocIcon} alt="file-icon" />
                </Box>
                <Box>
                  <Box className="file-name">{part.filename}</Box>
                  <Box className="file-size">{part.body.size}</Box>
                </Box>
              </Box>
              <FileDownloadIcon
                onClick={() =>
                  downloadAttachment(
                    gmail.id,
                    part.filename,
                    part.body.attachmentId,
                  )
                }
                style={{ fontSize: '18px' }}
                className="options-button"
              />
              {showRemoveAttachmentIcon && (
                <Box ml={1} className="options">
                  <ClearIcon
                    onClick={() =>
                      handleRemovedAttachments(part.partId, indexFromGmail)
                    }
                    style={{ fontSize: '18px' }}
                  />
                </Box>
              )}
            </Box>
          );
        } else {
          return null;
        }
      });
    } else {
      return null;
    }
  };

  const splitHtmlAtGmailQuote = (htmlString) => {
    if(htmlString){
      // Regular expression to find the first occurrence of a div with the class "gmail_quote"
      const regex = /<div[^>]*class=["'][^"']*gmail_quote[^"']*["'][^>]*>/;

      // Find the position of the first match
      const match = htmlString?.match(regex);

      if (match && match.index !== undefined) {
        // Get the starting index of the matched <div> tag
        const splitIndex = match.index;
      
        // Split the string into two parts
        const part1 = htmlString.slice(0, splitIndex);
        const part2 = htmlString.slice(splitIndex);
      
        return { part1, part2 };
      } else {
        // If no match is found, return the original string as part1 and an empty string as part2
        return { part1: htmlString, part2: '' };
      }
    } else {
      return { part1: '', part2: '' }
    }
  };

  const handleSendOrDraft = async (status: string, i, mail: Message, editorText: string, fileNames: File[], removedAttachments: string[]) => {
    try {
      setIsLoading(true);
      const lastMail = gmail[i - 1];
      let sendTo;

      if (replyToAll) {
        sendTo = lastMail?.payload?.headers
          .filter(obj => ['To', 'Cc', 'cC', 'CC'].includes(obj.name) || (obj.name === 'From' && obj.value !== gmailId))
          .map((obj) => obj.value)
          .join(', ');
      } else {
        sendTo = lastMail?.payload?.headers.find(
          (obj) => obj.name === 'From',
        ).value;
      }
      const formData = new FormData();
      formData.append('threadId', lastMail.threadId);
      formData.append(
        'to',
        sendTo
        // lastMail?.payload?.headers.find((obj) => obj.name === 'From').value,
      );
      formData.append('from', gmailId);
      formData.append(
        'subject',
        lastMail?.payload?.headers.find((obj) => obj.name === 'Subject').value,
      );
      formData.append('htmlContent', editorText);

      if (
        lastMail?.payload?.headers.find((obj) => obj.name === 'From').value !==
        gmailId
      ) {
        // checking if user is not replying to self
        lastMail?.payload?.headers.find((obj) => obj.name === 'Message-ID')
          ?.value &&
          formData.append(
            'inReplyTo',
            lastMail?.payload?.headers.find((obj) => obj.name === 'Message-ID')
              .value,
          );
      } else {
        // checking if user is replying to self
        // the first mail of thread do not have In-Reply-To, therefore we append
        // Message-ID'a value to  In-Reply-To 
        lastMail?.payload?.headers.find((obj) => obj.name === 'In-Reply-To')
          ?.value ?
          formData.append(
            'inReplyTo',
            lastMail?.payload?.headers.find((obj) => obj.name === 'In-Reply-To')
              .value,
          ) :
          formData.append(
            'inReplyTo',
            lastMail?.payload?.headers.find((obj) => obj.name === 'Message-ID' || obj.name === 'Message-Id')
              .value,
          )
          ;
      }

      lastMail?.payload?.headers.find((obj) => obj.name === 'Cc')?.value &&
        formData.append(
          'cC',
          lastMail?.payload?.headers.find((obj) => obj.name === 'Cc').value,
        );
      lastMail?.payload?.headers.find((obj) => obj.name === 'Bcc')?.value &&
        formData.append(
          'bcC',
          lastMail?.payload?.headers.find((obj) => obj.name === 'Bcc').value,
        );

      Array.isArray(fileNames) &&
        fileNames.forEach((file: File) => {
          console.log('file in loop', file);
          formData.append('attachments', file);
        });

      Array.isArray(removedAttachments) &&
        removedAttachments.forEach((el) => {
          formData.append('removedAttachmentsPartId', el);
        });

      if (status === 'send') {
        let response: any = {};
        // if (Object.keys(draftId).length) {
        // if (Array.isArray(draftId) && draftId.length) {
        if (mail.draftId) {
          // formData.append('draftId', draftId[mail.id]);
          formData.append('draftId', mail.draftId);
          Array.isArray(removedAttachments) &&
            removedAttachments.forEach((el) => {
              formData.append('removedAttachments', el);
            });
          response = await xpAccountIntegrationThreadSendDraft(formData);
        } else {
          response = await xpAccountIntegrationThreadSendReply(formData);
        }
        if (response?.data?.status == 200) {
          createNotification('success', response?.data?.message);
          setGmail(prev => {
            let prevCopy = [...prev]
            prevCopy.splice(i, 1)
            return [...prevCopy, response.data.data]
          })
        }
      } else {
        let response: any = {};
        if (mail.draftId) {
          formData.append('draftId', mail.draftId);
          response = await xpAccountIntegrationThreadUpdateDraft(formData);
          if (response.data.success) {

          }
        } else {
          response = await xpAccountIntegrationThreadCreateDraft(formData);  
          if (response.data.success) {

          }
        }
        if (response.data.success) {
          setGmail(prev => {
            const updatedGmail = [...prev]; // Create a shallow copy of prev
            updatedGmail.splice(i, 1, response.data.data); // Replace the element at index `i`
            return updatedGmail; // Return the updated array
        });
        }
      }
    } catch (error) {
      createNotification('error', error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const getMessagesForThread = async (i) => {
    let data = {
      threadId: params.threadId,
      gmailId,
    };
    let res = await xpAccountIntegrationGetMessagesForThread(data);
    if (res.data.success && res.data.status == 200) {
      setGmail(res.data.messages);
    } else {
      createNotification('error', res.data.message);
    }
  };

  // -------------- shadow DOM

  const EmailContent = ({ getHtmlOfContent }) => {
    const shadowRef = useRef(null);

    useEffect(() => {
      if (!shadowRef.current) return;

      // Attach Shadow DOM
      const shadowRoot = shadowRef.current.attachShadow({ mode: 'open' });

      // Create a container for the email content
      const contentContainer = document.createElement('div');
      contentContainer.style.all = 'initial'; // Reset styles to avoid conflicts

      // Insert the email content (use getHtmlOfContent to fetch your email HTML)
      contentContainer.innerHTML = getHtmlOfContent() || '';

      // Append the container to the Shadow DOM
      shadowRoot.appendChild(contentContainer);

      // Clean up if necessary when the component unmounts
      return () => {
        shadowRoot.innerHTML = '';
      };
    }, [getHtmlOfContent]);

    return (
      // Ref to hold the Shadow DOM root
      <div
        ref={shadowRef}
        style={{ width: '100%', height: 'auto', border: 'none' }}
      />
    );
  };


  // useEffect(() => {
  //   if (showReplyCompose && boxRef.current) {
  //     // Scroll to the bottom when the box becomes visible
  //     boxRef.current.scrollIntoView({ behavior: 'smooth' });
  //   }
  // }, [showReplyCompose]); // Runs every time `isBoxVisible` changes

  const toggleShowMore = () => {
    setShowMore((prev) => !prev);
  };

  const handleRemovedAttachments = (partId, indexFromGmail) => {
    setRemovedAttachments((prev) => [...prev, partId]);
  };

  const handleDiscard = async (i, mail) => {
    if (mail.draftId) {
      const data = {
        draftId: mail.draftId,
        gmailId: gmailId,
      };
      const res = await xpAccountIntegrationDeleteDraft(data);
      if (res.data.status === 200 && res.data.success) {
        console.log("Discarded")        
      }
    }
    setGmail(prev => {
      const updatedGmail = [...prev];
      updatedGmail.splice(i, 1); // Remove the item at index `i`
      return updatedGmail;
    })
  };

  const handleOpenReplyCompose = (i, isReplyingToAll) => {
    setReplyToAll(isReplyingToAll);
    //@ts-ignore
    setGmail((prev) => {
      const newPrev = [...prev]; // Make a shallow copy of the array
      newPrev.splice(i + 1, 0, {
        ...sampleMessage,
        snippet: '',
        id: (i + 1).toString(),
        labelIds: ['DRAFT'],
      });
      return newPrev; // Return the updated array
    });
  };

  const renderSummary = (emails) => {
    if(emails && Array.isArray(emails) && emails.length){
      const threadFirstEmail = emails.find((m) => m.id === m.threadId);
      if (threadFirstEmail) {
        return <Stack
          spacing={1}
          className="account-details"
          mb={2}
          sx={{ minWidth: '600px', border: '1px solid #5141E7' }}
        >
          <Box className="subtitle">Email Summary</Box>
          <Box className="small-subtitle">{threadFirstEmail.summary} </Box>
        </Stack>;
      }
    }
  };

  const markEmailAsBookmarked = async (id) => {
    let prevState: Gmail;
    try {
      const data = {
        messageId: id,
        gmailId: gmailId,
      };
  
      // Save the previous state to roll back in case of error
      setGmail(prev => {
        prevState = [...prev]; // Make a shallow copy of the previous state
        return prev.map(mail => {
          if (mail.id === id) {
            if (!mail.labelIds.includes("STARRED")) {
              return {
                ...mail,
                labelIds: [...mail.labelIds, "STARRED"],
              };
            }
            return mail;
          }
          return mail;
        });
      });

      setGmailDataAfterBookmarkingEmail(id)
  
      const res = await xpAccountIntegrationGmailMarkEmailAsBookmarked(data);
  
      if (res.data.success) {
        // getGmailData(false, undefined); // Refresh the data if the API call is successful
      } else {
        // Rollback to the previous state if the API call fails
        setGmail(prevState);
        createNotification('error', res.data.message);
      }
    } catch (error) {
      console.log('error', error);
      // Rollback to the previous state in case of an error
      setGmail(prevState);
      createNotification('error', error.message);
    }
  };
  

  console.log("===========gmail", gmail)
  
  const unBookmarkEmail = async (id) => {
    let prevState: Gmail;
    try {
      const data = {
        messageId: id,
        gmailId: gmailId,
      };

      setGmail(prev => {
        prevState = [...prev];
        return prev.map(mail => {
          if (mail.id === id) {
            if (mail.labelIds.includes("STARRED")) {
              // Create a new copy of the mail object with "STARRED" removed
              return {
                ...mail,
                labelIds: mail.labelIds.filter(label => label !== "STARRED"),
              };
            }
            return mail; // Return the original mail if "STARRED" is not present
          }
          return mail; // Return mail unchanged for non-matching IDs
        });
      });

      setGmailDataAfterUnBookmarkingEmail(id)

      const res = await xpAccountIntegrationGmailUnBookmarkEmail(data);

      if (res.data.success) {  
        // getGmailData(false, undefined);
      } else {
        setGmail(prevState);
        createNotification('error', res.data.message);
      }
    } catch (error) {
      setGmail(prevState);
      console.log('error', error);
      createNotification('error', error.message);
    }
  };

  const canReplyToAll = (originalEmail) => {
    const allRecipients = originalEmail.headers.filter(obj => ['To', 'Cc', 'cC'].includes(obj.name) || (obj.name === 'From' && obj.value !== gmailId)).map(obj => obj.value)

    // Exclude the user's email
    const otherRecipients = allRecipients.filter(email => email !== gmailId || !email.includes(gmailId));
    let finalArray = []
    otherRecipients.forEach(email =>{
       email.split(',').forEach(el => (el !== gmailId && !el.includes(gmailId)) && finalArray.push(el.trim()))
      })
    // Check if there are other recipients
    return finalArray.length > 1;
  }
  
  return (
    <EmailsLayout>
      <Box>
      <Box
        pb={1}
        mt={-5}
        mb={2}
        sx={{ borderBottom: '1px solid #EDECF5' }}
        className="top-filter-area"
      >
        <Grid
          container
          justifyContent={'space-between'}
          spacing={1}
          width={'100%'}
          alignItems="center"
        >
          <Grid>
            <Grid container spacing={1} width={'100%'} alignItems="center">
              <Grid>
                <IconButton
                  size="medium"
                  title="Back"
                  // onClick={closeGmailContent}
                  onClick={()=> navigate(`/emails/${mailType.toLowerCase()}`)}
                >
                  <KeyboardBackspaceOutlinedIcon />
                </IconButton>
              </Grid>
              {mailType !== 'TRASH' && <Grid>
                <IconButton
                  size="medium"
                  title="Archive"
                  onClick={() => archiveThread()}
                >
                  <ArchiveOutlinedIcon />
                </IconButton>
              </Grid>}
              <Grid>
                <IconButton
                  title="Mark as unread"
                  onClick={() => markThreadAsUnread()}
                >
                  <MarkEmailUnreadOutlinedIcon />
                </IconButton>
              </Grid>
              <Grid>
                <IconButton
                  size="medium"
                  title="Delete"
                  onClick={() => deleteThread()}
                >
                  <DeleteOutlineOutlinedIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          {searchParams.get('pageNo') && searchParams.get('limit') && <Grid>
            <Grid container spacing={1} className="team-dash-right">
              <Grid>
                <span>
                  {tempStart} of {totalCount}
                </span>
              </Grid>
              <Grid>
                <IconButton
                  onClick={handlePreviousMail}
                  size="small"
                  title="Previous"
                >
                  <NavigateBeforeOutlinedIcon />
                </IconButton>
              </Grid>
              <Grid>
                <IconButton onClick={handleNextMail} size="small" title="Next">
                  <NavigateNextOutlinedIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>}
        </Grid>
      </Box>

      <Box className="subject subtitle" mb={2}>
        {
          gmail?.[0]?.payload?.headers.find((g: any) => g.name === 'Subject')
            .value
        }
      </Box>

      {Array.isArray(gmail) &&
        gmail.length > 0 &&
        gmail.map((mail, i) => {
          console.log('==========>>>>mail==========', mail);
          return !mail.labelIds.includes('DRAFT') ? (
            <Box
              className="email-container"
              key={mail.id}
              mb={2}
              sx={{ borderBottom: '1px solid #EDECF5' }}
            >
              <Stack
                direction={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
                className="header"
                pb={2}
              >
                <Box>
                  <Stack spacing={1} direction={'row'} alignItems={'center'}>
                    <PersonOutlineOutlinedIcon />
                    <Box>
                      <span className="bold">Sender Name </span>
                      <span>&lt;</span>
                      {
                        mail.payload.headers.find((g: any) => g.name === 'From')
                          .value
                      }
                      <span>&gt;</span>
                    </Box>
                  </Stack>
                  <Stack spacing={1} direction={'row'} alignItems={'center'}>
                    <WatchLaterOutlinedIcon />
                    <Box>
                      {moment(
                        mail.payload.headers.find((g: any) => g.name === 'Date')
                          .value,
                      ).format('dddd, MMMM DD, YYYY h:mm:ss A')}
                    </Box>
                  </Stack>
                </Box>
                <Grid container spacing={1}>
                  <Grid>
                    <IconButton
                      title="Mark as unread"
                      onClick={() => markEmailAsUnread(mail.id)}
                    >
                      <MarkEmailUnreadOutlinedIcon />
                    </IconButton>
                  </Grid>
                  <Grid>
                    <IconButton
                      title="Bookmark"
                      onClick={() => mail.labelIds.includes("STARRED") ? unBookmarkEmail(mail.id) : markEmailAsBookmarked(mail.id) }
                    >
                      {mail.labelIds.includes("STARRED") ? <StarRateRounded /> : <StarBorderRoundedIcon />}
                    </IconButton>
                  </Grid>
                  <Grid>
                    <IconButton
                      size="medium"
                      title="Delete"
                      onClick={() => deleteEmail(mail.id)}
                    >
                      <DeleteOutlineOutlinedIcon />
                    </IconButton>
                  </Grid>
                  {/* {snippet: '', id: (i+1).toString(), labelIds: ["DRAFT"] } */}
                  <Grid>
                    <IconButton
                      size="medium"
                      title="Reply"
                      onClick={() => {
                        // setShowReplyCompose(true);
                        handleOpenReplyCompose(i, false);
                      }}
                    >
                      <ReplyIcon />
                    </IconButton>
                  </Grid>
                  {canReplyToAll(mail.payload) && <Grid>
                    <IconButton
                      size="medium"
                      title="Reply To All"
                      onClick={() => {
                        // setShowReplyCompose(true);
                        handleOpenReplyCompose(i, true);
                      }}
                    >
                      <ReplyAllIcon />
                    </IconButton>
                  </Grid>}
                </Grid>
              </Stack>

              <Box className="email-body" pb={0} mb={2}>
                <EmailContent
                  getHtmlOfContent={() =>
                    splitHtmlAtGmailQuote(getHtmlOfContent(mail))?.part1
                  }
                />
                {splitHtmlAtGmailQuote(getHtmlOfContent(mail))?.part2?.length !==
                  0 && (
                  <button
                    onClick={toggleShowMore}
                    style={{
                      backgroundColor: '#edecf5',
                      border: 'none',
                      borderRadius: '10px',
                      cursor: 'pointer',
                      marginBottom: '10px',
                    }}
                  >
                    <MoreHorizIcon style={{ fontSize: '16px' }} />
                  </button>
                )}
                {showMore && (
                  <EmailContent
                    getHtmlOfContent={() =>
                      splitHtmlAtGmailQuote(getHtmlOfContent(mail))?.part2
                    }
                  />
                )}
              </Box>
              {
                // attachmentIds.length > 0 &&
                <Box className="attachment-container" mt={1}>
                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    className="attachment-header"
                  >
                    {/* <Box className="paragraph bold">
                      {attachmentIds.length} Attachments
                    </Box> */}
                    {/* <Box className="paragraph bold">
                      <Tooltip
                        placement="bottom-end"
                        arrow
                        title="Download Attachments"
                      >
                        <IconButton size="small">
                          <FileDownloadIcon
                            color="primary"
                            onClick={() => downloadAllAttachments()}
                          />
                        </IconButton>
                      </Tooltip>
                    </Box> */}
                  </Stack>
                  <Stack
                    spacing={1}
                    direction={'row'}
                    mt={1}
                    className="attachments"
                  >
                    {renderAttachments(mail, false, i)}
                  </Stack>
                </Box>
              }
            </Box>
          ) : (
            <Fragment key={i}>
              <DraftComposeInThread 
                i={i} 
                mail={mail} 
                handleSendOrDraft={handleSendOrDraft} 
                handleDiscard={handleDiscard} 
                gmailId={gmailId} 
                downloadAttachment={downloadAttachment} 
                modules={modules}
                />
            </Fragment>
          );
        })}
      {/* Email Summary */}
      {renderSummary(gmail)}
      {/* {
        showReplyOrQuillContainer && (!showReplyCompose ? (
        <Stack className="reply" direction="row" spacing={1}>
          <Button
            variant={'outlined'}
            color={'primary'}
            size={'small'}
            startIcon={<ReplyIcon />}
            onClick={() => {
              setShowReplyCompose(true);
              setReplyToAll(false);
            }}
          >
            Reply
          </Button>

          {Array.isArray(gmail) &&
            gmail.length > 0 &&
            gmail?.[gmail.length - 1].payload.headers
              .filter(
                (obj) =>
                  obj.name === 'To' || obj.name === 'Cc' || obj.name === 'Bcc',
              )
              .map((obj) => obj.value).length > 1 && (
              <Button
                variant={'outlined'}
                color={'primary'}
                size={'small'}
                startIcon={<ReplyAllIcon />}
                onClick={() => {
                  setShowReplyCompose(true);
                  setReplyToAll(true);
                }}
              >
                Reply To All
              </Button>
            )}
        </Stack>
      ) : (
        loadReactQuill()
        ))
      } */}
      {isLoading && <Loader />}
      </Box>
    </EmailsLayout>
  );
};

const AutoResizeIframe: React.FC<AutoResizeIframeProps> = ({
  getHtmlOfContent,
}) => {
  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  useEffect(() => {
    const iframe = iframeRef.current;
    if (iframe) {
      iframe.addEventListener('load', adjustHeight);

      // Trigger adjustment after srcDoc updates
      const adjustOnSrcDocChange = () => {
        adjustHeight();
      };

      adjustOnSrcDocChange(); // Initial adjustment

      return () => {
        iframe.removeEventListener('load', adjustHeight);
      };
    }
  }, [getHtmlOfContent]);

  const adjustHeight = () => {
    const iframe = iframeRef.current;
    if (iframe && iframe.contentWindow) {
      try {
        const iframeDocument =
          iframe.contentDocument || iframe.contentWindow.document;
        if (iframeDocument) {
          const height =
            iframeDocument.documentElement.scrollHeight ||
            iframeDocument.body.scrollHeight;
          iframe.style.height = `${height}px`;
        }
      } catch (error) {
        console.error('Error adjusting iframe height:', error);
      }
    }
  };

  return (
    <>
      <iframe
        ref={iframeRef}
        style={{ width: '100%', border: 'none' }}
        srcDoc={getHtmlOfContent() || ''}
      />

      {/* <button onClick={toggleQuote}>...</button> */}
    </>
  );
};

export default GmailView;
