import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Actions } from 'ReduxStore/Actions';
import { NotificationContainer } from 'react-notifications';
import Loader from '../../Components/Loader/loader';
import { createNotification } from '../../helpers';
import '../../sass/main.scss';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import qs from 'qs';
import AuthLayout from '../../Layouts/AuthLayout';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Alert, Collapse, IconButton, Stack } from '@mui/material';
import SignInButtonIcon from '../../assets/icons/SignInButtonIcon.svg';
import MicrosoftIcon from '../../assets/icons/microsoftIcon.svg';
import GoogleIcon from '../../assets/icons/googleIcon.svg';
import Card from '../../Layouts/AuthLayout/SignInCard';
import { generateUrl } from 'helpers/common';
import { CancelIcon } from '../../eijent/components/CustomIcons';

const URL = process.env.REACT_APP_API_URL;
const REACT_BACKEND_URL = process.env.REACT_BACKEND_URL;
const REACT_GOOGLE_CLIENT_ID = process.env.REACT_GOOGLE_CLIENT_ID;
const REACT_SSO_REDIRECT_URI = process.env.REACT_SSO_REDIRECT_URI;
const REACT_GOOGLE_AUTH_URL = process.env.REACT_GOOGLE_AUTH_URL;

class Login extends Component {
  constructor(props) {
    super(props);
    // window.location.href = Url.wixUrl;
    this.state = {
      email: '',
      password: '',
      isRemember: false,
      errors: {},
      loading: false,
    };
  }

  componentDidMount() {
    if (localStorage.isRemember && localStorage.email !== '') {
      this.setState({
        isRemember: true,
        email: localStorage.email,
        password: localStorage.password,
      });
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const { login } = this.props; // Get the login state from Redux
    const { loading } = this.state;

    // Handle successful login
    if (
      login &&
      login.success &&
      login.token &&
      (prevProps.login.success !== login.success || prevProps.login.token !== login.token)
    ) {
      // Stop loading if needed
      if (loading) {
        this.setState({ loading: false });
      }

      // Show success message
      // this.handleAlert('success', 'Login successful!');

      // Delay before redirecting
      // setTimeout(() => {
      const path = window.location?.pathname;
      const currentPath = path.split('/')[1];
      if (currentPath === 'xp') {
        this.props.navigate(generateUrl('/my-events'));
      } else {
        this.props.navigate('/dashboard');
      }
      // }, 2000); // 2-second delay
    }

    // Handle failed login
    if (
      login &&
      login.error &&
      loading &&
      (prevProps.login.error !== login.error || prevState.loading !== loading)
    ) {
      // Show error message
      this.handleAlert('error', login.message || 'Login failed.');

      // Stop loading
      this.setState({ loading: false });
    }
  }

  UserData = async () => {
    return JSON.parse(localStorage.getItem('user_details'));
  };

  getServicePlanId = async () => {
    try {
      const users = await this.UserData();
      const res = await axios.get(`${URL}/addServicePlanInIllumeetXP`, {
        headers: {
          token: `bearer ${users.token}`,
          'Content-Type': 'application/json',
        },
      });
      let servicePlanId;
      if (res.status === 200) {
        if (res.data.status === 200) {
          servicePlanId = res.data?.servicePlanId;
          let user_details = JSON.parse(localStorage.getItem('user_details'));
          user_details.servicePlanId = servicePlanId;
          localStorage.setItem("user_details", JSON.stringify(user_details));
        } else {
          console.log('Something went wrong');
        }
      } else {
        console.log('Something went wrong');
      }
    } catch (error) {
      return error;
    }
  };

  handleLoading = () => {
    let loading = this.state.loading;
    this.setState({
      loading: !loading,
    });
  };

  handleCheckBox = (event) => {
    this.setState({
      [event.target.name]: event.target.checked,
    });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  isLoginValid = () => {
    const { email, password } = this.state;
    let error = {};
    let formIsValid = true;
    const regexTest = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (email === '' || email.trim().length === 0) {
      formIsValid = false;
      error['email'] = '*Please enter email.';
    }
    if (email && regexTest.test(email) === false) {
      formIsValid = false;
      error['email'] = '*Please enter a valid email address.';
    }
    if (password === '' || password.trim().length === 0) {
      formIsValid = false;
      error['password'] = '*Please enter password.';
    }

    if (!formIsValid) {
      const errorMessages = Object.values(error).join('\n'); // Combine error messages with a newline
      this.handleAlert('error', errorMessages); // Trigger the alert
    }
    this.setState({ errors: error });

    return formIsValid;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.isLoginValid()) {
      const { email, password, isRemember } = this.state;
      if (isRemember) {
        localStorage.email = email;
        localStorage.password = password;
        localStorage.isRemember = isRemember;
      } else {
        localStorage.removeItem('email');
        localStorage.removeItem('password');
        localStorage.removeItem('isRemember');
      }
      const requestbody = { email, password };
      this.props.userLogin(requestbody);
      this.handleLoading();
    }
  };



  handleGoogleLogin = () => {
    const params = {
      client_id: REACT_GOOGLE_CLIENT_ID,
      redirect_uri: REACT_SSO_REDIRECT_URI,
      response_type: 'code',
      scope: 'openid email profile',
      state: 'unique-random-string',
      access_type: 'offline',
      prompt: 'consent',
    };

    const queryString = qs.stringify(params);
    const authUrl = `${REACT_GOOGLE_AUTH_URL}?${queryString}`;

    // Popup dimensions
    const popupWidth = 600;
    const popupHeight = 600;

    const left = (window.screen.width - popupWidth) / 2;
    const top = (window.screen.height - popupHeight) / 2;

    // Open the popup in the center of the screen
    const popup = window.open(
      authUrl,
      'Google SSO Login',
      `width=${popupWidth},height=${popupHeight},top=${Math.max(top, 0)},left=${Math.max(left, 0)},scrollbars=yes`
    );

    if (!popup || popup.closed || typeof popup.closed === 'undefined') {
      alert('Popup blocked! Please allow popups for this website.');
      return;
    }

    // Poll the popup to detect when it closes
    const popupTimer = setInterval(() => {
      if (popup.closed) {
        clearInterval(popupTimer);
        console.log('Popup closed by user');
      }
    }, 500);

    // Remove existing listener to prevent duplicate actions
    window.removeEventListener('message', this.handleSSOMessage);

    // Add the message listener
    window.addEventListener('message', (event) => {
      this.handleSSOMessage(event);
      // If the message has been acknowledged, close the timer
      if (event.data === 'acknowledged') {
        clearInterval(popupTimer);
      }
    });
  };

  handleSSOMessage = (event) => {
    //console.log('Received event:', event); // Log the event object for debugging 
    let origin = event?.origin; 
    if (!origin.includes("localhost")) {
      origin = origin.endsWith("/") ? origin : `${origin}/`;
    }
    console.log('origin:=======', origin);

    if (origin !== REACT_BACKEND_URL) {
      console.error('Invalid origin:', event.origin);
      return;
    } 

    if (event.data.type === 'sso-login-success') {
      console.log('SSO Login Successful:');
      //mihai told remvoe this success message redirect to landing page
      // this.handleAlert('success', 'Login successful!');
      const userDetails = event?.data?.user;
      localStorage.setItem('user_role', userDetails?.role);
      localStorage.setItem('user_details', JSON.stringify(userDetails));

      // Redirect to the landing page 
      const path = window.location?.pathname;
      const currentPath = path.split('/')[1];
      if (currentPath === 'xp') {
        this.props.navigate(generateUrl('/my-events'));
      } else {
        this.props.navigate('/dashboard');
      }
    } else if (event.data.type === 'sso-login-error') {
      console.error('SSO Login Error:', event.data.error);
      this.handleAlert('error', event?.data?.error?.message);
    }
  };

  handleAlert = (type, message) => {
    this.setState({
      alert: { type, message },
    });
  };

  render() {
    const { email, password, errors, loading, isRemember } = this.state;
    let path = window.location?.pathname;
    let currentPath = path.split('/')[1];
    return (
      <>
        <Helmet>
          <title>Eijent | Login</title>
        </Helmet>
        <AuthLayout>
          <Card>
            <Typography
              component="h1"
              variant="h1"
              textAlign={'center'}
              sx={{ width: '100%', }}
            >
              Login
              {loading ? <Loader /> : null}
            </Typography>

            {/* ---Form--- */}
            <Stack spacing={3}>
              <Box
                component="form"
                onSubmit={this.handleSubmit}
                noValidate
                sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 3 }}
              >
                <FormControl>
                  <FormLabel htmlFor="email" error={errors.email} >E-MAIL</FormLabel>
                  <TextField
                    id="email"
                    type="email"
                    name="email"
                    value={email}
                    placeholder="your@email.com"
                    onChange={this.handleChange}
                    autoComplete="email"
                    autoFocus
                    required
                    fullWidth
                    variant="outlined"
                    size="large"
                    color={errors.email ? 'error' : 'primary'}
                  />
                </FormControl>
                <FormControl>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <FormLabel htmlFor="password" error={errors.password}>PASSWORD</FormLabel>
                    <Link
                      component="button"
                      type="button"
                      onClick={
                        currentPath === 'xp'
                          ? () => this.props.navigate(generateUrl('/forgotPassword'))
                          : () => this.props.navigate('/forgotPassword')
                      }
                      variant="body2"
                      sx={{ alignSelf: 'baseline' }}
                    >
                      Forgot your password?
                    </Link>
                  </Box>
                  <TextField
                    name="password"
                    placeholder="••••••"
                    type="password"
                    id="password"
                    value={password}
                    autoComplete="current-password"
                    autoFocus
                    required
                    fullWidth
                    variant="outlined"
                    size='large'
                    color={errors.password ? 'error' : 'primary'}
                    onChange={this.handleChange}
                    sx={{ fontFamily: 'monospace !important' }}
                  />
                </FormControl>

                <Button
                  type="submit"
                  size='large'
                  fullWidth
                  variant="contained"
                  color='primary'
                  endIcon={<img src={SignInButtonIcon} alt="arrow-icon" />}
                  sx={{
                    justifyContent: 'space-between',
                    marginTop: '16px'
                  }}
                >
                  Sign in
                </Button>
                <Collapse in={!!this.state.alert}>
                  <Alert
                    severity={this.state.alert?.type} // dynamic severity (error, success, etc.)
                    action={
                      <IconButton
                        aria-label="close"
                        size="small"
                        onClick={() => this.setState({ alert: null })} // Close the alert
                      >
                        <CancelIcon />
                      </IconButton>
                    }
                  >
                    {this.state.alert?.message.split('\n').map((msg, index) => (
                      <Box key={index}>{msg}</Box> // Render each message on a new line
                    ))}
                  </Alert>
                </Collapse>


              </Box>
              <Divider> <Typography variant="body2" >or</Typography></Divider>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
                <Button
                  fullWidth
                  size='large'
                  variant="outlined"
                  color='secodary'
                  onClick={this.handleGoogleLogin}
                  //onClick={() => alert('Sign in with Google account')}
                  startIcon={<img src={GoogleIcon} alt="Google-icon" />}
                  sx={{
                    justifyContent: 'start'
                  }}
                >
                  Continue with Google
                </Button>
                {/*  <Button
                  fullWidth
                  size='large'
                  variant="outlined"
                  color='secodary'
                  onClick={() => alert('Sign in with Microsoft account')}
                  startIcon={<img src={MicrosoftIcon} alt="Microsoft-icon" />}
                  sx={{
                    justifyContent: 'start'
                  }}
                >
                  Continue with Microsoft account
                </Button> */}
              </Box>
            </Stack>

            {/* ---Button--- */}
            <Stack spacing={1.5} alignItems={'center'}>
              <Box>
                <Button size='large' color='secondary'
                  onClick={
                    currentPath === 'xp'
                      ? () => this.props.navigate(generateUrl('/register'))
                      : () => this.props.navigate('/register')
                  }
                  variant='contained'>CREATE YOUR ACCOUNT</Button>
              </Box>
              <Typography variant="body2" sx={{ textAlign: 'center' }}>
                By continuing, you are agreeing with &nbsp;
                <span>
                  <Link
                    href="#"
                    variant="body2"
                    sx={{ alignSelf: 'center', color: '#000000' }}
                  >
                    EIJENT Terms & <br /> Conditions &nbsp;
                  </Link>
                  and &nbsp;
                  <Link
                    href="#"
                    variant="body2"
                    sx={{ alignSelf: 'center', color: '#000000' }}
                  >
                    Privacy Policy.

                  </Link>
                </span>
              </Typography>
            </Stack>
          </Card>
          <NotificationContainer />
        </AuthLayout>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  login: state.Login,

  // loading: state.Loader.loaderOne,
});

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      userLogin: Actions.loginRequest,

      // startLoading: Actions.startLoaderOne,
    },
    dispatch,
  );
export default connect(mapStateToProps, mapActionsToProps)(Login);
