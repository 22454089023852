import React, { useState } from "react";
import { Popover, Box, Typography, Avatar, Stack, Button, Divider, AvatarGroup, ButtonGroup } from "@mui/material";
import {
  ArrowDownIcon,
  AddNewIcon,

  HomeIconActive,
  InboxIconActive,
  TasksIconActive,
  OpportunitiesIconActive,
  AccountsIconActive,
  ContactsIconActive,
} from './CustomIcons';

// import NewWorkspace from "./NewWorkspace";
import NewJourney from "eijent/NewJourney";

export default function NewPopup() {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const NewItemsList = [
    // { name: 'Project', icon: <HomeIconActive /> },
    { name: 'Message', icon: <InboxIconActive /> },
    { name: 'Task', icon: <TasksIconActive /> },
    { name: 'Team', icon: <OpportunitiesIconActive /> },
    { name: 'Portfolio', icon: <AccountsIconActive /> },
    { name: 'Goal', icon: <ContactsIconActive /> },
    { name: 'Meeting', icon: <TasksIconActive /> },
  ];

  return (
    <div>
      {/* New Button */}
      <ButtonGroup disableElevation sx={{ borderColor: '#fff', }} size='large' color='primary' variant="contained" aria-label="Basic button group">
        <Button
          color='primary'
          variant="contained"
          size='large'
          sx={{
            paddingLeft: 1.5,
            borderRightColor: '#fff !important',
          }}
          startIcon={
            <Box sx={{ width: 38, height: 38, backgroundColor: "#000", borderRadius: 20, }}>
              <AddNewIcon />
            </Box>
          }
        >
          New
        </Button>
        <Button
          onClick={handleClick}
          sx={{
            width: 52,
            borderBottomLeftRadius: open ? "0px" : 8,
            borderBottomRightRadius: open ? "0px" : 8,
            transitionDuration: '200ms',
            "&::before": {
              content: '""',
              position: "absolute",
              bottom: open ? "-1px" : 0,
              left: "0",
              right: '0',
              height: open ? "1px" : 0,
              transitionDuration: '200ms',
              backgroundColor: "#5578FA",
              borderTopLeftRadius: "0px",
              borderTopRightRadius: "0px",

            },
          }}
        >
          <ArrowDownIcon />
        </Button>
      </ButtonGroup>

      {/* New list Popup */}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        slotProps={{
          paper: {
            sx: {
              marginTop: '1px',
              backgroundColor: '#5578FA',
              boxShadow: '0px 3px 6px #00000029',
              py: 2,
              borderTopRightRadius: 0,
            },
          },
        }}
      >
        <Stack className={'new-list'}

          sx={{
            overflow: 'auto',
            maxHeight: 'calc(100vh - 150px)',

          }}
        >

          <NewJourney />

          {NewItemsList.map((item, index) => (
            <Box
              key={index}
              sx={{
                width: 158,
                cursor: 'pointer',
                transitionDuration: '200ms',
                '&:hover': {
                  backgroundColor: 'rgb(0 0 0 / 5%)',
                }
              }}
              pl={4}
              pr={2}
              py={1.5}
              className={'new-list-item'}
            >
              <Stack direction={'row'} alignItems={'center'} gap={2.25} >
                {item.icon}

                <Typography variant="subtitle2" sx={{ fontWeight: 400, lineHeight: '16px', color: '#fff' }} >
                  {item.name}
                </Typography>
              </Stack>

            </Box>
          ))}


        </Stack>
      </Popover>
    </div>
  );
}
