import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Actions } from 'ReduxStore/Actions';
import Login from '../Pages/Login';
import { Url, showLogin } from '../constants/constants';
import { UserData } from 'helpers/common';
import withCustomRouter from '../Common/withCustomRouter';
import ForgotPassword from '../Pages/ForgotPassword';
import Register from '../Pages/Register';
import ConfirmAccount from '../Pages/ConfirmAccount';
import ResetPassword from '../Pages/ResetPassword';
import Home from 'eijent/internals/Home';
import PrivateUserRoute from './privateUserRoutes';
import PageNotFound from 'Components/PageNotFound';
import AiChatbot from 'Pages/AIChatbot';
import FileUpload from 'Pages/Files/FileUpload';
import { GmailProvider } from '../eijent/internals/Inbox/context/GmailContext';
import InboxRoutesWrapper from './InboxRoutesWrapper';
import { NotificationContainer } from 'react-notifications';

class EijentRoutes extends React.Component {
  constructor(props) {
    super(props);
    this.changeXpUsersWebSocketConnectionStatus =
      this.changeXpUsersWebSocketConnectionStatus.bind(this);
    this.handleWebSocketMessage = this.handleWebSocketMessage.bind(this);

    this.signalInterval = null;
    this.state = {
      xpUsersWebSocketConnectionCalled: false,
    };
  }

  componentDidMount() {
    this.startWSConnectionProcess()
  }

  componentDidUpdate(prevProps) {
    this.startWSConnectionProcess()
  }

  startWSConnectionProcess(){
    if (!this.state.xpUsersWebSocketConnectionCalled) {
      if (UserData()) {
        const user = UserData();
        this.connectWS(user.idToken);
        this.setState({ xpUsersWebSocketConnectionCalled: true });
      }
    }
  }

  connectWS(token) {
    let webSocketUrl = process.env.REACT_APP_AWS_USER_WEBSOCKET_URL;
    if (token) {
      webSocketUrl += `?xpToken=bearer ${token}`;
    }
    let socket = new WebSocket(webSocketUrl);
    socket.onopen = (e) => this.handleWebSocketOpen(e, socket);

    socket.onmessage = this.handleWebSocketMessage;

    socket.onclose = (e) => this.handleWebSocketClose(e, token);

    socket.onerror = function (error) {
      console.error('xp users WebSocket error:', error);
    };
  }

  handleWebSocketOpen(event, socket) {
    console.log('xp users WebSocket connection established.');
    this.changeXpUsersWebSocketConnectionStatus(true);
    this.signalInterval = setInterval(() => {
      console.log('after 9 minutes');
      socket.send(
        JSON.stringify({ action: 'ping', message: 'hi, from client' }),
      );
    }, 1000 * 60 * 9); //60*9
  }

  handleWebSocketMessage(event) {
    let data = JSON.parse(event.data);
    if (data.type === 'eijentNotification') {
      this.props.eijentNotificationListSuccess(data);
    }else if(data.type === 'smartNudges') {
      this.props.smartNudgesNotificationListSuccess(data);
    }else {
      this.props.setXpUsersWebSocketConnectionMessage(JSON.parse(event.data));
    }
  }

  handleWebSocketClose(event, token) {
    if (this.signalInterval) {
      clearInterval(this.signalInterval);
    }
    this.changeXpUsersWebSocketConnectionStatus(false);
    if (event.wasClean) {
      console.log(
        `xp users WebSocket - [close] Connection closed cleanly, code=${event.code} reason=${event.reason}`,
      );
    } else {
      this.connectWS(token);
    }
  }

  changeXpUsersWebSocketConnectionStatus(status) {
    this.props.changeXpUsersWebSocketConnectionStatus(status);
  }

  privateRoute = (path, Component) => {
    return (
      <Route
        path={path}
        element={
          <PrivateUserRoute
            element={
              <Component
                navigate={this.props.navigate}
                location={this.props.location}
                params={this.props.params}
              />
            }
          />
        }
      />
    );
  };

  inboxRoutes = () => {
    return (
      <Route
        path="/inbox/*"
        element={
          <GmailProvider>
            <InboxRoutesWrapper />
          </GmailProvider>
        }
      />
    );
  };

  render() {
    return (
      <>
        <Routes>
          <Route
            path="/"
            element={
              UserData() ? (
                <Navigate to="/dashboard" />
              ) : showLogin ? (
                <Login
                  navigate={this.props.navigate}
                  location={this.props.location}
                  params={this.props.params}
                />
              ) : (
                <Navigate to={Url.wixUrl} />
              )
            }
          />

          <Route
            path="/my-events"
            element={
              UserData() ? <Navigate to="/xp/my-events" /> : <Navigate to="/" />
            }
          />
          <Route
            path="/forgotPassword"
            element={
              <ForgotPassword
                navigate={this.props.navigate}
                location={this.props.location}
                params={this.props.params}
              />
            }
          />
          <Route
            path="/register"
            element={
              <Register
                navigate={this.props.navigate}
                location={this.props.location}
                params={this.props.params}
              />
            }
          />
          <Route
            path="/user/confirm-account"
            element={
              <ConfirmAccount
                navigate={this.props.navigate}
                location={this.props.location}
                params={this.props.params}
              />
            }
          />
          <Route
            path="/resetPassword/:token"
            element={
              <ResetPassword
                navigate={this.props.navigate}
                location={this.props.location}
                params={this.props.params}
              />
            }
          />

          {this.privateRoute('/dashboard', Home)}
          {this.inboxRoutes()}
          {/* Ai chat boat urls */}
          {this.privateRoute('/aichatbot/general/:id', AiChatbot)}
          {this.privateRoute('/aichatbot/chatwidget/:id', AiChatbot)}
          {this.privateRoute('/aichatbot/settings/:id', AiChatbot)}
          {this.privateRoute('/aichatbot/dashboard/:id', AiChatbot)}
          {this.privateRoute('/aichatbot/integrations/:id', AiChatbot)}
          {/* Drive urls */}
          {this.privateRoute('/files/home', FileUpload)}
          {this.privateRoute('/files/images', FileUpload)}
          {this.privateRoute('/files/docs', FileUpload)}
          {this.privateRoute('/files/videos', FileUpload)}
          {this.privateRoute('/files/images/:folderId*', FileUpload)}
          {this.privateRoute('/files/home/:folderId*', FileUpload)}
          {this.privateRoute('/files/videos/:folderId*', FileUpload)}
          {this.privateRoute('/files/docs/:folderId*', FileUpload)}
          <Route path="/404" element={<PageNotFound />} />
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Routes>
        <NotificationContainer />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  proutes: state.Proutes,
});

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      changeXpUsersWebSocketConnectionStatus:
        Actions.changeXpUsersWebSocketConnectionStatus,
      setXpUsersWebSocketConnectionMessage:
        Actions.setXpUsersWebSocketConnectionMessage,
      eijentNotificationListSuccess: Actions.eijentNotificationListSuccess,
      smartNudgesNotificationListSuccess: Actions.smartNudgesNotificationListSuccess,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(withCustomRouter(EijentRoutes));
