import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { Actions } from 'ReduxStore/Actions'
import { connect } from 'react-redux' 
import { ThemeProvider } from '@mui/material';

import SearchIcon from '../../../assets/images/icons/search.svg'
import ImportIcon from '../../../assets/images/icons/import.svg'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import '../../../sass/main.scss'
import CloseIcon from '@mui/icons-material/Close'
import Grid from '@mui/material/Grid2'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { Modal } from 'react-responsive-modal'
import { NotificationContainer } from 'react-notifications'
import Loader from '../../../Components/Loader/loader'
import Pagination from 'react-js-pagination'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { createNotification } from '../../../helpers'
import ExportIcon from '../../../assets/images/icons/export.svg'
import RightArrowIcon from '../../../assets/images/icons/right_arrow_icon.svg'

import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
} from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'
import { getProjectIdFromUrl } from '../../../helpers/common'
import Exporting from '../../../assets/images/export.gif';
import { authPermissionValidation } from 'helpers/permissionsHelper';
import { permissionsConstants } from 'constants/permissionsConstant';


export class GamificationChallenges extends Component {
  constructor(props) {
    super(props)

    this.state = {
      challengesList: [],
      searchText: '',
      page_no: 1,
      loading: false,
      itemCount: 10,
      startCount: 1,
      endCount: 0,
      limit: 10,
      sort_by: 'updatedAt',
      order: 'desc',
      openEngagedUser: false,
      engagedUser: [],
      loadMoreNumber: 0,
      engagedUsersArr: [],
      disableLoadMorePoints: true,
      increaseLoadMoreBy: 20,
      challengeId: '',
      challengesEngagedUsers: [],
      isExport: false,
      sortByForEngagedUsers: 'updatedAt',
      downloadExport:false
    }
  }

  componentDidMount() {
    let projectId = getProjectIdFromUrl()
    this.setState(
      {
        projectId,
      },
      () => {
        this.getGamificationChallenges()
      },
    )
  }

  getGamificationChallenges = () => {
    const { page_no, searchText, projectId, limit, sort_by, sortOrder, isExport } =
      this.state
    const body = {
      page_no,
      searchText:searchText.trim(),
      projectId,
      limit,
      sort_by,
      order: sortOrder,
      isExport,
      permission:isExport === true ? "Export Gamification" : "View Gamification",

    }
    this.props.getGamificationChallenges(body)
  }

  exportChallenges = () => {
    this.setState({
      isExport: true,
      downloadExport:this.props.gamification?.totalResultsChallenges?.totalResults <=1000  ?  false:true,
      loading :this.props.gamification?.totalResultsChallenges?.totalResults <=1000? true:false,
    }, () => this.getGamificationChallenges())
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.itemCount !== this.state.itemCount) {
      if (this.state.itemCount < this.state.totalResults) {
        this.setState({
          endCount: parseInt(this.state.itemCount),
        })
      } else {
        this.setState({
          endCount: parseInt(
            this.props.gamification?.totalResultsChallenges?.totalResults,
          ),
        })
      }
    }
    if (
      this.props.gamification.challengesEngagedUsersSuccess === true &&
      this.props.gamification.challengesEngagedUsers !==
      prevProps.gamification.challengesEngagedUsers
    ) {
      this.setState(
        {
          challengesEngagedUsers:
            this.props.gamification.challengesEngagedUsers,
            loading:false
        },
        () => this.loadMoreEngagedUsers(),
      )
    }

    if (
      this.props.gamification.getGamificationChallengesSuccess === true &&
      this.props.gamification.challenges !== prevProps.gamification.challenges
    ) {
      // finding if the selected service plan allows to create more challenges
      let userDetails = JSON.parse(localStorage.getItem('user_details'))
      let reqData = {
        servicePlanId: userDetails.servicePlanId,
        projectId: this.state.projectId
      }
      this.props.findIfAllowedFromSuperadminRequest(reqData)
      // finding if the selected service plan allows to create more challenges ends

      this.setState(
        {
          challengesList: this.props.gamification.challenges,
          totalResults:
            this.props.gamification?.totalResultsChallenges?.totalResults,
          isExport: false,
          loading:false,
          downloadExport:false
        },
        () => {
          if (this.state.itemCount >= this.state.totalResults) {
            this.setState({
              endCount: parseInt(this.state.totalResults),
            })
          } else if (
            this.state.itemCount < this.state.totalResults &&
            this.state.page_no === 1
          ) {
            this.setState({
              endCount: parseInt(this.state.itemCount),
            })
          }
        },
      )
    }

    if (
      this.props.gamification.error === true &&
      this.props.gamification.error !== prevProps.gamification.error
    ) {
      createNotification('error', this.props.gamification.message)
      this.setState({
        loading:false
      })
    }
    // setTimeout(() => {
    //   if (this.state.loading) {
    //     this.handleLoading()
    //   }
    // }, 200)
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleSearchSubmit = (e) => {
    e.preventDefault()
      this.setState(
        {
          page_no: 1,
          isExport: false,
          startCount:1,
        },
        () => this.getGamificationChallenges(),
      )
  }

  handlePageChange = (pageNumber) => {
    const { itemCount, totalResults } = this.state
    this.setState(
      (prevState, props) => ({
        startCount: pageNumber !== 1 ? itemCount * (pageNumber - 1) + 1 : 1,
        endCount:
          totalResults > itemCount * pageNumber
            ? itemCount * pageNumber
            : totalResults,
        page_no: pageNumber,
        totalResults:
          this.props.gamification?.totalResultsChallenges?.totalResults,
      }),
      () => {
        this.getGamificationChallenges()
      },
    )
  }

  selectItemCount = (e) => {
    this.setState(
      {
        itemCount: e.target.value,
        limit: e.target.value,
        page_no: 1,
        startCount: 1,
      },
      () => this.getGamificationChallenges(),
    )
  }

  sorting = (value) => {
    if (this.state.sort_by === value) {
      if (this.state.sortOrder === 'asc') {
        this.setState(
          {
            sortOrder: 'desc',
          },
          () => this.getGamificationChallenges(),
        )
      } else {
        this.setState(
          {
            sortOrder: 'asc',
          },
          () => this.getGamificationChallenges(),
        )
      }
    } else {
      this.setState(
        {
          sort_by: value,
          sortOrder: 'asc',
        },
        () => {
          this.getGamificationChallenges()
        },
      )
    }
  }

  handleOpenEngagedUser = (challenges) => {
    this.setState(
      {
        challengeId: challenges._id,
        openEngagedUser: true,
        engagedUsersArr: challenges.engagedUsers,
      },
      () => this.challengesEngagedUsers(),
    )
  }

  closeEngagedUser = () => {
    this.setState({
      openEngagedUser: false,
      loadMoreNumber: 0,
      searchText: ''
    })
  }

  handleLoading = () => {
    let loading = this.state.loading
    this.setState({
      loading: !loading,
    })
  }

  loadMoreEngagedUsers = () => {
    const { challengesEngagedUsers, loadMoreNumber, increaseLoadMoreBy } =
      this.state

    this.setState(
      {
        loadMoreNumber:
          challengesEngagedUsers?.length >= loadMoreNumber + increaseLoadMoreBy
            ? loadMoreNumber + increaseLoadMoreBy
            : challengesEngagedUsers.length,
      },
      () => {
        this.setState({})
        const { loadMoreNumber } = this.state
        this.setState({
          disableLoadMorePoints:
            challengesEngagedUsers.length === this.state.loadMoreNumber
              ? true
              : false,
        })
        let engagedUser = []
        for (let i = 0; i < loadMoreNumber; i++) {
          engagedUser.push(challengesEngagedUsers[i])
        }

        this.setState({
          engagedUser,
        })
      },
    )
  }

  challengesEngagedUsers = () => {
    try {
      const { projectId, searchText, challengeId, sortByForEngagedUsers, order } = this.state
      const requestBody = {
        projectId,
        searchText:searchText.trim(),
        challengeId,
        sort_by: sortByForEngagedUsers,
        order
      }
      this.props.challengesEngagedUsers(requestBody)
      this.handleLoading()
    } catch (error) { }
  }

  searchEngagedUser = (e) => {
    e.preventDefault()
    
      this.setState(
        {
          page_no: 1,
        },
        () => this.challengesEngagedUsers(),
      )
  }

  render() {
    const {
      challengesList,
      searchText,
      itemCount,
      startCount,
      endCount,
      sort_by,
      sortOrder,
      openEngagedUser,
      engagedUser,
    } = this.state
    return (
      <div>
        <Box mt={2}>
          <Grid container spacing={2} mb={2} className="top-filter-area">
            <Grid >
              <Grid container spacing={1}>
                <Grid >
                  <Box className="subtitle">{this.props.users.languageStateForRedux?.column_names?.gamification?.challenges}</Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid >
              <Grid container spacing={1.25} className="team-dash-right">
                <Grid  className="">
                  <form onSubmit={this.handleSearchSubmit}>
                    <TextField
                      name="searchText"
                      variant='standard'
                      placeholder={this.props.users.languageStateForRedux?.actions?.gamification?.searchChallenges}
                      className="search"
                      size="small"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img alt="" src={SearchIcon} />
                          </InputAdornment>
                        ),
                      }}
                      value={searchText}
                      onChange={this.handleChange}
                    />
                  </form>
                </Grid>
                { authPermissionValidation(permissionsConstants.exportGamification) &&   
                  <Grid >
                    <Button
                      size="small"
                      variant="outlined"
                      color="primary"
                      endIcon={<img alt="" src={ExportIcon} />}
                      disableElevation
                      disabled={Array.isArray(challengesList) && challengesList?.length > 0 ? false: true}
                      onClick={() => this.exportChallenges()}>{this.props.users.languageStateForRedux?.common?.export}
                    </Button>
                  </Grid>
                }
              </Grid>
            </Grid>
          </Grid>
          <TableContainer className="list-table" style={{ maxHeight: 'calc(100vh - 400px)', }}>
            <Table className="table">
              <TableHead style={{ position: 'sticky', top: '0', backgroundColor: '#f6f6fc', zIndex: 2, }}>
                <TableRow>
                  <TableCell
                    className={
                      'text ' +
                      (sort_by === 'challengeNameForSorting'
                        ? sortOrder === 'asc'
                          ? 'sort-asc'
                          : 'sort-des'
                        : '')
                    }
                    onClick={() => this.sorting('challengeNameForSorting')}
                  >
                    {this.props.users.languageStateForRedux?.column_names?.gamification?.challenges}{' '}
                  </TableCell>
                  <TableCell
                    className={
                      'text ' +
                      (sort_by === 'totalUsers'
                        ? sortOrder === 'asc'
                          ? 'sort-asc'
                          : 'sort-des'
                        : '')
                    }
                    onClick={() => this.sorting('totalUsers')}
                  >
                    {this.props.users.languageStateForRedux?.column_names?.gamification?.numAttendees}
                  </TableCell>
                  <TableCell
                    className={
                      'text ' +
                      (sort_by === 'points'
                        ? sortOrder === 'asc'
                          ? 'sort-asc'
                          : 'sort-des'
                        : '')
                    }
                    onClick={() => this.sorting('points')}
                  >
                    {this.props.users.languageStateForRedux?.column_names?.gamification?.points}
                  </TableCell>                  
                  <TableCell className="text" align="right"></TableCell>
                </TableRow>
              </TableHead>

              {Array.isArray(challengesList) && challengesList?.length > 0 ? (
                <>
                  <TableBody>
                    {Array.isArray(challengesList) &&
                      challengesList.length &&
                      challengesList.map((challenges) => {
                        return (
                          <TableRow>
                            <TableCell 
                                style={{cursor: "pointer"}}
                                className="long-text" 
                                onClick={() =>
                                  this.handleOpenEngagedUser(challenges)
                                }>
                              {challenges.challengeName
                                ? challenges.challengeName
                                : '--'}
                            </TableCell>
                            <TableCell className="text bold">
                              {' '}
                              {challenges ? challenges.totalUsers : '--'}
                            </TableCell>
                            <TableCell className="text">
                              {challenges.points
                                ? challenges.points
                                : '--'}
                            </TableCell>
                            <TableCell className="text" align="right">
                              <IconButton
                                variant="text"
                                onClick={() =>
                                  this.handleOpenEngagedUser(challenges)
                                }
                              >
                                <img alt="arrow" src={RightArrowIcon} />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        )
                      })}
                  </TableBody>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    className="tablePagination"
                  >
                    <Grid >
                      <Box component="span" className="small-subtitle">
                      {this.props.users.languageStateForRedux?.pagination?.showing} {startCount} {this.props.users.languageStateForRedux?.pagination?.of}{' '}
                        {
                          this.props.gamification?.totalResultsChallenges
                            ?.totalResults
                        }
                      </Box>
                    </Grid>
                    <Grid  >
                      <Grid container alignItems="center">
                        <Grid  >
                          <span className="small-subtitle">{this.props.users.languageStateForRedux?.pagination?.shows}</span>
                          <select
                            value={itemCount}
                            onChange={this.selectItemCount}
                          >
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                          </select>
                          <span className="small-subtitle">{this.props.users.languageStateForRedux?.pagination?.entries}</span>
                        </Grid>
                        <Grid  >
                          <Pagination
                            activePage={this.state.page_no}
                            itemsCountPerPage={this.state.itemCount}
                            totalItemsCount={
                              this.props.gamification?.totalResultsChallenges
                                ?.totalResults
                            }
                            pageRangeDisplayed={5}
                            onChange={this.handlePageChange}
                            prevPageText={
                              <NavigateBeforeIcon style={{ fontSize: 18 }} />
                            }
                            nextPageText={
                              <NavigateNextIcon style={{ fontSize: 18 }} />
                            }
                            itemClassFirst="first d-none"
                            itemClassLast="last d-none"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              ) : ( !this.state.loading && challengesList?.length === 0 && this.props.gamification?.getGamificationChallengesSuccess &&
                (<TableBody>
                  <TableRow>
                    <TableCell style={{ textAlign: 'center' }}>
                    {this.props.users.languageStateForRedux?.common?.noRecords}
                    </TableCell>
                  </TableRow>
                </TableBody>)
              )}
            </Table>
          </TableContainer>
        </Box>
        <Modal
          open={openEngagedUser}
          onClose={this.closeEngagedUser}
          classNames={{
            overlay: 'full-screen_overlay',
            modal:
              'full-screen_modal manage-custom-fields modal-default-close-none',
          }}
          center
        >
          <Grid container justifyContent="end" width={"100%"}>
            <Grid
              size={{xs: 12, md:6,
              lg:4,
              xl:3}}
             
              className="full-screen_modal__form"
            >
              <Box component="div" className="full-screen_modal__form_header">
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid  >
                    <h3 className="subtitle">
                      Engaged Users ({' '}
                      {Array.isArray(engagedUser) &&
                        engagedUser[0]?.earnedPoints >0 ?
                        engagedUser.length:0}{' '}
                      )
                    </h3>
                  </Grid>
                  <Grid  >
                    <Grid container>
                      <Box component={Grid}  ml={1}>
                        <CloseIcon
                          onClick={this.closeEngagedUser}
                          className="modal-close-button"
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <Box my={3} px={3}>
                <form onSubmit={this.searchEngagedUser}>
                  <TextField
                    placeholder="Search..."
                    className="search"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img alt="" src={SearchIcon} />
                        </InputAdornment>
                      ),
                    }}
                    name="searchText"
                    variant='standard'
                    value={searchText}
                    onChange={this.handleChange}
                  />
                </form>
              </Box>

              <Box
                component="div"
                px={0}
                py={0}
                sx={{ maxHeight: 'calc(100vh - 150px)' }}
                className="full-screen_modal__form_body"
              >
                <Box className="" component={TableContainer}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          className="tableHeadCell"
                          style={{
                            backgroundColor: '#EDECF5',
                            padding: '16px 24px',
                          }}
                        >
                          Email Address
                        </TableCell>
                        <TableCell
                          className="tableHeadCell text"
                          align="right"
                          style={{
                            backgroundColor: '#EDECF5',
                            padding: '16px 24px',
                          }}
                        >
                          Earned Points
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {Array.isArray(engagedUser) && engagedUser?.length > 0  && engagedUser[0]?.earnedPoints >0 ? (
                      <>
                        <TableBody>
                          {Array.isArray(engagedUser) &&
                            engagedUser.length &&
                            engagedUser.map((users) => {
                              return (
                                <TableRow>
                                  <TableCell
                                    className="tableBodyCell"
                                    scope="row"
                                    style={{ padding: '16px 24px' }}
                                  >
                                    {users.email}
                                  </TableCell>
                                  <TableCell
                                    className="tableBodyCell text"
                                    align="right"
                                    style={{ padding: '16px 24px' }}
                                  >
                                    {users.earnedPoints}
                                  </TableCell>
                                </TableRow>
                              )
                            })}
                        </TableBody>
                      </>
                    ) : (
                      <TableBody>
                        <TableRow>
                          <TableCell
                            colSpan={2}
                            style={{ textAlign: 'center' }}
                          >
                            There are no records to display
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </Box>
              </Box>
              <Box className="full-screen_modal__form_footer">
                <Grid container alignItems="center" justifyContent="center">
                  <Grid size={{xs: "grow",}}>
                    <Grid container justifyContent="center">
                      <Grid >
                        <Button
                          disableElevation
                          variant="contained"
                          color="primary"
                          onClick={this.loadMoreEngagedUsers}
                          disabled={
                            this.state.disableLoadMorePoints ? true : false
                          }
                        >
                          Load More
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Modal>

        <Modal
            open={this.state.downloadExport}
            classNames={{
              overlay: '',
              modal: 'Invite-User-Modal modal-default-close-none',
              
            }}
            center
          >
            <Grid
              component="form"
              container
              alignItems="center"
              justifyContent="center"
            >
              <Grid size={{xs: 12,}} pb={2.6}>
                <Box textAlign="center" mt={1.6} mb={3}>
                  <img alt="" src={Exporting} width="92" height="92" />
                </Box>
                <Box
                  component="p"
                  textAlign="center"
                  mb={3}
                  className="subsecondary bold"
                >
                  Please wait while we securely <br/>
                       export your data!
                </Box>
                <Box component="p" textAlign="center" className="paragraph">
                  Your information is being processed and will be ready for<br/>
                      download shortly. Thank you for your patience!
                </Box>
              </Grid>
            </Grid>
          </Modal>
        {this.state.loading ? <Loader /> : null}
        <NotificationContainer />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  gamification: state.Gamification,
  events: state.Events,
  users: state.Users,
})

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      getGamificationChallenges: Actions.getGamificationChallengesRequest,
      challengesEngagedUsers: Actions.challengesEngagedUsersRequest,
      findIfAllowedFromSuperadminRequest: Actions.findIfAllowedFromSuperadminRequest,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(GamificationChallenges)
