require("dotenv").config();

export const Url = {
    apiLive: process.env.PROD_VARNISH_API_URL ? process.env.PROD_VARNISH_API_URL : 'https://varnish-api.illumeetxp.com/',
    wixUrl: 'https://www.illumeetxp.com',
    opportunityAttachmentUrl: `${process.env.REACT_APP_IMAGE_URL}/oppAttachments` 
};

//export const showLogin = process.env.REACT_APP_SHOWLOGIN === "true" ? true : false;
export const showLogin =  true ;
export const accessType = 'adminUserIllumeetxpApp';
export const trackingId = process.env.REACT_APP_GA_TRACKING_ID;
export const defaultThumbnail = 'default-thumbnail.png';
export const ACCESS_CODE_EXPERING_PERIOD = 5000000;

export const permissions = {
    createProject: 'Create Project',
    viewProject: 'View Project',
    editProject: 'Edit Project',
    deleteProject: 'Delete Project',
    publishUnpublishProject: 'Publish/Unpublish Project',
    createPage: 'Create Page',
    viewPages: 'View Pages',
    editPage: 'Edit Page',
    deletePage: 'Delete Page',
    editPageSettings: 'Edit Page Settings',
    createCampaign: 'Create Campaign',
    viewCampaigns: 'View Campaigns',
    editCampaign: 'Edit Campaign',
    deleteCampaign: 'Delete Campaign',
    createSession: 'Create Session',
    viewSessions: 'View Sessions',
    editSession: 'Edit Session',
    deleteSession: 'Delete Session',
    createNotification: 'Create Notification',
    viewNotifications: 'View Notifications',
    editNotification: 'Edit Notification',
    deleteNotification: 'Delete Notification',
    editMembers: 'Edit Members',
    viewMembers: 'View Members',
    createAttendee: 'Create Attendee',
    viewAttendees: 'View Attendees',
    editAttendee: 'Edit Attendee',
    deleteAttendee: 'Delete Attendee',
    checkInCheckOutAttendee: 'CheckIn & CheckOut Attendee',
    importAttendee: 'Import Attendee',
    exportAttendees: 'Export Attendees',
    viewOutboundEmailsReports: 'View Outbound Emails Reports',
    editCustomFields: 'Edit Custom Fields',
    editGroups: 'Edit Groups',
    editTransactionalEmailTemplates: 'Edit Transactional Email Templates',
    resetPassword: 'Reset Password',
    webhooks: 'Webhooks',
    appIntegrations: 'App Integrations',
    viewGamification: 'View Gamification',
    editGamification: 'Edit Gamification',
    exportGamification: 'Export Gamification',
    addManualPoints: 'Add Manual Points',
    viewLiveDashboard: 'View Live Dashboard',
    createAudience: 'Create Audience',
    viewAudience: 'View Audience',
    editAudience: 'Edit Audience',
    deleteAudience: 'Delete Audience',
    rolesPermission: 'Roles & Permission',
    users: 'Users',
    createAccessCodes: 'Create AccessCodes',
    viewAccessCodes: 'View AccessCodes',
    editAccessCodes: 'Edit AccessCodes',
    deleteAccessCodes: 'Delete AccessCodes',
    helpdeskAdmin: 'Helpdesk - Admin',
    helpdeskAgent: 'Helpdesk - Agent',
    addKioskUser: 'addKioskUser',
    viewBadge: 'viewBadge',
    createMeetingForAll: 'createMeetingForAll',
    createMeetingForMyTeam: 'createMeetingForMyTeam',
    createMeetingForMyOwn: 'createMeetingForMyOwn',
    viewAllMeetings: 'viewAllMeetings',
    viewMyTeamMeetings: 'viewMyTeamMeetings',
    viewMyOwnMeetings: 'viewMyOwnMeetings',
    editAllMeetings: 'editAllMeetings',
    editMyTeamMeetings: 'editMyTeamMeetings',
    editMyOwnMeeting: 'editMyOwnMeeting',
    deleteAllMeeting: 'deleteAllMeeting',
    deleteMyTeamMeetings: 'deleteMyTeamMeetings',
    deleteMyOwnMeeting: 'deleteMyOwnMeeting',
    meetingSetting: 'meetingSetting',
    createChatWidget: 'createChatWidget',
    viewChatWidget: 'viewChatWidget',
    editChatWidget: 'editChatWidget',
    deleteChatWidget: 'deleteChatWidget',
    embedOnSide: 'embedOnSide',
    createAccount: 'createAccount',
    viewAccount: 'viewAccount',
    editAccount: 'editAccount',
    deleteAccount: 'deleteAccount',
    accountCustomFields: 'accountCustomFields',
    createOpportunity: 'createOpportunity',
    viewOpportunity: 'viewOpportunity',
    editOpportunity: 'editOpportunity',
    deleteOpportunity: 'deleteOpportunity',
    assignOpportunity: 'assignOpportunity',
    changeOpportunityStatus: 'changeOpportunityStatus',
    opportunityCustomFields: 'opportunityCustomFields',
    createContacts: 'createContacts',
    viewContacts: 'viewContacts',
    editContacts: 'editContacts',
    deleteContacts: 'deleteContacts',
    contactsCustomFields: 'contactsCustomFields',
    createDrive: 'createDrive',
    editDrive: 'editDrive',
    deleteDrive: 'deleteDrive',
    viewDrive: 'viewDrive'
};



//permission = {"pid":1,"pid":2};


export const permissionForCognitoIdToken = {
    "Create Project": 1,
    "View Project": 2,
    "Edit Project": 3,
    "Delete Project": 4,
    "Publish/Unpublish Project": 5,
    "Create Page": 6,
    "View Pages": 7,
    "Edit Page": 8,
    "Delete Page": 9,
    "Edit Page Settings": 10,
    "Create Campaign": 11,
    "View Campaigns": 12,
    "Edit Campaign": 13,
    "Delete Campaign": 14,
    "Create Session": 15,
    "View Sessions": 16,
    "Edit Session": 17,
    "Delete Session": 18,
    "Create Notification": 19,
    "View Notifications": 20,
    "Edit Notification": 21,
    "Delete Notification": 22,
    "Edit Members": 23,
    "View Members": 24,
    "Create Attendee": 25,
    "View Attendees": 26,
    "Edit Attendee": 27,
    "Delete Attendee": 28,
    "CheckIn & CheckOut Attendee": 29,
    "Import Attendee": 30,
    "Export Attendees": 31,
    "View Outbound Emails Reports": 32,
    "Edit Custom Fields": 33,
    "Edit Groups": 34,
    "Edit Transactional Email Templates": 35,
    "Reset Password": 36,
    "Webhooks": 37,
    "App Integrations": 38,
    "View Gamification": 39,
    "Edit Gamification": 40,
    "Export Gamification": 41,
    "Add Manual Points": 42,
    "View Live Dashboard": 43,
    "Create Audience": 44,
    "View Audience": 45,
    "Edit Audience": 46,
    "Delete Audience": 47,
    // "": 48,
    // "": 49,
    // "": 50,
    // "": 51,
    // "": 52,
    // "": 53,
    "Roles & Permission": 54,
    "Users": 55,
    "Create AccessCodes": 56,
    "View AccessCodes": 57,
    "Edit AccessCodes": 58,
    "Delete AccessCodes": 59,
    "Helpdesk - Admin": 60,
    "Helpdesk - Agent": 61,
    "addKioskUser": 62,
    "viewBadge": 63,
    "createMeetingForAll": 64,
    "createMeetingForMyTeam": 65,
    "createMeetingForMyOwn": 66,
    "viewAllMeetings": 67,
    "viewMyTeamMeetings": 68,
    "viewMyOwnMeetings": 69,
    "editAllMeetings": 70,
    "editMyTeamMeetings": 71,
    "editMyOwnMeeting": 72,
    "deleteAllMeeting": 73,
    "deleteMyTeamMeetings": 74,
    "deleteMyOwnMeeting": 75,
    "meetingSetting": 76,
    "createChatWidget": 77,
    "viewChatWidget": 78,
    "editChatWidget": 79,
    "deleteChatWidget": 80,
    "embedOnSide": 81,
    "createAccount": 82,
    "viewAccount": 83,
    "editAccount": 84,
    "deleteAccount": 85,
    "accountCustomFields": 86,
    "createOpportunity": 87,
    "viewOpportunity": 88,
    "editOpportunity": 89,
    "deleteOpportunity": 90,
    "assignOpportunity": 91,
    "changeOpportunityStatus": 92,
    "opportunityCustomFields": 93,
    "createContacts": 94,
    "viewContacts": 95,
    "editContacts": 96,
    "deleteContacts": 97,
    "contactsCustomFields": 98,
    "createDrive": 99,
    "editDrive": 100,
    "deleteDrive": 101,
    "viewDrive": 102
};

export const userDataLocalStorage = localStorage.getItem('user_details');

 
//     1: "Create Project",
//     2: "View Project",
//     3: "Edit Project",
//     4: "Delete Project",
//     5: "Publish/Unpublish Project",
//     6: "Create Page",
//     7: "View Pages",
//     8: "Edit Page",
//     9: "Delete Page",
//     10: "Edit Page Settings",
//     11: "Create Campaign",
//     12: "View Campaigns",
//     13: "Edit Campaign",
//     14: "Delete Campaign",
//     15: "Create Session",
//     16: "View Sessions",
//     17: "Edit Session",
//     18: "Delete Session",
//     19: "Create Notification",
//     20: "View Notifications",
//     21: "Edit Notification",
//     22: "Delete Notification",
//     23: "Edit Members",
//     24: "View Members",
//     25: "Create Attendee",
//     26: "View Attendees",
//     27: "Edit Attendee",
//     28: "Delete Attendee",
//     29: "CheckIn & CheckOut Attendee",
//     30: "Import Attendee",
//     31: "Export Attendees",
//     32: "View Outbound Emails Reports",
//     33: "Edit Custom Fields",
//     34: "Edit Groups",
//     35: "Edit Transactional Email Templates",
//     36: "Reset Password",
//     37: "Webhooks",
//     38: "App Integrations",
//     39: "View Gamification",
//     40: "Edit Gamification",
//     41: "Export Gamification",
//     42: "Add Manual Points",
//     43: "View Live Dashboard",
//     44: "Create Audience",
//     45: "View Audience",
//     46: "Edit Audience",
//     47: "Delete Audience",
//     48: "",
//     49: "",
//     50: "",
//     51: "",
//     52: "",
//     53: "",
//     54: "Roles & Permission",
//     55: "Users",
//     56: "Create AccessCodes",
//     57: "View AccessCodes",
//     58: "Edit AccessCodes",
//     59: "Delete AccessCodes",
//     60: "Helpdesk - Admin",
//     61: "Helpdesk - Agent",
//     62: "addKioskUser",
//     63: "viewBadge",
//     64: "createMeetingForAll",
//     65: "createMeetingForMyTeam",
//     66: "createMeetingForMyOwn",
//     67: "viewAllMeetings",
//     68: "viewMyTeamMeetings",
//     69: "viewMyOwnMeetings",
//     70: "editAllMeetings",
//     71: "editMyTeamMeetings",
//     72: "editMyOwnMeeting",
//     73: "deleteAllMeeting",
//     74: "deleteMyTeamMeetings",
//     75: "deleteMyOwnMeeting",
//     76: "meetingSetting",
//     77: "createChatWidget",
//     78: "viewChatWidget",
//     79: "editChatWidget",
//     80: "deleteChatWidget",
//     81: "embedOnSide",
//     82: "createAccount",
//     83: "viewAccount",
//     84: "editAccount",
//     85: "deleteAccount",
//     86: "accountCustomFields",
//     87: "createOpportunity",
//     88: "viewOpportunity",
//     89: "editOpportunity",
//     90: "deleteOpportunity",
//     91: "assignOpportunity",
//     92: "changeOpportunityStatus",
//     93: "opportunityCustomFields",
//     94: "createContacts",
//     95: "viewContacts",
//     96: "editContacts",
//     97: "deleteContacts",
//     98: "contactsCustomFields",
//     99: "createDrive",
//     100: "editDrive",
//     101: "deleteDrive",
//     102: "viewDrive"
// };
  
export const FILE_SECTION = {
    images: "Images",
    videos: "Videos",
    docs: "Documents"
}

export const FILE_TYPE = {
    folder: "Folder",
    file:"File"
}

