import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
    eijentNotificationListRequest: ['data'], // Action for initiating the request, passing payload data
    eijentNotificationListSuccess: ['notificationList','totalResults'], // Action for a successful response, passing the notification list
    eijentNotificationListError: ['error'], // Action for handling errors, passing error details
    eijentNotificationListReset: ['action'], // Action to reset the notification list
    markNotificationRequest: ['data'], // Action for initiating the request, passing payload data
    markNotificationSuccess: ['message'], // Action for a successful response, passing the notification list
    markNotificationError: ['error'], // Action for handling errors, passing error details
    smartNudgesNotificationListRequest: ['data'], // Action for initiating the request, passing payload data
    smartNudgesNotificationListSuccess: ['smartNudgesNotification','totalResults'], // Action for a successful response, passing the notification list
    smartNudgesNotificationListError: ['error'], // Action for handling errors, passing error details
});
export const Constants = Types
export default Creators