import React, { Component } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { createNotification } from '../../../helpers';
import CheckedInOut from '../../../assets/images/checkedInOut.svg';
import InvalidIcon from '../../../assets/images/icons/invalid.svg';
import SettingUser from '../../../assets/images/icons/setting-user.svg';
import { styled } from '@mui/styles';
import { Link } from 'react-router-dom';
import { Modal } from 'react-responsive-modal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Actions } from 'ReduxStore/Actions';
import Grid from '@mui/material/Grid2';
import { Stack } from '@mui/material';
import { NotificationContainer } from 'react-notifications';
import { ACCESS_CODE_EXPERING_PERIOD } from '../../../constants/constants';
import { getRemaningTime } from '../../../Common/getRemaningTime';
import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { permissions } from '../../../constants/constants';
import UploadFromFilesPopUp from 'Common/UploadFromFilesPopUp';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import { ThemeProvider } from '@mui/styles';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { theme } from '../../../theme/theme';
import Checkbox from '@mui/material/Checkbox';
import MoreIcon from '../../../assets/images/icons/more.svg';
import moment from 'moment';
import emailIcon from '../../../assets/images/icons/email.svg';
import Loader from '../../../Components/Loader/loader';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import SearchIcon from '../../../assets/images/icons/search.svg';
import Fade from '@mui/material/Fade';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Pagination from 'react-js-pagination';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import CloseIcon from '@mui/icons-material/Close';
import ExportIcon from '../../../assets/images/icons/export.svg';
import Groups from './Groups';
import {
  getPreparePermission,
  getProjectIdFromUrl,
  convertTZ,
  UserData,
  generateUrl,
} from '../../../helpers/common';


import TransactionalEmails from './TransactionalEmails';
import BadgesQrCodeTemplate from './BadgesQrCodeTemplate';
import axios from 'axios';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import publicIP from 'react-native-public-ip';
import EmailAnalytics from '../EmailAnalytics';
import Avatar from '@mui/material/Avatar';
import userImageIcon from '../../../../src/assets/images/icons/userImageIcon.svg';
import ImgCrop from '../../demo/MyProfile/Crop';
import {
  deleteAttendeeImageAPI,
  UploadAttendeePic,
  ListBadgesTemplateAPI,
} from 'ReduxStore/API/index.js';
import SuccessAlertPopUp from 'Common/SuccessAlertPopUp';
import FormControl from '@mui/material/FormControl';
import ProgresBar from '../../../Common/ProgressBar';
import { default as ReactSelect, OnChangeValue } from 'react-select';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import PrintIcon from '@mui/icons-material/Print';
import informationIcon from '../../../assets/images/icons/informationIcon.svg';
import SuccessPopup from '../../../assets/images/successPopup.svg';
import Importing from '../../../assets/images/importing.svg';
import Exporting from '../../../assets/images/export.gif';
import { BadgeContent } from './BadgePrint/BadgeContent';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import getReturnValues from './BadgePrint/BadgePrintCss';
import GoogleWorkspace from '../../../assets/images/icons/Google_G_Logo.svg';
import KioskUserIcon from '../../../../src/assets/images/icons/KioskUserIcon.svg';
import { FILE_SECTION, FILE_TYPE } from "../../../constants/constants";
import withCustomRouter from '../../../Common/withCustomRouter';
import { authPermissionValidation } from 'helpers/permissionsHelper';
import { permissionsConstants } from 'constants/permissionsConstant';

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;


const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 250,
      marginTop: 4,
    },
  },
};
// const BACKEND_URL = process.env.REACT_APP_API_URL;
const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;
const IMAGE_URL2 = process.env.REACT_APP_FILE_MODULE_URL;
const section = FILE_SECTION.images;
const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-input': {
    padding: '12.5px 14px',
  },
});

const reactSelectStyles = {
  multiValueRemove: (base, state) => {
    return state.data.source === "GoogleWorkspace" ? { ...base, display: 'none' } : base;
  },
};

const styles = {
  avatar: {
    // backgroundColor: '#FF4170',
    width: '38px',
    height: '38px',
  },
  avatarKiosk: {
    backgroundColor: '#281374',
    width: '38px',
    height: '38px',
  },
  avatarWhite: {
    backgroundColor: '#FFF',
    width: '38px',
    height: '38px',
  },
  AddAvatar: {
    // backgroundColor: '#FF4170',
    width: '80px',
    height: '80px',
  },
  addAvatarWhite: {
    backgroundColor: '#FFF',
    width: '38px',
    height: '38px',
  },
};

class Attendees extends Component {
  progressInterval = 0;
  constructor(props) {
    super(props);
    this.chooseAttendeeProfileImage = React.createRef(null);
    this.anchorRef = React.createRef();
    this.anchorRef1 = React.createRef();
    this.anchorRef2 = React.createRef();
    this.anchorRef3 = React.createRef();
    this.anchorRef4 = React.createRef();
    this.anchorRef5 = React.createRef();
    this.anchorRef6 = React.createRef();
    this.anchorRefAction = React.createRef();
    this.checkBoxIdsArr = [];
    this.checkBoxforDisable = [];
    this.checkBoxIdsAccessCode = [];
    this.checkboxRef = React.createRef();
    this.badgePrintComponentRef = React.createRef();
    this.state = {
      open: false,
      openForm: false,
      projectId: '',
      fieldLabel: '',
      fieldType: '',
      fieldName: '',
      fieldId: '',
      errors: {},
      isSaveAndExit: false,
      isEdit: false,
      openConfirmation: false,
      isExists: false,
      fullName: '',
      email: '',
      accessCode: '',
      isSystemUser: false,
      openAttendeeForm: false,
      openAddNewForm: false,
      isSaveAndExitAttendeeForm: false,
      isSaveAndExitForKiosk: false,
      open1: false,
      open2: false,
      open3: false,
      open4: false,
      open5: false,
      openAction: false,
      openSettings: false,
      openAttendeesSettings: false,
      page_no: 1,
      searchText: '',
      groupid: '',
      loading: false,
      filterOptionsSelected: [],
      checkboxIds: [],
      isDeleteAttendeeBox: false,
      statusFilterOptionsList: ['Checked In', 'Checked Out', 'Not Checked In'],
      statusFilterOptionsSelected: [],
      sourceFilterOptionsList: [
        'Registration Page',
        'Imported',
        'Manually Added',
        'Webhooks',
        'Google Workspace',
        'Meeting'
      ],
      sourceFilterOptionsSelected: [],
      groupFilterOptionsList: [],
      groupFilterOptionsSelected: [],
      isActiveFilterOptionsList: ['true', 'false'],
      isSystemUserFilterOptionsList: ['true', 'false'],
      isActiveFilterOptionsSelected: [],
      isSystemUserFilterOptionsSelected: [],
      openEnabledModal: false,
      isDisabledButton: false,
      isGroupsComp: false,
      sort_by: 'updatedAt',
      sortOrder: 'desc',
      itemCount: 10,
      startCount: 1,
      page_no2: 1,
      selectedGroupId: '',
      changeState: false,
      openAssignGroupModel: false,
      isAssignGroupButton: false,
      groupAssigned: '',
      openUnassignGroupModel: false,
      isUnassignGroupButton: false,
      searchTextForGroup: '',
      openEditAttendeeModal: false,
      openPrintBadgeModal: false,
      openCheckedinHistoryAttendeeModal: false,
      emailForEdit: '',
      customFieldValue: '', 
      project: {},
      openCheckinModal: false,
      isCheckinButton: false,
      checkinDate: new Date(),
      initialDate: new Date(),
      dateSelected: '',
      checkBoxIdsArrState: [],
      pageHasChanged: false,
      checked: false,
      attendeeIdForEdit: '',
      attendeeEmailForEdit: '',
      customFieldsForEdit: {},
      openImportModal: false,
      attendeeCsv: '',
      openValidation: false,
      openSuccess: false,
      isTransactionEmailsComp: false,
      isBadgesComp: false,
      importSuccessMessage: '',
      getAttendeesLoading: false,
      resetPasswordToggle: false,
      forgotPasswordEmail: '',
      listValue: '',
      checkboxValue: '',
      showAttendeesProfile: false,
      editByAttendees: false,
      mandatoryAttendeesProfile: false,
      cropPic: '',
      uploadImage: '',
      confirmImage: '',
      outBoundEmailReport: false,
      outBoundEmailSelected: '',
      passwordType: '',
      openAssignAccessCode: false,
      getUnassignAccessCode: [],
      searchTextForAccessCode: '',
      limitForAccessCode: 20,
      loadMoreNumber: false,
      isAccessCodeAssign: false,
      isAccessCode: false,
      selectAllChecked: false,
      accessCodeCount: 0,
      uniqueAttendees: 0,
      sendTempPassIfPrivatePassword: false,
      totalAttendeesInTheSelectedOption: 0,
      googleWorkspaceArr: [],
      containsGoogleWorkspaceAttendee: false,
      sourceOfAttendeeForEdit: null,
      showProgress: false,
      progressPercentage: 0,
      importAttendeesId: 0,
      import: false,
      badgesTemplates: [],
      hasRenderedByBadges: false,
      selectedGroups: [],
      badgeOutput: "",
      badgePrintSettings: {},
      downloadExport: false,
      endCount: 0,
      kioskUserIncludedIncheckBoxIdsArr: false,
      department: '',
      uploadFromFiles: false,
      buttonState: false,
      fileNameInS3: "",
      destinationFolder: "",
      userId: "",
      token: "",
      displayS3Image: false,
      parentId: "",
      parentForPopup: "Attendees"
    };
  }

  componentDidMount = () => {
    const users = UserData();
    this.setState({ userId: users._id, token: users.token });

    if (this.props.location?.state?.hasRenderedByTransactionEmail) {
      this.setState({
        isTransactionEmailsComp: true,
      });
    }

    if (this.props.location?.state?.hasRenderedByBadges) {
      this.setState({
        isBadgesComp: true,
      });
    }
    const projectId = getProjectIdFromUrl();
    this.setState(
      {
        projectId,
      }
    );
    this.listCustomFields();

    this.listgroups();
    this.getBadges();
    this.handleLoading();

    this.props?.location?.state?.attendeeEmail
      ? this.setState(
        {
          searchText: this.props?.location?.state?.attendeeEmail,
        },
        () => {
          let { projectId, searchText, page_no } = this.state;
          let requestbody = {
            projectId,
            searchText: searchText.trim(),
            page_no,
          };
          this.props.searchAttendeesRequest(requestbody);
        },
      )
      : this.getAttendeesList();
  };

  checkprint = async () => {
  };

  listgroups = () => {
    try {
      const projectId = getProjectIdFromUrl();
      const { page_no2, searchTextForGroup } = this.state;
      const body = {
        projectId,
        page_no2,
        searchText: searchTextForGroup.trim(),
      };
      if (projectId) {
        this.props.listAttendeesGroups(body);
      } else {
        createNotification('error', 'project Id is missing');
      }
    } catch (error) {
      createNotification('error', 'Something went wrong');
    }
  };

  listAttendees = () => {
    try {
      const projectId = getProjectIdFromUrl();
      const permission = 'View Attendees';
      this.props.listAttendees({
        projectId,
        permission,
        page_no: this.state.page_no,
        limit: this.state.itemCount,
      });
      const totalResults = this.props.attendees.totalAttendeesRecords;
      this.setState({
        totalResults,
      });
    } catch (err) {
      createNotification('error', 'Something went wrong');
    }
  };

  assignAccessCode = () => {
    try {
      const projectId = getProjectIdFromUrl();
      let body = {};
      body = {
        projectId,
        attendeesId: this.state.attendeesId,
        attendeesEmail: this.state.email,
        accessCodeIds: this.checkBoxIdsAccessCode,
        isAccessCodeAssign: this.state.isAccessCodeAssign,
        lngCode: this.props.users.newLangState,
      };
      this.props.assignAccessCode(body);
      this.handleLoading();
    } catch (err) {
      createNotification('error', 'Something went wrong');
    }
  };

  getUnusedAccessCode = () => {
    try {
      const projectId = getProjectIdFromUrl();
      let body = {};

      body = {
        projectId,
        limitForAccessCode: this.state.limitForAccessCode,
        searchTextForAccessCode: this.state.searchTextForAccessCode,
      };
      this.props.getUnusedAccessCode(body);
      this.handleGetAttendeesLoading();
    } catch (err) {
      createNotification('error', 'Something went wrong');
    }
  };

  listCustomFields = () => {
    try {
      const projectId = getProjectIdFromUrl();
      let data = {
        projectId,
      };
      this.props.getCustomFields(data);
    } catch (err) {
      createNotification('error', 'Something went wrong');
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.endCount - this.state.startCount + (this.state.kioskUserIncludedIncheckBoxIdsArr ? 0 : 1) ===
      this.checkBoxIdsArr.length &&
      prevState.selectAllChecked === false &&
      this.checkBoxIdsArr.length > 0
    ) {
      this.setState({
        selectAllChecked: true,
        kioskUserIncludedIncheckBoxIdsArr: false
      });
    } else if (
      this.state.endCount - this.state.startCount + 1 !==
      this.checkBoxIdsArr.length &&
      prevState.selectAllChecked === true
    ) {
      this.setState({
        selectAllChecked: false,
      });
    }

    const filterData = prevProps.attendees.attendees.filter(
      (attendee, id) => attendee._id === this.state.attendeeIdForEdit,
    );
    if (
      filterData[0]?.profileImage !== undefined &&
      filterData[0]?.profileImage !== this.state.uploadImage
    ) {
    }

    if (
      this.props.attendees.editAttendeeSuccess === true &&
      this.props.attendees.message !== prevProps.attendees.message
    ) {
      createNotification('success', this.props.attendees.message);
      this.getAttendeesList();
      this.handleCloseEditAttendeeModal();
      this.setState({
        emailForEdit: '',
        cropPic: '',
        uploadImage: '',
        customFieldsForEdit: '',
        loading: false,
      });
    }
    if (this.props.attendees.addCustomFieldSuccess === true) {
      this.listCustomFields();
      createNotification('success', this.props.attendees.message);
      if (!this.state.isSaveAndExit) {
        this.setState({
          fieldLabel: '',
          fieldName: '',
          fieldType: '',
          listValue: '',
          checkboxValue: '',
          customFieldsForEdit: '',
          showAttendeesProfile: false,
          mandatoryAttendeesProfile: false,
          editByAttendees: false,
          loading: false,
        });
      } else {
        this.setState({
          fieldLabel: '',
          fieldName: '',
          fieldType: '',
          listValue: '',
          checkboxValue: '',
          loading: false,
        });
        this.handleCloseForm();
      }
    } else if (
      this.props.attendees.deleteCustomFieldSuccess === true &&
      prevProps.attendees.deleteCustomFieldSuccess === false &&
      this.props.attendees.message !== prevProps.attendees.message
    ) {
      this.setState({
        loading: false,
      });
      this.listCustomFields();
      createNotification('success', this.props.attendees.message);
    } else if (
      this.props.attendees.updateCustomFieldSuccess === true &&
      prevProps.attendees.updateCustomFieldSuccess === false &&
      this.props.attendees.message !== prevProps.attendees.message
    ) {
      this.setState({
        loading: false,
      });
      this.listCustomFields();
      createNotification('success', this.props.attendees.message);
    } else if (
      this.props.attendees.addAttendeesSuccess === true &&
      prevProps.attendees.addAttendeesSuccess === false &&
      this.props.attendees.message !== prevProps.attendees.message
    ) {
      this.getAttendeesList();
      if (this.state.isSaveAndExitForKiosk && this.state.isSystemUser) {
        this.saveAndAddNewForKiosk();
      }
      else if (this.state.isSaveAndExitAttendeeForm && !this.state.isSystemUser) {
        this.saveAndAddNew();
      } else {
        this.handleCloseAttendeeForm();
        this.setState({
          getAttendeesLoading: false,
        });
      }
      createNotification('success', this.props.attendees.message);
    } else if (
      this.props.attendees.deleteAttendeesSuccess === true &&
      prevProps.attendees.deleteAttendeesSuccess === false &&
      this.props.attendees.message !== prevProps.attendees.message
    ) {
      this.checkBoxIdsArr = [];
      this.checkBoxforDisable = [];

      this.handleCloseConfirmation();
      this.setState(
        {
          isDeleteAttendeeBox: false,
          loading: false,
          page_no: 1,
          startCount: 1,
        },
        () => this.getAttendeesList(),
      );
      createNotification('success', this.props.attendees.message);
    } else if (
      this.props.attendees.updateAttendeeRequest === true &&
      prevProps.attendees.updateAttendeeRequest === false &&
      this.props.attendees.message !== prevProps.attendees.message
    ) {
      this.getAttendeesList();
      createNotification('success', this.props.attendees.message);
    } else if (
      this.props.attendees.enabledStatusSuccess === true &&
      prevProps.attendees.enabledStatusSuccess === false &&
      this.props.attendees.message !== prevProps.attendees.message
    ) {
      this.setState({
        loading: false,
      });
      this.getAttendeesList();
      createNotification('success', this.props.attendees.message);
      this.handleCloseEnabledModal();
      this.checkBoxIdsArr = [];
      this.checkBoxforDisable = [];
    } else if (
      this.props.attendees.actionAssignSuccess === true &&
      prevProps.attendees.actionAssignSuccess === false &&
      this.props.attendees.message !== prevProps.attendees.message
    ) {
      this.setState({
        loading: false,
      });
      this.getAttendeesList();
      createNotification('success', this.props.attendees.message);
      this.handleCloseAssignGroupModal();
      this.handleCloseUnassignGroupModal();
      this.checkBoxIdsArr = [];
      this.checkBoxforDisable = [];
    } else if (
      this.props.attendees.actionCheckinSuccess === true &&
      prevProps.attendees.actionCheckinSuccess === false &&
      this.props.attendees.message !== prevProps.attendees.message
    ) {
      this.setState({
        loading: false,
      });
      this.getAttendeesList();
      createNotification('success', this.props.attendees.message);
      this.handleCloseopenCheckinModal();
      this.checkBoxIdsArr = [];
      this.checkBoxforDisable = [];
    } else if (
      this.props.attendees.error === true &&
      this.props.attendees.message !== prevProps.attendees.message
    ) {
      this.setState({
        loading: false,
        getAttendeesLoading: false,
      });
      createNotification('error', this.props.attendees.message);
    }

    if (
      this.props.attendees.searchAttendeesSuccess === true &&
      prevProps.attendees.searchAttendeesSuccess === false &&
      this.props.attendees.message !== prevProps.attendees.message
    ) {
      this.setState({
        getAttendeesLoading: false,
        loading: false,
      });
      this.updatePaginationDropDownState();
    }

    if (
      this.props.attendees.downloadCsvSuccess === true &&
      prevProps.attendees.downloadCsvSuccess === false &&
      this.props.attendees.message !== prevProps.attendees.message
    ) {
      this.setState({
        loading: false,
      });
    }

    if (
      this.props.attendees.sortFilterAttendeesSuccess === true &&
      prevProps.attendees.sortFilterAttendeesSuccess === false &&
      this.props.attendees.message !== prevProps.attendees.message
    ) {
      this.findIfAllowedFromSuperadminRequest(); // finding if the selected service plan allows to create more notifications

      this.updatePaginationDropDownState();

      this.setState({
        getAttendeesLoading: false,
      });

      this.calculateTotalAttendeesInTheSelectedOption('source', this.state.sourceFilterOptionsSelected, 'Source');
      this.calculateTotalAttendeesInTheSelectedOption('status', this.state.statusFilterOptionsList, 'Status');
      this.calculateTotalAttendeesInTheSelectedOption('group', this.state.groupFilterOptionsSelected, 'Group');
      this.calculateTotalAttendeesInTheSelectedOption('isActive', this.state.isActiveFilterOptionsSelected, 'IsActive');
    }

    if (
      this.props.attendees.importAttendeesSuccess === true &&
      prevProps.attendees.importAttendeesSuccess === false &&
      this.props.attendees.message !== prevProps.attendees.message
    ) {
      this.setState({
        openImportModal: false,
        openValidation: false,
        attendeeCsv: '',
        uniqueAttendees: this.props.attendees.uniqueAttendees,
        importAttendeesId: this.props.attendees?.importAttendeesId

      });
      if (this.props.attendees?.importAttendeesId) {
        let params = {
          projectId: this.state.projectId,
          importAttendeesId: this.props.attendees?.importAttendeesId
        };
        this.getProgressCountOfAttendees(params);
        this.setState({
          showProgress: true,
          import: true
        });
      } else {
        this.getAttendeesList();

      }
    }

    if (
      this.props.attendees.unUsedAccessCodeSuccess === true &&
      prevProps.attendees.unUsedAccessCodeSuccess === false &&
      this.props.attendees.message !== prevProps.attendees.message
    ) {
      this.setState({
        getUnassignAccessCode: this.props.attendees.getUnassignAccessCode,
        getAttendeesLoading: false,
      });
    }

    if (
      this.props.attendees.assignAccessCodeSuccess === true &&
      prevProps.attendees.assignAccessCodeSuccess === false &&
      this.props.attendees.message !== prevProps.attendees.message
    ) {
      createNotification('success', this.props.attendees.message);
      this.checkBoxIdsAccessCode = [];
      this.setState(
        {
          openAssignAccessCode: false,
          getUnassignAccessCode: [],
          limitForAccessCode: 20,
          searchTextForAccessCode: '',
          isAccessCodeAssign: false,
          loading: false,
          searchText: '',
          accessCodeCount: this.props.attendees?.accessCodeCount,
        },
        () => this.getAttendeesList(),
      );
    }

    if (
      this.props.attendees.importAttendeesValidation === true &&
      prevProps.attendees.importAttendeesValidation === false &&
      this.props.attendees.message !== prevProps.attendees.message
    ) {
      this.setState({
        openValidation: true,
        loading: false
      });
    }

    if (
      this.props.attendees.importAttendeesError === true &&
      prevProps.attendees.importAttendeesError === false &&
      this.props.attendees.message !== prevProps.attendees.message
    ) {
      this.setState({
        loading: false,
      });
    } 

    if (
      this.props.attendees.getAttendeesGroupsSuccess === true &&
      prevProps.attendees.getAttendeesGroupsSuccess === false &&
      this.props.attendees.message !== prevProps.attendees.message
    ) {

      if (
        Array.isArray(this.props.attendees.attendeesGroups) &&
        this.props.attendees.attendeesGroups.length > 0
      ) {
        const groupsFilterOptionsListArray = this.props.attendees.attendeesGroups.filter((group) => {
          if (group.source !== "GoogleWorkspace") {
            return group;
          } else if (group.showToGroup) {
            return group;
          }
        });
        this.setState({
          ...this.state,
          groupFilterOptionsList: groupsFilterOptionsListArray
        });
      }
      this.setState({
        loading: false,
      });
    }

    if (
      this.props.attendees.exportCheckInHistorySuccess === true &&
      prevProps.attendees.exportCheckInHistorySuccess === false &&
      this.props.attendees.message !== prevProps.attendees.message
    ) {
      this.setState({
        loading: false,
      });
    }

    if (
      this.props.attendees.exportAttendeesSuccess === true &&
      prevProps.attendees.exportAttendeesSuccess === false &&
      this.props.attendees.message !== prevProps.attendees.message
    ) {
      this.setState({
        loading: false,
        downloadExport: false
      });
    }

    if (
      this.props.attendees.getCustomFieldSuccess === true &&
      prevProps.attendees.getCustomFieldSuccess === false &&
      this.props.attendees.message !== prevProps.attendees.message
    ) {
      this.setState({
        loading: false,
      });
      this.findIfAllowedFromSuperadminRequest();
    }
  }

  onChangeDate = (date) => {
    this.setState({ checkinDate: date });
  };

  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClickOpenForm = () => {
    this.setState({
      openForm: true,
    });
  };

  handleClickConfirmation = (customField) => {
    this.setState({
      openConfirmation: true,
    });
  };

  handleClickOpenAttendeeForm = () => {
    this.setState(
      {
        cropPic: '',
        uploadImage: '',
        customFieldsForEdit: '',
        email: '',
      },
      () => {
        this.setState({
          openAttendeeForm: true,
        });
      },
    );
  };

  handleClickAddNewForm = () => {
    this.setState(
      {
        cropPic: '',
        uploadImage: '',
        customFieldsForEdit: '',
        email: '',
      },
      () => {
        this.setState({
          openAddNewForm: true,
        });
      },
    );
  };

  handleClickOpenEnabledModal = () => {
    this.setState({
      openEnabledModal: true,
    });
  };

  handleClickopenCheckinModal = () => {
    this.setState({
      openCheckinModal: true,
      checkinDate: moment
        .tz(new Date(), this.props.project?.project?.timezone)
        .format('MM/DD/YYYY hh:mm a'),
    });
  };

  onCloseModal = () => {
    this.setState({ cropOpen: false });
  };

  handleUpload = () => {
    this.setState({ cropOpen: true, openEditAttendeeModal: true });
  };

  handleClickOpenImportModal = () => {
    this.setState({
      openImportModal: true,
      openValidation: false,
    });
  };

  handleClickOpenAssignGroupModal = () => {
    this.setState({
      openAssignGroupModel: true,
    });
  };

  handleClickOpenUnassignGroupModal = () => {
    this.setState({
      openUnassignGroupModel: true,
    });
  };

  handleClickOpenEditAttendeesModal = (attendee) => {
    this.setState({
      uploadImage: attendee.profileImage,
      openEditAttendeeModal: true,
      attendeeIdForEdit: attendee._id,
      emailForEdit: attendee.email,
      customFieldsForEdit: attendee.attendeesData,
      selectedGroups: attendee.groups?.map((g) => ({ label: g?.attendeesGroupName, value: g?._id, source: g?.source })),
      groupid: attendee.group ? attendee.group._id : null,
      sourceOfAttendeeForEdit: attendee.source,
      attendeeDataForEdit: attendee.attendeesData,
      googleWorkspaceDataForEdit: attendee.googleWorkspaceData,
      department: attendee.department ? attendee.department : '',
    });
  };

  handleClickOpenPrintBadgeModal = (attendee) => {
    this.handleLoading();
    const URL = process.env.REACT_APP_API_URL;
    const users = UserData();
    let params = {
      attendee
    };
    axios.post(`${URL}/attendees/generateBadgeForAttendee`, params, {
      headers: {
        token: `bearer ${users.token}`,
        'Content-Type': 'application/json',
        'check-grant': `${this.state.projectId}-${permissions.resetPassword}`,
      },
    }).then((data) => {
      this.handleLoading();
      if (data.data.status == 200) {
        this.setState({
          openPrintBadgeModal: true,
          badgeOutput: data.data.Page,
          attendeeIdForEdit: attendee._id,
          emailForEdit: attendee.email,
          customFieldsForEdit: attendee.attendeesData,
          badgePrintSettings: data.data.pageSettings
        });
      }
    });
  };

  printAttendeeBadge = () => {
    var printContent = document.getElementById("u_body").outerHTML;
    var win = window.open('', '', 'left=0,top=0,bottom=0,width=900,height=700');
    win.document.write('');
    win.document.write(printContent);
    win.document.write('');
    win.document.close();
    win.print();
  };

  // Checked in History
  handleClickOpenCheckedinHistoryAttendeesModal = (attendee) => {
    this.setState({
      openCheckedinHistoryAttendeeModal: true,
      attendeeCheckinHistory: attendee,
    });
  };

  handleClickOpenSuccess = () => {
    this.setState({ openSuccess: true });
  };

  handleClickOpenValidation = () => {
    this.setState({ openValidation: true });
  };

  handleImportClose = () => {
    this.setState({
      openImportModal: false,
      openSuccess: false,
      openValidation: false,
      attendeeCsv: '',
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleCloseForm = () => {
    this.setState({
      openForm: false,
      fieldLabel: '',
      fieldName: '',
      fieldType: '',
      mandatoryAttendeesProfile: false,
      editByAttendees: false,
      showAttendeesProfile: false,
      listValue: '',
      checkboxValue: '',
      errors: {},
      department: ''
    });
  };

  handleCloseConfirmation = () => {
    this.setState({
      openConfirmation: false,
    });
  };

  handleCloseAttendeeForm = () => {
    this.setState({
      openAttendeeForm: false,
      openAddNewForm: false,
      email: '',
      selectedGroupId: '',
      fieldName: '',
      fieldType: '',
      fieldLabel: '',
      customField: '',
      customFieldsForEdit: '',
      errors: '',
      uploadImage: '',
      cropPic: '',
      accessCode: '',
      sendTempPassIfPrivatePassword: false,
      selectedGroups: [],
      department: ''
    });
  };

  handleCloseEnabledModal = () => {
    this.setState({
      openEnabledModal: false,
    });
  };

  handleCloseopenCheckinModal = () => {
    this.setState({
      openCheckinModal: false,
    });
  };

  handleCloseOpenImportModal = () => {
    this.setState({
      openImportModal: false,
      attendeeCsv: '',
    });
  };

  handleCloseAssignGroupModal = () => {
    this.setState({
      openAssignGroupModel: false,
      groupid: '',
      errors: {},
    });
  };

  handleCloseUnassignGroupModal = () => {
    this.setState({
      openUnassignGroupModel: false,
    });
  };

  handleCloseEditAttendeeModal = () => {
    this.setState({
      openEditAttendeeModal: false,
      groupid: '',
      errors: '',
      cropPic: '',
      uploadImage: '',
      selectedGroups: [],
    });
  };

  handleClosePrintBadgeModal = () => {
    this.handleCloseEditAttendeeModal();
    this.setState({
      openPrintBadgeModal: false,
      errors: '',
      cropPic: '',
      badgePrintSettings: {}
    });
  };


  handleCloseCheckedinHistoryAttendeeModal = () => {
    this.setState({
      openCheckedinHistoryAttendeeModal: false,
    });
  };

  handleChange = (e) => {
    try {
      this.setState({
        [e.target.name]: e.target.value,
      });
    } catch (error) {
      createNotification('error', 'something went wrong');
    }
  };

  showAttendeesProfilesCheckbox = (e) => {
    const { showAttendeesProfile } = this.state;
    if (e.target.checked) {
      this.setState({
        showAttendeesProfile: e.target.checked,
      });
    } else {
      this.setState({
        showAttendeesProfile: false,
      });
    }
  };

  editByAttendeesCheckbox = (e) => {
    const { editByAttendees } = this.state;
    if (e.target.checked) {
      this.setState({
        editByAttendees: e.target.checked,
      });
    } else {
      this.setState({
        editByAttendees: false,
      });
    }
  };

  mandatoryAttendeesProfileCheckbox = (e) => {
    const { mandatoryAttendeesProfile } = this.state;
    if (e.target.checked) {
      this.setState({
        mandatoryAttendeesProfile: e.target.checked,
      });
    } else {
      this.setState({
        mandatoryAttendeesProfile: false,
      });
    }
  };

  handleLabel = (e) => {
    try {
      this.setState({
        fieldLabel: e.target.value,
        fieldName: e.target.value.toLowerCase().trim().replace(/ /g, ''),
      });
    } catch (err) {
      createNotification('error', 'something went wrong');
    }
  };

  openAddCustomFieldForm = () => {
    try {
      this.setState(
        {
          isEdit: false,
        },
        () => this.handleClickOpenForm(),
      );
    } catch (error) {
      createNotification('error', 'something went wrong');
    }
  };

  saveNewCustomFieldAndExit = () => {
    try {
      this.setState(
        {
          isSaveAndExit: true,
        },
        () => this.addNewCustomField(),
      );
    } catch (error) {
      createNotification('error', 'something went wrong');
    }
  };

  saveNewCustomField = () => {
    try {
      this.setState(
        {
          isSaveAndExit: false,
        },
        this.addNewCustomField(),
      );
    } catch (error) {
      createNotification('error', 'something went wrong');
    }
  };

  openEditCustomFieldForm = (customField) => {
    try {
      const {
        fieldName,
        fieldType,
        fieldLabel,
        listValue,
        checkboxValue,
        showAttendeesProfile,
        editByAttendees,
        mandatoryAttendeesProfile,
      } = customField.fieldData;
      const { _id } = customField;
      this.setState(
        {
          fieldName,
          fieldType,
          fieldLabel,
          fieldId: _id,
          isEdit: true,
          listValue:
            listValue === '' ? listValue?.split('') : listValue?.join('\n'),
          checkboxValue:
            checkboxValue === ''
              ? checkboxValue?.split('')
              : checkboxValue?.join('\n'),
          showAttendeesProfile,
          editByAttendees,
          mandatoryAttendeesProfile,
        },
        () => this.handleClickOpenForm(),
      );
    } catch (error) {
      createNotification('error', 'something went wrong');
    }
  };

  updateCustomField = () => {
    try {
      const projectId = getProjectIdFromUrl();
      const {
        fieldLabel,
        fieldName,
        fieldType,
        fieldId,
        listValue,
        checkboxValue,
        showAttendeesProfile,
        editByAttendees,
        mandatoryAttendeesProfile,
      } = this.state;
      if (this.isValid()) {
        const data = {
          projectId,
          fieldId,
          fieldLabel,
          fieldName,
          fieldType,
          listValue: listValue ? listValue.toString().trim() : '',
          checkboxValue: checkboxValue ? checkboxValue.toString().trim() : '',
          showAttendeesProfile,
          editByAttendees,
          mandatoryAttendeesProfile,
          lngCode: this.props.users.newLangState
        };
        this.props.updateCustomField(data);
        this.handleCloseForm();
      }
    } catch (error) {
      createNotification('error', 'something went wrong');
    }
  };

  addNewCustomField = () => {
    try {
      const {
        projectId,
        fieldLabel,
        fieldName,
        fieldType,
        listValue,
        checkboxValue,
        showAttendeesProfile,
        editByAttendees,
        mandatoryAttendeesProfile,
      } = this.state;
      if (this.isValid()) {
        const data = {
          projectId,
          fieldLabel,
          fieldName,
          fieldType,
          listValue: listValue.trim(),
          checkboxValue: checkboxValue.trim(),
          showAttendeesProfile,
          editByAttendees,
          mandatoryAttendeesProfile,
          lngCode: this.props.users.newLangState
        };
        this.props.addCustomFields(data);
        this.handleLoading();
      }
    } catch (error) {
      createNotification('error', 'something went wrong');
    }
  };

  isValid = () => {
    try {
      const { fieldLabel, fieldType, checkinDate, listValue, checkboxValue } = this.state;
      let formIsValid = true;
      let error = {};
      if (
        fieldLabel.trim().length === 0 ||
        fieldLabel === '' ||
        fieldLabel === undefined
      ) {
        formIsValid = false;
        error['fieldLabel'] = this.props.users.languageStateForRedux?.validations?.fieldName;
      }

      if (fieldLabel.trim().match(/^department$/i)) {
        formIsValid = false;
        error['fieldLabel'] = this.props.users.languageStateForRedux?.validations?.fieldNameDepartment;
      }
      if (fieldType === 'dropdown') {
        if (
          listValue === '' ||
          listValue === null ||
          !listValue ||
          listValue.trim().length === 0
        ) {
          formIsValid = false;
          error['listValue'] = 'Please enter at least one value.';
        }
      }

      if (fieldType === 'checkbox') {
        if (
          checkboxValue === '' ||
          checkboxValue === null ||
          !checkboxValue ||
          checkboxValue.trim().length === 0
        ) {
          formIsValid = false;
          error['checkbox'] = 'Please enter at least one value.';
        }
      }
      if (checkinDate === '' || checkinDate === null) {
        formIsValid = false;
        error['checkinDate'] = 'Please select checkin date.';
      }
      if (
        fieldType.trim().length === 0 ||
        fieldType === '' ||
        fieldType === undefined ||
        fieldType === 'Select'
      ) {
        formIsValid = false;
        error['fieldType'] = this.props.users.languageStateForRedux?.validations?.fieldType;
      }
      this.setState({
        errors: error,
      });
      return formIsValid;
    } catch (error) {
      createNotification('error', 'something went wrong');
    }
  };

  isValidAttendeeForm = () => {
    try {
      const { email, customFieldsForEdit } = this.state;
      const customFields = this.props.attendees?.customFields?.map((item) => item);
      const regexUrl =
        /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
      const regexForEmail =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      let formIsValid = true;
      let error = {};
      if (email.trim().length === 0 || email === '' || email === undefined) {
        formIsValid = false;
        error['email'] = this.props.users.languageStateForRedux?.validations?.writeEmail;
      } else if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
        formIsValid = true;
      } else {
        formIsValid = false;
        error['email'] = 'Please enter a valid email';
      }
      if (customFields) {
        for (let data of customFields && customFields) {
          let attendeesDataValid = {};
          attendeesDataValid[data._id] = customFieldsForEdit[data._id];
          const { fieldData } = data;
          const currentFieldData = customFieldsForEdit[data._id];
          const { fieldType } = fieldData || {};
          if (formIsValid === true && fieldType === 'email') {
            if (
              currentFieldData !== undefined &&
              currentFieldData?.trim().length !== 0 &&
              !currentFieldData !== ''
            ) {
              if (regexForEmail.test(currentFieldData) === false) {
                formIsValid = false;
                error['validEmail'] = `Please enter a valid email`;
              }
            }
          }

          if (formIsValid === true && fieldType === 'url') {
            if (
              currentFieldData !== undefined &&
              currentFieldData?.trim().length !== 0 &&
              !currentFieldData !== ''
            ) {
              if (regexUrl.test(currentFieldData) === false) {
                formIsValid = false;
                error['validUrl'] = `Please enter a valid URL`;
              }
            }
          }
        }
      }

      this.setState({
        errors: error,
      });
      return formIsValid;
    } catch (error) {
      createNotification('error', 'something went wrong');
    }
  };

  opendeleteCustomFieldBox = (customField) => {
    try {
      this.setState(
        {
          fieldId: customField._id,
        },
        () => this.handleClickConfirmation(),
      );
    } catch (error) {
      createNotification('error', 'something went wrong');
    }
  };

  deleteCustomField = () => {
    try {
      const projectId = getProjectIdFromUrl();
      const data = {
        projectId,
        fieldId: this.state.fieldId,
        lngCode: this.props.users.newLangState,
      };
      this.props.deleteCustomField(data);
      this.handleCloseConfirmation();
    } catch (error) {
      createNotification('error', 'something went wrong');
    }
    this.handleLoading();
  };

  addAttendee = (e) => {
    try {
      this.setState({
        displayS3Image: false
      });
      if (this.isValidAttendeeForm()) {
        const projectId = getProjectIdFromUrl();

        const customField = this.props.attendees?.customFields?.map(
          (item) => item._id,
        );
        let attendeesData = {};
        if (customField) {
          for (const item of customField) {
            attendeesData[item] = this.state.customFieldsForEdit[item];
          }
        }

        let newObj = {};
        for (const element in attendeesData) {
          newObj[element] = Array.isArray(attendeesData[element])
            ? attendeesData[element]
            : attendeesData[element]?.trim();
        }
        let systemUser = this.state.isSystemUser;
        const formData = new FormData();
        formData.append('projectId', this.state.projectId);
        formData.append('email', this.state.email);
        formData.append('accessCode', this.state.accessCode);
        formData.append('attendeeData', JSON.stringify(newObj));
        formData.append('isSystemUser', systemUser);
        formData.append('department', this.state.department);
        formData.append('destinationFolder', this.state.destinationFolder);
        formData.append('fileNameInS3', this.state.fileNameInS3);
        formData.append(
          'groups',
          this.state.selectedGroups?.map((g) => g.value)
        );
        formData.append(
          'profileImage',
          this.state?.cropPic ? this.state?.cropPic : this.state?.uploadImage,
        );
        this.props.addAttendee(formData);
        this.setState({
          getAttendeesLoading: true,
          sendTempPassIfPrivatePassword: false,
          openAddNewForm: false
        });
        formData.append('sendTempPassIfPrivatePassword', this.state.sendTempPassIfPrivatePassword);
        formData.append('lngCode', this.props.users.newLangState);
      }
    } catch (error) {
      createNotification('error', 'something went wrong');
    }
  };

  onProfileImageChange = (event, method) => {
    if (event.target.files && event.target.files[0]) {
      const imageFile = event.target.files[0];
      if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
        createNotification('error', 'Please select valid image file.');
        return false;
      }

      if (imageFile.size > 5242880) { //5 mb
        createNotification('error', this.props.users.languageStateForRedux?.common?.imageSizeErrMsg);
        return false;
      }

      let formData = new FormData();

      formData.append('imageFile', event.target.files[0]);
      formData.append('section', section);
      formData.append('type', FILE_TYPE.file);
      formData.append('parent', this.state.parentId);
      this.props.addImagesRequest((formData));
    }
  };

  saveAndExitAttendeeForm = (e) => {
    try {
      this.setState({ isSaveAndExitAttendeeForm: true, searchText: '', isSystemUser: false }, () => {
        this.addAttendee();
      });
    } catch (error) {
      createNotification('error', 'something went wrong');
    }
  };

  saveAttendeeForm = (e) => {
    try {
      this.setState({ isSaveAndExitAttendeeForm: false, searchText: '', isSystemUser: false }, () => {
        this.addAttendee();
      });
    } catch (error) {
      createNotification('error', 'something went wrong');
    }
  };

  saveAndExitAddNewForm = (e) => {
    try {
      this.setState({ isSaveAndExitForKiosk: true, searchText: '', isSystemUser: true }, () => {
        this.addAttendee();
      });
    } catch (error) {
      createNotification('error', 'something went wrong');
    }
  };

  saveKioskForm = (e) => {
    try {
      this.setState({ isSaveAndExitForKiosk: false, searchText: '', isSystemUser: true }, () => {
        this.addAttendee();
      });
    } catch (error) {
      createNotification('error', 'something went wrong');
    }
  };

  handleSearchSubmit = (e) => {
    try {
      e.preventDefault();
      const projectId = getProjectIdFromUrl();
      if (
        this.state.searchText !== '' &&
        this.state.searchText.trim().length > 0
      ) {
        this.setState({ page_no: 1, startCount: 1, loading: true }, () => {
          const { searchText, page_no, itemCount } = this.state;
          let requestbody = {
            projectId,
            searchText: searchText.trim(),
            page_no,
            limit: itemCount,
          };
          this.props.searchAttendeesRequest(requestbody);
          this.handleGetAttendeesLoading();
        });
      } else {
        this.getAttendeesList();
        this.handleGetAttendeesLoading();
      }
    } catch (error) {
      createNotification('error', 'something went wrong');
    }
  };

  deleteAttendee = (id) => {
    try {
      this.props.deleteAttendee(id);
    } catch (error) {
      createNotification('error', 'something went wrong');
    }
  };

  handleToggle = () => {
    this.setState({
      openSettings: !this.state.openSettings,
    });
  };

  handleAttendeesToggle = () => {
    this.setState({
      openAttendeesSettings: !this.state.openAttendeesSettings,
    });
  };

  handleSearchSubmitForAccessCode = (e) => {
    e.preventDefault();
    this.setState(
      {
        limitForAccessCode: 20,
      },
      () => this.getUnusedAccessCode(),
    );
  };

  handleLoading = () => {
    let loading = this.state.loading;
    this.setState({
      loading: !loading,
    });
  };

  handleToggle1 = () => {
    this.setState({
      open1: !this.state.open1,
    });
  };

  handleToggle2 = () => {
    this.setState({
      open2: !this.state.open2,
    });
  };

  handleToggle3 = () => {
    this.setState({
      open3: !this.state.open3,
    });
  };

  handleToggle4 = () => {
    this.setState({
      open4: !this.state.open4,
    });
  };

  handleToggle5 = () => {
    this.setState({
      open5: !this.state.open5,
    });
  };

  handleToggleAction = () => {
    this.setState({
      openAction: !this.state.openAction,
    });
  };

  filterHandleClose = (event) => {
    this.setState({
      open1: false,
      open2: false,
      open3: false,
      open4: false,
      open5: false,
      openAction: false,
      openSettings: false,
      openAttendeesSettings: false,
    });
  };

  handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      this.setState({});
      this.handleLoading();
    }
  };

  handlePageChange = (pageNumber) => {
    try {
      const projectId = getProjectIdFromUrl();
      this.checkBoxIdsArr = [];
      this.checkBoxforDisable = [];
      const { itemCount } = this.state;
      let totalResults = this.props.attendees.totalAttendeesRecords;
      this.setState(
        (prevState, props) => ({
          startCount: pageNumber !== 1 ? itemCount * (pageNumber - 1) + 1 : 1,
          endCount:
            totalResults > itemCount * pageNumber
              ? itemCount * pageNumber
              : totalResults,
          page_no: pageNumber,
          pageHasChanged: true,
          checked: false,
        }),
        () => {
          if (this.state.searchText !== '') {
            const { searchText, page_no, itemCount } = this.state;
            let requestbody = {
              projectId,
              searchText: searchText.trim(),
              page_no,
              limit: itemCount,
            };
            this.props.searchAttendeesRequest(requestbody);
          } else {
            this.getAttendeesList();
          }
        },
      );
    } catch (error) {
      createNotification('error', 'something went wrong');
    }
  };

  calculateTotalAttendeesInTheSelectedOption = (selectedFilter, filterOptionsSelectedArray, selectedFilterCaps) => {
    let totalAttendeesInTheSelectedOption;
    let countArr = [];
    if (filterOptionsSelectedArray.length > 1) {
      totalAttendeesInTheSelectedOption = filterOptionsSelectedArray.forEach((x) => {
        countArr.push(this.props.attendees.filterCnt[selectedFilter]?.[`filterBy${selectedFilterCaps}`]?.[x]);
      });
      const add = () => {
        let sum = 0;
        countArr.forEach(x => {
          if (x !== undefined) {
            sum += x;
          }
        });

        return sum;
      };
      totalAttendeesInTheSelectedOption = add();

    } else {
      let selection = filterOptionsSelectedArray[0];
      let count = this.props.attendees.filterCnt[selectedFilter]?.[`filterBy${selectedFilterCaps}`]?.[selection];
      totalAttendeesInTheSelectedOption = count ? count : 0;
    }

    this.setState({ [`total${selectedFilterCaps}InTheSelectedOption`]: totalAttendeesInTheSelectedOption });
  };

  handleFilterChange = (event, selectedFilter) => {
    event.preventDefault();
    try {
      let statusFilterOptionsSelectedArray = [
        ...this.state.statusFilterOptionsSelected,
      ];
      if (selectedFilter === 'status') {
        if (event.target.name === 'all') {
          if (event.target.checked === true) {
            statusFilterOptionsSelectedArray = [
              ...this.state.statusFilterOptionsList,
            ];
          } else {
            statusFilterOptionsSelectedArray = [];
          }
        } else {
          let filterIndex = statusFilterOptionsSelectedArray.indexOf(
            event.target.name,
          );
          if (filterIndex > -1) {
            statusFilterOptionsSelectedArray.splice(filterIndex, 1);
            this.calculateTotalAttendeesInTheSelectedOption('status', statusFilterOptionsSelectedArray, 'Status');
          } else {
            statusFilterOptionsSelectedArray.push(event.target.name);
            this.calculateTotalAttendeesInTheSelectedOption('status', statusFilterOptionsSelectedArray, 'Status');
          }
        }
      }

      let sourceFilterOptionsSelectedArray = [
        ...this.state.sourceFilterOptionsSelected,
      ];
      if (selectedFilter === 'source') {
        if (event.target.name === 'all') {
          if (event.target.checked === true) {
            sourceFilterOptionsSelectedArray = [
              ...this.state.sourceFilterOptionsList,
            ];
          } else {
            sourceFilterOptionsSelectedArray = [];
          }
        } else {
          let filterIndex = sourceFilterOptionsSelectedArray.indexOf(
            event.target.name,
          );
          if (filterIndex > -1) {
            sourceFilterOptionsSelectedArray.splice(filterIndex, 1);
            this.calculateTotalAttendeesInTheSelectedOption('source', sourceFilterOptionsSelectedArray, 'Source');
          } else {
            sourceFilterOptionsSelectedArray.push(event.target.name);
            this.calculateTotalAttendeesInTheSelectedOption('source', sourceFilterOptionsSelectedArray, 'Source');
          }
        }
      }

      let groupFilterOptionsSelectedArray = [
        ...this.state.groupFilterOptionsSelected,
      ];
      if (selectedFilter === 'group') {
        if (event.target.name === 'all') {
          if (event.target.checked === true) {
            groupFilterOptionsSelectedArray = [
              ...this.state.groupFilterOptionsList.map((el) => el._id),
            ];
          } else {
            groupFilterOptionsSelectedArray = [];
          }
        } else {
          let filterIndex = groupFilterOptionsSelectedArray.indexOf(
            event.target.name,
          );
          if (filterIndex > -1) {
            groupFilterOptionsSelectedArray.splice(filterIndex, 1);
            this.calculateTotalAttendeesInTheSelectedOption('group', groupFilterOptionsSelectedArray, 'Group');
          } else {
            groupFilterOptionsSelectedArray.push(event.target.name);
            this.calculateTotalAttendeesInTheSelectedOption('group', groupFilterOptionsSelectedArray, 'Group');
          }
        }
      }

      //for isActive filter select
      let isActiveFilterOptionsSelectedArray = [
        ...this.state.isActiveFilterOptionsSelected,
      ];



      if (selectedFilter === 'isActive') {
        if (event.target.name === 'all') {
          if (event.target.checked === true) {
            isActiveFilterOptionsSelectedArray = [
              ...this.state.isActiveFilterOptionsList,
            ];
          } else {
            isActiveFilterOptionsSelectedArray = [];
          }
        } else {
          let filterIndex = isActiveFilterOptionsSelectedArray.indexOf(
            event.target.name,
          );

          if (filterIndex > -1) {
            isActiveFilterOptionsSelectedArray.splice(filterIndex, 1);
            this.calculateTotalAttendeesInTheSelectedOption('isActive', isActiveFilterOptionsSelectedArray, 'IsActive');
          } else {
            isActiveFilterOptionsSelectedArray.push(event.target.name);
            this.calculateTotalAttendeesInTheSelectedOption('isActive', isActiveFilterOptionsSelectedArray, 'IsActive');
          }
        }
      }

      //for Type filter select
      let isSystemUserFilterOptionsSelectedArray = [
        ...this.state.isSystemUserFilterOptionsSelected,
      ];

      if (selectedFilter === 'isSystemUser') {
        if (event.target.name === 'all') {
          if (event.target.checked === true) {
            isSystemUserFilterOptionsSelectedArray = [
              ...this.state.isSystemUserFilterOptionsList,
            ];
          } else {
            isSystemUserFilterOptionsSelectedArray = [];
          }
        } else {
          let filterIndex = isSystemUserFilterOptionsSelectedArray.indexOf(
            event.target.name,
          );

          if (filterIndex > -1) {
            isSystemUserFilterOptionsSelectedArray.splice(filterIndex, 1);
            this.calculateTotalAttendeesInTheSelectedOption('isSystemUser', isSystemUserFilterOptionsSelectedArray, 'Type');
          } else {
            isSystemUserFilterOptionsSelectedArray.push(event.target.name);
            this.calculateTotalAttendeesInTheSelectedOption('isSystemUser', isSystemUserFilterOptionsSelectedArray, 'Type');
          }
        }
      }
      this.checkBoxIdsArr = [];
      this.checkBoxforDisable = [];
      this.setState(
        {
          statusFilterOptionsSelected: [...statusFilterOptionsSelectedArray],
          sourceFilterOptionsSelected: [...sourceFilterOptionsSelectedArray],
          groupFilterOptionsSelected: [...groupFilterOptionsSelectedArray],
          isActiveFilterOptionsSelected: [
            ...isActiveFilterOptionsSelectedArray,
          ],
          isSystemUserFilterOptionsSelected: [
            ...isSystemUserFilterOptionsSelectedArray,
          ],
          startCount: 1,
          page_no: 1,
          open1: false,
          open2: false,
          open3: false,
          open4: false,
          open5: false,
        },
        () => this.getAttendeesList(),
      );
    } catch (error) {
      createNotification('error', 'something went wrong');
    }
  };

  getAttendeesList = () => {
    const projectId = getProjectIdFromUrl();
    const {
      statusFilterOptionsSelected,
      sourceFilterOptionsSelected,
      groupFilterOptionsSelected,
      isActiveFilterOptionsSelected,
      isSystemUserFilterOptionsSelected,
      page_no,
      itemCount,
      sort_by,
      sortOrder,
    } = this.state;
    let requestbody = {
      projectId,
      status: statusFilterOptionsSelected,
      source: sourceFilterOptionsSelected,
      group: groupFilterOptionsSelected,
      active: isActiveFilterOptionsSelected.map((el) => el == 'true'),
      isSystemUser: isSystemUserFilterOptionsSelected.map((el) => el == 'true'),
      sort_by: sort_by,
      order: sortOrder,
      pageNumber: page_no,
      limit: itemCount,
      permission: 'View Attendees',
    };
    this.props.sortFilterAttendeesRequest(requestbody);
    this.handleGetAttendeesLoading();
  };

  handleGetAttendeesLoading = () => {
    this.setState({
      getAttendeesLoading: true,
    });
  };

  selectCheckBox = (e, attendee) => {
    try {
      let googleWorkspaceArray = this.state.googleWorkspaceArr;
      if (attendee) {
        const index = this.checkBoxIdsArr.indexOf(attendee._id);
        if (index === -1) {
          this.checkBoxIdsArr.push(attendee._id);
          this.checkBoxforDisable.push(attendee);
          if (attendee.source === "Google Workspace") {
            googleWorkspaceArray.push(attendee._id);
            this.setState({
              googleWorkspaceArr: [...googleWorkspaceArray]
            });
          }
        } else {
          this.checkBoxIdsArr.splice(index, 1);
          this.checkBoxforDisable.splice(index, 1);
          if (attendee.source === "Google Workspace") {

            googleWorkspaceArray = googleWorkspaceArray.filter(el => el !== attendee._id);
            this.setState({
              googleWorkspaceArr: [...googleWorkspaceArray]
            });
          }
        }

        this.setState({
          changeState: !this.state.changeState,
          containsGoogleWorkspaceAttendee: googleWorkspaceArray?.length > 0 ? true : false
        });
      }
      if (this.checkBoxIdsArr.length !== this.state.itemCount) {
        this.setState({
          checked: false,
        });
      }
    } catch (error) {
      createNotification('error', 'something went wrong');
    }
  };

  deleteAttendees = () => {
    const projectId = getProjectIdFromUrl();
    try {
      const body = {
        projectId,
        attendeesIds: this.checkBoxIdsArr,
        permission: 'Delete Custom Fields',
        lngCode: this.props.users.newLangState
      };
      this.props.deleteAttendee(body);
    } catch (error) {
      createNotification('error', 'something went wrong');
    }
    this.handleLoading();
  };

  isDisabled = () => {
    try {
      return this.checkBoxforDisable.some(item => item.isSystemUser === true) || (this.checkBoxforDisable.length <= 0) ? true : false;
    } catch (error) {
      createNotification('error', 'something went wrong');
    }
  };

  openDeleteAttendeeBox = () => {
    try {
      this.setState(
        {
          isDeleteAttendeeBox: true,
        },
        () => this.handleClickConfirmation(),
      );
    } catch (error) {
      createNotification('error', 'something went wrong');
    }
  };

  isValidGroupId = (groupId) => {
    let isFormValid = true;
    let errors = {};
    if (!groupId || groupId === 'Select') {
      errors['groupId'] = 'Please select a group';
      isFormValid = false;
    }
    this.setState({
      errors,
    });
    return isFormValid;
  };

  changeActionAssignStatus = () => {
    try {
      if (this.isValidGroupId(this.state.groupid)) {
        const projectId = getProjectIdFromUrl();
        this.setState(
          {
            isAssignGroupButton: false,
          },
          () => {
            const body = {
              projectId,
              attendeesIds: this.checkBoxIdsArr,
              group: this.state.groupid,
              permission: 'Assign Status Attendees',
              lngCode: this.props.users.newLangState,
            };
            this.props.setAssignGroupStatus(body);
            this.handleLoading();
          },
        );
      }
    } catch (error) {
      createNotification('error', 'something went wrong');
    }
  };

  changeActionUnassignStatus = () => {
    try {
      const { projectId } = this.state;
      this.setState(
        {
          isUnassignGroupButton: false,
        },
        () => {
          const body = {
            attendeesIds: this.checkBoxIdsArr,
            projectId,
            permission: 'Edit Attendee',
            lngCode: this.props.users.newLangState,
          };
          this.props.setAssignGroupStatus(body);
        },
      );
    } catch (error) {
      createNotification('error', 'something went wrong');
    }
    this.handleLoading();
  };

  changeEnabledStatus = () => {
    const projectId = getProjectIdFromUrl();
    try {
      this.setState(
        {
          isDisabledButton: false,
        },
        () => {
          const body = {
            attendeesIds: this.checkBoxIdsArr,
            status: true,
            projectId,
            lngCode: this.props.users.newLangState,
          };
          this.props.setEnabledStatus(body);
        },
      );
    } catch (error) {
      createNotification('error', 'something went wrong');
    }
    this.handleLoading();
  };

  changeCheckinStatus = async () => {
    try {
      const projectId = getProjectIdFromUrl();
      let ipv4Adress = '';
      try {
        ipv4Adress = await publicIP();
      } catch (err) { }
      const checkTimeEqui = convertTZ(
        this.state.checkinDate,
        this.props.project?.project?.timezone,
      );
      this.setState(
        {
          isCheckinButton: false,
        },
        () => {
          const body = {
            projectId,
            attendeesIds: this.checkBoxIdsArr,
            checkIn: checkTimeEqui,
            ip: ipv4Adress,
            lngCode: this.props.users.newLangState,
          };
          this.props.setCheckinStatus(body);
        },
      );
    } catch (error) {
      createNotification('error', 'something went wrong');
    }
    this.handleLoading();
  };

  changeDisabledStatus = () => {
    try {
      const projectId = getProjectIdFromUrl();
      this.setState(
        {
          isDisabledButton: true,
        },
        () => {
          const body = {
            projectId,
            attendeesIds: this.checkBoxIdsArr,
            status: false,
            lngCode: this.props.users.newLangState,
            // permission: 'Assign Status Attendees',
          };
          this.props.setEnabledStatus(body);
        },
      );
    } catch (error) {
      createNotification('error', 'something went wrong');
    }
    this.handleLoading();
  };

  changeCheckoutStatus = async () => {
    try {
      const projectId = getProjectIdFromUrl();
      let ipv4Adress = '';
      try {
        ipv4Adress = await publicIP();
      } catch (err) { }
      const checkTimeEqui = convertTZ(
        this.state.checkinDate,
        this.props.project?.project?.timezone,
      );
      this.setState(
        {
          isCheckinButton: true,
        },
        () => {
          const body = {
            projectId,
            attendeesIds: this.checkBoxIdsArr,
            checkOut: checkTimeEqui,
            permission: 'Assign Status Attendees',
            ip: ipv4Adress,
            lngCode: this.props.users.newLangState,
          };
          this.props.setCheckinStatus(body);
        },
      );
    } catch (error) {
      createNotification('error', 'something went wrong');
    }
    this.handleLoading();
  };

  openSetDisabledBox = () => {
    try {
      this.setState(
        {
          isDisabledButton: true,
        },
        () => this.handleClickOpenEnabledModal(),
      );
    } catch (error) {
      createNotification('error', 'something went wrong');
    }
  };

  openSetEnabledBox = () => {
    try {
      this.setState(
        {
          isDisabledButton: false,
        },
        () => this.handleClickOpenEnabledModal(),
      );
    } catch (error) {
      createNotification('error', 'something went wrong');
    }
  };

  openSetCheckoutBox = () => {
    try {
      this.setState(
        {
          isCheckinButton: true,
        },
        () => this.handleClickopenCheckinModal(),
      );
    } catch (error) {
      createNotification('error', 'something went wrong');
    }
  };

  openSetCheckinBox = () => {
    try {
      this.setState(
        {
          isCheckinButton: false,
        },
        () => this.handleClickopenCheckinModal(),
      );
    } catch (error) {
      createNotification('error', 'something went wrong');
    }
  };

  assignGroupAttendees = () => {
    try {
      const body = {
        attendeesIds: this.checkBoxIdsArr,
      };
      this.props.assignGroupAttendee(body);
    } catch (error) {
      createNotification('error', 'something went wrong');
    }
  };

  openAssignGroupAttendeeBox = () => {
    try {
      this.setState(
        {
          isAssignGroupButton: true,
        },
        () => this.handleClickOpenAssignGroupModal(),
      );
    } catch (error) {
      createNotification('error', 'something went wrong');
    }
  };

  openUnassignGroupAttendeeBox = () => {
    try {
      this.setState(
        {
          isAssignGroupButton: true,
        },
        () => this.handleClickOpenUnassignGroupModal(),
      );
    } catch (error) {
      createNotification('error', 'something went wrong');
    }
  };

  openGroups = () => {
    this.setState({
      isGroupsComp: true,
      isTransactionEmailsComp: false,
      openSettings: !this.state.openSettings,
      isBadgesComp: false,
    });
  };

  closeGroups = () => {
    this.setState({
      isGroupsComp: false,
      isTransactionEmailsComp: false,
      isBadgesComp: false,
    });
  };

  openTransactionEmailsModal = () => {
    this.setState({
      isTransactionEmailsComp: true,
      isGroupsComp: false,
      isBadgesComp: false,
      openSettings: !this.state.openSettings,
      openAttendeesSettings: !this.state.openAttendeesSettings,
    });
  };

  openBadgesModal = () => {
    this.setState({
      isBadgesComp: true,
      isTransactionEmailsComp: false,
      isGroupsComp: false,
      openSettings: !this.state.openSettings,
      openAttendeesSettings: !this.state.openAttendeesSettings,
    });
  };

  closeBadgesModel = () => {
    this.setState({
      isBadgesComp: false,
    });
  };
  closeTransactionEmailsModal = () => {
    this.setState({
      isTransactionEmailsComp: false,
    });
  };

  sorting = (value) => {
    if (this.state.sort_by === value) {
      if (this.state.sortOrder === 'asc') {
        this.setState(
          {
            sortOrder: 'desc',
          },
          () => this.getAttendeesList(),
        );
      } else {
        this.setState(
          {
            sortOrder: 'asc',
          },
          () => this.getAttendeesList(),
        );
      }
    } else {
      this.setState(
        {
          sort_by: value,
          sortOrder: 'asc',
        },
        () => this.getAttendeesList(),
      );
    }
  };

  selectItemCount = (e) => {
    this.checkBoxIdsArr = [];
    this.setState(
      {
        itemCount: e.target.value,
        page_no: 1,
        startCount: 1,
        selectAllChecked: false,
      },
      () => this.getAttendeesList(),
    );
  };

  handleCustomFieldChange = (e, q) => {
    this.setState({
      [e.target.name]: {
        value: e.target.value,
        type: e.target.type,
      },
      customFieldsForEdit: {
        ...this.state.customFieldsForEdit,
        [e.target.name]: e.target.value,
      },
    });
  };

  onAttendeeFileChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const csvFile = event.target.files[0];

      if (!csvFile.name.match(/\.(csv)$/)) {
        createNotification('error', 'Please select valid csv file.');
        return false;
      }
      this.setState({ attendeeCsv: event.target.files[0] });
    }
  };

  isValidCsv = () => {
    const { attendeeCsv } = this.state;
    let error = {};
    let formIsValid = true;

    if (attendeeCsv === '') {
      formIsValid = false;
      error['attendeeFile'] = '*Please select csvfile.';
    }
    this.setState({ errors: error });
    return formIsValid;
  };

  handleImportSubmit = (e) => {
    e.preventDefault();
    if (this.isValidCsv()) {
      const { attendeeCsv } = this.state;
      if (attendeeCsv !== '') {
        const projectId = getProjectIdFromUrl();
        const formData = new FormData();
        formData.append('projectId', projectId);
        formData.append('attendeeCsv', attendeeCsv);
        this.props.importAttendeesRequest(formData);
      }
    }
  };


  saveEditAttendee = () => {
    try {
      const { emailForEdit, customFieldsForEdit } = this.state;
      const customField = this.props.attendees?.customFields.map(
        (item) => item._id,
      );
      const customFields = this.props.attendees.customFields?.map((item) => item);

      const attendeesData = {};
      for (const item of customField) {
        attendeesData[item] = customFieldsForEdit[item];
      }

      this.isValid = () => {
        let formIsValid = true;
        const regexUrl =
          /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
        const regexForEmail =
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let error = {};
        if (
          emailForEdit.trim().length === 0 ||
          emailForEdit === '' ||
          emailForEdit === undefined
        ) {
          formIsValid = false;
          error['emailForEdit'] = this.props.users.languageStateForRedux?.validations?.writeEmail;
        } else if (
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emailForEdit)
        ) {
          formIsValid = true;
        } else {
          formIsValid = false;
          error['emailForEdit'] = 'Please enter a valid email';
        }

        for (let data of customFields) {
          let attendeesDataValid = {};
          attendeesDataValid[data._id] = customFieldsForEdit[data._id];
          const { fieldData } = data;
          const currentFieldData = customFieldsForEdit[data._id];
          const { fieldType } = fieldData || {};
          if (formIsValid === true && fieldType === 'email') {
            if (
              currentFieldData !== undefined &&
              currentFieldData?.trim().length !== 0 &&
              !currentFieldData !== ''
            ) {
              if (regexForEmail.test(currentFieldData) === false) {
                formIsValid = false;
                error['validEmail'] = `Please enter a valid email`;
              }
            }
          }

          if (formIsValid === true && fieldType === 'url') {
            if (
              currentFieldData !== undefined &&
              currentFieldData?.trim().length !== 0 &&
              !currentFieldData !== ''
            ) {
              if (regexUrl.test(currentFieldData) === false) {
                formIsValid = false;
                error['validUrl'] = `Please enter a valid URL`;
              }
            }
          }
        }
        this.setState({
          errors: error,
        });
        return formIsValid;
      };
      if (this.isValid()) {
        let newObj = {};
        for (const element in attendeesData) {
          newObj[element] = Array.isArray(attendeesData[element])
            ? attendeesData[element]
            : attendeesData[element]?.trim();
        }
        const formData = new FormData();
        formData.append('projectId', this.state.projectId);
        formData.append('email', emailForEdit);
        formData.append('attendeeId', this.state.attendeeIdForEdit);
        formData.append('attendeeData', JSON.stringify(newObj));
        formData.append('lngCode', this.props.users.newLangState);
        formData.append('department', this.state.department);
        formData.append('groups', this.state.selectedGroups?.map((g) => g.value));
        this.props.editAttendee(formData);
      }
    } catch (error) {

      createNotification(
        'error',
        'something went wrong while saving the edited attendees' + error,
      );
    }
  };

  saveAttendeeProfile = async () => {
    const formData = new FormData();
    formData.append('projectId', this.state.projectId);
    formData.append('attendeeId', this.state.attendeeIdForEdit);
    formData.append('lngCode', this.props.users.newLangState);
    formData.append('destinationFolder', this.state.destinationFolder);
    formData.append('fileNameInS3', this.state.fileNameInS3);
    formData.append(
      'profileImage',
      this.state.cropPic ? this.state.cropPic : this.state.uploadImage,
    );
    await UploadAttendeePic(formData).then((response) => {
      if (response.data.status == 200) {
        createNotification('success', response.data.message);
        this.setState(
          {
            uploadImage: response.data?.profileImageTemp,
          },
          () =>
            this.setState({
              cropOpen: false,
              openEditAttendeeModal: false,
              loading: false,
            }),
        );
      } else {
        createNotification('error', response.data.message);
      }
    });
    this.getAttendeesList();
  };

  addNewProfilePic = () => {
    this.setState({});
    this.setState({ cropOpen: false });
  };

  getData = (val) => {
    this.setState({ cropPic: val });
  };

  getBadges = async (e) => {
    const projectId = getProjectIdFromUrl();
    let paramsData = {
      projectId: projectId,
    };
    await ListBadgesTemplateAPI(paramsData).then((response) => {
      if (response.data.status == 200) {
        this.setState({
          badgesTemplates: response.data.templates,
          loading: false
        });
      }
    });
  };

  deleteProfileImageOnClick = async (e) => {
    let paramsData = {
      attendeeId: this.state.attendeeIdForEdit,
      profileImage: this.state.uploadImage,
      projectId: this.state.projectId,
      lngCode: this.props.users.newLangState
    };
    await deleteAttendeeImageAPI(paramsData).then((response) => {
      if (response.data.status == 200) {
        createNotification('success', response.data.message);
        this.setState({
          uploadImage: '',
        });
      } else {
        createNotification('error', response.data.message);
      }
    });
    this.getAttendeesList();
  };

  changeDate = (e) => {
    this.setState({
      checkinDate: e,
    });
  };

  handleSelectAllAttendees = (e) => {
    this.setState({
      checked: e.target.checked,
    });
    if (e.target.checked === true) {
      this.props.attendees.attendees.forEach((attendee) => {
        if (this.checkBoxIdsArr.indexOf(attendee._id) === -1) {
          if (!attendee.isSystemUser) {
            this.checkBoxIdsArr.push(attendee._id);
            this.checkBoxforDisable.push(attendee);
          } else {
            this.setState({
              kioskUserIncludedIncheckBoxIdsArr: true,
            });
          }
        }
      });
    }
    if (e.target.checked === false) {
      this.checkBoxIdsArr = [];
      this.checkBoxforDisable = [];
    }
  };

  saveAndAddNew() {
    this.setState({
      fullName: '',
      selectedGroupId: '',
      email: '',
      fieldName: '',
      fieldType: '',
      fieldLabel: '',
      customField: '',
      customFieldsForEdit: '',
      errors: '',
      uploadImage: '',
      cropPic: '',
      accessCode: '',
      sendTempPassIfPrivatePassword: false,
      selectedGroups: [],
      openAddNewForm: false,
      openAttendeeForm: true,
      department: ''
    });
  }

  saveAndAddNewForKiosk() {
    this.setState({
      fullName: '',
      selectedGroupId: '',
      openAttendeeForm: false,
      email: '',
      fieldName: '',
      fieldType: '',
      fieldLabel: '',
      customField: '',
      customFieldsForEdit: '',
      errors: '',
      uploadImage: '',
      cropPic: '',
      accessCode: '',
      sendTempPassIfPrivatePassword: false,
      selectedGroups: [],
      openAddNewForm: true,
      openAttendeeForm: false,
      department: ''
    });
  }

  downloadCsvFile(e) {
    e.preventDefault();
    this.props.downloadCsvRequest({ projectId: this.state.projectId });
    this.handleLoading();
  }

  exportAttendeesCsv = () => {
    this.props.exportAttendeesRequest({
      projectId: this.state.projectId,
      attendeeId: this.checkBoxIdsArr,
    });
    this.setState({
      downloadExport: (this.checkBoxIdsArr.length >= 0) && (this.props.attendees.totalAttendeesRecords <= 1000) ? false : true,
      loading: this.checkBoxIdsArr.length > 0 || this.props.attendees.totalAttendeesRecords <= 1000 ? true : false,
    });
  };

  exportCheckInHistory = (attendee) => {
    this.props.exportCheckInHistoryRequest({
      projectId: this.state.projectId,
      attendeeId: attendee._id,
    });
    this.setState({
      loading: true
    });
  };

  updatePaginationDropDownState() {
    if (this.state.itemCount >= this.props.attendees.totalAttendeesRecords) {
      let end = this.props.attendees.totalAttendeesRecords;
      setTimeout(() => {
        this.setState({
          endCount: end
        });
      }, 0);
    } else {
      setTimeout(() => {
        this.setState({
          endCount:
            this.props.attendees.totalAttendeesRecords >
              this.state.itemCount * this.state.page_no
              ? this.state.itemCount * this.state.page_no
              : this.props.attendees.totalAttendeesRecords,
        });
      }, 0);
    }
  }

  handleCloseResetPassword = () => {
    this.setState({
      resetPasswordToggle: false,
      forgotPasswordEmail: '',
    });
  };

  handleOpenResetPassword = (attendee) => {
    this.setState({
      resetPasswordToggle: true,
      forgotPasswordEmail: attendee.email,
    });
  };

  handleResetPassword = () => {
    try {
      if (this.state.forgotPasswordEmail == '') {
        throw Error('Email is required.');
      }

      const sendPasswordResetData = {
        forgotPasswordEmail: this.state.forgotPasswordEmail,
        fpprojectId: getProjectIdFromUrl(),
        lngCode: this.props.users.newLangState
      };

      this.handleLoading();

      const URL = process.env.REACT_APP_API_URL;
      const users = UserData();
      axios
        .post(`${URL}/attendees/sendResetPasswordLink`, sendPasswordResetData, {
          headers: {
            token: `bearer ${users.token}`,
            'Content-Type': 'application/json',
            'check-grant': `${this.state.projectId}-${permissions.resetPassword}`,
          },
        })
        .then((data) => {

          if (data.data.status == 200) {
            createNotification(
              'success',
              data.data
                ? data.data.message
                : 'Reset Password link send successfully.',
            );
            this.handleCloseResetPassword();

          } else {
            createNotification('error', data.data.error);
          }
          this.setState({
            loading: false
          });
        })
        .catch((err) => {
          createNotification('error', 'Something went wrong');
        });
    } catch (err) {
      createNotification('error', err.message);
    }
  };

  openReport = () => {
    this.setState({ openAction: false });
    this.props.viewTransactionalReport(true);
  };

  closeReport = () => {
    this.props.viewTransactionalReport(false);
  };

  goToGamification(email) {
    this.setState({
      email: email,
    });
    this.props.navigate({
      pathname: generateUrl(`/project-dashboard/gamification/leaderboard/${this.state.projectId}`),
      state: { attendeeEmail: email, hasLeaderboardOpenedFromAttendees: true },
    });
  }

  openAssignAccesscodemodal = (email, _id) => {
    this.getUnusedAccessCode();
    this.setState({
      email: email,
      attendeesId: _id,
      openAssignAccessCode: true,
    });
  };

  accessCodeAssign = () => {
    this.assignAccessCode();
  };

  closeAssignAccessCode = () => {
    this.checkBoxIdsAccessCode = [];
    this.setState({
      openAssignAccessCode: false,
      getUnassignAccessCode: [],
      limitForAccessCode: 20,
      searchTextForAccessCode: '',
      isAccessCodeAssign: false,
    });
  };

  goToAccessCode(email) {
    this.setState({
      email: email,
    });
    this.props.navigate({
      pathname: `/xp/project-dashboard/access-code/${this.state.projectId}`,
      state: { attendeeEmailForAccessCode: email },
    });
  }

  openOutBoundEmailReport = (email) => {
    this.setState({
      outBoundEmailReport: true,
      outBoundEmailSelected: email,
    });
    this.props.viewTransactionalReport(true);
  };

  closeOutBoundEmailReport = () => {
    this.setState({
      outBoundEmailReport: false,
      outBoundEmailSelected: '',
    });
    this.props.viewTransactionalReport(false);
  };

  handleChangeCheck = (event) => {
    const { value, checked } = event.target;
    let checkValue = this.state.customFieldsForEdit[event.target.name]
      ? this.state.customFieldsForEdit[event.target.name]
      : [];
    if (checked) {
      let checkValues = [];
      checkValues.push(event.target.value);
      checkValue = checkValue.concat(checkValues);

      this.setState({
        [event.target.name]: {
          value: event.target.value,
          type: event.target.type,
        },
        customFieldsForEdit: {
          ...this.state.customFieldsForEdit,
          [event.target.name]: checkValue,
        },
      });
    } else {
      let check = checkValue?.filter((event) => event !== value);
      if (check.length === 0) {
        let customFieldsForEdit = this.state.customFieldsForEdit;
        delete customFieldsForEdit[event.target.name];
        this.setState({
          customFieldsForEdit,
        });
      } else {
        this.setState({
          customFieldsForEdit: {
            ...this.state.customFieldsForEdit,
            [event.target.name]: check,
          },
        });
      }
    }
  };

  showRestOfCheckbox = (data) => {
    let checkLength = data.length;
    return data?.slice(1, checkLength);
  };

  findIfAllowedFromSuperadminRequest = () => {
    let userDetails = JSON.parse(localStorage.getItem('user_details'));
    let reqData = {
      servicePlanId: userDetails.servicePlanId,
      projectId: this.state.projectId,
    };
    this.props.findIfAllowedFromSuperadminRequest(reqData);
  };

  loadMoreAccessCode = () => {
    this.setState(
      {
        limitForAccessCode: this.state.limitForAccessCode + 20,
      },
      () => this.getUnusedAccessCode(),
    );
  };

  selectCheckBoxAccessCode = (e, accessCode) => {
    try {
      if (accessCode) {
        const index = this.checkBoxIdsAccessCode.indexOf(accessCode._id);
        if (index === -1) {
          this.checkBoxIdsAccessCode.push(accessCode._id);
        } else {
          this.checkBoxIdsAccessCode.splice(index, 1);
        }
        this.setState({
          changeState: !this.state.changeState,
        });
      }
    } catch (error) {
      createNotification('error', 'something went wrong');
    }
  };

  handleTempPassCheckbox = (e) => {
    this.setState({
      sendTempPassIfPrivatePassword: e.target.checked
    });
  };

  singleChecked = (val) => {
    let temp;
    switch (val) {
      case 'Checked In': temp = this.props.users.languageStateForRedux?.column_names?.attendees?.checkedIn;
        return temp;

      case 'Checked Out': temp = this.props.users.languageStateForRedux?.column_names?.attendees?.checkedOut;
        return temp;

      case 'Not Checked In': temp = this.props.users.languageStateForRedux?.column_names?.attendees?.notChecked;
        return temp;

      case 'Registration Page': temp = this.props.users.languageStateForRedux?.dropdowns?.attendees?.registration;
        return temp;

      case 'Imported': temp = this.props.users.languageStateForRedux?.dropdowns?.accessCodes?.imported;
        return temp;

      case 'Manually Added': temp = this.props.users.languageStateForRedux?.dropdowns?.attendees?.manAdded;
        return temp;

      case 'Webhooks': temp = this.props.users.languageStateForRedux?.dropdowns?.attendees?.webhooks;
        return temp;

      case 'Google Workspace': temp = this.props.users.languageStateForRedux?.dropdowns?.attendees?.googleSpace;
        return temp;

      case 'Meeting': temp = this.props.users.languageStateForRedux?.formfields?.meetings?.meeting;
        return temp;

      default: temp = val;
        break;
    }
  };

  filterText = (filterOptionsSelected, filterOptionsSelectedAll, filterName, filterType, filterTypeCaps) => {
    let attendeeCountExist = this.props.attendees && this.props.attendees.filterCnt && Object.keys(this.props.attendees.filterCnt).length;
    if (filterOptionsSelected.length === 1) {
      if (filterType === 'status' || filterType === 'source') {
        let selectedOne = this.singleChecked(filterOptionsSelected[0]);
        return `${selectedOne} (${this.state[`total${filterTypeCaps}InTheSelectedOption`]})`;
      } else if (filterType === 'isActive') {
        let isEnabled = filterOptionsSelected[0] === 'false' ? this.props.users.languageStateForRedux?.dropdowns?.attendees?.disabled : this.props.users.languageStateForRedux?.dropdowns?.attendees?.enabled;
        return `${isEnabled} (${this.state[`total${filterTypeCaps}InTheSelectedOption`]})`;
      } else if (filterType === 'group') {
        let singleFilteredGroup = this.state.groupFilterOptionsList.filter(group => group._id === filterOptionsSelected[0]);
        return `${singleFilteredGroup[0].attendeesGroupName}  (${this.state[`total${filterTypeCaps}InTheSelectedOption`]})`;
      }

    } else if (filterOptionsSelected.length !== 0) {
      if (filterOptionsSelectedAll) {
        return (this.props.users.languageStateForRedux?.common?.all) + ` ${filterName} (${attendeeCountExist ? this.props.attendees.filterCnt?.[filterType]?.All : 0})`;
      } else {
        return `${filterOptionsSelected.length} ${filterName}  ` + (this.props.users.languageStateForRedux?.dropdowns?.attendees?.selected) + ` (${this.state[`total${filterTypeCaps}InTheSelectedOption`]})`;
      }
    } else {
      return (this.props.users.languageStateForRedux?.common?.all) + ` ${filterName} (${attendeeCountExist ? (this.props.attendees.filterCnt?.[filterType]?.All ? this.props.attendees.filterCnt?.[filterType]?.All : 0) : 0})`;
    }
  };


  getProgressCountOfAttendees = (params) => {
    const users = UserData();
    const eventSource = new EventSource(`${BACKEND_API_URL}/attendees/import-progress/${params.importAttendeesId}/${params.projectId}?token=bearer ${users.token}`);
    eventSource.onmessage = event => {
      //we will get error if any error occured in the import|copy progress
      if (event.data === 'error') {
        //closing eventSource connection
        eventSource.close();
        createNotification('error', 'Error occured in the progress!');
        //hiding progress bar popup
        this.setState({
          showProgress: false,
          import: false,
          progressPercentage: 0
        });
      } else {
        const progress = parseInt(event.data);
        this.setState({
          progressPercentage: progress
        });

        //if the progress completed 
        if (progress === 100) {
          //close progress popup & open successful messsage after some delay
          setTimeout(() => {
            this.getAttendeesList();
            this.setState({
              showProgress: false,
              import: false,
              openSuccess: true,
              progressPercentage: 0
            });
          }, 500);
          eventSource.close();
        }
      }
    };
  };

  hideProgress = () => {
    this.setState({
      showProgress: false
    });
  };

  afterBadgePrint = () => {
    this.setState({ openPrintBadgeModal: false });
  };

  handlePrintError = (error) => {
    // Handle the error here
  };

  showRestOfGroups = (group) => {
    let groupLength = group.length;
    return group.slice(1, groupLength);
  };

  customOptionLabel = (prop) => {
    return <div>
      {prop?.source === "GoogleWorkspace" && <img src={GoogleWorkspace} style={{ marginRight: "5px" }} alt="google workspace" width="14px" />}
      <span>{' '}</span>
      <span>{prop.label}</span>
    </div>;
  };

  getOptionsForEditAttendeesGroups = () => {
    let arrayToMapOver = [];
    if (this.state.sourceOfAttendeeForEdit === "Google Workspace") {
      arrayToMapOver = this.props.attendees.attendeesGroups.filter((o) => {
        if (o.source !== 'GoogleWorkspace') {
          return o;
        }
      });
    } else {
      arrayToMapOver = this.props.attendees.attendeesGroups.filter((o) => o.source !== 'GoogleWorkspace');
    }
    return arrayToMapOver.map((o) => { return { label: o.attendeesGroupName, value: o._id, source: o.source }; });
  };

  isOptionDisabled = (option) => {
    return this.state?.selectedGroups?.includes({ ...option, source: "GoogleWorkspace" });
  };

  handleGroupsSelectChange = (selected, actionMeta) => {
    switch (actionMeta.action) {
      case 'remove-value':
        this.setState({
          selectedGroups: this.state.selectedGroups.filter(g => g.value !== actionMeta.removedValue.value)
        });
        break;
      case 'pop-value':
        return selected;
      case 'select-option':
        this.setState({
          selectedGroups: selected
        });
        break;
      case 'clear':
        selected = actionMeta.removedValues.filter(g => g.source === "GoogleWorkspace");
        this.setState({
          selectedGroups: selected
        });
        break;
      default:
        return selected;
    }
  };

  uploadFromFiles = () => {
    this.setState({
      uploadFromFiles: true,
      buttonState: false
    });
  };

  handleCloseFileModulePopup = () => {
    this.setState({
      uploadFromFiles: false,
      buttonState: true
    });
  };


  handleChoosedFile = (file) => {
    if (this.state.openAttendeeForm === false) {

      this.setState(
        {
          s3File: file,
          confirmImage: file,
          destinationFolder: "profilePictures",
          fileNameInS3: file.fileNameInS3,
          uploadFromFiles: false,
          cropOpen: true,
          openEditAttendeeModal: false
        }
      );
    }
    else {
      this.setState(
        {
          s3File: file,
          confirmImage: file,
          destinationFolder: "profilePictures",
          fileNameInS3: file.fileNameInS3,
          uploadFromFiles: false,
          displayS3Image: true,
          cropOpen: true
        }
      );
    }
  };

  chooseAttendeeProfileImageClick = (id) => {
    console.log("aaaaaaaaaaaaaaaa");
    this.setState({
      parentId: id
    });
    this.chooseAttendeeProfileImage.current.click();
  };

  handleFileChosen = (file) => {
    this.setState({ fileNameInS3: file?.fileNameInS3 });
  };
  render() {
    const {
      showProgress,
      progressPercentage,
      accessCode,
      isAccessCode,
      getUnassignAccessCode,
      openAssignAccessCode,
      customFieldsForEdit,
      fieldLabel,
      checkinDate,
      fieldType,
      errors,
      isEdit,
      email,
      open,
      open1,
      open2,
      open3,
      open4,
      open5,
      openAction,
      page_no,
      loading,
      openSettings,
      openAttendeesSettings,
      statusFilterOptionsList,
      statusFilterOptionsSelected,
      sourceFilterOptionsList,
      sourceFilterOptionsSelected,
      groupFilterOptionsList,
      groupFilterOptionsSelected,
      isActiveFilterOptionsList,
      isActiveFilterOptionsSelected,
      isSystemUserFilterOptionsList,
      isSystemUserFilterOptionsSelected,
      isDisabledButton,
      isGroupsComp,
      startCount,
      isCheckinButton,
      groupid,
      endCount,
      itemCount, 
      emailForEdit,
      sort_by,
      sortOrder,
      openValidation,
      openSuccess,
      isTransactionEmailsComp,
      isBadgesComp,
      getAttendeesLoading,
      listValue,
      checkboxValue,
      outBoundEmailReport,
      outBoundEmailSelected,
      containsGoogleWorkspaceAttendee,
      badgesTemplates,
      badgeOutput,
      badgePrintSettings,
      department,
      userId,
      fileNameInS3,
      token
    } = this.state;
    const { customFields } = this.props.attendees;
    const statusFilterOptionsSelectedAll =
      statusFilterOptionsSelected.length === statusFilterOptionsList.length
        ? true
        : false;
    const sourceFilterOptionsSelectedAll =
      sourceFilterOptionsSelected.length === sourceFilterOptionsList.length
        ? true
        : false;
    const groupFilterOptionsSelectedAll =
      groupFilterOptionsSelected.length === groupFilterOptionsList.length
        ? true
        : false;
    const isActiveFilterOptionsSelectedAll =
      isActiveFilterOptionsSelected.length === isActiveFilterOptionsList.length
        ? true
        : false;

    const isSystemUserFilterOptionsSelectedAll =
      isSystemUserFilterOptionsSelected.length === isSystemUserFilterOptionsList.length
        ? true
        : false;

    return (
      <ThemeProvider theme={theme}>
        {this.props.attendees.isReportComp ? (
          outBoundEmailReport ? (
            <EmailAnalytics
              project={this.props.project}
              closeReport={this.closeOutBoundEmailReport}
              outBoundEmailSelected={outBoundEmailSelected}
            />
          ) : (
            <EmailAnalytics
              project={this.props.project}
              closeReport={this.closeReport}
              selectedEmailType={'transactional'}
            />
          )
        ) : (
          <>
            {isGroupsComp && !isTransactionEmailsComp ? (
              <Groups closeGroups={this.closeGroups} getAttendeesList={this.getAttendeesList} />
            )

              : !isGroupsComp && isTransactionEmailsComp ? (
                <TransactionalEmails
                  closeTransactionEmailsModal={this.closeTransactionEmailsModal}
                />
              )
                : !isGroupsComp && !isTransactionEmailsComp && isBadgesComp ? (
                  <BadgesQrCodeTemplate
                    closeBadgesModel={this.closeBadgesModel}
                  />
                )
                  : (
                    <>
                      <Grid container spacing={2} mb={2} className="top-filter-area">
                        <Grid size={{ lg: "grow", }} >
                          <Grid container spacing={1}>
                            <Grid >
                              <Box
                                component={Button}
                                variant="contained"
                                sx={{
                                  backgroundColor: theme.palette.background.light,
                                  color: theme.palette.common.black,
                                  textTransform: 'capitalize !important',
                                  fontWeight: '400',
                                  '&:hover, &.active': {
                                    backgroundColor: '#000056 !important',
                                    color: theme.palette.primary.contrastText,
                                  },
                                }}
                                size="small"
                                className={
                                  statusFilterOptionsSelected.length ? 'active' : ''
                                }
                                disableElevation
                                ref={this.anchorRef1}
                                aria-controls={open1 ? 'menu-list-grow' : undefined}
                                aria-haspopup="true"
                                onClick={this.handleToggle1}
                                endIcon={<ArrowDropDownIcon />}
                              >
                                {
                                  this.filterText(statusFilterOptionsSelected, statusFilterOptionsSelectedAll, this.props.users.languageStateForRedux?.unlayer?.modalSettings?.attendees, 'status', 'Status')
                                }
                              </Box>
                              <Popper
                                open={open1}
                                placement="bottom-start"
                                className="filter-options"
                                transition
                                anchorEl={this.anchorRef1.current}
                                role={undefined}
                                disablePortal
                              >
                                {({ TransitionProps, placement }) => (
                                  <Fade {...TransitionProps} timeout={250}>
                                    <Box
                                      className="option-list"
                                      sx={{
                                        backgroundColor:
                                          theme.palette.background.light,
                                        borderRadius: '4px',
                                        marginTop: '8px',
                                        overflowY: 'scroll',
                                        maxHeight: 200,
                                      }}
                                    >
                                      <ClickAwayListener
                                        onClickAway={this.filterHandleClose}
                                      >
                                        <MenuList
                                          autoFocusItem={open}
                                          id="menu-list-grow"
                                          onKeyDown={this.handleListKeyDown}
                                        >
                                          <MenuItem className="checkbox-item">
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  className="checkbox"

                                                  color="default"
                                                  name="all"
                                                  onChange={(e) =>
                                                    this.handleFilterChange(
                                                      e,
                                                      'status',
                                                    )
                                                  }
                                                  checked={
                                                    statusFilterOptionsSelectedAll
                                                  }
                                                />
                                              }
                                              label={(this.props.users.languageStateForRedux?.common?.all) + ` (${Object.keys(
                                                this.props.attendees.filterCnt,
                                              ).length
                                                ? this.props.attendees.filterCnt
                                                  ?.status?.All
                                                : 0
                                                })`}
                                              sx={{ padding: 0 }}
                                            />
                                          </MenuItem>
                                          {statusFilterOptionsList.map((option) => {
                                            return (
                                              <MenuItem
                                                key={option}
                                                className="checkbox-item"
                                              >
                                                <FormControlLabel
                                                  control={
                                                    <Checkbox
                                                      className="checkbox"
                                                      color="default"
                                                      name={option}
                                                      onChange={(e) =>
                                                        this.handleFilterChange(
                                                          e,
                                                          'status',
                                                        )
                                                      }
                                                      checked={
                                                        statusFilterOptionsSelected.indexOf(
                                                          option,
                                                        ) > -1
                                                          ? true
                                                          : false
                                                      }
                                                    />
                                                  }
                                                  label={(option === 'Checked In' ? this.props.users.languageStateForRedux?.column_names?.attendees?.checkedIn
                                                    : option === 'Checked Out' ? this.props.users.languageStateForRedux?.column_names?.attendees?.checkedOut
                                                      : option === 'Not Checked In' ? this.props.users.languageStateForRedux?.column_names?.attendees?.notChecked
                                                        : null) + `  (${Object.keys(
                                                          this.props.attendees.filterCnt,
                                                        ).length
                                                          ? this.props.attendees.filterCnt
                                                            ?.status?.filterByStatus[option]
                                                            ? this.props.attendees
                                                              .filterCnt?.status?.filterByStatus[option]
                                                            : 0
                                                          : 0
                                                        })`}
                                                />
                                              </MenuItem>
                                            );
                                          })}
                                        </MenuList>
                                      </ClickAwayListener>
                                    </Box>
                                  </Fade>
                                )}
                              </Popper>
                            </Grid>
                            <Grid >
                              <Box
                                component={Button}
                                variant="contained"
                                sx={{
                                  backgroundColor: theme.palette.background.light,
                                  color: theme.palette.common.black,
                                  textTransform: 'capitalize !important',
                                  fontWeight: '400',

                                  '&:hover, &.active': {
                                    backgroundColor: '#000056 !important',
                                    color: theme.palette.primary.contrastText,
                                  },
                                }}
                                size="small"
                                className={
                                  sourceFilterOptionsSelected.length ? 'active' : ''
                                }
                                disableElevation
                                ref={this.anchorRef2}
                                aria-controls={open2 ? 'menu-list-grow' : undefined}
                                aria-haspopup="true"
                                onClick={this.handleToggle2}
                                endIcon={<ArrowDropDownIcon />}
                              >
                                {
                                  this.filterText(sourceFilterOptionsSelected, sourceFilterOptionsSelectedAll, this.props.users.languageStateForRedux?.dropdowns?.attendees?.sources, 'source', 'Source')
                                }

                              </Box>
                              <Popper
                                open={open2}
                                sx={{ top: 10 }}
                                className="filter-options"
                                placement="bottom-start"
                                transition
                                anchorEl={this.anchorRef2.current}
                                role={undefined}
                                disablePortal
                              >
                                {({ TransitionProps, placement }) => (
                                  <Fade {...TransitionProps} timeout={250}>
                                    <Box
                                      className="option-list"
                                      sx={{
                                        backgroundColor:
                                          theme.palette.background.light,
                                        borderRadius: '4px',
                                        marginTop: '8px',
                                        overflowY: 'scroll',
                                        maxHeight: 200,
                                      }}
                                    >
                                      <ClickAwayListener
                                        onClickAway={this.filterHandleClose}
                                      >
                                        <MenuList
                                          autoFocusItem={open}
                                          id="menu-list-grow"
                                          onKeyDown={this.handleListKeyDown}
                                        >
                                          <MenuItem className="checkbox-item">
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  className="checkbox"

                                                  color="default"
                                                  name="all"
                                                  onChange={(e) =>
                                                    this.handleFilterChange(
                                                      e,
                                                      'source',
                                                    )
                                                  }
                                                  checked={
                                                    sourceFilterOptionsSelectedAll
                                                  }
                                                />
                                              }
                                              label={(this.props.users.languageStateForRedux?.common?.all) + ` (${Object.keys(this.props.attendees.filterCnt,).length ? this.props.attendees.filterCnt?.source?.All : 0})`}
                                              sx={{ padding: 0 }}
                                            />
                                          </MenuItem>
                                          {sourceFilterOptionsList.map((option) => {
                                            return (
                                              <MenuItem
                                                key={option}
                                                className="checkbox-item"
                                              >
                                                <FormControlLabel
                                                  control={
                                                    <Checkbox
                                                      className="checkbox"
                                                      color="default"
                                                      name={option}
                                                      onChange={(e) =>
                                                        this.handleFilterChange(
                                                          e,
                                                          'source',
                                                        )
                                                      }
                                                      checked={
                                                        sourceFilterOptionsSelected.indexOf(
                                                          option,
                                                        ) > -1
                                                          ? true
                                                          : false
                                                      }
                                                    />
                                                  }
                                                  label={(option === 'Registration Page' ? this.props.users.languageStateForRedux?.dropdowns?.attendees?.registration
                                                    : option === 'Imported' ? this.props.users.languageStateForRedux?.dropdowns?.accessCodes?.imported
                                                      : option === 'Manually Added' ? this.props.users.languageStateForRedux?.dropdowns?.attendees?.manAdded
                                                        : option === 'Webhooks' ? this.props.users.languageStateForRedux?.dropdowns?.attendees?.webhooks
                                                          : option === 'Google Workspace' ? this.props.users.languageStateForRedux?.dropdowns?.attendees?.googleSpace
                                                            : option === 'Meeting' ? this.props.users.languageStateForRedux?.formfields?.meetings?.meeting
                                                              : null) + `  (${Object.keys(
                                                                this.props.attendees.filterCnt,
                                                              ).length
                                                                ? this.props.attendees.filterCnt
                                                                  ?.source?.filterBySource[option]
                                                                  ? this.props.attendees
                                                                    .filterCnt?.source?.filterBySource[option]
                                                                  : 0
                                                                : 0
                                                              })`}
                                                />
                                              </MenuItem>
                                            );
                                          })}
                                        </MenuList>
                                      </ClickAwayListener>
                                    </Box>
                                  </Fade>
                                )}
                              </Popper>
                            </Grid>
                            <Grid disabled={true}>
                              <Box
                                component={Button}
                                variant="contained"
                                sx={{
                                  backgroundColor: theme.palette.background.light,
                                  color: theme.palette.common.black,
                                  textTransform: 'capitalize !important',
                                  fontWeight: '400',

                                  '&:hover, &.active': {
                                    backgroundColor: '#000056 !important',
                                    color: theme.palette.primary.contrastText,
                                  },
                                }}
                                size="small"
                                className={
                                  groupFilterOptionsSelected?.length ? 'active' : ''
                                }
                                disableElevation
                                ref={this.anchorRef3}
                                aria-controls={open3 ? 'menu-list-grow' : undefined}
                                aria-haspopup="true"
                                onClick={this.handleToggle3}
                                disabled={
                                  this.props?.attendees?.filterCnt?.group?.All > 0
                                    ? false
                                    : true
                                }
                                endIcon={<ArrowDropDownIcon />}
                              >
                                {
                                  this.filterText(groupFilterOptionsSelected, groupFilterOptionsSelectedAll, this.props.users.languageStateForRedux?.dropdowns?.attendees?.groups, 'group', 'Group')
                                }
                                {' '}
                              </Box>
                              <Popper
                                open={open3}
                                sx={{ top: 10 }}
                                className="filter-options"
                                placement="bottom-start"
                                transition
                                anchorEl={this.anchorRef3.current}
                                role={undefined}
                                disablePortal
                              >
                                {({ TransitionProps, placement }) => (
                                  <Fade {...TransitionProps} timeout={250}>
                                    <Box
                                      className="option-list"
                                      sx={{
                                        backgroundColor:
                                          theme.palette.background.light,
                                        borderRadius: '4px',
                                        marginTop: '8px',
                                        overflowY: 'scroll',
                                        maxHeight: 200,
                                      }}
                                    >
                                      <ClickAwayListener
                                        onClickAway={this.filterHandleClose}
                                      >
                                        <MenuList
                                          autoFocusItem={open}
                                          id="menu-list-grow"
                                          onKeyDown={this.handleListKeyDown}
                                        >
                                          <MenuItem className="checkbox-item">
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  className="checkbox"

                                                  color="default"
                                                  name="all"
                                                  onChange={(e) =>
                                                    this.handleFilterChange(
                                                      e,
                                                      'group',
                                                    )
                                                  }
                                                  checked={
                                                    groupFilterOptionsSelectedAll
                                                  }
                                                />
                                              }
                                              label={(this.props.users.languageStateForRedux?.common?.all) + ` (${this.props?.attendees?.filterCnt?.group
                                                ? this.props.attendees.filterCnt.group.All
                                                : 0
                                                })`}
                                              sx={{ padding: 0 }}
                                            />
                                          </MenuItem>
                                          {groupFilterOptionsList.map((option) => {
                                            return (
                                              <MenuItem
                                                key={option?._id}
                                                className="checkbox-item"
                                              >
                                                <FormControlLabel
                                                  control={
                                                    <>
                                                      <Checkbox
                                                        className="checkbox"
                                                        color="default"
                                                        name={option?._id}
                                                        onChange={(e) =>
                                                          this.handleFilterChange(
                                                            e,
                                                            'group',
                                                          )
                                                        }
                                                        checked={
                                                          groupFilterOptionsSelected.indexOf(
                                                            option?._id,
                                                          ) > -1
                                                            ? true
                                                            : false
                                                        }
                                                      />
                                                      {option?.source === "GoogleWorkspace" && <img src={GoogleWorkspace} alt="google workspace" width="14px" style={{ marginRight: "5px" }} />}
                                                    </>
                                                  }

                                                  label={`${option?.attendeesGroupName
                                                    } (${Object.keys(
                                                      this.props.attendees.filterCnt,
                                                    ).length
                                                      ? this.props.attendees.filterCnt
                                                        ?.group?.filterByGroup[option._id]
                                                        ? this.props.attendees
                                                          .filterCnt?.group?.filterByGroup[option._id]
                                                        : 0
                                                      : 0
                                                    })`}
                                                />

                                              </MenuItem>
                                            );
                                          })}
                                        </MenuList>
                                      </ClickAwayListener>
                                    </Box>
                                  </Fade>
                                )}
                              </Popper>
                            </Grid>
                            <Grid>
                              <Box
                                component={Button}
                                variant="contained"
                                sx={{
                                  backgroundColor: theme.palette.background.light,
                                  color: theme.palette.common.black,
                                  textTransform: 'capitalize !important',
                                  fontWeight: '400',
                                  '&:hover, &.active': {
                                    backgroundColor: '#000056 !important',
                                    color: theme.palette.primary.contrastText,
                                  },
                                }}
                                size="small"
                                className={
                                  isActiveFilterOptionsSelected.length ? 'active' : ''
                                }
                                disableElevation
                                ref={this.anchorRef4}
                                aria-controls={open4 ? 'menu-list-grow' : undefined}
                                aria-haspopup="true"
                                onClick={this.handleToggle4}
                                endIcon={<ArrowDropDownIcon />}
                              >
                                {/* {`All Status (${Object.keys(this.props.attendees.filterCnt).length
                            ? this.props.attendees.filterCnt?.isActive?.All
                            : 0
                            })`} */}
                                {
                                  this.filterText(isActiveFilterOptionsSelected, isActiveFilterOptionsSelectedAll, this.props.users.languageStateForRedux?.common?.status, 'isActive', 'IsActive')
                                }
                              </Box>

                              <Popper
                                open={open4}
                                placement="bottom-start"
                                className="filter-options"
                                transition
                                anchorEl={this.anchorRef4.current}
                                role={undefined}
                                disablePortal
                              >
                                {({ TransitionProps, placement }) => (
                                  <Fade {...TransitionProps} timeout={250}>
                                    <Box
                                      className="option-list"
                                      sx={{
                                        backgroundColor:
                                          theme.palette.background.light,
                                        borderRadius: '4px',
                                        marginTop: '8px',
                                        overflowY: 'scroll',
                                        maxHeight: 200,
                                      }}
                                    >
                                      <ClickAwayListener
                                        onClickAway={this.filterHandleClose}
                                      >
                                        <MenuList
                                          autoFocusItem={open}
                                          id="menu-list-grow"
                                          onKeyDown={this.handleListKeyDown}
                                        >
                                          <MenuItem className="checkbox-item">
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  className="checkbox"

                                                  color="default"
                                                  name="all"
                                                  onChange={(e) =>
                                                    this.handleFilterChange(
                                                      e,
                                                      'isActive',
                                                    )
                                                  }
                                                  checked={
                                                    isActiveFilterOptionsSelectedAll
                                                  }
                                                />
                                              }
                                              label={(this.props.users.languageStateForRedux?.common?.all) + ` (${Object.keys(
                                                this.props.attendees.filterCnt,
                                              ).length
                                                ? this.props.attendees.filterCnt
                                                  ?.isActive?.All
                                                : 0
                                                })`}
                                              sx={{ padding: 0 }}
                                            />
                                          </MenuItem>
                                          {isActiveFilterOptionsList.map((option) => {
                                            return (
                                              <MenuItem
                                                key={option}
                                                className="checkbox-item"
                                              >
                                                <FormControlLabel
                                                  control={
                                                    <Checkbox
                                                      className="checkbox"
                                                      color="default"
                                                      name={option}
                                                      onChange={(e) =>
                                                        this.handleFilterChange(
                                                          e,
                                                          'isActive',
                                                        )
                                                      }
                                                      checked={
                                                        isActiveFilterOptionsSelected.indexOf(
                                                          option,
                                                        ) > -1
                                                          ? true
                                                          : false
                                                      }
                                                    />
                                                  }
                                                  label={`${option === 'true'
                                                    ? this.props.users.languageStateForRedux?.dropdowns?.attendees?.enabled
                                                    : this.props.users.languageStateForRedux?.dropdowns?.attendees?.disabled
                                                    } (${Object.keys(
                                                      this.props.attendees.filterCnt,
                                                    ).length
                                                      ? this.props.attendees.filterCnt
                                                        ?.isActive?.filterByIsActive[option]
                                                        ? this.props.attendees
                                                          .filterCnt?.isActive?.filterByIsActive[
                                                        option
                                                        ]
                                                        : 0
                                                      : 0
                                                    })`}
                                                />
                                              </MenuItem>
                                            );
                                          })}
                                        </MenuList>
                                      </ClickAwayListener>
                                    </Box>
                                  </Fade>
                                )}
                              </Popper>
                            </Grid>
                            <Grid >
                              <Box
                                component={Button}
                                variant="contained"
                                sx={{
                                  backgroundColor: theme.palette.background.light,
                                  color: theme.palette.common.black,
                                  textTransform: 'capitalize !important',
                                  fontWeight: '400',
                                  '&:hover, &.active': {
                                    backgroundColor: '#000056 !important',
                                    color: theme.palette.primary.contrastText,
                                  },
                                }}
                                size="small"
                                className={
                                  isSystemUserFilterOptionsSelected.length ? 'active' : ''
                                }
                                disableElevation
                                ref={this.anchorRef5}
                                aria-controls={open5 ? 'menu-list-grow' : undefined}
                                aria-haspopup="true"
                                onClick={this.handleToggle5}
                                endIcon={<ArrowDropDownIcon />}
                              >

                                {(isSystemUserFilterOptionsSelected.length === 2 || isSystemUserFilterOptionsSelected.length === 0) ? ((this.props.users.languageStateForRedux?.dropdowns?.attendees?.allType) + ` (${Object.keys(this.props.attendees.filterCnt).length ? this.props.attendees.filterCnt?.isSystemUser?.AllType : 0})`) : isSystemUserFilterOptionsSelected.includes('true') ? (this.props.users.languageStateForRedux?.dropdowns?.attendees?.kiosk) + ` (${this.props.attendees?.filterCnt?.isSystemUser?.filterByType?.true ? this.props.attendees?.filterCnt?.isSystemUser?.filterByType?.true : 0})` :
                                  (this.props.users.languageStateForRedux?.dropdowns?.attendees?.attendee) + ` (${this.props.attendees?.filterCnt?.isSystemUser?.filterByType?.false})`}
                              </Box>
                              <Popper
                                open={open5}
                                placement="bottom-start"
                                className="filter-options"
                                transition
                                anchorEl={this.anchorRef5.current}
                                role={undefined}
                                disablePortal
                              >
                                {({ TransitionProps, placement }) => (
                                  <Fade {...TransitionProps} timeout={250}>
                                    <Box
                                      className="option-list"
                                      sx={{
                                        backgroundColor:
                                          theme.palette.background.light,
                                        borderRadius: '4px',
                                        marginTop: '8px',
                                        overflowY: 'scroll',
                                        maxHeight: 200,
                                      }}
                                    >
                                      <ClickAwayListener
                                        onClickAway={this.filterHandleClose}
                                      >
                                        <MenuList
                                          autoFocusItem={open}
                                          id="menu-list-grow"
                                          onKeyDown={this.handleListKeyDown}
                                        >
                                          <MenuItem className="checkbox-item">
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  className="checkbox"

                                                  color="default"
                                                  name="all"
                                                  onChange={(e) =>
                                                    this.handleFilterChange(
                                                      e,
                                                      'isSystemUser',
                                                    )
                                                  }
                                                  checked={
                                                    isSystemUserFilterOptionsSelectedAll
                                                  }
                                                />
                                              }
                                              label={(this.props.users.languageStateForRedux?.common?.all) + ` (${Object.keys(
                                                this.props.attendees.filterCnt,
                                              ).length
                                                ? this.props.attendees.filterCnt?.isSystemUser?.AllType
                                                : 0
                                                })`}
                                              sx={{ padding: 0 }}
                                            />
                                          </MenuItem>

                                          {isSystemUserFilterOptionsList.map((option) => {
                                            return (
                                              <MenuItem
                                                key={option}
                                                className="checkbox-item"
                                              >
                                                <FormControlLabel
                                                  control={
                                                    <Checkbox
                                                      className="checkbox"
                                                      color="default"
                                                      name={option}
                                                      onChange={(e) =>
                                                        this.handleFilterChange(
                                                          e,
                                                          'isSystemUser',
                                                        )
                                                      }
                                                      checked={
                                                        isSystemUserFilterOptionsSelected.indexOf(
                                                          option,
                                                        ) > -1
                                                          ? true
                                                          : false
                                                      }
                                                    />
                                                  }
                                                  label={`${option === 'true'
                                                    ? this.props.users.languageStateForRedux?.dropdowns?.attendees?.kiosk
                                                    : this.props.users.languageStateForRedux?.dropdowns?.attendees?.attendee
                                                    } (${Object.keys(
                                                      this.props.attendees.filterCnt,
                                                    ).length
                                                      ? this.props.attendees.filterCnt
                                                        ?.isSystemUser?.filterByType[option]
                                                        ? this.props.attendees
                                                          .filterCnt?.isSystemUser?.filterByType[
                                                        option
                                                        ]
                                                        : 0
                                                      : 0
                                                    })`}
                                                />
                                              </MenuItem>
                                            );
                                          })}
                                        </MenuList>
                                      </ClickAwayListener>
                                    </Box>
                                  </Fade>
                                )}
                              </Popper>
                            </Grid>
                            <Grid >
                              <Box
                                component={Button}
                                variant="contained"
                                sx={{
                                  textTransform: 'capitalize !important',
                                  fontWeight: '400',
                                  backgroundColor: '#000056 !important',
                                  color: '#ffffff !important',
                                }}
                                size="small"
                              >
                                {this.checkBoxIdsArr.length} {this.props.users.languageStateForRedux?.dropdowns?.attendees?.selected}
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid >
                          <Grid container spacing={1.25} className="team-dash-right">
                            <Grid className="">
                              <form onSubmit={this.handleSearchSubmit}>
                                <TextField
                                  name="searchText"
                                  variant='standard'
                                  placeholder={this.props.users.languageStateForRedux?.common?.search}
                                  className="search"
                                  size="small"
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <img alt="" src={SearchIcon} />
                                      </InputAdornment>
                                    ),
                                  }}
                                  value={this.state.searchText}
                                  onChange={this.handleChange}
                                />
                              </form>
                            </Grid>
                            <Grid >
                              <div>
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  disableElevation
                                  ref={this.anchorRefAction}
                                  aria-controls={
                                    openAction ? 'menu-list-grow' : undefined
                                  }
                                  aria-haspopup="true"
                                  onClick={this.handleToggleAction}
                                  endIcon={<ArrowDropDownIcon />}
                                >
                                  {this.props.users.languageStateForRedux?.dropdowns?.common?.actions}
                                </Button>
                                <Popper
                                  open={openAction}
                                  sx={{ top: 10 }}
                                  className="filter-options"
                                  placement="bottom-start"
                                  transition
                                  anchorEl={this.anchorRefAction.current}
                                  role={undefined}
                                  disablePortal
                                >
                                  {({ TransitionProps, placement }) => (
                                    <Fade {...TransitionProps} timeout={250}>
                                      <Box
                                        sx={{
                                          backgroundColor: theme.palette.common.white,
                                          boxShadow:
                                            '0px 6px 20px rgba(0, 0, 0, 0.2)',
                                          borderRadius: '4px',
                                          marginTop: '8px',
                                        }}
                                      >
                                        <ClickAwayListener
                                          onClickAway={this.filterHandleClose}
                                        >
                                          <MenuList
                                            autoFocusItem={openAction}
                                            id="menu-list-grow"
                                            onKeyDown={this.handleListKeyDown}
                                          >
                                            {
                                              authPermissionValidation(permissionsConstants.checkInCheckOutAttendee) && (
                                                <>
                                                  <MenuItem
                                                    disabled={this.isDisabled()}
                                                    onClick={this.openSetCheckinBox}
                                                  >
                                                    {this.props.users.languageStateForRedux?.column_names?.attendees?.checkedIn}
                                                  </MenuItem>
                                                  <MenuItem
                                                    disabled={this.isDisabled()}
                                                    onClick={this.openSetCheckoutBox}
                                                  >
                                                    {this.props.users.languageStateForRedux?.column_names?.attendees?.checkedOut}

                                                  </MenuItem>
                                                </>
                                              )}
                                            {
                                              authPermissionValidation(permissionsConstants.editAttendee) && (
                                                <>
                                                  <MenuItem
                                                    disabled={
                                                      this.props?.attendees
                                                        ?.totalGroupsToListInGroupsTab > 0
                                                        ? this.isDisabled()
                                                        : true
                                                    }
                                                    onClick={
                                                      this
                                                        .handleClickOpenAssignGroupModal
                                                    }
                                                  >
                                                    {this.props.users.languageStateForRedux?.dropdowns?.attendees?.assignGroup}

                                                  </MenuItem>
                                                  <MenuItem
                                                    disabled={
                                                      this.props?.attendees
                                                        ?.totalGroupsToListInGroupsTab > 0
                                                        ? this.isDisabled()
                                                        : true
                                                    }
                                                    onClick={
                                                      this
                                                        .handleClickOpenUnassignGroupModal
                                                    }
                                                  >
                                                    {this.props.users.languageStateForRedux?.dropdowns?.attendees?.unAssignGroup}
                                                  </MenuItem>
                                                </>
                                              )}
                                            {
                                              authPermissionValidation(permissionsConstants.deleteAttendee) && (
                                                <MenuItem
                                                  disabled={
                                                    !containsGoogleWorkspaceAttendee ?
                                                      (this.checkBoxforDisable.length <= 0) ? true : false : true
                                                  }
                                                  onClick={this.openDeleteAttendeeBox}
                                                  style={{
                                                    color: theme.palette.secondary.main,
                                                  }}
                                                >
                                                  {this.props.users.languageStateForRedux?.common?.delete}
                                                </MenuItem>
                                              )}
                                            {
                                              authPermissionValidation(permissionsConstants.importAttendee) && (
                                                <MenuItem
                                                  disabled={false}
                                                  onClick={
                                                    this.handleClickOpenImportModal
                                                  }
                                                >
                                                  {this.props.users.languageStateForRedux?.common?.import}
                                                </MenuItem>
                                              )}
                                            {
                                              authPermissionValidation(permissionsConstants.editAttendee) && (
                                                <>
                                                  <MenuItem
                                                    disabled={this.isDisabled()}
                                                    onClick={this.openSetEnabledBox}
                                                  >
                                                    {this.props.users.languageStateForRedux?.dropdowns?.attendees?.enabledUpperCase}
                                                  </MenuItem>
                                                  <MenuItem
                                                    disabled={this.isDisabled()}
                                                    onClick={this.openSetDisabledBox}
                                                  >
                                                    {this.props.users.languageStateForRedux?.dropdowns?.attendees?.disabledUpperCase}
                                                  </MenuItem>
                                                </>
                                              )}
                                            {
                                              authPermissionValidation(permissionsConstants.exportAttendees)
                                              && (
                                                <>
                                                  <MenuItem
                                                    disabled={this.isDisabled()}
                                                    onClick={this.exportAttendeesCsv}
                                                  >
                                                    {this.props.users.languageStateForRedux?.common?.export}
                                                  </MenuItem>
                                                  <MenuItem
                                                    disabled={
                                                      Array.isArray(
                                                        this.props.attendees.attendees,
                                                      ) &&
                                                        (this.props.attendees.attendees
                                                          .length > 0) && this.checkBoxIdsArr.length > 0
                                                        ? true
                                                        : false
                                                    }
                                                    onClick={this.exportAttendeesCsv}
                                                  >
                                                    {this.props.users.languageStateForRedux?.dropdowns?.accessCodes?.exportAll}
                                                  </MenuItem>
                                                </>
                                              )}
                                            {
                                              authPermissionValidation(permissionsConstants.viewOutboundEmailsReports)
                                              && (
                                                <MenuItem onClick={this.openReport}>
                                                  {this.props.users.languageStateForRedux?.dropdowns?.attendees?.transactionEmailReport}
                                                </MenuItem>
                                              )}
                                          </MenuList>
                                        </ClickAwayListener>
                                      </Box>
                                    </Fade>
                                  )}
                                </Popper>
                              </div>
                            </Grid>

                            <Grid >
                              <div>
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  disableElevation
                                  ref={this.anchorRef}
                                  aria-controls={
                                    openSettings ? 'menu-list-grow' : undefined
                                  }
                                  aria-haspopup="true"
                                  onClick={this.handleToggle}
                                  endIcon={<ArrowDropDownIcon />}
                                >
                                  {this.props.users.languageStateForRedux?.dropdowns?.common?.settings}
                                </Button>
                                <Popper
                                  open={openSettings}
                                  className="filter-options"
                                  sx={{ top: 10 }}
                                  placement="bottom-end"
                                  transition
                                  anchorEl={this.anchorRef.current}
                                  role={undefined}
                                  disablePortal
                                >
                                  {({ TransitionProps, placement }) => (
                                    <Fade {...TransitionProps} timeout={250}>
                                      <Box
                                        sx={{
                                          backgroundColor: theme.palette.common.white,
                                          boxShadow:
                                            '0px 6px 20px rgba(0, 0, 0, 0.2)',
                                          borderRadius: '4px',
                                          marginTop: '8px',
                                        }}
                                      >
                                        <ClickAwayListener
                                          onClickAway={this.filterHandleClose}
                                        >
                                          <MenuList
                                            autoFocusItem={openSettings}
                                            id="menu-list-grow"
                                            onKeyDown={this.handleListKeyDown}
                                          >
                                            {
                                              authPermissionValidation(permissionsConstants.editCustomFields) && (
                                                <MenuItem
                                                  onClick={this.handleClickOpen}
                                                >
                                                  {this.props.users.languageStateForRedux?.dropdowns?.attendees?.custom}
                                                </MenuItem>
                                              )}
                                            {
                                              authPermissionValidation(permissionsConstants.editGroups) && (
                                                <MenuItem
                                                  disabled={false}
                                                  onClick={this.openGroups}
                                                >
                                                  {this.props.users.languageStateForRedux?.dropdowns?.attendees?.groups}
                                                </MenuItem>
                                              )}
                                            {
                                              authPermissionValidation(permissionsConstants.editTransactionalEmailTemplates)
                                              && (
                                                <MenuItem
                                                  disabled={false}
                                                  onClick={
                                                    this.openTransactionEmailsModal
                                                  }
                                                >
                                                  {this.props.users.languageStateForRedux?.dropdowns?.attendees?.transactionEmail}
                                                </MenuItem>
                                              )}
                                            { authPermissionValidation(permissionsConstants.viewBadge) && (
                                                <MenuItem
                                                  disabled={false}
                                                >
                                                  {
                                                    Array.isArray(badgesTemplates) && badgesTemplates.length > 0 &&
                                                    <Link
                                                      style={{ fontSize: '16px', width: '100%' }}
                                                      to={{ pathname: `/project-page/update-badges-template-page/pid/${badgesTemplates[0]?.eventId}/tid/${badgesTemplates[0]?._id}/pn/${badgesTemplates[0]?.pageName}/purl/${badgesTemplates[0]?.path}`, state: { hasRenderedByBadges: this.state.hasRenderedByBadges } }}>Badges</Link>
                                                  }
                                                </MenuItem>
                                              )}
                                          </MenuList>
                                        </ClickAwayListener>
                                      </Box>
                                    </Fade>
                                  )}
                                </Popper>
                              </div>
                            </Grid>

                            <Grid >
                              <div>
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  disableElevation
                                  ref={this.anchorRef6}
                                  aria-controls={
                                    openAttendeesSettings ? 'menu-list-grow' : undefined
                                  }
                                  aria-haspopup="true"
                                  onClick={this.handleAttendeesToggle}
                                  endIcon={<ArrowDropDownIcon />}
                                >
                                  {this.props.users.languageStateForRedux?.dropdowns?.attendees?.new}
                                </Button>
                                <Popper
                                  open={openAttendeesSettings}
                                  className="filter-options"
                                  sx={{ top: 10 }}
                                  placement="bottom-end"
                                  transition
                                  anchorEl={this.anchorRef6.current}
                                  role={undefined}
                                  disablePortal
                                >
                                  {({ TransitionProps, placement }) => (
                                    <Fade {...TransitionProps} timeout={250}>
                                      <Box
                                        sx={{
                                          backgroundColor: theme.palette.common.white,
                                          boxShadow:
                                            '0px 6px 20px rgba(0, 0, 0, 0.2)',
                                          borderRadius: '4px',
                                          marginTop: '8px',
                                        }}
                                      >
                                        <ClickAwayListener
                                          onClickAway={this.filterHandleClose}
                                        >
                                          <MenuList
                                            autoFocusItem={openAttendeesSettings}
                                            id="menu-list-grow"
                                            onKeyDown={this.handleListKeyDown}
                                          >
                                            { authPermissionValidation(permissionsConstants.createAttendee) && (
                                              <MenuItem
                                                onClick={this.handleClickOpenAttendeeForm}
                                              >
                                                {this.props.users.languageStateForRedux?.dropdowns?.attendees?.newAttendee}
                                              </MenuItem>
                                            )}
                                            { authPermissionValidation(permissionsConstants.addKioskUser) && (
                                              <MenuItem
                                                disabled={false}
                                                onClick={this.handleClickAddNewForm}
                                              >
                                                {this.props.users.languageStateForRedux?.dropdowns?.attendees?.newKiosk}
                                              </MenuItem>
                                            )}
                                          </MenuList>
                                        </ClickAwayListener>
                                      </Box>
                                    </Fade>
                                  )}
                                </Popper>
                              </div>
                            </Grid> 
                          </Grid>
                        </Grid>
                      </Grid>
                      <TableContainer
                        className="list-table"
                        style={{ maxHeight: 'calc(100vh - 380px)' }}
                      >
                        <Table className="table">
                          <TableHead
                            style={{
                              position: 'sticky',
                              top: '0',
                              backgroundColor: '#f6f6fc',
                              zIndex: 2,
                            }}
                          >
                            <TableRow>
                              <TableCell className="checkbox sticky-cell">
                                <Checkbox
                                  color="primary"
                                  p={0}
                                  onChange={(e) => {
                                    this.handleSelectAllAttendees(e);
                                  }}
                                  checked={this.state.selectAllChecked === true ? true : false}
                                />
                              </TableCell>
                              <TableCell
                                className="sticky-cell"
                                style={{
                                  maxWidth: '62px',
                                  minWidth: '62px',
                                  left: 50,
                                }}
                              ></TableCell>
                              <TableCell
                                className={
                                  'email sticky-cell ' +
                                  (sort_by === 'email'
                                    ? sortOrder === 'asc'
                                      ? 'sort-asc'
                                      : 'sort-des'
                                    : '')
                                }
                                style={{ left: 112 }}
                                onClick={() => this.sorting('email')}
                              >
                                {this.props.users.languageStateForRedux?.column_names?.attendees?.email}
                              </TableCell>
                              <TableCell
                                className={
                                  'status ' +
                                  (sort_by === 'status'
                                    ? sortOrder === 'asc'
                                      ? 'sort-asc'
                                      : 'sort-des'
                                    : '')
                                }
                                onClick={() => this.sorting('status')}
                              >
                                {this.props.users.languageStateForRedux?.common?.status}
                              </TableCell>
                              {this.props.project?.project?.isAccessCode && (
                                <TableCell
                                  className={
                                    'status ' +
                                    (sort_by === 'accessCodeExpiryDate'
                                      ? sortOrder === 'asc'
                                        ? 'sort-asc'
                                        : 'sort-des'
                                      : '')
                                  }
                                  onClick={() => this.sorting('accessCodeExpiryDate')}
                                >
                                  {this.props.users.languageStateForRedux?.column_names?.attendees?.remainingTime}
                                </TableCell>
                              )}
                              <TableCell
                                className={
                                  'text ' +
                                  (sort_by === 'source'
                                    ? sortOrder === 'asc'
                                      ? 'sort-asc'
                                      : 'sort-des'
                                    : '')
                                }
                                onClick={() => this.sorting('source')}
                              >
                                {this.props.users.languageStateForRedux?.common?.source}
                              </TableCell>
                              <TableCell
                                className={
                                  'text ' +
                                  (sort_by === 'isSystemUser'
                                    ? sortOrder === 'asc'
                                      ? 'sort-asc'
                                      : 'sort-des'
                                    : '')
                                }
                                onClick={() => this.sorting('isSystemUser')}
                              >
                                Type
                              </TableCell>
                              <TableCell
                                className={
                                  'date-time ' +
                                  (sort_by === 'checkIn'
                                    ? sortOrder === 'asc'
                                      ? 'sort-asc'
                                      : 'sort-des'
                                    : '')
                                }
                                onClick={() => this.sorting('checkIn')}
                              >
                                {this.props.users.languageStateForRedux?.column_names?.attendees?.checkedIn}
                              </TableCell>
                              <TableCell
                                className={
                                  'date-time ' +
                                  (sort_by === 'checkOut'
                                    ? sortOrder === 'asc'
                                      ? 'sort-asc'
                                      : 'sort-des'
                                    : '')
                                }
                                onClick={() => this.sorting('checkOut')}
                              >
                                {this.props.users.languageStateForRedux?.column_names?.attendees?.checkedOut}
                              </TableCell>
                              <TableCell
                                className={
                                  'long-text ' +
                                  (sort_by === 'isActive'
                                    ? sortOrder === 'asc'
                                      ? 'sort-asc'
                                      : 'sort-des'
                                    : '')
                                }
                                onClick={() => this.sorting('isActive')}
                              >
                                {this.props.users.languageStateForRedux?.column_names?.attendees?.enabledDisabled}
                              </TableCell>
                              <TableCell
                                className={
                                  'long-text ' +
                                  (sort_by === 'group'
                                    ? sortOrder === 'asc'
                                      ? 'sort-asc'
                                      : 'sort-des'
                                    : '')
                                }
                                onClick={() => this.sorting('group')}
                              >
                                {this.props.users.languageStateForRedux?.column_names?.attendees?.groups}{' '}
                              </TableCell>
                              <TableCell
                                className={
                                  'long-text ' +
                                  (sort_by === 'department'
                                    ? sortOrder === 'asc'
                                      ? 'sort-asc'
                                      : 'sort-des'
                                    : '')
                                }
                                onClick={() => this.sorting('department')}
                              >
                                {this.props.users.languageStateForRedux?.column_names?.attendees?.department}{' '}
                              </TableCell>
                              {this.props.attendees &&
                                this.props.attendees.customFields &&
                                this.props.attendees.customFields.map((data, i) => {
                                  return (
                                    <TableCell
                                      key={i}
                                      className={
                                        'long-text ' +
                                        (sort_by ===
                                          `attendeeData.${data.fieldData.fieldName}`
                                          ? sortOrder === 'asc'
                                            ? 'sort-asc'
                                            : 'sort-des'
                                          : '')
                                      }
                                      onClick={() =>
                                        this.sorting(
                                          `attendeeData.${data.fieldData.fieldName}`,
                                        )
                                      }
                                    >
                                      {data.fieldData.fieldLabel}
                                    </TableCell>
                                  );
                                })}
                              <TableCell
                                className={
                                  'date-time ' +
                                  (sort_by === 'createdAt'
                                    ? sortOrder === 'asc'
                                      ? 'sort-asc'
                                      : 'sort-des'
                                    : '')
                                }
                                onClick={() => this.sorting('createdAt')}
                              >
                                {this.props.users.languageStateForRedux?.column_names?.attendees?.registeredOn}
                              </TableCell>
                              <TableCell className="options">{this.props.users.languageStateForRedux?.common?.action}</TableCell>
                            </TableRow>
                          </TableHead>

                          {Array.isArray(this.props.attendees.attendees) && this.props.attendees?.sortFilterAttendeesSuccess &&
                            this.props.attendees.attendees.length > 0 ? (
                            <TableBody>
                              {this.props.attendees &&
                                this.props.attendees.attendees &&
                                this.props.attendees.attendees.map((attendee, i) => {
                                  return (
                                    <TableRow key={attendee._id} className={
                                      attendee?.isSystemUser == true ? 'system-user' : 'normal-attendee'
                                    }>
                                      {
                                        <TableCell className="checkbox sticky-cell">
                                          <Checkbox
                                            name={attendee._id}
                                            color="primary"
                                            p={0}
                                            onChange={(e) =>
                                              this.selectCheckBox(e, attendee)
                                            }
                                            checked={
                                              this.checkBoxIdsArr.indexOf(
                                                attendee._id,
                                              ) !== -1
                                                ? true
                                                : false
                                            }
                                          />
                                        </TableCell>
                                      }
                                      <TableCell
                                        className="sticky-cell"
                                        style={{
                                          maxWidth: '62px',
                                          minWidth: '62px',
                                          left: 50,
                                        }}
                                      >
                                        <Badge overlap="circular" badgeContent={attendee.isSystemUser == true ? <Tooltip title="Kiosk User" arrow placement='bottom'><Box className='kiosk-user-tag' >
                                          KIOSK</Box></Tooltip> : 0}>
                                          <Avatar
                                            style={
                                              attendee.profileImage
                                                ? styles.avatarWhite
                                                : (attendee.isSystemUser ? styles.avatarKiosk : styles.avatar)
                                            }
                                          >
                                            {attendee.profileImage ? (
                                              <img
                                                width="38"
                                                alt="profile image"
                                                src={
                                                  IMAGE_URL +
                                                  '/profilePictures/' +
                                                  attendee.profileImage
                                                }
                                              />
                                            ) : (
                                              <img
                                                width="25"
                                                alt="profile image"
                                                src={attendee.isSystemUser ? KioskUserIcon : userImageIcon}
                                              />
                                            )}
                                          </Avatar>
                                        </Badge>
                                      </TableCell>
                                      <TableCell
                                        className="email sticky-cell"
                                        style={{ left: 112 }}
                                        title={attendee.email}
                                      >
                                        {attendee.email}
                                      </TableCell>
                                      <TableCell className="status">
                                        <span
                                          className={
                                            attendee.status === 'Not Checked In'
                                              ? 'table-status light'
                                              : attendee.status === 'Checked In'
                                                ? 'table-status success'
                                                : 'table-status danger'
                                          }
                                        >
                                          {this.singleChecked(attendee.status)}
                                        </span>
                                      </TableCell>
                                      {this.props.project?.project?.isAccessCode && (
                                        <TableCell className="status">
                                          <span
                                            className={
                                              attendee.loginHistory.length > 0 &&
                                              attendee.accessCodeExpiryDate &&
                                              (getRemaningTime(
                                                attendee.accessCodeExpiryDate,
                                              ) === this.props.users.languageStateForRedux?.dropdowns?.accessCodes?.expired
                                                ? 'table-status light'
                                                : 'table-status success')
                                            }
                                          >
                                            {attendee.loginHistory.length > 0 &&
                                              (attendee.isAcessCodeLifeTime == false
                                                ? attendee.accessCodeExpiryDate
                                                  ? getRemaningTime(
                                                    attendee.accessCodeExpiryDate,
                                                  )
                                                  : ''
                                                : this.props.users.languageStateForRedux?.formfields?.accessCodes?.lifetime)}
                                          </span>
                                        </TableCell>
                                      )}
                                      <TableCell className="text">
                                        {this.singleChecked(attendee.source)}
                                      </TableCell>
                                      <TableCell className="text">
                                        {attendee.isSystemUser == true ? this.props.users.languageStateForRedux?.column_names?.attendees?.kiosk : this.props.users.languageStateForRedux?.dropdowns?.attendees?.attendee}
                                      </TableCell>
                                      <TableCell className="date-time">
                                        {attendee.checkIn
                                          ? moment
                                            .tz(
                                              attendee.checkIn,
                                              this.props.project?.project?.timezone,
                                            )
                                            .format('MMM, DD YYYY, h:mm A')
                                          : ''}
                                      </TableCell>
                                      <TableCell className="date-time">
                                        {attendee.checkOut
                                          ? moment
                                            .tz(
                                              attendee.checkOut,
                                              this.props.project?.project?.timezone,
                                            )
                                            .format('MMM, DD YYYY, h:mm A')
                                          : ''}
                                      </TableCell>
                                      {attendee.isActive ? (
                                        <TableCell className="long-text">
                                          {this.props.users.languageStateForRedux?.dropdowns?.attendees?.enabledUpperCase}
                                        </TableCell>
                                      ) : (
                                        <Box
                                          component={TableCell}
                                          style={{
                                            color: theme.palette.secondary.main,
                                          }}
                                          className="long-text"
                                        >
                                          {this.props.users.languageStateForRedux?.dropdowns?.attendees?.disabledUpperCase}
                                        </Box>
                                      )}
                                      <TableCell className="date-time" groups={attendee.groups}>
                                        <Stack
                                          direction="row"
                                          alignItems={'center'}
                                          spacing={1}
                                        >
                                          {attendee?.groups[0]?.source === "GoogleWorkspace" && <img src={GoogleWorkspace} alt="google workspace" width="14px" style={{ marginRight: "5px" }} />}
                                          <span className='long-text' style={{ maxWidth: "80%", }}> {attendee.groups && attendee.groups?.length > 0 ? attendee.groups[0]?.attendeesGroupName : ''}</span>
                                          {attendee.groups?.length > 1 &&
                                            <div className="detail-popup">
                                              <span className="count">
                                                +{attendee.groups.length - 1}
                                              </span>
                                              <div className="info">
                                                {this.showRestOfGroups(attendee.groups).map((group, i) => {
                                                  return (
                                                    <div key={i}>
                                                      <p>
                                                        {group.source === "GoogleWorkspace" && <img src={GoogleWorkspace} alt="google workspace" width="14px" style={{ marginRight: "5px" }} />}
                                                        {group.attendeesGroupName}
                                                      </p>
                                                    </div>
                                                  );
                                                })}
                                              </div>
                                            </div>
                                          }
                                        </Stack>
                                      </TableCell>
                                      <TableCell className="long-text">
                                        {attendee.department}
                                      </TableCell>


                                      {attendee?.source !== "Google Workspace" ? this.props.attendees &&
                                        this.props.attendees.customFields &&
                                        this.props.attendees.customFields.map(
                                          (data, j) => {
                                            return (
                                              <>
                                                {data.fieldData.fieldType ===
                                                  'checkbox' && (
                                                    <TableCell className="long-text" style={{ overflow: 'visible' }}>
                                                      <Stack
                                                        direction="row"
                                                        alignItems={'center'}
                                                        spacing={1}
                                                      >
                                                        <span className='long-text' style={{ maxWidth: "80%", }}>
                                                          {' '}
                                                          {attendee.attendeesData[
                                                            data._id
                                                          ]
                                                            ? attendee.attendeesData[
                                                            data._id
                                                            ][0]
                                                            : ''}
                                                        </span>
                                                        {attendee.attendeesData[
                                                          data._id
                                                        ] &&
                                                          attendee.attendeesData[
                                                            data._id
                                                          ]?.length > 1 && (
                                                            <div className="detail-popup">
                                                              <span className="count">
                                                                +
                                                                {attendee.attendeesData[
                                                                  data._id
                                                                ]?.length - 1}
                                                              </span>
                                                              <div className="info">
                                                                {this.showRestOfCheckbox(
                                                                  attendee
                                                                    ?.attendeesData[
                                                                  data._id
                                                                  ],
                                                                )?.map(
                                                                  (checkbox, i) => {
                                                                    return (
                                                                      <div key={i}>
                                                                        <p>
                                                                          {checkbox}
                                                                        </p>
                                                                      </div>
                                                                    );
                                                                  },
                                                                )}
                                                              </div>
                                                            </div>
                                                          )}
                                                      </Stack>
                                                    </TableCell>
                                                  )}
                                                {data.fieldData.fieldType !==
                                                  'checkbox' && (
                                                    <TableCell className="long-text" key={j}>
                                                      {attendee.attendeesData[data._id]
                                                        ? attendee.attendeesData[
                                                        data._id
                                                        ]
                                                        : ''}
                                                    </TableCell>
                                                  )}
                                              </>
                                            );
                                          },
                                        ) :

                                        Array.isArray(this.props?.attendees?.customFields) && this.props?.attendees?.customFields?.map(data => {
                                          return (
                                            <TableCell className="long-text">
                                              {attendee?.googleWorkspaceData?.[data?._id] || attendee.attendeesData?.[data?._id]}
                                            </TableCell>
                                          );
                                        })
                                      }
                                      <TableCell className="date-time">
                                        {attendee.createdAt
                                          ? moment
                                            .tz(
                                              attendee.createdAt,
                                              this.props.project?.project?.timezone,
                                            )
                                            .format('MMM, DD YYYY, h:mm A')
                                          : ''}
                                      </TableCell>

                                      {!attendee.isSystemUser ? <TableCell className="options">
                                        {' '}
                                        <div className="options-button">
                                          <img alt="" src={MoreIcon} />{' '}
                                        </div>
                                        {
                                          (attendee.isSystemUser ?  authPermissionValidation(permissionsConstants.viewBadge)  : authPermissionValidation(permissionsConstants.viewAttendees)) &&
                                          <>
                                            <div className="options-list">
                                              { authPermissionValidation(permissionsConstants.editAttendee) && !attendee.isSystemUser && (
                                                <>
                                                  <div
                                                    onClick={() =>
                                                      this.handleClickOpenEditAttendeesModal(
                                                        attendee,
                                                      )
                                                    }
                                                  >
                                                    {this.props.users.languageStateForRedux?.common?.edit}
                                                  </div>
                                                  <hr />
                                                </>
                                              )}
                                              { authPermissionValidation(permissionsConstants.viewOutboundEmailsReports) && !attendee.isSystemUser && (
                                                  <div
                                                    onClick={() =>
                                                      this.openOutBoundEmailReport(
                                                        attendee?.email,
                                                      )
                                                    }
                                                  >
                                                    {this.props.users.languageStateForRedux?.actions?.attendees?.outBound}
                                                  </div>
                                                )} 
                                              { authPermissionValidation(permissionsConstants.viewAttendees) 
                                              && !attendee.isSystemUser && (
                                                <div
                                                  onClick={() =>
                                                    this.handleClickOpenCheckedinHistoryAttendeesModal(
                                                      attendee,
                                                    )
                                                  }
                                                >
                                                  {this.props.users.languageStateForRedux?.actions?.attendees?.checkedInHistory}
                                                </div>
                                              )}

                                              {attendee.isActive ?
                                                (this.props.project?.project?.passwordType ===
                                                  'Private_Password'
                                                  &&
                                                  (
                                                    <>
                                                      { authPermissionValidation(permissionsConstants.resetPassword) && !attendee.isSystemUser && (
                                                        <div
                                                          onClick={() =>
                                                            this.handleOpenResetPassword(
                                                              attendee,
                                                            )
                                                          }
                                                        >
                                                          {this.props.users.languageStateForRedux?.actions?.attendees?.resetPass}
                                                        </div>
                                                      )}
                                                    </>
                                                  )) :
                                                (
                                                  <Tooltip
                                                    title="Disabled Attendee"
                                                    placement="top"
                                                    arrow
                                                  >
                                                    <div className="disabled">
                                                      {this.props.users.languageStateForRedux?.actions?.attendees?.resetPass}
                                                    </div>
                                                  </Tooltip>
                                                )
                                              }
                                              { authPermissionValidation(permissionsConstants.viewGamification)
                                              && !attendee.isSystemUser && (
                                                <div
                                                  onClick={() =>
                                                    this.goToGamification(attendee?.email)
                                                  }
                                                >
                                                  {this.props.users.languageStateForRedux?.actions?.attendees?.gamificationPoints}
                                                </div>
                                              )}

                                              {this.props.project?.project?.isAccessCode && !attendee.isSystemUser && (
                                                <div
                                                  onClick={() =>
                                                    this.goToAccessCode(attendee?.email)
                                                  }
                                                >
                                                  {this.props.users.languageStateForRedux?.actions?.attendees?.viewAcc}
                                                </div>
                                              )}
                                              {!attendee.isSystemUser && this.props.project?.project?.isAccessCode &&
                                                (attendee.isActive ?
                                                  (
                                                    attendee?.isAcessCodeLifeTime !== true ? (
                                                      attendee?.loginHistory?.length == 0 &&
                                                        attendee.accessCodeCount >= 1 ? (
                                                        <Tooltip
                                                          title="Assign the multiple access code after attendee check-in"
                                                          placement="top"
                                                          arrow
                                                        >
                                                          <div className="disabled">
                                                            {this.props.users.languageStateForRedux?.actions?.attendees?.assignAcc}
                                                          </div>
                                                        </Tooltip>
                                                      ) : (
                                                        <div
                                                          onClick={() =>
                                                            this.openAssignAccesscodemodal(
                                                              attendee?.email,
                                                              attendee?._id,
                                                            )
                                                          }
                                                        >
                                                          {this.props.users.languageStateForRedux?.actions?.attendees?.assignAcc}
                                                        </div>
                                                      )
                                                    ) : (
                                                      this.props.project?.project?.isAccessCode && (
                                                        <Tooltip
                                                          title="Attendee have lifetime access code"
                                                          placement="top"
                                                          arrow
                                                        >
                                                          <div className="disabled">
                                                            {this.props.users.languageStateForRedux?.actions?.attendees?.assignAcc}
                                                          </div>
                                                        </Tooltip>
                                                      )
                                                    )
                                                  ) :
                                                  (
                                                    <Tooltip
                                                      title="Disabled Attendee"
                                                      placement="top"
                                                      arrow
                                                    >
                                                      <div className="disabled">
                                                        {this.props.users.languageStateForRedux?.actions?.attendees?.assignAcc}
                                                      </div>
                                                    </Tooltip>
                                                  )
                                                )
                                              }
                                              { authPermissionValidation(permissionsConstants.viewBadge) && (
                                                <div
                                                  onClick={() =>
                                                    this.handleClickOpenPrintBadgeModal(
                                                      attendee,
                                                    )
                                                  }
                                                >
                                                  {this.props.users.languageStateForRedux?.actions?.attendees?.viewBadge}
                                                </div>
                                              )}

                                            </div>
                                          </>
                                        }
                                      </TableCell> : <TableCell className="options"></TableCell>}
                                    </TableRow>
                                  );
                                })}
                            </TableBody>
                          ) : (!this.state.loading && this.props.attendees.attendees.length === 0 && this.props.attendees?.sortFilterAttendeesSuccess &&
                            (<TableBody>
                              <TableRow>
                                <TableCell style={{ textAlign: 'center' }}>
                                  {this.props.users.languageStateForRedux?.common?.noRecords}
                                </TableCell>
                              </TableRow>
                            </TableBody>)
                          )}
                        </Table>
                      </TableContainer>

                      {Array.isArray(this.props.attendees.attendees) &&
                        this.props.attendees.attendees.length > 0 && (
                          <Grid
                            container
                            alignItems="center"
                            justifyContent="space-between"
                            className="tablePagination"
                          >
                            <Grid >
                              <Box component="span" className="small-subtitle">
                                {this.props.users.languageStateForRedux?.pagination?.showing} {startCount} {this.props.users.languageStateForRedux?.pagination?.to} {endCount} {this.props.users.languageStateForRedux?.pagination?.of} {' '}
                                {this.props.attendees.totalAttendeesRecords}
                              </Box>
                            </Grid>
                            <Grid >
                              <Grid container alignItems="center">
                                <Grid >
                                  <span className="small-subtitle">{this.props.users.languageStateForRedux?.pagination?.shows}</span>
                                  <select
                                    value={itemCount}
                                    onChange={this.selectItemCount}
                                  >
                                    <option value={10}>10</option>
                                    <option value={20}>20</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                  </select>
                                  <span className="small-subtitle">{this.props.users.languageStateForRedux?.pagination?.entries}</span>
                                </Grid>
                                <Grid >
                                  <Pagination
                                    activePage={page_no}
                                    itemsCountPerPage={this.state.itemCount}
                                    totalItemsCount={
                                      this.props.attendees.totalAttendeesRecords
                                    }
                                    pageRangeDisplayed={5}
                                    onChange={this.handlePageChange}
                                    prevPageText={
                                      <NavigateBeforeIcon style={{ fontSize: 18 }} />
                                    }
                                    nextPageText={
                                      <NavigateNextIcon style={{ fontSize: 18 }} />
                                    }
                                    itemClassFirst="first d-none"
                                    itemClassLast="last d-none"
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                    </>
                  )}
          </>
        )}
        <Modal
          open={this.state.open}
          onClose={this.handleClose}
          classNames={{
            overlay: 'full-screen_overlay',
            modal: 'full-screen_modal manage-custom-fields',
          }}
          center
        >
          <Grid container justifyContent="end" width={"100%"}>
            <Grid
              size={{
                xs: 12,
                md: 6,
                lg: 5,
                xl: 4
              }}

              className="full-screen_modal__form"
            >
              <Box component="div" className="full-screen_modal__form_header">
                <h3 className="subtitle">{this.props.users.languageStateForRedux?.column_names?.attendees?.manage}</h3>
              </Box>
              <Box
                component="div"
                px={3}
                py={2}
                className="full-screen_modal__form_body"
              >
                <Grid container justifyContent="between" alignItems="center">
                  <Grid size={{ xs: "grow", }}>
                    <Box component="p" className="paragraph">
                      {this.props.users.languageStateForRedux?.dropdowns?.attendees?.custom} (
                      {this.props.attendees.customFields &&
                        this.props.attendees.customFields.length}
                      )
                    </Box>
                  </Grid>
                  <Grid >
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      onClick={() => this.openAddCustomFieldForm()}
                    >
                      {this.props.users.languageStateForRedux?.buttons?.attendees?.newField}
                    </Button>
                  </Grid>
                </Grid>
                <Box
                  className="custom-fields-table"
                  component={TableContainer}
                  py={3}
                >
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell className="tableHeadCell">
                          {this.props.users.languageStateForRedux?.column_names?.attendees?.fieldName}
                        </TableCell>
                        <TableCell className="tableHeadCell" align="left">
                          {this.props.users.languageStateForRedux?.column_names?.attendees?.type}
                        </TableCell>
                        <TableCell className="tableHeadCell" align="left">
                          {this.props.users.languageStateForRedux?.common?.action}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {customFields && customFields.length ? (
                      <TableBody>
                        {customFields &&
                          customFields.map((customField, index) => (
                            <TableRow key={index}>
                              <TableCell className="tableBodyCell" scope="row">
                                {customField.fieldData.fieldLabel}
                              </TableCell>
                              <TableCell className="tableBodyCell" align="left">
                                {customField.fieldData.fieldType === 'dropdown'
                                  ? 'List'
                                  : customField.fieldData.fieldType ===
                                    'checkbox'
                                    ? 'Boolean'
                                    : customField.fieldData.fieldType
                                      .charAt(0)
                                      .toUpperCase() +
                                    customField.fieldData.fieldType.slice(1)}
                              </TableCell>
                              <TableCell className="tableBodyCell" align="left">
                                <Box
                                  className="action-button"
                                  component="span"
                                  mr={2.5}
                                >
                                  <EditIcon
                                    style={{
                                      fontSize: 18,
                                      color: '#777777',
                                    }}
                                    onClick={() =>
                                      this.openEditCustomFieldForm(customField)
                                    }
                                  />
                                </Box>
                                <Box className="action-button" component="span">
                                  <DeleteIcon
                                    style={{
                                      fontSize: 18,
                                      color: '#777777',
                                    }}
                                    onClick={() => {
                                      this.opendeleteCustomFieldBox(customField);
                                    }}
                                  />
                                </Box>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    ) : (
                      <TableBody>
                        <TableRow>
                          <TableCell
                            className="tableBodyCell"
                            colSpan={3}
                            style={{ border: 'none', textAlign: 'center' }}
                          >
                            {this.props.users.languageStateForRedux?.common?.noRecords}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Modal>
        <Modal
          open={this.state.openForm}
          onClose={this.handleCloseForm}
          classNames={{
            overlay: '',
            modal: 'Invite-User-Modal',
          }}
          center
        >
          <Grid component="form" container className="modal-wrapper">
            <Grid size={{ xs: 12, }} pb={4}>
              {!isEdit ? (
                <>
                  <Box component="p" mb={1} className="subtitle">
                    {this.props.users.languageStateForRedux?.formfields?.attendees?.addNew}
                    {/* Add new field */}
                  </Box>
                  <Box component="p" mb={0} className="small-subtitle">
                    {this.props.users.languageStateForRedux?.formfields?.attendees?.newDesc}
                    {/* You can add a new custom field and data type. */}
                  </Box>
                </>
              ) : (
                <>
                  <Box component="p" mb={1} className="subtitle">
                    {this.props.users.languageStateForRedux?.formfields?.attendees?.editField}
                    {/* Edit field */}
                  </Box>
                  <Box component="p" mb={0} className="small-subtitle">
                    {this.props.users.languageStateForRedux?.formfields?.attendees?.newDesc}
                    {/* You can edit a custom field by changing name and data type. */}
                  </Box>
                </>
              )}
            </Grid>
            <Grid size={{ xs: 12, }} pb={4}>
              <Box component="div" className="inputField" pb={4}>
                <label>
                  {this.props.users.languageStateForRedux?.formfields?.attendees?.name}
                  {/* Name  */}
                  <span className="required">*</span>
                </label>
                <input
                  type="text"
                  className="form-control small"
                  placeholder={this.props.users.languageStateForRedux?.formfields?.attendees?.enterName}
                  value={fieldLabel}
                  name="fieldLabel"
                  onChange={this.handleLabel}
                />
                <span className="error_mesage required">
                  {' '}
                  {errors.fieldLabel}{' '}
                </span>
              </Box>
              <Box component="div" className="inputField" pb={2}>
                <label>
                  {this.props.users.languageStateForRedux?.formfields?.attendees?.type}
                  {/* Type  */}
                  <span className="required">*</span>
                </label>
                <FormControl
                  size="small"
                  fullWidth
                >
                  <Select
                    type="text"
                    className="form-control select small"
                    placeholder="select"
                    value={fieldType}
                    name="fieldType"
                    onChange={this.handleChange}
                    style={{ padding: '4px 0px', }}
                    MenuProps={MenuProps}
                    displayEmpty
                  >
                    <MenuItem value="" disabled hidden>Select</MenuItem>
                    <MenuItem value="text">Text</MenuItem>
                    <MenuItem value="number">Number</MenuItem>
                    <MenuItem value="date">Date</MenuItem>
                    <MenuItem value="url">URL</MenuItem>
                    <MenuItem value="email">Email</MenuItem>
                    <MenuItem value="dropdown">List</MenuItem>
                    <MenuItem value="checkbox">Boolean</MenuItem>
                  </Select>
                  <span className="error_mesage required">
                    {' '}
                    {errors.fieldType}{' '}
                  </span>
                </FormControl>
              </Box>
              <Box>
                {fieldType && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        onChange={(e) => this.showAttendeesProfilesCheckbox(e)}
                        checked={this.state.showAttendeesProfile}
                      />
                    }
                    label={this.props.users.languageStateForRedux?.formfields?.attendees?.firstCheckbox}
                  // "Show on the attendees profile."
                  />
                )}
                {this.state.showAttendeesProfile === true && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        onChange={(e) => this.editByAttendeesCheckbox(e)}
                        checked={this.state.editByAttendees}
                      />
                    }
                    label={this.props.users.languageStateForRedux?.formfields?.attendees?.secondCheckbox}
                  // "Can be updated by the attendees."
                  />
                )}
                {this.state.editByAttendees === true && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        onChange={(e) =>
                          this.mandatoryAttendeesProfileCheckbox(e)
                        }
                        checked={this.state.mandatoryAttendeesProfile}
                      />
                    }
                    label={this.props.users.languageStateForRedux?.formfields?.attendees?.thirdCheckbox}
                  // "Mandatory for attendees profile."
                  />
                )}
              </Box>
              {fieldType === 'dropdown' && (
                <Box component="div" className="inputField" pb={1}>
                  <label>
                    Option per line <span className="required">*</span>
                  </label>
                  <textarea
                    rows={'4'}
                    className="form-control"
                    value={listValue}
                    name="listValue"
                    onChange={this.handleChange}
                  >
                    {' '}
                  </textarea>
                  <span className="error_mesage required">
                    {' '}
                    {errors.listValue}{' '}
                  </span>
                </Box>
              )}

              {fieldType === 'checkbox' && (
                <Box component="div" className="inputField" pb={1}>
                  <label>Option per line <span className="required">*</span> </label>
                  <textarea
                    rows={'4'}
                    className="form-control"
                    value={checkboxValue}
                    name="checkboxValue"
                    onChange={this.handleChange}
                  >
                    {' '}
                  </textarea>
                  <span className="error_mesage required">
                    {' '}
                    {errors.checkbox}{' '}
                  </span>
                </Box>
              )}
            </Grid>
            <Grid size={{ xs: 12, }}>
              <Grid container justifyContent="end">
                <Stack spacing={1} direction="row" fullWidth>
                  {!isEdit ? (
                    <>
                      {' '}
                      <Button
                        variant="outlined"
                        color="primary"
                        disableElevation
                        onClick={this.saveNewCustomField}
                      >
                        {this.props.users.languageStateForRedux?.buttons?.attendees?.savenew}
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={this.saveNewCustomFieldAndExit}
                      >
                        {this.props.users.languageStateForRedux?.buttons?.attendees?.save}
                      </Button>{' '}
                    </>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      onClick={this.updateCustomField}
                    >
                      {this.props.users.languageStateForRedux?.buttons?.attendees?.updateSave}
                      {/* Update & Save */}
                    </Button>
                  )}
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Modal>
        <Modal
          open={this.state.openConfirmation}
          onClose={this.handleCloseConfirmation}
          classNames={{
            overlay: '',
            modal: 'Invite-User-Modal',
          }}
          center
        >
          <Box component={Grid} container className="" px={1.5}>
            <Box component={Grid} mt={-1} size={{ xs: 12, }} pb={3}>
              <Box component="p" mb={1} className="subtitle">
                {' '}
                {this.props.users.languageStateForRedux?.common?.confirmDelete}
              </Box>
              <p className="small-subtitle">
                {this.props.users.languageStateForRedux?.common?.deleteMsg}
              </p>
            </Box>
            <Grid size={{ xs: 12, }}>
              <Grid container justifyContent="end">
                <Stack spacing={1} direction="row" fullWidth>
                  {this.state.isDeleteAttendeeBox ? (
                    <>
                      <Button
                        variant="contained"
                        color="secondary"
                        disableElevation
                        onClick={() => this.deleteAttendees()}
                      >
                        {this.props.users.languageStateForRedux?.common?.delete}
                      </Button>
                      <Button
                        variant="outlined"
                        disableElevation
                        onClick={this.handleCloseConfirmation}
                      >
                        {this.props.users.languageStateForRedux?.common?.cancel}
                      </Button>
                    </>
                  ) : (
                    <>
                      {' '}
                      <Button
                        variant="contained"
                        color="secondary"
                        disableElevation
                        onClick={() => this.deleteCustomField()}
                      >
                        {this.props.users.languageStateForRedux?.common?.delete}
                      </Button>
                      <Button
                        variant="outlined"
                        disableElevation
                        onClick={this.handleCloseConfirmation}
                      >
                        {this.props.users.languageStateForRedux?.common?.cancel}
                      </Button>
                    </>
                  )}
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Modal>
        <Modal
          open={this.state.openAssignGroupModel}
          onClose={this.handleCloseAssignGroupModal}
          classNames={{
            overlay: '',
            modal: 'Invite-User-Modal',
          }}
          center
        >
          <Grid component="form" container className="modal-wrapper">
            <Grid size={{ xs: 12, }} pb={3}>
              <Box component="p" mb={1} className="subtitle">
                {this.props.users.languageStateForRedux?.dropdowns?.attendees?.assignGroup}
              </Box>
              <p className="small-subtitle">
                {this.props.users.languageStateForRedux?.actions?.attendees?.selected}:{' '}
                <Box
                  component="span"
                  sx={{ color: theme.palette.primary.dark }}
                  color="primary"
                >
                  {this.checkBoxIdsArr.length}
                </Box>
              </p>
            </Grid>
            <Grid size={{ xs: 12, }} pb={4}>
              <Box component="div" className="inputField" pb={1}>
                <label>{this.props.users.languageStateForRedux?.actions?.attendees?.selectGroup}</label>
                <FormControl
                  size="small"
                  fullWidth
                >
                  <Select
                    type="text"
                    className="form-control select small"
                    placeholder="select"
                    value={groupid}
                    name="groupid"
                    onChange={this.handleChange}
                    style={{ padding: '4px 0px', }}
                    MenuProps={MenuProps}
                    displayEmpty
                  >
                    <MenuItem value="" disabled hidden>Select</MenuItem>
                    {this.props.attendees &&
                      Array.isArray(this.props.attendees.attendeesGroups) &&
                      this.props.attendees.attendeesGroups.length > 0 &&
                      this.props.attendees.attendeesGroups.filter((g) => g.source !== "GoogleWorkspace").map((group) => {
                        return (
                          <MenuItem key={group._id} value={group._id}>
                            {group.attendeesGroupName}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  <span className="error_mesage required">
                    {' '}
                    {errors.groupId}{' '}
                  </span>
                </FormControl>
              </Box>
            </Grid>
            <Grid size={{ xs: 12, }}>
              <Grid container justifyContent="end">
                <Stack spacing={1} direction="row" fullWidth>
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={this.changeActionAssignStatus}
                  >
                    {this.props.users.languageStateForRedux?.buttons?.attendees?.assign}
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={this.handleCloseAssignGroupModal}
                  >
                    {this.props.users.languageStateForRedux?.common?.cancel}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Modal>
        <Modal
          open={this.state.openUnassignGroupModel}
          onClose={this.handleCloseUnassignGroupModal}
          classNames={{
            overlay: '',
            modal: 'Invite-User-Modal',
          }}
          center
        >
          <Grid component="form" container className="modal-wrapper">
            <Grid size={{ xs: 12, }} pb={3}>
              <Box component="p" mb={1} className="subtitle">
                {this.props.users.languageStateForRedux?.dropdowns?.attendees?.unAssignGroup}
              </Box>
              <p className="small-subtitle">
                {this.props.users.languageStateForRedux?.actions?.attendees?.selected}:{' '}
                <Box
                  component="span"
                  sx={{ color: theme.palette.primary.dark }}
                  color="primary"
                >
                  {this.checkBoxIdsArr.length}
                </Box>
              </p>
            </Grid>
            <Grid size={{ xs: 12, }} pb={4}>
              <p className="paragraph" style={{ fontWeight: 'normal' }}>
                {this.props.users.languageStateForRedux?.messages?.unassignAttendeeMsg}
              </p>
            </Grid>
            <Grid size={{ xs: 12, }}>
              <Grid container justifyContent="end">
                <Stack spacing={1} direction="row" fullWidth>
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={this.changeActionUnassignStatus}
                  >
                    {this.props.users.languageStateForRedux?.buttons?.common?.yes}
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={this.handleCloseUnassignGroupModal}
                  >
                    {this.props.users.languageStateForRedux?.common?.cancel}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Modal>
        <Modal
          open={this.state.openAttendeeForm}
          onClose={this.handleCloseAttendeeForm}
          classNames={{
            overlay: '',
            modal: 'Invite-User-Modal',
          }}
          center
          closeOnOverlayClick={false}
        >
          <Grid component="form" container className="modal-wrapper">
            <Grid size={{ xs: 12, }} pb={4}>
              <Box component="p" mb={1} className="subtitle">
                {this.props.users.languageStateForRedux?.actions?.attendees?.addNew}
              </Box>
              <Box component="p" mb={0} className="small-subtitle">
                {this.props.users.languageStateForRedux?.messages?.addAttendeeMsg}
              </Box>
            </Grid>

            <Box component="div" pb={4}>
              <Grid container spacing={2.1} alignItems="center">
                <Grid >
                  <Avatar
                    style={
                      this.state.cropPic
                        ? styles.addAvatarWhite
                        : styles.AddAvatar
                    }
                  > {this.state.uploadImage ? (
                    <img
                      style={{ height: '100%', width: '100%' }}
                      alt="profile image"
                      src={
                        IMAGE_URL +
                        '/profilePictures/' +
                        this.state.uploadImage
                      }
                    />
                  ) : (
                    <img
                      width={'40'}
                      alt="profile image"
                      style={{
                        height: this.state.cropPic !== '' ? '100%' : '40px',
                        width: this.state.cropPic !== '' ? '100%' : '40px',
                      }}
                      src={
                        this.state.cropPic !== ''
                          ? window.URL.createObjectURL(this.state.cropPic)
                          : userImageIcon
                      }
                    />
                  )}
                  </Avatar>
                </Grid>
                <Grid >
                  <Stack direction="row" spacing={1}>
                    {this.state.cropPic ? (
                      <>
                        <Button
                          onClick={this.uploadFromFiles}
                          variant="outlined"
                          color="primary"
                        >
                          {this.props.users.languageStateForRedux?.buttons?.attendees?.change}
                        </Button>
                        <Button
                          onClick={() => {
                            this.setState({ cropPic: '' });
                          }}
                          variant="outlined"
                          color="secondary"
                        >
                          {this.props.users.languageStateForRedux?.buttons?.attendees?.remove}
                        </Button>
                      </>
                    ) : (
                      <span
                        onClick={this.uploadFromFiles}
                        className="btn btn-secondary btn-file"
                        style={{
                          padding: '12px',
                          backgroundColor: 'transparent',
                          color: '#5141E7',
                        }}
                      >
                        {this.props.users.languageStateForRedux?.buttons?.attendees?.photo}{' '}
                      </span>
                    )}
                  </Stack>
                  <span
                    className="btn-file"
                  >
                    <input
                      type="file"
                      accept="image/*"
                      id="profilePic"
                      name="profilePic"
                      ref={this.chooseAttendeeProfileImage}
                      onChange={(e) => this.onProfileImageChange(e, 'add')}
                    />
                  </span>
                </Grid>
              </Grid>
            </Box>

            <Grid size={{ xs: 12, }} pb={4}>
              <Box component="div" className="inputField" pb={2}>
                <Box component="label" display="flex" alignItems="center">
                  <img alt="" src={emailIcon} />
                  <Box component="span" pl={1}>
                    {this.props.users.languageStateForRedux?.column_names?.attendees?.email}<span className="required">*</span>
                  </Box>
                </Box>
                <input
                  type="email"
                  className="form-control small"
                  placeholder={this.props.users.languageStateForRedux?.formfields?.attendees?.kioskModalPlaceholder}
                  value={email}
                  name="email"
                  onChange={this.handleChange}
                />
                <span className="error_mesage required"> {errors.email} </span>
              </Box>
              <Box component="div" className="inputField" pb={2}>
                <label>{this.props.users.languageStateForRedux?.actions?.attendees?.selectGroup}</label>
                <ReactSelect
                  options={this.props.attendees.attendeesGroups.filter((o) => o.source !== 'GoogleWorkspace').map((o) => { return { label: o.attendeesGroupName, value: o._id, source: o.source }; })}
                  isMulti
                  onChange={(selected) => this.setState({ selectedGroups: selected })}
                  closeMenuOnSelect={false}
                  // isSearchable={false}
                  value={this.state.selectedGroups}
                />
              </Box>
              <Box component="div" className="inputField" pb={2}>
                <label>{this.props.users.languageStateForRedux?.column_names?.attendees?.department}</label>
                <input
                  type="text"
                  className="form-control small"
                  placeholder={this.props.users.languageStateForRedux?.validations?.enterDepartment}
                  value={department}
                  name="department"
                  onChange={this.handleChange}
                />
              </Box>
              {this.props.project?.project?.isAccessCode && (
                <Box component="div" className="inputField" pb={2}>
                  <Box component="label" display="flex" alignItems="center">
                    <Box component="span" pl={1}>
                      {this.props.users.languageStateForRedux?.column_names?.accessCodes?.code}
                    </Box>
                  </Box>
                  <input
                    type="text"
                    className="form-control small"
                    placeholder={this.props.users.languageStateForRedux?.validations?.enterAccessCode}
                    value={accessCode}
                    name="accessCode"
                    onChange={this.handleChange}
                  />
                </Box>
              )}
              {this.props.attendees &&
                Array.isArray(this.props.attendees.customFields) &&
                this.props.attendees.customFields.length > 0 &&
                this.props.attendees.customFields.map((customField) => {
                  return (
                    <Box
                      key={customField._id}
                      component="div"
                      className="inputField"
                      pb={2}
                    >
                      <Box component="label" display="flex" alignItems="center">
                        <Box component="span">
                          {customField.fieldData.fieldLabel}
                        </Box>
                      </Box>
                      {customField.fieldData.fieldType !== 'dropdown' &&
                        customField.fieldData.fieldType !== 'checkbox' && (
                          <input
                            placeholder={
                              customField.fieldData.fieldType !== 'date'
                                ? `Enter ${customField.fieldData.fieldLabel}`
                                : null
                            }
                            className="form-control small"
                            name={customField._id}
                            value={
                              this.state.customFieldsForEdit &&
                              this.state.customFieldsForEdit[customField._id]
                            }
                            type={customField.fieldData.fieldType}
                            onChange={this.handleCustomFieldChange}
                          />
                        )}

                      <span className="required">
                        {' '}
                        {errors[customField._id] &&
                          `Please enter the ${customField.fieldData.fieldLabel}`}{' '}
                      </span>
                      {customField.fieldData.fieldType === 'email' && (
                        <span className="required"> {errors.validEmail} </span>
                      )}
                      {customField.fieldData.fieldType === 'url' && (
                        <span className="required"> {errors.validUrl} </span>
                      )}

                      {customField.fieldData.fieldType === 'dropdown' && (
                        <FormControl
                          size="small"
                          fullWidth
                        >
                          <Select
                            value={
                              this.state.customFieldsForEdit &&
                              this.state.customFieldsForEdit[customField._id]
                            }
                            onChange={this.handleCustomFieldChange}
                            placeholder={
                              customField.fieldData.fieldType !== 'date'
                                ? `Enter ${customField.fieldData.fieldLabel}`
                                : ``
                            }
                            className="form-control select small"
                            name={customField._id}
                            style={{ padding: '0px 0px', }}
                            MenuProps={MenuProps}
                            displayEmpty
                          >
                            <MenuItem value="" disabled hidden
                            // label={`Select a ${customField.fieldData.fieldLabel}`}
                            >
                              {`Select a ${customField.fieldData.fieldLabel}`}
                            </MenuItem>
                            {customField?.fieldData?.listValue?.map(
                              (data, id) => (

                                <MenuItem key={id} value={data}>
                                  {data}
                                </MenuItem>

                              ),
                            )}
                          </Select>
                        </FormControl>
                      )}
                      {customField?.fieldData?.fieldType === 'checkbox' &&
                        customField.fieldData?.checkboxValue?.map((item) => (
                          <label>
                            <input
                              type="checkbox"
                              name={customField._id}
                              value={item}
                              onChange={this.handleChangeCheck}
                            />{' '}
                            {item}
                          </label>
                        ))}
                    </Box>
                  );
                })}
              {this.props.project?.project?.passwordType === 'Private_Password' && <FormControlLabel control={<Checkbox color='primary' onChange={this.handleTempPassCheckbox} />} label="Send email with Temporary Password" />}

            </Grid>
            <Grid size={{ xs: 12, }}>
              <Grid container justifyContent="end">
                <Stack spacing={1} direction="row" fullWidth>
                  <>
                    {' '}
                    <Button
                      variant="outlined"
                      color="primary"
                      disableElevation
                      onClick={this.saveAndExitAttendeeForm}
                    >
                      {this.props.users.languageStateForRedux?.buttons?.attendees?.savenew}
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      onClick={this.saveAttendeeForm}
                    >
                      {this.props.users.languageStateForRedux?.buttons?.attendees?.save}
                    </Button>{' '}
                  </>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Modal>

        {/* + Add new Modal  */}
        <Modal
          open={this.state.openAddNewForm}
          onClose={this.handleCloseAttendeeForm}
          classNames={{
            overlay: '',
            modal: 'Invite-User-Modal',
          }}
          center
          closeOnOverlayClick={false}
        >
          <Grid component="form" container className="modal-wrapper">
            <Grid size={{ xs: 12, }} pb={4}>
              <Box component="p" mb={1} className="subtitle">
                {this.props.users.languageStateForRedux?.formfields?.attendees?.kioskModalHeading}
              </Box>
              <Box component="p" mb={0} className="small-subtitle">
                {this.props.users.languageStateForRedux?.formfields?.attendees?.kioskModalDesc}
              </Box>
            </Grid>

            <Grid size={{ xs: 12, }} pb={4}>
              <Box component="div" className="inputField" pb={2}>
                <Box component="label" display="flex" alignItems="center">
                  <img alt="" src={emailIcon} />
                  <Box component="span" pl={1}>
                    {this.props.users.languageStateForRedux?.column_names?.attendees?.email}<span className="required">*</span>
                  </Box>
                </Box>
                <input
                  type="email"
                  className="form-control small"
                  placeholder={this.props.users.languageStateForRedux?.formfields?.attendees?.kioskModalPlaceholder}
                  value={email}
                  name="email"
                  onChange={this.handleChange}
                />
                <span className="error_mesage required"> {errors.email} </span>
              </Box>
            </Grid>
            <Grid size={{ xs: 12, }}>
              <Grid container justifyContent="end">
                <Stack spacing={1} direction="row" fullWidth>
                  <>
                    {' '}
                    <Button
                      variant="outlined"
                      color="primary"
                      disableElevation
                      onClick={this.saveAndExitAddNewForm}
                    >
                      {this.props.users.languageStateForRedux?.buttons?.attendees?.savenew}
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      onClick={this.saveKioskForm}
                    >
                      {this.props.users.languageStateForRedux?.buttons?.attendees?.save}
                    </Button>{' '}
                  </>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Modal>;
        {/* + Add new Modal End here */}


        <Modal
          open={this.state.openEnabledModal}
          onClose={this.handleCloseEnabledModal}
          classNames={{
            overlay: '',
            modal: 'Invite-User-Modal',
          }}
          center
        >
          <Grid component="form" container className="modal-wrapper">
            <Grid size={{ xs: 12, }} pb={3}>
              <Box component="p" mb={1} className="subtitle">
                {this.props.users.languageStateForRedux?.messages?.change} {isDisabledButton ? this.props.users.languageStateForRedux?.common?.disabled : this.props.users.languageStateForRedux?.common?.enabled}{' '}
              </Box>
              <p className="small-subtitle">
                {this.props.users.languageStateForRedux?.actions?.attendees?.selected}:{' '}
                <Box
                  component="span"
                  sx={{ color: theme.palette.primary.dark }}
                  color="primary"
                >
                  {this.checkBoxIdsArr.length}
                </Box>
              </p>
            </Grid>
            <Grid size={{ xs: 12, }} pb={4}>
              <p className="paragraph">
                <strong>
                  {this.props.users.languageStateForRedux?.messages?.sure}{' '}
                  {isDisabledButton ? this.props.users.languageStateForRedux?.common?.disable : this.props.users.languageStateForRedux?.common?.enable} {this.props.users.languageStateForRedux?.actions?.attendees?.selectedAtt}{' '}
                </strong>
              </p>
            </Grid>
            <Grid size={{ xs: 12, }}>
              <Grid container justifyContent="end">
                <Stack spacing={1} direction="row" fullWidth>
                  {isDisabledButton ? (
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      onClick={this.changeDisabledStatus}
                    >
                      {this.props.users.languageStateForRedux?.buttons?.common?.yes}
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      onClick={this.changeEnabledStatus}
                    >
                      {this.props.users.languageStateForRedux?.buttons?.common?.yes}
                    </Button>
                  )}
                  <Button
                    variant="outlined"
                    onClick={this.handleCloseEnabledModal}
                  >
                    {this.props.users.languageStateForRedux?.common?.cancel}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Modal>
        <Modal
          open={this.state.openEditAttendeeModal}
          onClose={this.handleCloseEditAttendeeModal}
          classNames={{
            overlay: '',
            modal: 'Invite-User-Modal',
          }}
          center
          closeOnOverlayClick={false}
        >
          <Grid component="form" container className="modal-wrapper">
            <Grid size={{ xs: 12, }} pb={3}>
              <Box component="p" mb={1} className="subtitle">
                {this.props.users.languageStateForRedux?.formfields?.attendees?.edit}
              </Box>
              <p className="small-subtitle">
                {this.props.users.languageStateForRedux?.formfields?.attendees?.editDesc}
              </p>
            </Grid>
            <Grid size={{ xs: 12, }} pb={4}>
              <Box component="div" pb={4}>
                <Grid container spacing={2.1} alignItems="center">
                  <Grid >
                    <Avatar
                      style={
                        this.state.uploadImage
                          ? styles.addAvatarWhite
                          : styles.AddAvatar
                      }
                    >
                      {this.state.uploadImage ? (
                        <img
                          style={{ height: '100%', width: '100%' }}
                          alt="profile image"
                          src={
                            IMAGE_URL +
                            '/profilePictures/' +
                            this.state.uploadImage
                          }
                        />
                      ) : (
                        <img
                          width={'40'}
                          alt="profile image"
                          src={userImageIcon}
                        />
                      )}
                    </Avatar>
                  </Grid>
                  <Grid >
                    <Stack direction="row" spacing={1}>
                      {this.state.uploadImage ? (
                        <>
                          <Button
                            onClick={this.uploadFromFiles}
                            variant="outlined"
                            color="primary"
                          >
                            {this.props.users.languageStateForRedux?.buttons?.attendees?.change}
                          </Button>
                          <Button
                            onClick={this.deleteProfileImageOnClick}
                            variant="outlined"
                            color="secondary"
                          >
                            {this.props.users.languageStateForRedux?.buttons?.attendees?.remove}
                          </Button>
                        </>
                      ) : (
                        <span
                          onClick={this.uploadFromFiles}
                          className="btn btn-secondary btn-file"
                          style={{
                            padding: '12px',
                            backgroundColor: 'transparent',
                            color: '#5141E7',
                          }}
                        >
                          {this.props.users.languageStateForRedux?.buttons?.attendees?.photo}{' '}
                        </span>
                      )}
                    </Stack>
                    <span
                      className="btn-file"
                    >
                      <input
                        type="file"
                        accept="image/*"
                        id="profilePic"
                        name="profilePic"
                        ref={this.chooseAttendeeProfileImage}
                        onChange={(e) => this.onProfileImageChange(e, 'add')}
                      />
                    </span>
                  </Grid>
                </Grid>
              </Box>

              <Box component="div" className="inputField" pb={2}>
                <Box component="label" display="flex" alignItems="center">
                  <img alt="" src={emailIcon} />
                  <Box component="span" pl={1}>
                    {this.props.users.languageStateForRedux?.column_names?.attendees?.email} <span className="required">*</span>
                  </Box>
                </Box>
                <input
                  type="text"
                  disabled
                  placeholder="Enter email addresses"
                  className="form-control small"
                  name="emailForEdit"
                  value={emailForEdit}
                  onChange={this.handleChange}
                />
                <span className="required"> {errors.emailForEdit} </span>
              </Box>
              <Box component="div" className="inputField" pb={2}>
                <label>{this.props.users.languageStateForRedux?.actions?.attendees?.selectGroup}</label>
                <ReactSelect
                  options={this.getOptionsForEditAttendeesGroups()}
                  //  styles={reactSelectStyles}
                  isMulti
                  onChange={this.handleGroupsSelectChange}
                  closeMenuOnSelect={false}
                  value={this.state.selectedGroups}
                  // components={{ Option: this.customOptionLabel }}
                  isOptionDisabled={this.isOptionDisabled}
                // getOptionLabel={this.customOptionLabel}
                />

              </Box>
              <Box component="div" className="inputField" pb={2}>
                <label>Department</label>
                <input
                  type="text"
                  className="form-control small"
                  placeholder="Enter Departmet"
                  value={department}
                  name="department"
                  onChange={this.handleChange}
                  disabled={this.state.sourceOfAttendeeForEdit === "Google Workspace"}
                />
              </Box>
              {this.state.sourceOfAttendeeForEdit !== "Google Workspace" ?
                this.props.attendees &&
                Array.isArray(this.props.attendees.customFields) &&
                this.props.attendees.customFields.length > 0 &&
                this.props.attendees.customFields.map((customField) => {
                  return (

                    <Box
                      key={customField._id}
                      component="div"
                      className="inputField"
                      pb={2}
                    >
                      <Box component="label" display="flex" alignItems="center">
                        <Box component="span">
                          {customField.fieldData.fieldLabel}
                        </Box>
                      </Box>
                      {customField.fieldData.fieldType !== 'dropdown' &&
                        customField.fieldData.fieldType !== 'checkbox' && (
                          <input
                            placeholder={
                              customField.fieldData.fieldType !== 'date'
                                ? `Enter ${customField.fieldData.fieldLabel}`
                                : null
                            }
                            className="form-control small"
                            name={customField._id}
                            value={
                              this.state.customFieldsForEdit &&
                              this.state.customFieldsForEdit[customField._id]
                            }
                            type={customField.fieldData.fieldType}
                            onChange={this.handleCustomFieldChange}
                          />
                        )}

                      <span className="required">
                        {' '}
                        {errors[customField._id] &&
                          `Please enter the ${customField.fieldData.fieldLabel}`}{' '}
                      </span>
                      {customField.fieldData.fieldType === 'email' && (
                        <span className="required"> {errors.validEmail} </span>
                      )}
                      {customField.fieldData.fieldType === 'url' && (
                        <span className="required"> {errors.validUrl} </span>
                      )}

                      {customField.fieldData.fieldType === 'dropdown' && (
                        <FormControl
                          size="small"
                          fullWidth
                        >
                          <Select
                            type="text"
                            value={
                              this.state.customFieldsForEdit &&
                              this.state.customFieldsForEdit[customField._id]
                            }
                            onChange={this.handleCustomFieldChange}
                            placeholder={
                              customField.fieldData.fieldType !== 'date'
                                ? `Enter ${customField.fieldData.fieldLabel}`
                                : ``
                            }
                            className="form-control select small"
                            name={customField._id}
                            style={{ padding: '0px 0px', }}
                            MenuProps={MenuProps}
                            displayEmpty
                          >
                            <MenuItem
                              value="" disabled selected hidden
                            >
                              {`Select a ${customField.fieldData.fieldLabel}`}
                            </MenuItem>
                            {customField?.fieldData?.listValue?.map(
                              (data, id) => (

                                <MenuItem key={id} value={data}>
                                  {data}
                                </MenuItem>

                              ),
                            )}
                          </Select>
                        </FormControl>
                      )}
                      {customField?.fieldData?.fieldType === 'checkbox' &&
                        customField.fieldData?.checkboxValue?.map((item) => (
                          <label>
                            <input
                              type="checkbox"
                              name={customField._id}
                              value={item}
                              checked={
                                customFieldsForEdit[customField._id]?.indexOf(
                                  item,
                                ) > -1
                                  ? true
                                  : false
                              }
                              onChange={this.handleChangeCheck}
                            />{' '}
                            {item}
                          </label>
                        ))}
                    </Box>
                  );
                })
                :
                this.props.attendees &&
                Array.isArray(this.props.attendees.customFields) &&
                this.props.attendees.customFields.length > 0 &&
                this.props.attendees.customFields.map((customField) => {
                  return (
                    <Box
                      key={customField._id}
                      component="div"
                      className="inputField"
                      pb={2}
                    >
                      <Box component="label" display="flex" alignItems="center">
                        <Box component="span">
                          {customField.fieldData.fieldLabel}
                        </Box>
                        {this.state.googleWorkspaceDataForEdit[customField._id] && (<Tooltip
                          title="Google workspace's custom fields are not editable."
                          placement="right"
                          arrow
                        >
                          <Box
                            component="img"
                            sx={{ width: '20px' }}
                            ml={1}
                            alt="information Icon"
                            src={informationIcon}
                          />
                        </Tooltip>)}
                      </Box>

                      {customField.fieldData.fieldType !== 'dropdown' &&
                        customField.fieldData.fieldType !== 'checkbox' && (
                          <input
                            placeholder={
                              customField.fieldData.fieldType !== 'date'
                                ? `Enter ${customField.fieldData.fieldLabel}`
                                : null
                            }
                            class="form-control small"
                            name={customField._id}
                            disabled={this.state.googleWorkspaceDataForEdit[customField._id]}
                            value={
                              this.state.googleWorkspaceDataForEdit[customField._id] ?
                                this.state.googleWorkspaceDataForEdit[customField._id] :
                                (this.state.customFieldsForEdit &&
                                  this.state.customFieldsForEdit[customField._id])
                            }
                            type={customField.fieldData.fieldType}
                            onChange={this.handleCustomFieldChange}
                          />
                        )}

                      <span className="required">
                        {' '}
                        {errors[customField._id] &&
                          `Please enter the ${customField.fieldData.fieldLabel}`}{' '}
                      </span>
                      {customField.fieldData.fieldType === 'email' && (
                        <span className="required"> {errors.validEmail} </span>
                      )}
                      {customField.fieldData.fieldType === 'url' && (
                        <span className="required"> {errors.validUrl} </span>
                      )}

                      {customField.fieldData.fieldType === 'dropdown' && (
                        <FormControl
                          size="small"
                          fullWidth
                        >
                          <Select
                            type="text"
                            value={
                              this.state.customFieldsForEdit &&
                              this.state.customFieldsForEdit[customField._id]
                            }
                            onChange={this.handleCustomFieldChange}
                            placeholder={
                              customField.fieldData.fieldType !== 'date'
                                ? `Enter ${customField.fieldData.fieldLabel}`
                                : ``
                            }
                            className="form-control select small"
                            name={customField._id}
                            style={{ padding: '0px 0px', }}
                            MenuProps={MenuProps}
                            displayEmpty
                          >
                            <MenuItem
                              value="" disabled selected hidden
                            >
                              {`Select a ${customField.fieldData.fieldLabel}`}
                            </MenuItem>
                            {customField?.fieldData?.listValue?.map(
                              (data, id) => (

                                <MenuItem key={id} value={data}>
                                  {data}
                                </MenuItem>

                              ),
                            )}
                          </Select>
                        </FormControl>
                      )}
                      {customField?.fieldData?.fieldType === 'checkbox' &&
                        customField.fieldData?.checkboxValue?.map((item) => (
                          <label>
                            <input
                              type="checkbox"
                              name={customField._id}
                              value={item}
                              checked={
                                customFieldsForEdit[customField._id]?.indexOf(
                                  item,
                                ) > -1
                                  ? true
                                  : false
                              }
                              onChange={this.handleChangeCheck}
                            />{' '}
                            {item}
                          </label>
                        ))}
                    </Box>
                  );
                })
              }
            </Grid>
            <Grid size={{ xs: 12, }}>
              <Grid container justifyContent="end">
                <Stack spacing={1} direction="row" fullWidth>
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={this.saveEditAttendee}
                  >
                    {this.props.users.languageStateForRedux?.buttons?.attendees?.save}
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    disableElevation
                    // onClick={this.handleClosePrintBadgeModal}
                    onClick={this.handleCloseEditAttendeeModal}
                  >
                    {this.props.users.languageStateForRedux?.common?.cancel}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Modal>;

        {/* Badge Modal start */}
        <Modal
          open={this.state.openPrintBadgeModal}
          onClose={this.handleClosePrintBadgeModal}
          classNames={{
            overlay: '',
            modal: 'Invite-User-Modal',
          }}
          center
        >
          <Grid component="div" container className="modal-wrapper">
            <Grid size={{ xs: 12, }} pb={3}>
              <Stack direction={'row'} justifyContent={'space-between'} alignItems={'end'}>
                <Box>
                  <Box component="p" mb={1} className="subtitle">
                    {this.props.users.languageStateForRedux?.formfields?.attendees?.badge}
                  </Box>
                  <p className="small-subtitle">
                    {this.props.users.languageStateForRedux?.formfields?.attendees?.badgeDesc}
                  </p>
                </Box>
                <ReactToPrint
                  content={() => this.badgePrintComponentRef}
                  pageStyle={getReturnValues(badgePrintSettings)}
                  copyStyles={false}
                  onAfterPrint={this.afterBadgePrint}
                >
                  <PrintContextConsumer>
                    {({ handlePrint }) => (
                      <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={handlePrint}
                        startIcon={<PrintIcon />}
                      >
                        {this.props.users.languageStateForRedux?.buttons?.attendees?.print}
                      </Button>
                    )}
                  </PrintContextConsumer>
                </ReactToPrint>
              </Stack>
            </Grid>
            <Grid size={{ xs: 12, }} className='bagde-modal' id='bagde-modal' component="div" textAlign="center">
              <BadgeContent ref={el => (this.badgePrintComponentRef = el)} badgeOutput={badgeOutput} />
            </Grid>
          </Grid>
        </Modal>;
        {/* Badge Modal end */}

        <Modal
          open={this.state.openCheckedinHistoryAttendeeModal}
          onClose={this.handleCloseCheckedinHistoryAttendeeModal}
          classNames={{
            overlay: 'full-screen_overlay',
            modal:
              'full-screen_modal manage-custom-fields modal-default-close-none',
          }}
          center
        >
          <Grid container justifyContent="end" width={"100%"}>
            <Grid
              size={{
                md: 6,
                lg: 7,
                xl: 8
              }}
              onClick={this.handleCloseCheckedinHistoryAttendeeModal}
            ></Grid>
            <Grid
              size={{
                xs: 12,
                md: 6,
                lg: 5,
                xl: 4
              }}

              className="full-screen_modal__form"
            >
              <Box component="div" className="full-screen_modal__form_header">
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid >
                    <h3 className="subtitle">
                      {this.props.users.languageStateForRedux?.actions?.attendees?.checkedInHistory} (
                      {this.state.attendeeCheckinHistory &&
                        this.state.attendeeCheckinHistory.loginHistory &&
                        Array.isArray(
                          this.state.attendeeCheckinHistory.loginHistory,
                        )
                        ? this.state.attendeeCheckinHistory.loginHistory.length
                        : 0}
                      )
                    </h3>
                  </Grid>
                  <Grid >
                    <Grid container>
                      <Grid >
                        { authPermissionValidation(permissionsConstants.exportAttendees) && (
                          <Button
                            variant="outlined"
                            color="primary"
                            endIcon={<img alt="" src={ExportIcon} />}
                            disableElevation
                            disabled={
                              this.state.attendeeCheckinHistory &&
                                this.state.attendeeCheckinHistory.loginHistory &&
                                Array.isArray(
                                  this.state.attendeeCheckinHistory.loginHistory,
                                ) &&
                                this.state.attendeeCheckinHistory.loginHistory
                                  .length > 0
                                ? false
                                : true
                            }
                            onClick={() =>
                              this.exportCheckInHistory(
                                this.state.attendeeCheckinHistory,
                              )
                            }
                          >
                            {this.props.users.languageStateForRedux?.common?.export}
                          </Button>
                        )}
                      </Grid>
                      <Box component={Grid} ml={1}>
                        <CloseIcon
                          onClick={
                            this.handleCloseCheckedinHistoryAttendeeModal
                          }
                          className="modal-close-button"
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <Box component="div" className="full-screen_modal__form_header">
                <Grid container justifyContent="between" alignItems="center">
                  <Grid size={{ xs: "grow", }}>
                    <Box
                      component="p"
                      className="subtitle-regular text-primary-light"
                    >
                      {this.state.attendeeCheckinHistory &&
                        this.state.attendeeCheckinHistory.email}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box
                component="div"
                px={3}
                py={1}
                sx={{ maxHeight: 'calc(100vh - 150px)' }}
                className="full-screen_modal__form_body"
              >
                <Box className="custom-fields-table" component={TableContainer}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell className="tableHeadCell">
                          {this.props.users.languageStateForRedux?.column_names?.attendees?.checkedIn}
                        </TableCell>
                        <TableCell className="tableHeadCell" align="left">
                          {this.props.users.languageStateForRedux?.column_names?.attendees.checkedOut}
                        </TableCell>
                        <TableCell className="tableHeadCell" align="left">
                          {this.props.users.languageStateForRedux?.column_names?.attendees?.ip}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {this.state.attendeeCheckinHistory &&
                      this.state.attendeeCheckinHistory.loginHistory &&
                      Array.isArray(
                        this.state.attendeeCheckinHistory.loginHistory,
                      ) &&
                      this.state.attendeeCheckinHistory?.loginHistory?.length >
                      0 ? (
                      <TableBody>
                        {this.state.attendeeCheckinHistory.loginHistory.map(
                          (loginHistoryData) => {
                            return (
                              <TableRow>
                                <TableCell
                                  className="tableBodyCell"
                                  scope="row"
                                >
                                  {loginHistoryData.checkedIn &&
                                    loginHistoryData.checkedIn !== null
                                    ? moment
                                      .tz(
                                        loginHistoryData.checkedIn,
                                        this.props.project?.project?.timezone,
                                      )
                                      .format('MMM, DD YYYY, h:mm A')
                                    : ''}
                                </TableCell>
                                <TableCell
                                  className="tableBodyCell"
                                  scope="row"
                                >
                                  {loginHistoryData.checkedOut &&
                                    loginHistoryData.checkedOut !== null
                                    ? moment
                                      .tz(
                                        loginHistoryData.checkedOut,
                                        this.props.project?.project?.timezone,
                                      )
                                      .format('MMM, DD YYYY, h:mm A')
                                    : ''}
                                </TableCell>
                                <TableCell
                                  className="tableBodyCell"
                                  align="left"
                                >
                                  {loginHistoryData.ipAddress}
                                </TableCell>
                              </TableRow>
                            );
                          },
                        )}
                      </TableBody>
                    ) : (
                      <TableBody>
                        <TableRow>
                          <TableCell
                            className="tableBodyCell"
                            colSpan={3}
                            style={{ border: 'none', textAlign: 'center' }}
                          >
                            {this.props.users.languageStateForRedux?.common?.noRecords}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Modal >;

        {/* crop image popup */}
        < Modal
          open={this.state.cropOpen}
          onClose={this.onCloseModal}
          center
          classNames={{
            overlay: '',
            modal: 'Invite-User-Modal change-profile-image',
          }
          }
        >
          <Grid component="form" container className="modal-wrapper">
            <Grid size={{ xs: 12, }} mt={0} pb={4}>
              <Box component="p" mb={1} className="subtitle">
                {this.props.users.languageStateForRedux?.audienceTab?.profilePicture}
              </Box>
              <p className="small-subtitle">
                {this.props.users.languageStateForRedux?.audienceTab?.pictureModalDesc}
              </p>
            </Grid>
            <ImgCrop
              s3File={this.state.s3File}
              profilePic={this.getData}
              pic={this.state.uploadImage}
              uploadImage={this.state.confirmImage}
              langState={this.props.users.languageStateForRedux}
              handleFileChosen={this.handleFileChosen}
            />
            <Grid size={{ xs: 12, }}>
              <Grid container justifyContent="end">
                <Stack spacing={1} direction="row" fullWidth>
                  <Button
                    onClick={this.onCloseModal}
                    variant="contained"
                    // color="primary"
                    disableElevation
                  >
                    {this.props.users.languageStateForRedux?.common?.cancel}
                  </Button>
                  <Button
                    onClick={
                      this.state.openAttendeeForm !== true
                        ? () =>
                          this.setState({ loading: true }, () =>
                            this.saveAttendeeProfile(),
                          )
                        : () => this.addNewProfilePic()
                    }
                    variant="contained"
                    color="primary"
                    disableElevation
                  >
                    {this.props.users.languageStateForRedux?.buttons?.attendees?.save}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Modal >;
        {/* crop image close */}

        < Modal
          open={this.state.openCheckinModal}
          onClose={this.handleCloseopenCheckinModal}
          classNames={{
            overlay: '',
            modal: 'Invite-User-Modal',
          }}
          center
        >
          <Grid
            component="form"
            container
            alignItems="center"
            justifyContent="center"
            className="modal-wrapper"
          >
            <Grid size={{ xs: 12, }} pb={3}>
              <Box textAlign="center" mb={4}>
                <img alt="" src={CheckedInOut} />
              </Box>
              <Box component="p" textAlign="center" mb={1} className="subtitle">
                {' '}
                {isCheckinButton ? this.props.users.languageStateForRedux?.column_names?.attendees?.checkedOut : this.props.users.languageStateForRedux?.column_names?.attendees?.checkedIn}{' '}
              </Box>
            </Grid>
            <Box component={Grid} mb={4} size={{ xs: "grow", }} >
              <label className="paragraph">
                {this.props.users.languageStateForRedux?.column_names?.attendees?.choose}<span className="required">*</span>
              </label>
              <LocalizationProvider
                style={{ width: 100 }}
                dateAdapter={AdapterDateFns}
              >
                <MobileDateTimePicker
                  renderInput={(params) => (
                    <StyledTextField
                      variant="outlined"
                      fullWidth
                      size="medium"
                      {...params}
                    />
                  )}
                  selected={checkinDate}
                  checkinDate={checkinDate}
                  value={checkinDate}
                  maxDate={new Date()}
                  onChange={(e) => {
                    this.changeDate(e);
                  }}
                  inputFormat="MMM, dd yyyy, hh:mm a"
                />
              </LocalizationProvider>
            </Box>
            <Grid size={{ xs: 12, }}>
              <Grid
                component="form"
                container
                alignItems="center"
                justifyContent="center"
              >
                <Box component={Grid} mb={2} size={{ xs: 12, }}>
                  {isCheckinButton ? (
                    <Button
                      fullWidth
                      size="large"
                      variant="contained"
                      color="primary"
                      disableElevation
                      onClick={this.changeCheckoutStatus}
                    >
                      {this.props.users.languageStateForRedux?.column_names?.attendees?.checkedOut}
                    </Button>
                  ) : (
                    <Button
                      fullWidth
                      size="large"
                      variant="contained"
                      color="primary"
                      disableElevation
                      onClick={this.changeCheckinStatus}
                    >
                      {this.props.users.languageStateForRedux?.column_names?.attendees?.checkedIn}
                    </Button>
                  )}
                </Box>
                <Grid size={{ xs: 12, }}>
                  <Button
                    fullWidth
                    size="large"
                    variant="text"
                    color="primary"
                    disableElevation
                    onClick={this.handleCloseopenCheckinModal}
                  >
                    {this.props.users.languageStateForRedux?.common?.cancel}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Modal >
        <Modal
          open={this.state.openImportModal}
          onClose={this.handleCloseOpenImportModal}
          classNames={{
            overlay: '',
            modal: 'Invite-User-Modal',
          }}
          center
        >
          <Grid component="div" container className="modal-wrapper">
            <Grid size={{ xs: 12, }} pb={3}>
              <Box component="p" mb={1} className="subtitle">
                {this.props.users.languageStateForRedux?.actions?.attendees?.import}
              </Box>
              <p className="small-subtitle">
                {this.props.users.languageStateForRedux?.messages?.importAttendeeMsg}
              </p>
            </Grid>
            <Grid size={{ xs: 12, }} pb={4}>
              <Box component="div" className="inputField" pb={1}>
                <label>
                  {this.props.users.languageStateForRedux?.actions?.attendees?.select} <span className="required">*</span>
                </label>
                <input
                  type="file"
                  className="form-control small"
                  placeholder=""
                  id="attendeeFile"
                  name="attendeeFile"
                  onChange={this.onAttendeeFileChange}
                  accept=".csv"
                />
              </Box>
              <span className="error_mesage required">
                {' '}
                {errors.attendeeFile}{' '}
              </span>
              <Box component="label" display="flex" alignItems="center">
                <img alt="" src={ExportIcon} />
                <Box
                  component="a"
                  href=""
                  className="secondary-text small-subtitle"
                  pl={1}
                  onClick={(e) => {
                    this.downloadCsvFile(e);
                  }}
                >
                  {this.props.users.languageStateForRedux?.actions?.attendees?.download}
                </Box>
              </Box>
            </Grid>
            <Grid size={{ xs: 12, }}>
              <Grid container justifyContent="end">
                <Stack spacing={1} direction="row" fullWidth>
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={this.handleImportSubmit}
                  >
                    {this.props.users.languageStateForRedux?.common?.import}
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={this.handleCloseOpenImportModal}
                  >
                    {this.props.users.languageStateForRedux?.common?.cancel}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Modal>

        <SuccessAlertPopUp
          openModal={openSuccess}
          closeModalFunc={this.handleImportClose}
          title={this.props.users.languageStateForRedux?.formfields?.attendees?.importSuccessfull}
          description={(this.props.users.languageStateForRedux?.formfields?.attendees?.importMsgOne) + ` <strong> ${this.state.uniqueAttendees} </strong> ` + (this.props.users.languageStateForRedux?.formfields?.attendees?.importMsgTwo)}
          buttonColor="primary"
          buttonFunc={this.handleImportClose}
          buttonText={this.props.users.languageStateForRedux?.buttons?.common?.done}
        />
        <Modal
          open={openValidation}
          onClose={this.handleImportClose}
          classNames={{
            overlay: '',
            modal: 'Invite-User-Modal',
          }}
          center
        >
          <Grid component="div" container className="modal-wrapper">
            <Box
              component={Grid}
              size={{ xs: 12, }}
              sx={{ borderBottom: '1px solid rgba(0, 0, 86, 0.23)' }}
              mb={4}
            >
              <Box component="p" mb={0} className="subtitle">
                {this.props.users.languageStateForRedux?.formfields?.attendees?.validationHeading}
              </Box>
              <p className="small-subtitle">
                {this.props.users.languageStateForRedux?.formfields?.attendees?.makeChanges}
              </p>
            </Box>
            <Grid size={{ xs: 12, }} pb={4}>
              <Box
                component="div"
                sx={{
                  border: '1px solid rgba(0, 0, 86, 0.23)',
                  borderRadius: '4px',
                }}
              >
                <Box
                  component={Grid}
                  p={1.25}
                  sx={{ borderBottom: '1px solid rgba(0, 0, 86, 0.23)' }}
                  container
                >
                  <Grid pl={2}>
                    <img alt="" src={InvalidIcon} />
                  </Grid>
                  <Grid >
                    <Box
                      pl={1}
                      component={'strong'}
                      className=""
                      color={theme.palette.common.black}
                    >
                      {this.props.users.languageStateForRedux?.formfields?.attendees?.invalid} ({this.props.attendees.rejectedEmails})
                    </Box>
                  </Grid>
                </Box>
                <Box
                  className="custom-fields-table"
                  p={1.25}
                  sx={{ maxHeight: '200px' }}
                  component={TableContainer}
                >
                  <Table aria-label="simple table">
                    <TableBody>
                      <TableRow>
                        <TableCell className="tableBodyCell" scope="row">
                          <strong>{this.props.users.languageStateForRedux?.formfields?.attendees?.duplicate}</strong>{' '}
                        </TableCell>
                        <TableCell className="tableBodyCell" align="right">
                          {this.props.attendees.duplicateEmailsInCsv}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="tableBodyCell" scope="row">
                          <strong>{this.props.users.languageStateForRedux?.formfields?.attendees?.invalidEmails}</strong>{' '}
                        </TableCell>
                        <TableCell className="tableBodyCell" align="right">
                          {this.props.attendees.invalidEmailsRejection}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="tableBodyCell" scope="row">
                          <strong>{this.props.users.languageStateForRedux?.formfields?.attendees?.already}</strong>{' '}
                        </TableCell>
                        <TableCell className="tableBodyCell" align="right">
                          {this.props.attendees.alreadyExistInDb}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="tableBodyCell" scope="row">
                          <strong>{this.props.users.languageStateForRedux?.formfields?.attendees?.noGroup}</strong>{' '}
                        </TableCell>
                        <TableCell className="tableBodyCell" align="right">
                          {this.props.attendees?.groupNotExists}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Box>
              </Box>
            </Grid>
            <Grid size={{ xs: 12, }}>
              <Grid container justifyContent="end">
                <Stack spacing={1} direction="row" fullWidth>
                  <Button
                    variant="outlined"
                    color="primary"
                    disableElevation
                    onClick={this.handleClickOpenImportModal}
                  >
                    {this.props.users.languageStateForRedux?.common?.retry}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={this.handleImportClose}
                  >
                    {this.props.users.languageStateForRedux?.common?.close}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Modal>
        <Modal
          open={this.state.resetPasswordToggle}
          onClose={this.handleCloseResetPassword}
          classNames={{
            overlay: '',
            modal: 'Invite-User-Modal',
          }}
          center
        >
          <Grid container className="modal-wrapper">
            <Grid size={{ xs: 12, }} pb={4}>
              <Box component="p" mb={1} className="subtitle">
                {this.props.users.languageStateForRedux?.common?.confirmReplicate}
              </Box>
              <Box component="p" mb={1} className="small-subtitle">
                {this.props.users.languageStateForRedux?.formfields?.attendees?.resetPassDesc}
              </Box>
            </Grid>
            <Grid size={{ xs: 12, }}>
              <Grid container justifyContent="end">
                <Stack spacing={1} direction="row" fullWidth>
                  {
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={this.handleResetPassword}
                      >
                        {this.props.users.languageStateForRedux?.buttons?.attendees?.yesSend}
                      </Button>
                      <Button
                        variant="outlined"
                        disableElevation
                        onClick={this.handleCloseResetPassword}
                      >
                        {this.props.users.languageStateForRedux?.common?.cancel}
                      </Button>
                    </>
                  }
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Modal>

        <Modal
          open={openAssignAccessCode}
          onClose={this.closeAssignAccessCode}
          classNames={{
            overlay: 'full-screen_overlay',
            modal:
              'full-screen_modal manage-custom-fields modal-default-close-none',
          }}
          center
        >
          <Grid container justifyContent="end" width={"100%"}>
            <Grid
              size={{
                xs: 12, md: 7,
                lg: 6,
                xl: 4
              }}

              className="full-screen_modal__form"
            >
              <Box component="div" className="full-screen_modal__form_header">
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid >
                    <h3 className="subtitle">{this.props.users.languageStateForRedux?.actions?.attendees?.assignAcc}</h3>
                  </Grid>
                  <Grid >
                    <Grid container>
                      <Grid >
                        <Button
                          variant="contained"
                          color="primary"
                          disableElevation
                          disabled={
                            this.checkBoxIdsAccessCode.length > 0 ? false : true
                          }
                          onClick={this.accessCodeAssign}
                        >
                          {this.props.users.languageStateForRedux?.actions?.attendees?.assignAcc}
                        </Button>
                      </Grid>
                      <Box component={Grid} ml={1}>
                        <CloseIcon
                          onClick={this.closeAssignAccessCode}
                          className="modal-close-button"
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <Box
                component="div"
                px={3}
                py={2}
                className="full-screen_modal__form_body"
              >
                <Grid
                  spacing={1.5}
                  container
                  justifyContent="between"
                  alignItems="center"
                >
                  <Grid size={{ xs: 12, }} pb={2}>
                    <Grid size={{ xs: 12, }} pb={2} className="inputField">
                      <label>{this.props.users.languageStateForRedux?.column_names?.attendees?.email}</label>
                      <Box className="paragraph">{this.state.email}</Box>
                    </Grid>
                  </Grid>
                  <Grid size={{ xs: "grow", }}>
                    <Box component="p" className="paragraph">
                      {this.props.users.languageStateForRedux?.formfields?.attendees?.unusedAccCode} ({this.props.attendees.totalAccessCode}
                      )
                    </Box>
                  </Grid>
                  <Grid className="">
                    <form onSubmit={this.handleSearchSubmitForAccessCode}>
                      <TextField
                        name="searchTextForAccessCode"
                        placeholder="Search..."
                        className="search"
                        size="small"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <img alt="" src={SearchIcon} />
                            </InputAdornment>
                          ),
                        }}
                        value={this.state.searchTextForAccessCode}
                        onChange={this.handleChange}
                      />
                    </form>
                  </Grid>
                </Grid>

                <Box
                  className="custom-fields-table"
                  scomponent={TableContainer}
                  sx={{
                    maxHeight: 'calc(100vh - 330px)',
                    overflowY: 'scroll',
                  }}
                  my={2}
                >
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          className="tableHeadCell checkbox"
                          scope="row"
                        ></TableCell>
                        <TableCell className="tableHeadCell email" scope="row">
                          {this.props.users.languageStateForRedux?.column_names?.accessCodes?.code}
                        </TableCell>
                        <TableCell
                          className="tableHeadCell long-text"
                          style={{ width: '180px', maxWidth: '180px' }}
                          scope="row"
                        >
                          {this.props.users.languageStateForRedux?.column_names?.accessCodes?.expiringPd}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {Array.isArray(getUnassignAccessCode) &&
                      getUnassignAccessCode?.length > 0 ? (
                      <>
                        <TableBody>
                          {Array.isArray(getUnassignAccessCode) &&
                            getUnassignAccessCode?.length &&
                            getUnassignAccessCode.map((accessCode) => (
                              <TableRow>
                                <TableCell
                                  className="tableBodyCell checkbox"
                                  scope="row"
                                >
                                  <Checkbox
                                    name={accessCode._id}
                                    color="primary"
                                    p={0}
                                    onChange={(e) =>
                                      this.selectCheckBoxAccessCode(
                                        e,
                                        accessCode,
                                      )
                                    }
                                    checked={
                                      this.checkBoxIdsAccessCode.indexOf(
                                        accessCode._id,
                                      ) !== -1
                                        ? true
                                        : false
                                    }
                                  />
                                </TableCell>
                                <TableCell
                                  className="tableBodyCell email"
                                  align="left"
                                >
                                  {accessCode.accessCode}
                                </TableCell>
                                <TableCell
                                  className="tableBodyCell long-text"
                                  align="left"
                                  style={{ width: '180px', maxWidth: '180px' }}
                                >
                                  {' '}
                                  {accessCode.expringPeriod &&
                                    accessCode.expringPeriod ===
                                    ACCESS_CODE_EXPERING_PERIOD
                                    ? 'Lifetime'
                                    : accessCode.expringPeriod}{' '}
                                  {accessCode.expringPeriod &&
                                    accessCode.expringPeriod !==
                                    ACCESS_CODE_EXPERING_PERIOD
                                    ? 'Hours'
                                    : ''}
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </>
                    ) : (
                      <TableBody>
                        <TableRow>
                          <TableCell
                            colSpan={3}
                            style={{ textAlign: 'center' }}
                          >
                            {this.props.users.languageStateForRedux?.common?.noRecords}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </Box>
              </Box>
              <Box className="full-screen_modal__form_footer">
                <Grid container alignItems="center" justifyContent="center">
                  <Grid size={{ xs: "grow", }}>
                    <Grid container justifyContent="center">
                      <Grid>
                        <Button
                          disableElevation
                          variant="contained"
                          color="primary"
                          onClick={this.loadMoreAccessCode}
                          disabled={
                            this.props.attendees?.totalAccessCode ===
                              getUnassignAccessCode?.length
                              ? true
                              : false
                          }
                        >
                          Load More
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Modal >

        <Modal
          open={showProgress}
          onClose={this.hideProgress}
          classNames={{
            overlay: '',
            modal: 'Invite-User-Modal',
          }}
          center
        >
          <ProgresBar progressPercentage={progressPercentage} import={this.state.import} />
        </Modal>

        <Modal
          open={this.state.downloadExport}
          classNames={{
            overlay: '',
            modal: 'Invite-User-Modal modal-default-close-none',

          }}
          center
        >
          <Grid
            component="form"
            container
            alignItems="center"
            justifyContent="center"
          >
            <Grid size={{ xs: 12, }} pb={2.6}>
              <Box textAlign="center" mt={1.6} mb={3}>
                <img alt="" src={Exporting} width="92" height="92" />
              </Box>
              <Box
                component="p"
                textAlign="center"
                mb={3}
                className="subsecondary bold"
              >
                Please wait while we securely <br />
                export your data!
              </Box>
              <Box component="p" textAlign="center" className="paragraph">
                Your information is being processed and will be ready for<br />
                download shortly. Thank you for your patience!
              </Box>
            </Grid>
          </Grid>
        </Modal>

        <UploadFromFilesPopUp
          chooseAttendeeProfileImage={this.chooseAttendeeProfileImage}
          chooseAttendeeProfileImageClick={this.chooseAttendeeProfileImageClick}
          onProfileImageChange={this.onProfileImageChange}
          uploadFromFiles={this.state.uploadFromFiles}
          buttonState={this.state.buttonState}
          handleCloseUploadFromFileModule={this.handleCloseFileModulePopup}
          handleChoosedFile={this.handleChoosedFile}
          parentForPopup={this.state.parentForPopup}
        />

        <NotificationContainer />;
        {loading || getAttendeesLoading ? <Loader /> : null}
      </ThemeProvider >
    );
  }
}
const mapStateToProps = (state) => ({
  attendees: state.Attendees,
  project: state.Projects,
  events: state.Events,
  googleWorkspace: state.GoogleWorkspace,
  users: state.Users,
  files: state.Files
});
const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      setAssignGroupStatus: Actions.actionAssignRequest,
      listAttendeesGroups: Actions.listAttendeesGroupsRequest,
      sortAttendees: Actions.sortAttendeesRequest,
      setEnabledStatus: Actions.enabledStatusRequest,
      editAttendee: Actions.editAttendeeRequest,
      setCheckinStatus: Actions.actionCheckinRequest,
      updateAttendee: Actions.updateAttendeeRequest,
      deleteAttendee: Actions.deleteAttendeesRequest,
      addAttendee: Actions.addAttendeesRequest,
      listAttendees: Actions.listAttendeesRequest,
      updateCustomField: Actions.updateCustomFieldRequest,
      deleteCustomField: Actions.deleteCustomFieldsRequest,
      getCustomFields: Actions.customFieldsRequest,
      addCustomFields: Actions.addCustomFieldsRequest,
      searchAttendeesRequest: Actions.searchAttendeesRequest,
      sortFilterAttendeesRequest: Actions.sortFilterAttendeesRequest,
      importAttendeesRequest: Actions.importAttendeesRequest,
      downloadCsvRequest: Actions.downloadCsvRequest,
      exportAttendeesRequest: Actions.exportAttendeesRequest,
      exportCheckInHistoryRequest: Actions.exportCheckInHistoryRequest,
      getProjectRequest: Actions.getProjectRequest,
      viewTransactionalReport: Actions.viewTransactionalReport,
      findIfAllowedFromSuperadminRequest:
        Actions.findIfAllowedFromSuperadminRequest,
      assignAccessCode: Actions.assignAccessCodeRequest,
      getUnusedAccessCode: Actions.unUsedAccessCodeRequest,
      getFieldMapping: Actions.getFieldMappingRequest,
      addImagesRequest: Actions.addImagesRequest
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(withCustomRouter(Attendees)); 
