import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Actions } from 'ReduxStore/Actions';
import { NotificationContainer } from 'react-notifications';
import { createNotification } from '../../helpers';
import '../../sass/main.scss';
import { GetInvitedUserDetailsAPI } from '../../ReduxStore/API';
//import * as React from 'react';
import { Helmet } from 'react-helmet';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AuthLayout from '../../Layouts/AuthLayout';
import Card from '../../Layouts/AuthLayout/SignInCard';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { Alert, AlertTitle, Collapse, FormLabel, IconButton, InputAdornment, Stack } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Loader from '../../Components/Loader/loader';
import Grid from '@mui/material/Grid2';
import { CancelIcon } from '../../eijent/components/CustomIcons';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

const label = (
  <span>
    I agree Illumeet &nbsp;
    <a href="/terms_and_conditions" target="_blank">
      Terms & Conditions
    </a>
  </span>
);

class RegisterPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      password: '',
      confirmPassword: '',
      email: '',
      phoneNumber: '',
      company: '',
      errors: {},
      terms: false,
      loading: false,
      showPassword : false,
      showConfirmPassword: false,
    };
  }

  componentDidMount() {
    const { location } = this.props;
    // Parse query parameters from the location prop
    const searchParams = new URLSearchParams(location.search);
    const invited = searchParams.get('invited');
    const token = searchParams.get('token');
    this.setState({ invited: invited });
    this.setState({ invitedToken: token });
    if (invited === '1' && token) {
      this.getInvitedUserDetails(token);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // const login = this.props.login;
    if (
      this.props.isRegister === true &&
      this.props.registerMsg !== prevProps.registerMsg
    ) {
      this.handleAlert('success', this.props.registerMsg);
      let st = (prevState.loading ? this.setState({ loading: false }) : null);
      setTimeout(() => {
        this.props.navigate('/');
      }, 2000);
    }

    if (this.props.error && this.props.errMessage !== prevProps.errMessage) {
      this.handleAlert('error', this.props.errMessage);
      let st = prevState.loading ? this.setState({ loading: false }) : null;
    }
  }


  getInvitedUserDetails = async (token) => {
    try {
      const data = { inviteToken: token };
      const response = await GetInvitedUserDetailsAPI(data);
      console.log("response", response);

      // Prefill email in the form
      this.setState({ email: response?.data?.users?.email });
    } catch (error) {
      console.error('Error fetching user details:', error);
      this.setState({ loading: false });
    }
  };


  handleLoading = () => {
    let loading = this.state.loading;
    this.setState({
      loading: !loading,
    });
  };

  isValidOLD = () => {
    const {
      firstName,
      lastName,
      password,
      confirmPassword,
      email,
      company,
      phoneNumber,
      terms,
    } = this.state;
    let error = {};
    let formIsValid = true;
    const regexTest = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const mobPattern = /^[0-9]{10}$/;
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;


    if (firstName === '' || firstName.trim().length === 0) {
      formIsValid = false;
      error['firstName'] = '*Please enter first name.';
    }
    if (lastName === '' || lastName.trim().length === 0) {
      formIsValid = false;
      error['lastName'] = '*Please enter last name.';
    }

    if (password === '' || password.trim().length === 0) {
      formIsValid = false;
      error['password'] = '*Please enter password.';
    }

    if (password && passwordRegex.test(password) === false) {
      formIsValid = false;
      error['password'] = "*Password must have at least 1 uppercase letter, 1 lowercase letter, 1 number, 1 special character, and a minimum length of 8.";
    }
    if (confirmPassword === '' || confirmPassword.trim().length === 0) {
      formIsValid = false;
      error['confirmPassword'] = '*Please confirm your password.';
    } else if (password !== confirmPassword) {
      formIsValid = false;
      error['confirmPassword'] = '*Passwords do not match.';
    }
    if (email === '' || email.trim().length === 0) {
      formIsValid = false;
      error['email'] = '*Please enter email.';
    }
    if (email && regexTest.test(email) === false) {

      formIsValid = false;
      error['email'] = '*Please enter a valid email address.';
    }
    if (phoneNumber === '' || phoneNumber.trim().length === 0) {
      formIsValid = false;
      error['phoneNumber'] = '*Phone enter phone number.';
    }
    if (phoneNumber && mobPattern.test(phoneNumber) === false) {
      formIsValid = false;
      error['phoneNumber'] =
        '*Phone number should not less than 10 or nor more than 15.';
    }

    if (company === '' || company.trim().length === 0) {
      formIsValid = false;
      error['company'] = '*Please enter company name.';
    }

    //  if (terms === false) {
    //    formIsValid = false;
    //    error['terms'] = '*I agree Illumeet terms and conditions.';
    //  }  

    if (!formIsValid) {
     const errorMessages = Object.values(error).join('\n'); // Combine error messages with a newline
    //  const errorMessages = "Please fill in all mandatory fields before proceeding"; // Combine error messages with a newline
      this.handleAlert('error', errorMessages); // Trigger the alert
    }

    this.setState({ errors: error });
    return formIsValid;
  };

  isValid = () => {
    const {
      firstName,
      lastName,
      password,
      confirmPassword,
      email,
      company,
      phoneNumber,
    } = this.state;
  
    let error = {};
    let formIsValid = true;
    let hasMandatoryFieldError = false;
  
    const regexTest = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const mobPattern = /^[0-9]{10}$/;
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  
    // ✅ Mandatory Fields Check
    if (!firstName || firstName.trim() === "") {
      hasMandatoryFieldError = true;
      formIsValid = false;
      error["firstName"] = "*Required field";
    }
    if (!lastName || lastName.trim() === "") {
      hasMandatoryFieldError = true;
      formIsValid = false;
      error["lastName"] = "*Required field";
    }
    if (!password || password.trim() === "") { 
      hasMandatoryFieldError = true;
      formIsValid = false;
      error["password"] = "*Required field";
    }
    if (!confirmPassword || confirmPassword.trim() === "") {
      hasMandatoryFieldError = true;
      formIsValid = false;
      error["confirmPassword"] = "*Confrim password not matched";
    }
    if (!email || email.trim() === "") {
      hasMandatoryFieldError = true;
      formIsValid = false;
      error["email"] = "*Required field";
    }
    if (!company || company.trim() === "") {
      hasMandatoryFieldError = true;
      formIsValid = false;
      error["company"] = "*Required field";
    }
    if (!phoneNumber || phoneNumber.trim() === "") {
      hasMandatoryFieldError = true;
      formIsValid = false;
      error["phoneNumber"] = "*Required field";
    }
  
    // Show Generic Error Message if Any Mandatory Field is Empty
    if (hasMandatoryFieldError) {
      this.handleAlert("error", "Please fill in all mandatory fields before proceeding.");
    }
  
    // Password Validation
    if (!passwordRegex.test(password)) {
      formIsValid = false;
      error["password"] =
        "Password must have at least 1 uppercase letter, 1 lowercase letter, 1 number, 1 special character, and a minimum length of 8.";
    } 
  
    //  Confirm Password Validation
    if (password && confirmPassword && password !== confirmPassword) {
      formIsValid = false;
      error["confirmPassword"] = "Passwords do not match.";
    }
   
    //  Email Validation
    if (email && !regexTest.test(email)) {
      formIsValid = false;
      error["email"] = "Please enter a valid email address.";
    }
  
    // Phone Number Validation
    if (phoneNumber && !mobPattern.test(phoneNumber)) {
      formIsValid = false;
      error["phoneNumber"] = "Phone number must be exactly 10 digits.";
    }
  
    //  Show specific validation errors if they exist
    if (!formIsValid && !hasMandatoryFieldError) {
      const errorMessages = Object.values(error).join("\n");
      this.handleAlert("error", errorMessages);
    }
  
    //  Set `errors` in state so MUI highlights inputs
    this.setState({ errors: error });
  
    return formIsValid;
  }; 
  

  handleChange = (event) => {
    let error = {};
    if (event.target.name === 'phoneNumber') {
      const re = /^[0-9\b]+$/;
      if (event.target.value === '' || re.test(event.target.value)) {
        this.setState({
          [event.target.name]: event.target.value,
        });
        this.setState({ errors: {} });
      } else {
        error['phoneNumber'] = '*Please enter a valid numeric phone number.';
        this.setState({ errors: error });
      }
    } else {
      this.setState({
        [event.target.name]: event.target.value,
      });
    }


  };

  handleCheckBox = (event) => {
    this.setState({
      [event.target.name]: event.target.checked,
    });
  };

  togglePasswordVisibility = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  toggleConfirmPasswordVisibility = () => {
    this.setState((prevState) => ({
      showConfirmPassword: !prevState.showConfirmPassword,
    }));
  };

  handleSubmit = (e) => {
    e.preventDefault();

    if (this.isValid()) {
      const {
        firstName,
        lastName,
        password,
        confirmPassword,
        email,
        phoneNumber,
        company,
        invited,
        invitedToken,
      } = this.state;
      const requestbody = {
        firstName,
        lastName,
        password,
        email,
        phoneNumber,
        company,
        invited,
        invitedToken,
      };
      this.props.userRegister(requestbody);
      this.handleLoading();
      this.props.startLoading();
    }
  };


  handleAlert = (type, message) => {
    this.setState({
      alert: { type, message },
    });
  };
  render() {
    const {
      firstName,
      lastName,
      password,
      confirmPassword,
      email,
      company,
      phoneNumber,
      errors,
      terms,
      loading,
    } = this.state;
    return (
      <AuthLayout>
        <Helmet>
          <title>Eijent | Register</title>
        </Helmet>
        <Box component="form" onSubmit={this.handleSubmit}>
          <Card >
            <Stack spacing={2}>
              <Typography
                component="h1"
                variant="h1"
                textAlign={'center'}
                sx={{ width: '100%', }}
              >
                Registration

                {loading ? <Loader /> : null}
              </Typography>

              {/* ---Form--- */}
              <Grid container spacing={2}
              // sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 2 }}
              >
                <Grid component={FormControl} size={{ md: 6, }}>
                <FormLabel htmlFor="firstName" error={!!errors.firstName}>First Name</FormLabel>
                  <TextField
                    id="firstName"
                    type="text"
                    name="firstName"
                    value={firstName}
                    placeholder="First Name"
                    autoFocus
                    fullWidth
                    variant="outlined"
                    size="large"
                    onChange={this.handleChange}
                    color={errors.firstName ? 'error' : 'primary'}
                    slotProps={{
                      input: {
                        endAdornment: <InputAdornment position="end"  >
                          <Typography color='error'>
                            *
                          </Typography>
                        </InputAdornment>,
                      },
                    }}
                  />
                </Grid>

                <Grid component={FormControl} size={{ md: 6, }}> 
                  <FormLabel htmlFor="lastName" error={!!errors.lastName} >Last Name</FormLabel>
                  <TextField
                    id="lastName"
                    type="text"
                    name="lastName"
                    value={lastName}
                    onChange={this.handleChange}
                    placeholder="Last Name"
                    autoComplete="last name"
                    fullWidth
                    variant="outlined"
                    size="large"
                    color={errors.lastName ? 'error' : 'primary'}
                    slotProps={{
                      input: {
                        endAdornment: <InputAdornment position="end"  >
                          <Typography color='error'>
                            *
                          </Typography>
                        </InputAdornment>,
                      },
                    }}
                  />
                </Grid>

                <Grid component={FormControl} size={{ md: 6, }}>
                <FormLabel htmlFor="password" error={!!errors.password}>Password</FormLabel>
                  <TextField
                    name="password"
                    id="password"
                    value={password}
                    placeholder="Password"
                    type={this.state.showPassword ? "text" : "password"}
                    //type="password"
                    autoComplete="password"
                    onChange={this.handleChange}
                    fullWidth
                    variant="outlined"
                    size='large'
                    color={errors.password ? 'error' : 'primary'}  
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={this.togglePasswordVisibility}
                            size='small'
                            color='transparent'
                            variant='contained'
                            edge="end"
                            sx={{
                              backgroundColor: "transparent", // Remove background
                              "&:hover": {
                                backgroundColor: "transparent", // Prevent hover background
                              },
                            }}
                          >
                            {this.state.showPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid> 


                <Grid component={FormControl} size={{ md: 6, }}>
                <FormLabel htmlFor="confirmPassword" error={!!errors.confirmPassword}>Confirm Password</FormLabel>
                   <TextField
                    type={this.state.showConfirmPassword ? "text" : "password"}
                    name="confirmPassword"
                    value={confirmPassword}
                    placeholder="Confirm Password"
                    onChange={this.handleChange}
                    autoComplete="confirmPassword"
                    fullWidth
                    variant="outlined"
                    size='large'
                    color={errors.confirmPassword ? 'error' : 'primary'} 
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={this.toggleConfirmPasswordVisibility}
                            edge="end"
                            size='small'
                            color='transparent'
                            variant='contained'
                            sx={{
                              backgroundColor: "transparent", // Remove background
                              "&:hover": {
                                backgroundColor: "transparent", // Prevent hover background
                              },
                            }}
                          >
                            {this.state.showConfirmPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                   /*  slotProps={{
                      input: {
                        endAdornment: <InputAdornment position="end"  >
                          <Typography color='error'>
                            *
                          </Typography>
                        </InputAdornment>,
                      },
                    }} */
                  />
                </Grid>

                <Grid component={FormControl} size={{ md: 6, }}>
                  <FormLabel htmlFor="email" error={!!errors.email} >E-mail</FormLabel>
                  <TextField
                    id="email"
                    type="email"
                    name="email"
                    value={email}
                    onChange={this.handleChange}
                    placeholder="Work email"
                    autoComplete="email"
                    disabled={this.state.invited == '1'}
                    fullWidth
                    variant="outlined"
                    size="large"
                    color={errors.email ? 'error' : 'primary'}
                    slotProps={{
                      input: {
                        endAdornment: <InputAdornment position="end"  >
                          <Typography color='error'>
                            *
                          </Typography>
                        </InputAdornment>,
                      },
                    }}
                  />
                </Grid>


                <Grid component={FormControl} size={{ md: 6, }}>
                  <FormLabel htmlFor="phoneNumber" error={!!errors.phoneNumber} >Phone Number</FormLabel>
                  <TextField
                    id="phone"
                    type="tel"
                    name="phoneNumber"
                    value={phoneNumber}
                    onChange={this.handleChange}
                    placeholder="Phone"
                    autoComplete="phoneNumber"
                    fullWidth
                    variant="outlined"
                    size="large"
                    color={errors.phoneNumber ? 'error' : 'primary'}
                    slotProps={{
                      input: {
                        endAdornment: <InputAdornment position="end"  >
                          <Typography color='error'>
                            *
                          </Typography>
                        </InputAdornment>,
                      },
                    }}
                  />
                </Grid>

                <Grid component={FormControl} size={{ md: 12, }}>
                  <FormLabel htmlFor="company" error={!!errors.company} >Company</FormLabel>
                  <TextField
                    id="company"
                    type="text"
                    name="company"
                    value={company}
                    onChange={this.handleChange}
                    placeholder="Company"
                    autoComplete="company"
                    fullWidth
                    variant="outlined"
                    size="large"
                    color={errors.company ? 'error' : 'primary'}
                    slotProps={{
                      input: {
                        endAdornment: <InputAdornment position="end"  >
                          <Typography color='error'>
                            *
                          </Typography>
                        </InputAdornment>,
                      },
                    }}
                  />
                </Grid>

              </Grid>
            </Stack>
            <Collapse in={!!this.state.alert}>
              <Alert
                severity={this.state.alert?.type} // dynamic severity (error, success, etc.)
                action={
                  <IconButton
                    aria-label="close"
                    size="small"
                    onClick={() => this.setState({ alert: null })} // Close the alert
                  >
                    <CancelIcon />
                  </IconButton>
                }
              >
                {this.state.alert?.message.split('\n').map((msg, index) => (
                  <Box key={index}>{msg}</Box> // Render each message on a new line
                ))}
              </Alert>
            </Collapse>
            {/* ---Button--- */}
            <Stack spacing={2.5} alignItems={'center'}>
              <Box>
                <Button size='large' color='primary' type="submit" variant='contained'>CREATE YOUR ACCOUNT</Button>
              </Box>
            </Stack>
          </Card>
        </Box>
        <NotificationContainer />
      </AuthLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  isRegister: state.Register.isRegister,
  error: state.Register.registerErr,
  errMessage: state.Register.errMessage,
  registerMsg: state.Register.registerMsg,
  loading: state.Loader.loading,
});

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      userRegister: Actions.userRegisterRequest,
      startLoading: Actions.startLoading,
    },
    dispatch,
  );
export default connect(mapStateToProps, mapActionsToProps)(RegisterPage);
