import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Stack } from '@mui/material';
import { Actions } from 'ReduxStore/Actions';
import Loader from '../../Components/Loader/loader';
import { createNotification, redirectTo } from '../../helpers';
import { NotificationContainer } from 'react-notifications';
import '../../sass/main.scss';
import DashboardLayout from '../../Layouts/DashboardLayout';
import { Button } from '@mui/material';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import Pagination from 'react-js-pagination';
import ImportModal from './importModal';
import ContactModal from './contactModal';
import UpdateGroupModal from './updateGroupModal';
import DeleteGroupModal from './deleteGroupModal';
import AssignGroupModal from './assignGroup';
import UpdateModal from './updateModal';
import GroupModal from '../Contact/groupModal';
import LogsIcon from '../../assets/images/icons/agenda.svg';
import InvalidIcon from '../../assets/images/icons/invalid.svg';
import moment from 'moment';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import {
  DeleteAllContactAPI,
  ListAllContactAPI,
  ImportProgressAPI,
} from 'ReduxStore/API';
import ImportStatusModal from '../Contact/importStatusModal';
import { generateCsv, mkConfig } from 'export-to-csv';
import { ThemeProvider } from '@mui/material';

import { theme } from '../../theme/theme';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import ExportIcon from '../../assets/images/icons/export.svg';
import ImportIcon from '../../assets/images/icons/import.svg';
import BackButtonOutlined from '../../assets/images/icons/backButtonOutlined.svg';
import Typography from '@mui/material/Typography';
import MoreIcon from '../../assets/images/icons/more.svg';
import SearchIcon from '../../assets/images/icons/search.svg';
import InputAdornment from '@mui/material/InputAdornment';
import Checkbox from '@mui/material/Checkbox';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { generateUrl, getSystemLevelPermissions } from '../../helpers/common';
import SuccessAlertPopUp from 'Common/SuccessAlertPopUp';
import CustomSelectDropdown from 'Components/CustomDropdowns/CustomSelectDropdown';
import userImageIcon from '../../assets/images/icons/userImageIcon.svg';
import ProgresBar from '../../Common/ProgressBar';
import { UserData } from '../../helpers/common';
import Exporting from '../../assets/images/export.gif';
import withCustomRouter from '../../Common/withCustomRouter';
import { permissionsConstants } from '../../constants/permissionsConstant';
import { authPermissionValidation } from '../../helpers/permissionsHelper';
const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;
const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;


class ContactGroups extends Component { 
  constructor(props) {
    super(props);
    this.state = {
      isFilterVisible: false,
      isFilterImports: false,
      title: 'Audience Contacts',
      pageTitle: 'Audience Contacts',
      open: false,
      errors: {},
      loading: false,
      page_no: 1,
      totalResults: 0,
      groupOpen: false,
      importOpen: false,
      assignGroupOpen: false,
      updateOpen: false,
      EditContact: false,
      updateGroupOpen: false,
      deleteGroupOpen: false,
      singleData: {},
      singleDataContact: {},
      contacts: [],
      allChecked: false,
      list: [],
      showDelete: false,
      searchText: '',
      group_id: '',
      listAll: [],
      imported: 0,
      rejected: 0,
      importOpenModal: false,
      ids: [],
      importContactProgressId: 0,
      contactsData: [],
      openDeleteContactModel: false,
      contactIdForDelete: false,
      editData: {},
      openDeleteAllContactModel: false,
      startCount: 1,
      endCount: 0,
      itemCount: 10,
      limit: 10,
      order: 'desc',
      sort_by: 'updatedAt',
      openValidation: false,
      openSuccess: false,
      showProgress: false,
      progressPercentage: 0,
      importAttendeesId: 0,
      import: false,
      downloadExport: false,
      allContactsList: [],
      customFields: []
    };
  }

  async componentDidMount() {
    this.groupRequestForDropDown();
    if (this.props.params.id && this.props.params.id !== '') {
      await this.setState({ group_id: this.props.params.id }, () => this.listContactRequest());
    }
    this.listAudienceCustomFields();

  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.itemCount !== this.state.itemCount) {
      if (this.state.itemCount < this.state.totalResults) {
        this.setState({
          endCount: parseInt(this.state.itemCount),
        });
      } else {
        this.setState({
          endCount: parseInt(this.props.contacts.totalResults),
        });
      }
    }

    if (
      this.props.contacts.importContactValidation === true &&
      prevProps.contacts.importContactValidation === false &&
      this.props.contacts.message !== prevProps.contacts.message
    ) {
      this.setState({
        loading: false,
      });
      this.setState({
        openValidation: true,
      });
    }

    if (
      this.props.contacts.contactSuccess &&
      !prevProps.contacts.contactSuccess
    ) {
      this.setState(
        {
          page_no: 1,
          startCount: 1,
        },
        () => this.listContactRequest(),
      );
    }

    if (
      this.state.isTotalRecords ||
      (this.props.contacts.message === 'Contact list get successfully' &&
        prevProps.contacts.message !== 'Contact list get successfully')
    ) {
      this.setState(
        {
          isTotalRecords: false,
          totalResults: this.props.contacts.totalResults,
          list: this.props.contacts.contacts
        },
        () => {
          if (this.state.itemCount >= this.state.totalResults) {
            this.setState({
              endCount: parseInt(this.state.totalResults),
            });
          } else if (
            this.state.itemCount < this.state.totalResults &&
            this.state.page_no === 1
          ) {
            this.setState({
              endCount: parseInt(this.state.itemCount),
            });
          }
        },
      );
    }

    if (
      this.props.contacts.success === true &&
      this.props.contacts.message !== prevProps.contacts.message
    ) {
      this.setState({
        totalResults: this.props?.contacts?.totalResults,
      });

      if (this.state.itemCount >= this.props.contacts.totalResults) {
        this.setState({
          endCount: this.props.contacts.totalResults,
        });
      }
    }

    if (
      this.props.contacts.deleteContactSuccess &&
      !prevProps.contacts.deleteContactSuccess
    ) {
      this.setState(
        {
          openDeleteContactModel: false,
          openDeleteAllContactModel: false,
          page_no: 1,
          startCount: 1,
        },
        () => this.listContactRequest(),
      );
    }

    if (
      this.props.contacts.success === true &&
      this.props.contacts.message !== prevProps.contacts.message
    ) {
      createNotification('success', this.props.contacts.message);
      let st = prevState.loading ? this.setState({ loading: false }) : null;
      this.setState({ totalResults: this.props.contacts.totalResults });
      this.setState({ showDelete: false, allChecked: false });
    }

    if (
      this.props.contacts.listSuccess === true &&
      this.props.contacts.message !== prevProps.contacts.message
    ) {
      // finding if the selected service plan allows to create more Contacts
      let userDetails = JSON.parse(localStorage.getItem('user_details'));
      let reqData = {
        servicePlanId: userDetails.servicePlanId,
        projectId: this.state.projectId,
      };
      this.props.findIfAllowedFromSuperadminOuterRequest(reqData);
      // finding if the selected service plan allows to create more Contacts ends

      let st = prevState.loading ? this.setState({ loading: false }) : null;
      this.setState({ totalResults: this.props.contacts.totalResults });
    }

    if (
      this.props.contacts.error === true &&
      this.props.contacts.message !== prevProps.contacts.message
    ) {
      createNotification('error', this.props.contacts.message, '');
      let st = prevState.loading ? this.setState({ loading: false }) : null;
    }

    if (
      this.props.contacts.searchError === true &&
      this.props.contacts.message !== prevProps.contacts.message
    ) {
      createNotification('error', this.props.contacts.message, '');
      let st = prevState.loading ? this.setState({ loading: false }) : null;

      this.setState({ list: [] });
    }
    if (
      this.props.contacts.importSuccess === true &&
      this.props.contacts.message !== prevProps.contacts.message
    ) {

      if (this.props.contacts?.importContactId) {
        this.getProgressCountOfAudience(this.props.contacts.importContactId, 'import');
        this.setState({
          importOpen: false,
          showProgress: true,
          import: true,
          loading: false
        });
      } else {
        this.listContactRequest();
      }
    }

    if (
      this.props.contacts.contactSuccess === true &&
      this.props.contacts.message !== prevProps.contacts.message
    ) {
      this.setState(
        {
          totalResults: this.props.contacts.totalResults,
          open: false,
        },
        () => this.listContactRequest(),
      );
    }
    if (
      this.props.contacts.groupSuccess === true &&
      this.props.contacts.message !== prevProps.contacts.message
    ) {
      this.setState({
        totalResults: this.props.contacts.totalResults,
        groupOpen: false,
      });
    }
    if (
      this.props.contacts.updateSuccess === true &&
      this.props.contacts.message !== prevProps.contacts.message
    ) {
      this.setState(
        {
          updateOpen: false,
          assignGroupOpen: false,
          showDelete: false,
          allChecked: false,
        },
        () => this.listContactRequest(),
      );
    }
    if (
      this.props.groups.success &&
      this.props.groups.message !== prevProps.groups.message
    ) {
      this.setState(
        {
          updateGroupOpen: false,
          deleteGroupOpen: false,
          assignGroupOpen: false,
        },
      );
    }
  }

  groupRequestForDropDown = () => {
    try {
      const { sort_by, order } = this.state;
      const requestBody = {
        page_no: 1,
        sort_by,
        order,
        permission: 'View Audience',
      };
      this.props.groupRequest(requestBody);
    } catch (error) {
      createNotification('error', 'Something went wrong in audience listing');
    }
  };

  listContactRequest = (setToStart) => {
    const { page_no, group_id, searchText, itemCount, sort_by, order } =
      this.state;
    this.props.listContactRequest({
      page_no: setToStart ? true : page_no,
      group_id,
      searchText: searchText.trim(),
      limit: itemCount,
      sort_by,
      order,
      permission: 'View Audience',
    });
    this.setState({
      loading: true,
    });
  };

  sorting = (sort_by) => {
    this.setState(
      {
        sort_by,
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        page_no: 1,
        startCount: 1,
      },
      () => {
        this.listContactRequest();
      },
    );
  };

  handleDeleteConfirm = () => {
    try {
      const { contactIdForDelete } = this.state;
      const body = {
        contactIdForDelete,
      };
      this.props.deleteContactRequest({
        _id: contactIdForDelete,
        group_id: this.state.group_id,
        permission: 'Delete Audience',
        lngCode: this.props.users.newLangState
      });
      this.setState({
        loading: true,
        openDeleteContactModel: false
      });
    } catch (error) {
      createNotification('error', 'something went wrong');
    }
  };

  handleClickOpenDeleteContactModal = (_id, group_id) => {
    this.setState({
      openDeleteContactModel: true,
      contactIdForDelete: _id,
    });
  };

  handleCloseOpenDeleteContactModel = () => {
    this.setState({
      openDeleteContactModel: false,
      openDeleteAllContactModel: false,
    });
  };
  handleClickOpenDeleteAllContactModal = () => {
    this.setState({
      openDeleteAllContactModel: true,
    });
  };


  f1 = async () => {
    let data =
      this.props.contacts.contacts &&
      Array.isArray(this.props.contacts.contacts.contactId) &&
      this.props.contacts.contacts.contactId.map((data, index) => {
        return {
          firstName: data.firstName,
          lastName: data.lastName,
          _id: data._id,
          isChecked: false,
          email: data.email,
          company: data.company,
          profilePic: data.profilePic,
          groupName: this.props.contacts.contacts.groupName,
          groupId: this.props.contacts.contacts._id,
          createdAt: data.createdAt,
          updatedAt: data.updatedAt,
        };
      });

    return await data;
  };

  handleChangeCheck = async (e) => {
    let itemName = e.target.name;
    let checked = e.target.checked;
    await this.setState((prevState) => {
      let { allChecked, list } = this.state;
      if (itemName === 'checkAll') {
        allChecked = checked;
        list = list.map((item) => ({ ...item, isChecked: checked }));
      } else {
        list = list.map((item) =>
          item.email === itemName ? { ...item, isChecked: checked } : item,
        );

        allChecked = list.every((item) => item.isChecked);
      }

      return { list, allChecked };
    });

    let data = await this.state.list.map((item) => {
      if (true === item.isChecked) {
        return item._id;
      }
    });
    let ids = await data.filter(function (value, index, arr) {
      return value !== undefined;
    });

    ids.length > 0
      ? this.setState({ showDelete: true })
      : this.setState({ showDelete: false });
  };

  onOpenImportStatusModal = () => {
    this.setState({ importOpenModal: true });
  };

  onCloseImportStatusModal = () => {
    this.setState({ importOpenModal: false });
  };
  onOpenAssignGroup = () => {
    this.setState({ assignGroupOpen: true });
  };

  onCloseAssignGroup = () => {
    this.setState({ assignGroupOpen: false });
  };

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  onCloseEditContactModal = () => {
    this.setState({ EditContact: false });
  };

  onOpenGroupModal = () => {
    this.setState({ groupOpen: true });
  };

  onCloseGroupModal = () => {
    this.setState({ groupOpen: false });
  };
  onOpenGroupModal = () => {
    this.setState({ groupOpen: true });
  };
  onOpenUpdateGroupModal = () => {
    this.setState({ updateGroupOpen: true });
  };

  onCloseUpdateGroupModal = () => {
    this.setState({ updateGroupOpen: false });
  };

  onOpenDeleteGroupModal = () => {
    this.setState({ deleteGroupOpen: true });
  };

  onCloseDeleteGroupModal = () => {
    this.setState({ deleteGroupOpen: false });
  };

  handleChange = (name, option) => {
    this.setState({
      [option.label]: option.value,
    });
    if (name === 'group_id' && option.value !== '') {
      this.setState({
        group_id: option.value,
      }, () => this.listContactRequest());
    }
  };

  handleChange2 = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
    if (event.target.name === 'group_id' && event.target.value !== '') {
      this.listContactRequest();
    }
  };

  handlePageChange = (pageNumber) => {
    try {
      const { itemCount, totalResults } = this.state;
      this.setState(
        (prevState, props) => ({
          startCount: pageNumber !== 1 ? itemCount * (pageNumber - 1) + 1 : 1,
          endCount:
            totalResults > itemCount * pageNumber
              ? itemCount * pageNumber
              : totalResults,
          page_no: pageNumber,
          pageHasChanged: true,
          totalResults: this.props.contacts.totalResults,
        }),
        () => {
          this.listContactRequest();
        },
      );
    } catch (error) {
      createNotification('error', 'something went wrong');
    }
  };

  FilterOpen = () => {
    this.setState((prevState) => ({
      isFilterVisible: !prevState.isFilterVisible,
    }));
  };
  ImportsOpen = () => {
    this.setState((prevState) => ({
      isFilterImports: !prevState.isFilterImports,
    }));
  };

  handleLoading = () => {
    let loading = this.state.loading;
    this.setState({
      loading: !loading,
    });
  };

  openImportModal = () => {
    this.setState({ importOpen: true });
  };

  onCloseImportModal = () => {
    this.setState({ importOpen: false });
  };

  onCloseUpdateModal = () => {
    this.setState({ updateOpen: false });
  };
  openUpdateModal = (data) => {
    this.setState({ updateOpen: true, singleData: data });
  };

  openEditContactModal = (data) => {
    this.setState({
      EditContact: true,
      editData: data,
    });
  };

  deleteContact = (id) => {
    let requestbody =
      this.state.group_id !== ''
        ? {
          page_no: this.state.page_no,
          group_id: this.state.group_id,
          id: id,
          permission: 'Delete Audience',
        }
        : { page_no: this.state.page_no, id: id, permission: 'Delete Audience' };
    this.props.deleteContactRequest(requestbody);
    this.setState({ allChecked: false });
    this.handleLoading();
  };

  deleteAll = () => {
    this.handleLoading();
    DeleteAllContactAPI()
      .then((result) => {
        this.handleLoading();
        if (result.data.status === 200) {
          this.setState({
            contacts: [],
            allContactsList: [],
            totalResults: 0,
            list: [],
            listAll: [],
          });
          createNotification('success', result.data.message);
          this.listContactRequest();
        } else {
          createNotification('error', result.data.message);
        }
      })
      .catch(console.log);
  };

  deleteCheck = async () => {
    let data = await this.state.list.map((item) => {
      if (true === item.isChecked) {
        return item._id;
      }
    });
    let ids = await data.filter(function (value, index, arr) {
      return value !== undefined;
    });

    let requestbody =
      this.state.group_id !== ''
        ? {
          ids: ids,
          page_no: this.state.page_no,
          group_id: this.state.group_id,
          permission: 'Delete Audience',
          lngCode: this.props.users.newLangState
        }
        : {
          ids: ids,
          page_no: this.state.page_no,
          permission: 'Delete Audience',
          lngCode: this.props.users.newLangState
        };
    this.props.deleteManyContactRequest(requestbody);
    this.handleLoading();
  };

  assignGroup = async () => {
    let data = await this.state.list.map((item) => {
      if (true === item.isChecked) {
        return item.email;
      }
    });
    let ids = await data.filter(function (value, index, arr) {
      return value !== undefined;
    });

    this.setState({ ids: ids, assignGroupOpen: true });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ list: [] });
    this.setState(
      {
        page_no: 1,
        startCount: 1,
      },
      () => this.listContactRequest(),
    );
  };

  exportCsv = (id) => {
    this.setState({
      downloadExport: this.props.contacts.totalResults <= 1000 ? false : true,
      loading: this.props.contacts.totalResults <= 1000 ? true : false,
    });
    let requestbody =
      this.state.group_id !== ''
        ? {
          page_no: this.state.page_no,
          group_id: this.state.group_id,
          permission: 'View Audience',
        }
        : { page_no: this.state.page_no, permission: 'View Audience' };
    ListAllContactAPI(requestbody)
      .then((result) => {
        if (result.data.status === 200) {
          if (
            result.data.allContactsList &&
            Array.isArray(result.data.allContactsList.contactId)
          ) {
            this.setState({
              customFields: result.data.customFields,
              allContactsList: result.data.allContactsList
            });

            let headerList = [
              "email",
            ];
            if (this.state.customFields && this.state.customFields[0]?.customFieldsList) {
              this.state.customFields[0].customFieldsList.map((item) => {
                headerList.push(item.label);
              });
            }
            const rowList = [];
            if (this.state.allContactsList.contactId.length) {

              for (let i = 0; i < this.state.allContactsList.contactId.length; i++) {
                let rowData = {
                  Email: this.state.allContactsList.contactId[i].email
                };

                if (this.state.customFields && this.state.customFields[0]?.customFieldsList) {
                  this.state.customFields[0]?.customFieldsList?.map((item) => {
                    rowData[item.label] = this.state.allContactsList.contactId[i].customFieldData[item.customFieldId] ? this.state.allContactsList.contactId[i].customFieldData[item.customFieldId] : '';
                  });
                }
                rowList.push(rowData);
              }
            }

            const options = {
              fieldSeparator: ',',
              quoteStrings: '"',
              decimalSeparator: '.',
              showLabels: true,
              showTitle: false,
              useTextFile: false,
              useBom: false,
              useKeysAsHeaders: true,
              filename: 'contacts',
            };

            const csvExporter = new generateCsv(options);
            if (rowList.length > 0) {
              csvExporter.generateCsv(rowList);
            }
          }
        } else {
          createNotification('error', result.data.message);
        }
        this.setState({ loading: false, downloadExport: false });
      })
      .catch(console.log);
  };

  sampleCsv = () => {
    var data = [
      {
        firstName: 'value',
        lastName: 'value',
        email: 'value',
        company: 'value',
      },
    ];

    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      title: 'Sample Csv',
      useTextFile: false,
      useBom: false,
      useKeysAsHeaders: true,
      filename: 'sample',
    };

    const csvExporter = new generateCsv(options);
    if (data.length > 0) {
      csvExporter.generateCsv(data);
    }
  };

  selectItemCount = (e) => {
    this.setState(
      {
        itemCount: e.target.value,
        limit: e.target.value,
        page_no: 1,
        startCount: 1,
        searchText: '',
      },
      () => this.listContactRequest(),
    );
  };

  goBack = () => {
    this.props.navigate(generateUrl('/audience'));
  };

  handleClickCloseSuccess = () => {
    this.setState({ openSuccess: false });
  };

  handleClickCloseValidation = () => {
    this.setState({
      openValidation: false,
      importOpen: false,
    });
  };

  handleClickReteyValidation = () => {
    this.setState({
      openValidation: false,
      loading: false,
    });
  };

  importLogs = () => {
    this.props.navigate({
      pathname: `/xp/importlogs`,
    });
  };


  getProgressCountOfAudience = (importContactProgressId, importedType) => {
    const users = UserData();
    const eventSource = new EventSource(
      `${BACKEND_API_URL}/groups/import-progress/${importContactProgressId}?token=bearer ${users.token}`,
    );
    eventSource.onmessage = event => {
      //we will get error if any error occured in the import|copy progress
      if (event.data === 'error') {
        //closing eventSource connection
        eventSource.close();
        createNotification('error', 'Error occured in the progress!');
        //hiding progress bar popup
        this.setState({
          showProgress: false,
          import: false,
          progressPercentage: 0
        });
      } else {
        const progress = parseInt(event.data);
        this.setState({
          progressPercentage: progress
        });

        //if the progress completed 
        if (progress === 100) {
          //close progress popup & open successful messsage after some delay
          this.listContactRequest();
          setTimeout(() => {
            this.setState({
              showProgress: false,
              import: false,
              openSuccess: true,
              progressPercentage: 0
            });
          }, 500);
          eventSource.close();
        }
      }
    };
  };

  hideProgress = () => {
    this.setState({
      showProgress: false
    });
  };

  listAudienceCustomFields = () => {
    try {
      this.props.listAudienceCustomFields();
    } catch (err) {
      createNotification('error', 'Something went wrong');
    }
  };


  render() {
    const {
      customFields,
      progressPercentage,
      showProgress,
      title,
      pageTitle,
      open,
      loading,
      page_no,
      groupOpen,
      importOpen,
      updateOpen,
      EditContact,
      singleData,
      showDelete,
      updateGroupOpen,
      deleteGroupOpen,
      group_id,
      importOpenModal,
      imported,
      rejected,
      assignGroupOpen,
      ids,
      list,
      showImportProgress,
      totalImported,
      totalRecord,
      endCount,
      startCount,
      itemCount,
    } = this.state;

    let OptionsValue =
      this.props.groups.groups &&
      this.props.groups.groups?.map((data) => {
        return {
          label: data.groupName,
          value: data._id,
        };
      });

    let checkedAudienceCount = 0;

    if (Array.isArray(list)) {
      checkedAudienceCount = list.filter(
        (item) => item.isChecked === true,
      ).length;
    }
    const styles = {
      avatar: {
        backgroundColor: '#bdbdbd',
        width: '38px',
        height: '38px',
      },
      avatarWhite: {
        backgroundColor: '#FFF',
        width: '38px',
        height: '38px',
      },
    };

    return (
      <DashboardLayout
        title={title}
        pageTitle={pageTitle}
        showImportProgress={showImportProgress}
        totalImported={totalImported}
        totalRecord={totalRecord}
      >
        <ThemeProvider theme={theme}>
          <div className="dashboardMiddleArea">
            <Grid container spacing={2} mb={2} className="top-filter-area">
              <Grid >
                <Grid container alignItems={'center'} spacing={2}>
                  <Grid >
                    <Button
                      size="small"
                      style={{ padding: '0', minWidth: '0' }}
                      variant="link"
                      onClick={this.goBack}
                      color="primary"
                    >
                      <img src={BackButtonOutlined} alt="back" />
                    </Button>
                  </Grid>
                  <Grid >
                    <Typography variant="body2" className="small-subtitle">
                      {this.props.users.languageStateForRedux?.audienceTab?.allAudience}{' '}
                      <KeyboardArrowRightIcon style={{ fontSize: '18px' }} />{' '}
                      {this.props.users.languageStateForRedux?.eventList?.contacts}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid >
                <Grid container spacing={1}>
                  <Grid >
                    <Button
                      onClick={() => this.importLogs()}
                      variant="text"
                      color="primary"
                      startIcon={<img alt="" src={LogsIcon} />}
                    >
                      {this.props.users.languageStateForRedux?.audienceTab?.importErrLogs}
                    </Button>
                  </Grid>
                  {authPermissionValidation(permissionsConstants.createAudience) && (
                    <>
                      <Grid >
                        <Button
                          onClick={this.openImportModal}
                          size="small"
                          variant="outlined"
                          color="primary"
                          endIcon={<img alt="" src={ImportIcon} />}
                        >
                          {this.props.users.languageStateForRedux?.common?.import}
                        </Button>
                      </Grid>
                    </>
                  )}

                  <Grid >
                    <Button
                      onClick={this.exportCsv}
                      size="small"
                      variant="outlined"
                      color="primary"
                      disabled={
                        Array.isArray(list) && list?.length > 0 ? false : true
                      }
                      endIcon={<img alt="" src={ExportIcon} />}
                    >
                      {this.props.users.languageStateForRedux?.common?.export}
                    </Button>
                  </Grid>
                  {authPermissionValidation(permissionsConstants.createAudience) && (
                    <Grid >
                      <Button
                        onClick={this.onOpenModal}
                        size="small"
                        variant="contained"
                        color="primary"
                      >
                        {this.props.users.languageStateForRedux?.audienceTab?.addContact}
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Box component={'div'} p={0} mb={3} className="header-common">
              <Grid >
                <h1 className="heading"> {this.props.users.languageStateForRedux?.audienceTab?.audienceContacts}</h1>
              </Grid>
            </Box>
            <Grid container spacing={2} mb={3} className="top-filter-area">
              <Grid >
                <Grid container spacing={1}>
                  <Grid >
                    <CustomSelectDropdown
                      options={OptionsValue}
                      name="group_id"
                      buttonVariant={'contained'} // outlined, contained
                      buttonSize={'small'} // small, medium, large
                      placeholder={this.props.users.languageStateForRedux?.common?.select} // any name
                      type={'filter'} // filter, selectBox
                      value={group_id}
                      onChange={this.handleChange}
                    />
                  </Grid>
                  <Grid >
                    <Box
                      component={Button}
                      variant="contained"
                      sx={{
                        backgroundColor: theme.palette.background.light,
                        color: theme.palette.common.black,
                        textTransform: 'capitalize !important',
                        fontWeight: '400',

                        '&:hover, &.active': {
                          backgroundColor: theme.palette.primary.dark,
                          color: theme.palette.primary.contrastText,
                        },
                      }}
                      size="small"
                      disableElevation
                      aria-haspopup="true"
                    >
                      {this.props.users.languageStateForRedux?.eventList?.contacts} ({list ? this.props.contacts.totalResults : 0})
                    </Box>
                  </Grid>
                  {showDelete ? (
                    <>
                      <Grid >
                        <Button
                          size="small"
                          onClick={this.assignGroup}
                          variant="contained"
                          color="primary"
                        >
                          {this.props.users.languageStateForRedux?.audienceTab?.assignAudience}
                        </Button>
                      </Grid>
                      <Grid >
                        <Button
                          size="small"
                          onClick={() =>
                            this.handleClickOpenDeleteAllContactModal()
                          }
                          variant="contained"
                          color="secondary"
                        >
                          {this.props.users.languageStateForRedux?.common?.deleteChecked}
                        </Button>
                      </Grid>
                    </>
                  ) : null}

                  <Grid >
                    <Box
                      component={Button}
                      variant="contained"
                      sx={{
                        backgroundColor: theme.palette.background.light,
                        color: theme.palette.common.black,
                        textTransform: 'capitalize !important',
                        fontWeight: '400',

                        '&:hover, &.active': {
                          backgroundColor: theme.palette.primary.dark,
                          color: theme.palette.primary.contrastText,
                        },
                      }}
                      className="active"
                      size="small"
                      disableElevation
                      aria-haspopup="true"
                    >
                      {checkedAudienceCount} {this.props.users.languageStateForRedux?.dropdowns?.attendees?.selected}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid >
                <Grid container spacing={1.25} className="team-dash-right">
                  <Grid className="">
                    <form onSubmit={this.handleSubmit}>
                      <TextField
                        name="searchText"
                        variant='standard'
                        placeholder={this.props.users.languageStateForRedux?.common?.search}
                        className="search"
                        size="small"
                        onChange={this.handleChange2}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <img alt="" src={SearchIcon} />
                            </InputAdornment>
                          ),
                        }}
                      />
                      <button
                        type="submit"
                        style={{ display: 'none' }}
                      ></button>
                    </form>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <TableContainer
              className="list-table"
              style={{ maxHeight: 'calc(100vh - 380px)' }}
            >
              <Table className="table">
                <>
                  <TableHead>
                    <TableRow>
                      <TableCell className="checkbox">
                        <Checkbox
                          color="primary"
                          p={0}
                          name="checkAll"
                          checked={this.state.allChecked}
                          onChange={this.handleChangeCheck}
                        />
                      </TableCell>
                      <TableCell
                        style={{ minWidth: '62px', maxWidth: '62px' }}
                      >
                      </TableCell>
                      <TableCell
                        className={`email ${this.state.sort_by === 'email'
                            ? this.state.order === 'asc'
                              ? 'sort-asc'
                              : 'sort-des'
                            : null
                          }`}
                        onClick={() => this.sorting('email')}
                      >
                        {this.props.users.languageStateForRedux?.column_names?.teams?.email}
                      </TableCell>
                      {this.props.groups.customFields &&
                        this.props.groups.customFields.length ?
                        this.props.groups.customFields.map((data, i) => {
                          return (
                            <TableCell
                              key={i}
                              className={
                                'long-text ' +
                                (this.state.sort_by ===
                                  `customFieldData.${data.fieldData.fieldName}`
                                  ? this.state.sort_by === 'asc'
                                    ? 'sort-asc'
                                    : 'sort-des'
                                  : '')
                              }
                              onClick={() =>
                                this.sorting(
                                  `customFieldData.${data.fieldData.fieldName}`,
                                )
                              }
                            >
                              {data.fieldData.fieldLabel}
                            </TableCell>
                          );
                        }) : null
                      }
                      <TableCell
                        className={`date-time ${this.state.sort_by === 'updatedAt'
                            ? this.state.order === 'asc'
                              ? 'sort-asc'
                              : 'sort-des'
                            : null
                          }`}
                        onClick={() => this.sorting('updatedAt')}
                      >
                        {this.props.users.languageStateForRedux?.column_names?.attendees?.lastUpdated}
                      </TableCell>
                      {authPermissionValidation(permissionsConstants.editAudience) ||
                        authPermissionValidation(permissionsConstants.deleteAudience) ? (
                        <TableCell className="options"></TableCell>
                      ) : null}
                    </TableRow>
                  </TableHead>
                  {list && Array.isArray(list) && list.length > 0 ? (
                    <TableBody>
                      {list &&
                        Array.isArray(list) &&
                        list.map((data, i) => {
                          return (
                            <TableRow>
                              <TableCell className="checkbox">
                                <Checkbox
                                  color="primary"
                                  key={data.id}
                                  name={data.email}
                                  value={data.email}
                                  checked={
                                    data.isChecked === true ? true : false
                                  }
                                  onChange={this.handleChangeCheck}
                                />
                              </TableCell>
                              <TableCell className=""
                                style={{ minWidth: '62px', maxWidth: "62px" }}
                              >
                                <Grid container spacing={2} alignItems="center">
                                  <Grid >
                                    <Avatar
                                      style={
                                        data.profilePic
                                          ? styles.avatarWhite
                                          : styles.avatar
                                      }
                                    >
                                      {data.profilePic &&
                                        data.profilePic !== '' ? (

                                        <img
                                          // this is the condition for to identify which image is comming from attendee and which image is comming from contact
                                          // profilePic means contact and profileImage means attendee image
                                          src={data.profilePic.split("-")[0] === "profilePic" ? (`${process.env.REACT_APP_IMAGE_URL}/contactsImages/${data.profilePic}`) : (IMAGE_URL +
                                            '/profilePictures/' +
                                            data.profilePic)}
                                          alt=""
                                          width="38"
                                        />
                                      )
                                        : (
                                          <img
                                            src={userImageIcon}
                                            alt=""
                                            width="25"
                                          />
                                        )}
                                    </Avatar>
                                  </Grid>
                                </Grid>
                              </TableCell>
                              <TableCell className="email">
                                {data.email}
                              </TableCell>
                              {this.props.groups.customFields &&
                                this.props.groups.customFields.length ?
                                Array.isArray(
                                  this.props?.groups?.customFields,
                                ) &&
                                this.props?.groups?.customFields?.map(
                                  (field, j) => {
                                    return (
                                      <>
                                        {field.fieldData.fieldType ===
                                          'checkbox' && (
                                            <TableCell className="long-text">
                                              <Stack
                                                direction="row"
                                                alignItems={'center'}
                                                spacing={1}
                                              >
                                                <span className='long-text' style={{ maxWidth: "80%", }}>
                                                  {' '}
                                                  {data.customFieldData[
                                                    field._id
                                                  ]
                                                    ? data.customFieldData[
                                                    field._id
                                                    ][0]
                                                    : '--'}
                                                </span>
                                                {data.customFieldData[
                                                  field._id
                                                ] &&
                                                  data.customFieldData[
                                                    field._id
                                                  ]?.length > 1 && (
                                                    <div className="detail-popup">
                                                      <span className="count">
                                                        +
                                                        {data.customFieldData[
                                                          field._id
                                                        ]?.length - 1}
                                                      </span>
                                                    </div>
                                                  )}
                                              </Stack>
                                            </TableCell>
                                          )}
                                        {field.fieldData.fieldType !==
                                          'checkbox' && (
                                            <TableCell className="long-text" key={j}>
                                              {data.customFieldData[field._id]
                                                ? data.customFieldData[
                                                field._id
                                                ]
                                                : '--'}
                                            </TableCell>
                                          )}
                                      </>
                                    );
                                  },
                                ) : null
                              }
                              <TableCell className="date-time">
                                {data.updatedAt
                                  ? moment(data.updatedAt).format(
                                    'MMM, DD YYYY, h:mm A',
                                  )
                                  : '--'}
                              </TableCell>
                              {authPermissionValidation(permissionsConstants.editAudience) ||
                               authPermissionValidation(permissionsConstants.deleteAudience) ? (
                                <TableCell className="options">
                                  {' '}
                                  <div className="options-button">
                                    <img alt="" src={MoreIcon} />{' '}
                                  </div>
                                  <div className="options-list">
                                    {authPermissionValidation(permissionsConstants.editAudience) && (
                                      <div
                                        onClick={() =>
                                          this.openUpdateModal(data)
                                        }
                                      >
                                        {this.props.users.languageStateForRedux?.common?.edit}
                                      </div>
                                    )}
                                    {authPermissionValidation(permissionsConstants.editAudience) &
                                      authPermissionValidation(permissionsConstants.deleteAudience) ? (
                                      <hr />
                                    ) : null}
                                    {authPermissionValidation(permissionsConstants.deleteAudience) && (
                                      <div
                                        onClick={() =>
                                          this.handleClickOpenDeleteContactModal(
                                            data._id,
                                            group_id,
                                          )
                                        }
                                        style={{
                                          color: theme.palette.secondary.main,
                                        }}
                                      >
                                        {this.props.users.languageStateForRedux?.common?.delete}
                                      </div>
                                    )}
                                  </div>
                                </TableCell>
                              ) : null}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  ) : (
                    <TableBody>
                      <TableRow>
                        <TableCell style={{ textAlign: 'center' }}>
                          {this.props.users.languageStateForRedux?.common?.noRecords}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </>
              </Table>
            </TableContainer>
            {Array.isArray(list) && list?.length > 0 && (
              <div className="tablePagination">
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  className="tablePagination"
                >
                  <Grid >
                    <Box component="span" className="small-subtitle">
                      {this.props.users.languageStateForRedux?.pagination?.showing} {startCount} {this.props.users.languageStateForRedux?.pagination?.to} {endCount} {this.props.users.languageStateForRedux?.pagination?.of}{' '}
                      {this.state.totalResults}
                    </Box>
                  </Grid>
                  <Grid >
                    <Grid container alignItems="center">
                      <Grid >
                        <span className="small-subtitle">{this.props.users.languageStateForRedux?.pagination?.shows}</span>
                        <select
                          value={itemCount}
                          onChange={this.selectItemCount}
                        >
                          <option value={10}>10</option>
                          <option value={20}>20</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>
                        <span className="small-subtitle">{this.props.users.languageStateForRedux?.pagination?.entries}</span>
                      </Grid>
                      <Grid >
                        <Pagination
                          activePage={page_no}
                          itemsCountPerPage={this.state.itemCount}
                          totalItemsCount={this.props.contacts.totalResults}
                          pageRangeDisplayed={5}
                          onChange={this.handlePageChange}
                          prevPageText={
                            <NavigateBeforeIcon style={{ fontSize: 18 }} />
                          }
                          nextPageText={
                            <NavigateNextIcon style={{ fontSize: 18 }} />
                          }
                          itemClassFirst="first d-none"
                          itemClassLast="last d-none"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            )}
          </div>

          <Modal
            open={this.state.openDeleteContactModel}
            onClose={this.handleCloseOpenDeleteContactModel}
            classNames={{
              overlay: '',
              modal: 'Invite-User-Modal',
            }}
            center
          >
            <Box component={Grid} container className="" px={1.5}>
              <Box component={Grid} mt={-1} size={{ xs: 12, }} pb={3}>
                <Box component="p" mb={1} className="subtitle">
                  {' '}
                  {this.props.users.languageStateForRedux?.common?.confirmDelete}
                </Box>
                <p className="small-subtitle">
                  {this.props.users.languageStateForRedux?.common?.deleteMsg}
                </p>
              </Box>
              <Grid size={{ xs: 12, }}>
                <Grid container justifyContent="end">
                  <Stack spacing={1} direction="row" fullWidth>
                    {
                      <>
                        <Button
                          variant="contained"
                          color="secondary"
                          disableElevationon
                          onClick={() => this.handleDeleteConfirm()}
                        >
                          {this.props.users.languageStateForRedux?.common?.delete}
                        </Button>
                        <Button
                          variant="outlined"
                          disableElevation
                          onClick={() =>
                            this.handleCloseOpenDeleteContactModel()
                          }
                        >
                          {this.props.users.languageStateForRedux?.common?.cancel}
                        </Button>
                      </>
                    }
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </Modal>

          <Modal
            open={this.state.openDeleteAllContactModel}
            onClose={this.handleCloseOpenDeleteContactModel}
            classNames={{
              overlay: '',
              modal: 'Invite-User-Modal',
            }}
            center
          >
            <Box component={Grid} container className="" px={1.5}>
              <Box component={Grid} mt={-1} size={{ xs: 12, }} pb={3}>
                <Box component="p" mb={1} className="subtitle">
                  {' '}
                  {this.props.users.languageStateForRedux?.common?.confirmDelete}
                </Box>
                <p className="small-subtitle">
                  {this.props.users.languageStateForRedux?.common?.deleteMsg}
                </p>
              </Box>
              <Grid size={{ xs: 12, }}>
                <Grid container justifyContent="end">
                  <Stack spacing={1} direction="row" fullWidth>
                    {
                      <>
                        <Button
                          variant="contained"
                          color="secondary"
                          disableElevationon
                          onClick={() => this.deleteCheck()}
                        >
                          {this.props.users.languageStateForRedux?.common?.delete}
                        </Button>
                        <Button
                          variant="outlined"
                          disableElevation
                          onClick={() =>
                            this.handleCloseOpenDeleteContactModel()
                          }
                        >
                          {this.props.users.languageStateForRedux?.common?.cancel}
                        </Button>
                      </>
                    }
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </Modal>

          <Modal
            open={open}
            onClose={this.onCloseModal}
            classNames={{
              overlay: '',
              modal: 'Invite-User-Modal',
            }}
            center
          >
            <ContactModal
              isCreate={true}
              page_no={page_no}
              groups={this.props.groups.groups}
              group_id={this.state.group_id}
              listContactRequest={this.listContactRequest}
            />
          </Modal>

          <Modal
            classNames={{
              overlay: '',
              modal: 'Invite-User-Modal',
            }}
            open={updateOpen}
            onClose={this.onCloseUpdateModal}
            center
          >
            <UpdateModal
              page_no={page_no}
              singleData={singleData}
              groups={this.props.groups.groups}
              customFields={this.props?.groups?.customFields}
            />
          </Modal>

          <Modal
            open={EditContact}
            onClose={this.onCloseEditContactModal}
            classNames={{
              overlay: '',
              modal: 'Invite-User-Modal',
            }}
            center
            onCloseEditContactModal={this.onCloseEditContactModal}
          >
            <ContactModal
              isCreate={false}
              page_no={page_no}
              groups={this.props.groups.groups}
              group_id={group_id}
              editData={this.state.editData}
            />
          </Modal>

          <Modal
            classNames={{
              overlay: '',
              modal: 'Invite-User-Modal',
            }}
            open={importOpen}
            onClose={this.onCloseImportModal}
            center
          >
            <ImportModal
              page_no={page_no}
              groups={this.props.groups.groups}
              group_id={group_id}
              customFields={this.props?.groups?.customFields}
              onCloseImportModal={this.onCloseImportModal}
            />
          </Modal>

          <Modal
            open={updateGroupOpen}
            classNames={{
              overlay: '',
              modal: 'Invite-User-Modal',
            }}
            onClose={this.onCloseUpdateGroupModal}
            center
          >
            <UpdateGroupModal page_no={page_no} group_id={group_id} />
          </Modal>

          <Modal
            open={deleteGroupOpen}
            onClose={this.onCloseDeleteGroupModal}
            center
          >
            <DeleteGroupModal page_no={page_no} group_id={group_id} />
          </Modal>
          <Modal
            open={importOpenModal}
            onClose={this.onCloseImportStatusModal}
            center
          >
            <ImportStatusModal imported={imported} rejected={rejected} />
          </Modal>

          <Modal
            open={assignGroupOpen}
            classNames={{
              overlay: '',
              modal: 'Invite-User-Modal',
            }}
            onClose={this.onCloseAssignGroup}
            center
          >
            <AssignGroupModal
              page_no={page_no}
              groups={this.props.groups.groups}
              ids={ids}
              currentGroup={this.state.group_id}
            />
          </Modal>

          <Modal open={groupOpen} onClose={this.onCloseGroupModal} center>
            <GroupModal page_no={page_no} />
          </Modal>

          <SuccessAlertPopUp
            openModal={this.state.openSuccess}
            closeModalFunc={this.handleClickCloseSuccess}
            title={this.props.users.languageStateForRedux?.formfields?.attendees?.importSuccessfull}
            description={(this.props.users.languageStateForRedux?.formfields?.attendees?.importMsgOne) + `  <strong> ${this.props.contacts?.importedCount} </strong> ` + (this.props.users.languageStateForRedux?.audienceTab?.importContactsDesc)}
            buttonColor="primary"
            buttonFunc={this.handleClickCloseSuccess}
            buttonText={this.props.users.languageStateForRedux?.buttons?.common?.done}
          />

          <Modal
            open={this.state.openValidation}
            onClose={this.handleClickCloseValidation}
            classNames={{
              overlay: '',
              modal: 'Invite-User-Modal',
            }}
            center
          >
            <Grid component="div" container className="modal-wrapper">
              <Box
                component={Grid}
                mt={-4}
                size={{ xs: 12, }}
                sx={{ borderBottom: '1px solid rgba(0, 0, 86, 0.23)' }}
                mb={4}
              >
                <Box component="p" mb={1} className="subtitle">
                  {this.props.users.languageStateForRedux?.formfields?.attendees?.validationHeading}
                </Box>
                <p className="small-subtitle">
                  {this.props.users.languageStateForRedux?.formfields?.attendees?.makeChanges}
                </p>
              </Box>
              <Grid size={{ xs: 12, }} pb={4}>
                <Box
                  component="div"
                  sx={{
                    border: '1px solid rgba(0, 0, 86, 0.23)',
                    borderRadius: '4px',
                  }}
                >
                  <Box
                    component={Grid}
                    p={1.25}
                    sx={{ borderBottom: '1px solid rgba(0, 0, 86, 0.23)' }}
                    container
                  >
                    <Grid pl={2}></Grid>
                    <img alt="" src={InvalidIcon} />
                    <Grid >
                      <Box
                        pl={1}
                        component={'strong'}
                        className=""
                        color={theme.palette.common.black}
                      >
                        {this.props.users.languageStateForRedux?.formfields?.attendees?.invalid} ({this.props?.contacts?.rejectedEmails})
                      </Box>
                    </Grid>
                  </Box>
                  <Box
                    className="custom-fields-table"
                    p={1.25}
                    sx={{ maxHeight: '200px' }}
                    component={TableContainer}
                  >
                    <Table aria-label="simple table">
                      <TableBody>
                        <TableRow>
                          <TableCell className="tableBodyCell" scope="row">
                            <strong>{this.props.users.languageStateForRedux?.formfields?.attendees?.duplicate}</strong>{' '}
                          </TableCell>
                          <TableCell className="tableBodyCell" align="right">
                            {this.props?.contacts?.duplicateContactsInCsv}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="tableBodyCell" scope="row">
                            <strong>{this.props.users.languageStateForRedux?.formfields?.attendees?.invalidEmails}</strong>{' '}
                          </TableCell>
                          <TableCell className="tableBodyCell" align="right">
                            {this.props?.contacts?.invalidEmailsRejection}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="tableBodyCell" scope="row">
                            <strong>{this.props.users.languageStateForRedux?.audienceTab?.mandatoryMissing}</strong>{' '}
                          </TableCell>
                          <TableCell className="tableBodyCell" align="right">
                            {this.props?.contacts?.mandetoryMissingInCsv}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="tableBodyCell" scope="row">
                            <strong>{this.props.users.languageStateForRedux?.formfields?.attendees?.already}</strong>{' '}
                          </TableCell>
                          <TableCell className="tableBodyCell" align="right">
                            {this.props?.contacts?.alreadyExistInDb}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Box>
                </Box>
              </Grid>
              <Grid size={{ xs: 12, }}>
                <Grid container justifyContent="end">
                  <Stack spacing={1} direction="row" fullWidth>
                    <Button
                      variant="outlined"
                      color="primary"
                      disableElevation
                      onClick={this.handleClickReteyValidation}
                    >
                      {this.props.users.languageStateForRedux?.common?.retry}
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      onClick={this.handleClickCloseValidation}
                    >
                      {this.props.users.languageStateForRedux?.common?.close}
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Modal>

          <Modal
            open={showProgress}
            onClose={this.hideProgress}
            classNames={{
              overlay: '',
              modal: 'Invite-User-Modal',
            }}
            center
          >
            <ProgresBar progressPercentage={progressPercentage} import={this.state.import} />
          </Modal>

          <Modal
            open={this.state.downloadExport}
            classNames={{
              overlay: '',
              modal: 'Invite-User-Modal modal-default-close-none',

            }}
            center
          >
            <Grid
              component="form"
              container
              alignItems="center"
              justifyContent="center"
            >
              <Grid size={{ xs: 12, }} pb={2.6}>
                <Box textAlign="center" mt={1.6} mb={3}>
                  <img alt="" src={Exporting} width="92" height="92" />
                </Box>
                <Box
                  component="p"
                  textAlign="center"
                  mb={3}
                  className="subsecondary bold"
                >
                  Please wait while we securely <br />
                  export your data!
                </Box>
                <Box component="p" textAlign="center" className="paragraph">
                  Your information is being processed and will be ready for<br />
                  download shortly. Thank you for your patience!
                </Box>
              </Grid>
            </Grid>
          </Modal>

          <NotificationContainer />
          {loading || this.props.users.isLoading ? <Loader /> : null}
        </ThemeProvider>
      </DashboardLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  contacts: state.Contacts,
  groups: state.Groups,
  users: state.Users
});

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      listAudienceCustomFields: Actions.listAudienceCustomFieldsRequest,
      groupRequest: Actions.groupRequest,
      listContactRequest: Actions.listContactRequest,
      deleteContactRequest: Actions.deleteContactRequest,
      deleteManyContactRequest: Actions.deleteManyContactRequest,
      searchContactRequest: Actions.searchContactRequest,
      csvRequest: Actions.csvRequest,
      xslRequest: Actions.xslRequest,
      findIfAllowedFromSuperadminOuterRequest:
        Actions.findIfAllowedFromSuperadminOuterRequest,
    },
    dispatch,
  );
export default connect(mapStateToProps, mapActionsToProps)(withCustomRouter(ContactGroups));
