import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import PrivateUserRoute from './privateUserRoutes';
import EmailsListContainer from 'eijent/internals/Inbox/EmailsListContainer';
import EmailView from 'eijent/internals/Inbox/EmailView';
import ComposeEmail from 'eijent/internals/Inbox/ComposeEmail';

class InboxRoutesWrapper extends React.Component {
  render() {
    return (
      <Routes>
        {/* Team inbox routes */}
        <Route path="team-inbox/all" element={<PrivateUserRoute element={<EmailsListContainer />} />} />

        {/* My inbox list routes */}
        <Route path="my-inbox/all" element={<PrivateUserRoute element={<EmailsListContainer />} />} />
        <Route path="my-inbox/pinned" element={<PrivateUserRoute element={<EmailsListContainer />} />} />
        <Route path="my-inbox/priority" element={<PrivateUserRoute element={<EmailsListContainer />} />} />
        <Route path="my-inbox/new-opportunity" element={<PrivateUserRoute element={<EmailsListContainer />} />} />
        <Route path="my-inbox/at-risk-opportunity" element={<PrivateUserRoute element={<EmailsListContainer />} />} />
        <Route path="my-inbox/ai-creation" element={<PrivateUserRoute element={<EmailsListContainer />} />} />
        <Route path="all-mails/all" element={<PrivateUserRoute element={<EmailsListContainer />} />} />
        <Route path="all-mails/pinned" element={<PrivateUserRoute element={<EmailsListContainer />} />} />
        <Route path="all-mails/priority" element={<PrivateUserRoute element={<EmailsListContainer />} />} />
        <Route path="all-mails/new-opportunity" element={<PrivateUserRoute element={<EmailsListContainer />} />} />
        <Route path="all-mails/at-risk-opportunity" element={<PrivateUserRoute element={<EmailsListContainer />} />} />
        <Route path="all-mails/ai-creation" element={<PrivateUserRoute element={<EmailsListContainer />} />} />

        

        {/* View routes */}
        <Route path="my-inbox/all/view/:threadId" element={<PrivateUserRoute element={<EmailView />} />} />
        <Route path="my-inbox/pinned/view/:threadId" element={<PrivateUserRoute element={<EmailView />} />} />
        <Route path="my-inbox/priority/view/:threadId" element={<PrivateUserRoute element={<EmailView />} />} />
        <Route path="my-inbox/new-opportunity/view/:threadId" element={<PrivateUserRoute element={<EmailView />} />} />
        <Route path="my-inbox/at-risk-opportunity/view/:threadId" element={<PrivateUserRoute element={<EmailView />} />} />
        <Route path="my-inbox/ai-creation/view/:threadId" element={<PrivateUserRoute element={<EmailView />} />} />
        <Route path="sent/view/:threadId" element={<PrivateUserRoute element={<EmailView />} />} />
        <Route path="bookmarks/view/:threadId" element={<PrivateUserRoute element={<EmailView />} />} />
        <Route path="trash/view/:threadId" element={<PrivateUserRoute element={<EmailView />} />} />
        <Route path="all-mails/all/view/:threadId" element={<PrivateUserRoute element={<EmailView />} />} />
        <Route path="all-mails/pinned/view/:threadId" element={<PrivateUserRoute element={<EmailView />} />} />
        <Route path="all-mails/priority/view/:threadId" element={<PrivateUserRoute element={<EmailView />} />} />
        <Route path="all-mails/new-opportunity/view/:threadId" element={<PrivateUserRoute element={<EmailView />} />} />
        <Route path="all-mails/at-risk-opportunity/view/:threadId" element={<PrivateUserRoute element={<EmailView />} />} />
        <Route path="all-mails/ai-creation/view/:threadId" element={<PrivateUserRoute element={<EmailView />} />} />


        {/* Other inbox routes */}
        <Route path="sent" element={<PrivateUserRoute element={<EmailsListContainer />} />} />
        <Route path="bookmarks" element={<PrivateUserRoute element={<EmailsListContainer />} />} />
        <Route path="trash" element={<PrivateUserRoute element={<EmailsListContainer />} />} />
        <Route path="drafts" element={<PrivateUserRoute element={<EmailsListContainer />} />} />
        <Route path="compose" element={<PrivateUserRoute element={<ComposeEmail />} />} />
        <Route path="compose/:draftId" element={<PrivateUserRoute element={<ComposeEmail />} />} />
        <Route path="drafts/compose/:draftId" element={<PrivateUserRoute element={<ComposeEmail />} />} />

        {/* Catch-all route for any invalid inbox paths */}
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Routes>
    );
  }
}

export default InboxRoutesWrapper;
