import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Actions } from 'ReduxStore/Actions';
import { Helmet } from 'react-helmet';
import { createNotification } from '../../helpers';
import '../../sass/main.scss';
import AuthLayout from '../../Layouts/AuthLayout';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import SignInButtonIcon from '../../assets/icons/SignInButtonIcon.svg';
import Card from '../../Layouts/AuthLayout/SignInCard';
import Loader from '../../Components/Loader/loader';
import { Alert, Collapse, IconButton, Stack } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      errors: {},
      loading: false,
    };
  }

  componentDidMount() { }

  componentDidUpdate(prevProps, prevState) {
    const login = this.props.login;
    if (
      login &&
      login.forgotSuccess &&
      this.props.login.forgotMsg !== prevProps.login.forgotMsg
    ) {
      this.handleAlert("success", "Password reset link has been sent to your email.");
      let st = prevState.loading ? this.setState({ loading: false }) : null;
    } else if (login && login.forgotError && this.state.loading) {
      this.handleAlert('error', login.forgotMsg);
      this.handleLoading();
    }
  }

  handleLoading = () => {
    let loading = this.state.loading;
    this.setState({
      loading: !loading,
    });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  isLoginValid = () => {
    const { email, password } = this.state;
    let error = {};
    let formIsValid = true;
    const regexTest = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email === '' || email.trim().length === 0) {
      formIsValid = false;
      error['email'] = '*Please enter email.';
    }
    if (email && regexTest.test(email) === false) {
      formIsValid = false;
      error['email'] = '*Please enter a valid email address.';
    }
    this.setState({ errors: error });
    if (!formIsValid) {
      const errorMessages = Object.values(error).join('\n'); // Combine error messages with a newline
      this.handleAlert('error', errorMessages); // Trigger the alert
    }
    return formIsValid;
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    if (this.isLoginValid()) {
      const { email } = this.state;
      const requestbody = { email };
      await this.props.forgotPassword(requestbody);
       // Show success message after request is successful 
      this.handleLoading();
    }
  };

  handleAlert = (type, message) => {
    this.setState({
      alert: { type, message },
    });
  };


  render() {
    const { email, errors, loading } = this.state;
    return (
      <AuthLayout>
        <Helmet>
          <title>Eijent | Forgot Password</title>
        </Helmet>
        <Box component="form" onSubmit={this.handleSubmit}>
          <Card >
            <Typography
              component="h1"
              variant="h1"
              textAlign={'center'}
              sx={{ width: '100%', }}
            >
              Forgot password

              {loading ? <Loader /> : null}
            </Typography>

            {/* ---Form--- */}
            <Box
              sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 2 }}
            >
              <Typography variant='body2'>
                We will send an email with instructions on how to reset your password.
              </Typography>
              <FormControl>
                <TextField
                  id="email"
                  type="email"
                  name="email"
                  placeholder="Email"
                  fullWidth
                  variant="outlined"
                  size="large"
                  value={email}
                  onChange={this.handleChange}
                />
              </FormControl>
            </Box>

            <Collapse in={!!this.state.alert}>
              <Alert
                severity={this.state.alert?.type} // dynamic severity (error, success, etc.)
                action={
                  <IconButton
                    aria-label="close"
                    size="small"
                    onClick={() => this.setState({ alert: null })} // Close the alert
                  >
                    <CancelIcon />
                  </IconButton>
                }
              >
                {this.state.alert?.message.split('\n').map((msg, index) => (
                  <Box key={index}>{msg}</Box> // Render each message on a new line
                ))}
              </Alert>
            </Collapse>


            {/* ---Button--- */}
            <Stack spacing={2.5} alignItems={'center'}>
              <Box>
                <Button
                  size='large'
                  color='primary'
                  variant='contained'
                  type="submit"
                  endIcon={<img src={SignInButtonIcon} alt="arrow-icon" />}
                  sx={{
                    justifyContent: 'space-between',
                    marginTop: '16px',
                    minWidth: '222px',
                  }}>Send</Button>
              </Box>
            </Stack>
          </Card>
        </Box>
      </AuthLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  login: state.Login,
  // loading: state.Loader.loaderOne,
});

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      userLogin: Actions.loginRequest,
      forgotPassword: Actions.forgotPasswordRequest,
      // startLoading: Actions.startLoaderOne,
    },
    dispatch,
  );
export default connect(mapStateToProps, mapActionsToProps)(ForgotPassword);
