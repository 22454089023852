import React, { Component } from 'react';
import addImg from '../../../assets/images/icons/add.svg';
import HomePageIcon from '../../../assets/images/icons/home-page-indicator.svg';
import LoginPageIcon from '../../../assets/images/icons/login-page-indicator.svg';
import pagethum from '../../../assets/images/tamplate-thum.png';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import { Modal } from 'react-responsive-modal';
import { Stack } from '@mui/material';
import { generateUrl, getEventURL, getHostName, UserData } from '../../../helpers/common';
import axios from 'axios';
import { createNotification } from '../../../helpers';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NotificationContainer } from 'react-notifications';
import urlImg from '../../../assets/images/icons/url.svg';
import Loader from '../../../Components/Loader/loader';
import Tooltip from '@mui/material/Tooltip';
import { accessType } from '../../../constants/constants';
import moment from 'moment';
import { Actions } from 'ReduxStore/Actions';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ConfirmationAlertPopUp from '../../../Common/ConfirmationAlertPopUp';
import KioskIcon from '../../../assets/images/icons/kioskIcon.svg';
import { permissions } from '../../../constants/constants';
import { Buffer } from 'buffer'; // Import Buffer from the buffer package
import { useNavigate, useLocation, useParams, Link } from 'react-router-dom';
import { authPermissionValidation } from 'helpers/permissionsHelper';
import { permissionsConstants } from 'constants/permissionsConstant';



// Define the withRouter HOC
function withRouter(Component) {
  return function ComponentWithRouterProps(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams(); // Get params from the URL 
    return <Component {...props} navigate={navigate} location={location} params={params} />;
  };
}

const URL = process.env.REACT_APP_API_URL;
const pageAccessType = Buffer.from(accessType).toString('base64');
class ManagePage extends Component {
  constructor(props) {
    super(props);
    this.permData = {};
    this.success = true;
    this.state = {
      value: 0,
      showLoader: false,
      registrationTemplates: [],
      template: [],
      templateName: '',
      selectedRegTemplate: '',
      landingPage: false,
      loading: false,
      registrationPage: false,
      existingPaths: [],
      eventId: '',
      templateId: '',
      events: {},
      open: false, 
      openReplicateModal: false,
    };
  }

  UserData = async () => {
    const currentPath = window.location.pathname;
    if (currentPath.includes('member')) {
      return JSON.parse(localStorage.getItem('member_details'));
    } else {
      return JSON.parse(localStorage.getItem('user_details'));
    }
  };

  async getProjectTemplate() {
    const arr = window.location.href.split('/');
    const projectId = arr[arr.length - 1];
    const users = await this.UserData();
    this.setState({
      loading: true
    });
    try {
      const response = await axios.get(
        `${URL}/getProjectTemplate?projectId=${projectId}`,
        {
          headers: {
            token: `bearer ${users.token}`,
          },
        },
      );
      if (response.data && response.data.status == 200) {
        this.setState({
          template: response.data.templates,
          events: response.data.events,
          loading: false
        });
        // finding if the selected service plan allows to create more pages
        let userDetails = JSON.parse(localStorage.getItem('user_details'));
        let reqData = {
          servicePlanId: userDetails.servicePlanId,
          projectId: projectId,
        };
        this.props.findIfAllowedFromSuperadminRequest(reqData);
        // finding if the selected service plan allows to create more pages ends
      } else {
        throw Error(response.data.error);
      }
    } catch (err) {
      createNotification('error', err.message);
      setTimeout(() => {
        window.location.href = `/my-events`;
      }, 1500);
    }
  }

  async componentDidMount() {
    const arr = window.location.href.split('/');
  const eventId = arr[arr.length - 1];
  
  if (eventId) {
    this.setState({ eventId: eventId }, () => { 
      // Now safely use this.state.eventId
      this.getProjectTemplate();
    });
  } else {
    console.error("eventId not found in the URL");
  }


    const apiUrl = process.env.REACT_APP_API_URL; 
    this.setState({ templateId: this.props.editorData.templateId });
    this.getProjectTemplate();
  }

  getPreparePermission(state) {
    let perms = {};
    if (Array.isArray(state)) {
      state.forEach((data) => {
        perms[data.permission_name] = true;
      });
    }
    return perms;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.project &&
      this.success == true &&
      this.props.project.project &&
      this.props.project.project.permissions
    ) {
      this.success = false;
      this.permData = this.getPreparePermission(
        this.props.project.project.permissions,
      ); 
    }
    if (
      this.props.project.replicatePageSuccess === true &&
      this.props.project.message !== prevProps.project.message
    ) {
      this.setState(
        {
          openReplicateModal: false,
          loading: false
        },
        () => {
          this.getProjectTemplate();
          createNotification('Success', this.props.project.message);
        },
      );
    }

  }

  async openUnlayerEditor(pageId, pageName, pageUrl) {
    this.props.navigate({
      pathname: `/project-page/update-page/pid/${this.state.eventId}/tid/${pageId}/pn/${pageName}/purl/${pageUrl}`,
      state: { hasRenderedByManagePage: true, lngCode: this.props.users.newLangState },
    });
  }

  handleLoading = () => {
    let loading = this.state.loading;
    this.setState({
      loading: !loading,
      page_no: 1,
    });
  };

  deletePage = async () => {
    const arr = window.location.href.split('/');
    const projectId = arr[arr.length - 1];
    const users = UserData();
    this.setState({
      loading: true
    });
    const response = await axios.delete(
      `${URL}/deletePageDetails?pageId=${this.state.pageId}`,
      {
        headers: {
          'Content-Type': 'application/json',
          'check-grant': projectId + `-${permissions.deletePage}`,
          token: `bearer ${users.token}`,
        },
      },

    );

    if (response.data.message === 'Page Deleted Successfully.') {
      createNotification('success', this.props.users.languageStateForRedux?.messages?.deletePageSuccess);

      this.getProjectTemplate();
      this.handleClose();
      this.setState({
        loading: false
      });
    }
  };

  openPreview = (pageUrl) => {
    this.props.navigate({
      pathname: generateUrl(`/project-dashboard/preview-page/${this.state.eventId}`),
      search: `pagepath=${pageUrl}&sentFrom=managepages&lngCode=${this.props.users.newLangState}`,
    });
  };

  handleClickOpen = (pageId) => {
    this.setState({
      open: true,
      pageId: pageId,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  openCantDeleteModel = () => {
    this.setState({
      openCantDeleteModal: true,
    });
  };

  handleCloseCantDeleteModal = () => {
    this.setState({
      openCantDeleteModal: false,
    });
  };

  openReplicateModal = (pageIdToReplicate) => {
    this.setState({
      openReplicateModal: true,
      pageIdToReplicate: pageIdToReplicate,
    });
  };
  closeReplicateModal = () => {
    this.setState({
      openReplicateModal: false,
      pageIdToReplicate: '',
    });
  };

  replicatePage = () => {
    this.props.replicatePageAction({ pageId: this.state.pageIdToReplicate, lngCode: this.props.users.newLangState });
    // this.handleLoading()
  };

  goToNewPage = () => {
    this.props.navigate({
      pathname: generateUrl(`/add-new-page/${this.state.eventId}`),
      state: { lngCode: this.props.users.newLangState }

    });
  };

  render() {
    const { template, events, loading } = this.state;
    return (
      <>
        <div class="manage-page-wrapper">
          <Grid container spacing={2}>
            {template &&
              template.map((page, i) => {
                return (
                  <Grid size={{xs: 12, md:6, lg:4, xl:3}}>
                    <div Class="page-card">
                      <div
                        class="page-card-img mangepage-page-card"
                        style={{ backgroundImage: `url(${pagethum})` }}
                      >
                        {/* <iframe
                          scrolling="no"
                          className="page-preview"
                          src={`/project-dashboard/preview-page/${this.state.eventId}?pagepath=${page.path}`}
                          title=""
                        ></iframe> */}  
                        { this.state.eventId ? ( // Ensure eventId is available before rendering the iframe
                          <iframe
                            scrolling="no"
                            className="page-preview"
                            src={`/project-dashboard/preview-page/${this.state.eventId}?pagepath=${page.path}`}
                            title="Page Preview"
                          ></iframe>
                        ) : (
                          <p>Loading preview...</p> // Optionally show a loading state until eventId is available
                        )}
                      </div>
                      <div class="page-card-body">
                        <h3 className="page-card-title">
                          {page ? page.pageName : ''}{' '}
                        </h3>
                        <Tooltip arrow placement='bottom' title={`${getEventURL(
                          events.eventDomain,
                          events.vanityDomainName,
                          events.isVanityDomainActive,
                          events.isVanityDomainVerified,
                        )}/${page.path}`} >
                          <span className="page-card-url">
                            <a
                              title={`${getEventURL(
                                events.eventDomain,
                                events.vanityDomainName,
                                events.isVanityDomainActive,
                                events.isVanityDomainVerified,
                              )}/${page.path}`}
                              target="_blank"
                              href={`${getEventURL(
                                events.eventDomain,
                                events.vanityDomainName,
                                events.isVanityDomainActive,
                                events.isVanityDomainVerified,
                              )}/${page.path}`}
                              rel="noreferrer"
                            >
                              {`${getEventURL(
                                events.eventDomain,
                                events.vanityDomainName,
                                events.isVanityDomainActive,
                                events.isVanityDomainVerified,
                              )}/${page.path}`}
                            </a>
                            <span>
                              <a
                                href={`${getEventURL(
                                  events.eventDomain,
                                  events.vanityDomainName,
                                  events.isVanityDomainActive,
                                  events.isVanityDomainVerified,
                                )}/${page.path}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img alt="url-img" src={urlImg} />
                              </a>
                            </span>
                          </span>
                        </Tooltip>
                        <p className="page-card-status">
                          {this.props.users.languageStateForRedux?.column_names?.attendees?.lastUpdated}{' '}
                          {page.updatedAt
                            ? moment
                              .tz(
                                page.updatedAt,
                                this.props.project?.project?.timezone,
                              )
                              .format('MMM, DD YYYY, h:mm A')
                            : '--'}{' '}
                        </p>

                        <Grid container spacing={1} columns={16}>

                          { authPermissionValidation(permissionsConstants.viewPages) && (
                            <Grid size={{xs: "grow",}}>
                              <Button
                                onClick={() => this.openPreview(page.path)}
                                fullWidth
                                variant="outlined"
                                color="primary"
                                className="mb-2"
                              >
                                {this.props.users.languageStateForRedux?.buttons?.managePages?.preview}
                              </Button>
                            </Grid>
                          )}
                          { authPermissionValidation(permissionsConstants.editPage) && (
                            <Grid size={{xs: "grow",}}>
                              <Button
                                onClick={() =>
                                  this.openUnlayerEditor(
                                    page._id,
                                    page.pageName,
                                    page.path,
                                  )
                                }
                                fullWidth
                                variant="outlined"
                                color="primary"
                                className="mb-2"
                              >
                                {this.props.users.languageStateForRedux?.buttons?.managePages?.edit}
                              </Button>
                            </Grid>
                          )}
                        </Grid>
                        <Grid container spacing={0} alignItems={'center'}>
                          {page.isLoginPage && (
                            <Grid >
                              <Tooltip
                                title={this.props.users.languageStateForRedux?.formfields?.managePages?.login}
                                placement="top"
                                arrow
                              >
                                <Box style={{ padding: '6px' }}>
                                  <img alt="Login page" src={LoginPageIcon} />
                                </Box>
                              </Tooltip>
                            </Grid>
                          )}
                          {page.isHomePage && (
                            <Grid >
                              <Tooltip
                                title={this.props.users.languageStateForRedux?.formfields?.managePages?.home}
                                placement="top"
                                arrow
                              >
                                <Box style={{ padding: '6px' }}>
                                  <img alt="Home page" src={HomePageIcon} />
                                </Box>
                              </Tooltip>
                            </Grid>
                          )}
                          {page.pageType == 'Kiosk' && (
                            <Grid >
                              <Tooltip
                                title={this.props.users.languageStateForRedux?.formfields?.managePages?.kiosk}
                                placement="top"
                                arrow
                              >
                                <Box style={{ padding: '6px' }}>
                                  <img alt="Home page" src={KioskIcon} />
                                </Box>
                              </Tooltip>
                            </Grid>
                          )}
                          <Grid size={{xs: "grow",}}>
                            {
                               authPermissionValidation(permissionsConstants.deletePage) && !page.isLoginPage && page.pageType !== 'Kiosk' && (
                                <Button
                                  style={{
                                    paddingLeft: authPermissionValidation(permissionsConstants.deletePage)
                                      ? !page.isHomePage
                                        ? '45px'
                                        : ''
                                      : '',
                                  }}
                                  color="secondary"
                                  fullWidth
                                  onClick={
                                    !page.isHomePage
                                      ? () => this.handleClickOpen(page._id)
                                      : this.openCantDeleteModel
                                  }
                                >
                                  {this.props.users.languageStateForRedux?.actions?.managePages?.deletePage}
                                </Button>
                              )}
                          </Grid>
                          {page.pageType !== 'Kiosk' && <Grid >
                            <div className="options">
                              <MoreHorizIcon />
                              <div className="options-list">
                                <div
                                  onClick={() =>
                                    this.openReplicateModal(page._id)
                                  }
                                >
                                  {this.props.users.languageStateForRedux?.common?.replicate}
                                </div>
                              </div>
                            </div>

                          </Grid>}
                        </Grid>
                      </div>
                    </div>
                  </Grid>
                );
              })}
            {authPermissionValidation(permissionsConstants.createPage) && (
              <Grid size={{xs: 12, md:6, lg:4, xl:3}}>
                <div Class="add-page"
                  onClick={() => this.goToNewPage()}
                >

                  <div class="add-icon">
                    <img alt="" src={addImg} />
                    <p>{this.props.users.languageStateForRedux?.actions?.managePages?.addNew} </p>
                  </div>

                </div>
              </Grid>
            )}
          </Grid>
          <Modal
            open={this.state.open}
            onClose={this.handleClose}
            classNames={{
              overlay: '',
              modal: 'Invite-User-Modal',
            }}
            center
          >
            <Box component={Grid} container className="" px={1.5}>
              <Box component={Grid} mt={-1} size={{xs: 12,}} pb={3}>
                <Box component="p" mb={1} className="subtitle">
                  {' '}
                  {this.props.users.languageStateForRedux?.common?.confirmDelete}
                </Box>
                <p className="small-subtitle">
                  {this.props.users.languageStateForRedux?.common?.deleteMsg}
                </p>
              </Box>
              <Grid size={{xs: 12,}}>
                <Grid container justifyContent="end">
                  <Stack spacing={1} direction="row" fullWidth>
                    <Button
                      variant="contained"
                      color="secondary"
                      disableElevation
                      onClick={() => this.deletePage()}
                    >
                      {this.props.users.languageStateForRedux?.common?.delete}
                    </Button>
                    <Button
                      variant="outlined"
                      disableElevation
                      onClick={this.handleClose}
                    >
                      {this.props.users.languageStateForRedux?.common?.cancel}
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </Modal>
          <Modal
            open={this.state.openCantDeleteModal}
            onClose={this.handleCloseCantDeleteModal}
            classNames={{
              overlay: '',
              modal: 'Invite-User-Modal',
            }}
            center
          >
            <Grid container spacing={3} className="modal-wrapper">
              <Grid size={{xs: 12,}}>
                <Box component="p" mb={0.5} className="subtitle">
                  {this.props.users.languageStateForRedux?.formfields?.managePages?.cantDelete}
                </Box>
                <p className="small-subtitle">
                  {this.props.users.languageStateForRedux?.formfields?.managePages?.desc}
                </p>
              </Grid>

              <Grid size={{xs: 12,}}>
                <Grid container justifyContent="end">
                  <Stack spacing={1} direction="row" fullWidth>
                    <Link to={generateUrl(`/edit-project/${this.state.eventId}`)}>
                      <Button variant="contained" color="primary" disableElevation>
                        {this.props.users.languageStateForRedux?.buttons?.managePages?.goToSetting}
                      </Button>
                    </Link>
                    <Button
                      variant="outlined"
                      disableElevation
                      onClick={this.handleCloseCantDeleteModal}
                    >
                      {this.props.users.languageStateForRedux?.common?.cancel}
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Modal>


          <ConfirmationAlertPopUp
            openModal={this.state.openReplicateModal}
            closeModalFunc={this.closeReplicateModal}
            title={this.props.users.languageStateForRedux?.common?.confirmReplicate}
            text={this.props.users.languageStateForRedux?.actions?.managePages?.textMsg}
            confirmationButtonText={this.props.users.languageStateForRedux?.common?.replicate}
            confirmationButtonColor="primary"
            closeButtonText={this.props.users.languageStateForRedux?.common?.cancel}
            functionality={this.replicatePage}
          />
          <NotificationContainer />
        </div>
        {loading ? <Loader /> : null}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    project: state.Projects,
    events: state.Events,
    users: state.Users,
  };
};

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      replicatePageAction: Actions.replicatePageRequest,
      findIfAllowedFromSuperadminRequest:
        Actions.findIfAllowedFromSuperadminRequest,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapActionsToProps)(withRouter(ManagePage));

