
const userDataLocalStorage = localStorage.getItem('user_details');
const userData = userDataLocalStorage
const user = JSON.parse(userData);
 
 
export const permissionsConstants = {
    createProject: 1,
    viewProject: 2,
    editProject: 3,
    deleteProject: 4,
    publishUnpublishProject: 5,
    createPage: 6,
    viewPages: 7,
    editPage: 8,
    deletePage: 9,
    editPageSettings: 10,
    createCampaign: 11,
    viewCampaigns: 12,
    editCampaign: 13,
    deleteCampaign: 14,
    createSession: 15,
    viewSessions: 16,
    editSession: 17,
    deleteSession: 18,
    createNotification: 19,
    viewNotifications: 20,
    editNotification: 21,
    deleteNotification: 22,
    editMembers: 23,
    viewMembers: 24,
    createAttendee: 25,
    viewAttendees: 26,
    editAttendee: 27,
    deleteAttendee: 28,
    checkInCheckOutAttendee: 29,
    importAttendee: 30,
    exportAttendees: 31,
    viewOutboundEmailsReports: 32,
    editCustomFields: 33,
    editGroups: 34,
    editTransactionalEmailTemplates: 35,
    resetPassword: 36,
    webhooks: 37,
    appIntegrations: 38,
    viewGamification: 39,
    editGamification: 40,
    exportGamification: 41,
    addManualPoints: 42,
    viewLiveDashboard: 43,
    createAudience: 44,
    viewAudience: 45,
    editAudience: 46,
    deleteAudience: 47,
    rolesPermission: 54,
    users: 55,
    createAccessCodes: 56,
    viewAccessCodes: 57,
    editAccessCodes: 58,
    deleteAccessCodes: 59,
    helpdeskAdmin: 60,
    helpdeskAgent: 61,
    addKioskUser: 62,
    viewBadge: 63,
    createMeetingForAll: 64,
    createMeetingForMyTeam: 65,
    createMeetingForMyOwn: 66,
    viewAllMeetings: 67,
    viewMyTeamMeetings: 68,
    viewMyOwnMeetings: 69,
    editAllMeetings: 70,
    editMyTeamMeetings: 71,
    editMyOwnMeeting: 72,
    deleteAllMeeting: 73,
    deleteMyTeamMeetings: 74,
    deleteMyOwnMeeting: 75,
    meetingSetting: 76,
    createChatWidget: 77,
    viewChatWidget: 78,
    editChatWidget: 79,
    deleteChatWidget: 80,
    embedOnSide: 81,
    createAccount: 82,
    viewAccount: 83,
    editAccount: 84,
    deleteAccount: 85,
    accountCustomFields: 86,
    createOpportunity: 87,
    viewOpportunity: 88,
    editOpportunity: 89,
    deleteOpportunity: 90,
    assignOpportunity: 91,
    changeOpportunityStatus: 92,
    opportunityCustomFields: 93,
    createContacts: 94,
    viewContacts: 95,
    editContacts: 96,
    deleteContacts: 97,
    contactsCustomFields: 98,
    createDrive: 99,
    editDrive: 100,
    deleteDrive: 101,
    viewDrive: 102
};

export const SystemLevelPermissions = {
    viewLiveDashboard: 43,
    createAccount: 82,
    viewAccount: 83,
    editAccount: 84,
    accountCustomFields: 86,
    createOpportunity: 87,
    viewOpportunity: 88,
    editOpportunity: 89,
    assignOpportunity: 91,
    changeOpportunityStatus: 92,
    opportunityCustomFields: 93,
    createContacts: 94,
    viewContacts: 95,
    editContacts: 96,
    deleteContacts: 97,
    contactsCustomFields: 98,
};


 


