import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { IconButton, Stack, Stepper, Step, StepLabel } from "@mui/material";
import {
  ArrowLeftIcon,
  ReportingIconActive,
  CancelIcon,
  ArrowRightWhiteIcon,
} from "../components/CustomIcons";
import { useTheme } from '@mui/material/styles';

import EijentCopilot from "../EijentCopilot/index";
import InitialData from "./InitialData";
import JourneySummary from "./JourneySummary";
import JourneyWorkflow from "./JourneyWorkflow";



const steps = ["Type", "Initial journey data", "Define Workflow", "Confirmation",];

const NewJourney: React.FC = () => {
  const theme = useTheme();

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "calc(100% - 200px)",
    height: "calc(100vh - 60px)",
    // bgcolor: "#F2F2F2",
    bgcolor: "#FFFFFF",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up('xs')]: {
      width: "calc(100% - 100px)",
      height: "calc(100vh - 40px)",
    },
    [theme.breakpoints.up('sm')]: {
      width: "calc(100% - 100px)",
      height: "calc(100vh - 40px)",
    },
    [theme.breakpoints.up('md')]: {
      width: "calc(100% - 100px)",
      height: "calc(100vh - 40px)",
    },
    [theme.breakpoints.up('lg')]: {
      width: "calc(100% - 100px)",
      height: "calc(100vh - 40px)",
    },
    [theme.breakpoints.up('xl')]: {
      width: "calc(100% - 200px)",
      height: "calc(100vh - 60px)",
    },
  };
  const [open, setOpen] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState<number>(0);
  const [skipped, setSkipped] = React.useState<Set<number>>(new Set());

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const isStepOptional = (step: number) => {
    return step === 0;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div>
      <Box
        onClick={handleOpen}
        sx={{
          width: 158,
          cursor: "pointer",
          transitionDuration: "200ms",
          "&:hover": { backgroundColor: "rgb(0 0 0 / 5%)" },
        }}
        pl={4}
        pr={2}
        py={1.5}
        className="new-list-item"
      >
        <Stack direction="row" alignItems="center" gap={2.25}>
          <ReportingIconActive />
          <Typography variant="subtitle2" sx={{ fontWeight: 400, lineHeight: "16px", color: "#fff" }}>
            Journey
          </Typography>
        </Stack>
      </Box>

      <Modal
        open={open}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        slotProps={{
          backdrop: { timeout: 200, sx: { backdropFilter: "blur(4px)", backgroundColor: "rgba(255, 255, 255, 0.5)" } },
        }}
      >
        <Box sx={modalStyle}>
          {/* Header */}
          <Stack pl={4} pr={2} py={2} borderBottom="1px solid #D9D9D9" direction="row" alignItems="center" justifyContent="space-between">
            <Typography id="modal-title" variant="h2">
              New Journey
            </Typography>

            <Box display="flex" width="60%" alignItems="start" gap={4}>
              {/* Stepper */}
              <Stepper activeStep={activeStep}
                sx={{
                  flexGrow: 1,
                  border: '1px solid #D9D9D9',
                  borderRadius: '23px',
                  backgroundColor: '#fff',
                  padding: 3,
                }}
              >
                {steps.map((label, index) => {
                  const stepProps: { completed?: boolean; } = {};
                  const labelProps: {
                    optional?: React.ReactNode;
                  } = {};
                  // if (isStepOptional(index)) {
                  //   labelProps.optional = (
                  //     <Typography variant="caption">Optional</Typography>
                  //   );
                  // }
                  if (isStepSkipped(index)) {
                    stepProps.completed = false;
                  }
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>

              {/* Close Button */}
              <IconButton size="small" variant='contained'
                // @ts-ignore
                color='white'
                onClick={handleClose}>
                <CancelIcon />
              </IconButton>
            </Box>
          </Stack>

          {/* Step Content */}
          <Box flexGrow={1} sx={{ overflow: 'auto', }} >
            {activeStep === 0 && <EijentCopilot />}
            {activeStep === 1 && <InitialData />}
            {activeStep === 2 && <JourneyWorkflow />}
            {activeStep === 3 && <JourneySummary />}
            {activeStep === steps.length && (
              <Typography sx={{ mt: 2, mb: 1 }} align="center">All steps completed - you're finished</Typography>
            )}
          </Box>

          {/* Footer */}
          <Box sx={{ p: 2.5, display: "flex", alignItems: "center", justifyContent: "center", borderTop: "1px solid #D9D9D9" }}>
            <Stack direction="row" gap={1.5}>
              {activeStep !== 0 &&
                <IconButton
                  // @ts-ignore
                  color="white"
                  aria-label="back" size="large"  onClick={handleBack}>
                  <ArrowLeftIcon />
                </IconButton>
              }

              {isStepOptional(activeStep) && (
                <Button
                  size="large"
                  color="primary"
                  variant="contained"
                  sx={{ minWidth: 115, justifyContent: "space-between", backgroundColor: "#54CBB9", "&:hover": { backgroundColor: "#54CBB9" } }}
                  endIcon={<ArrowRightWhiteIcon />}
                  onClick={handleSkip}
                >
                  Skip
                </Button>
              )}

              <Button size="large" color="primary" disabled={activeStep === 3} variant="contained" sx={{ minWidth: 115, justifyContent: "space-between" }} endIcon={<ArrowRightWhiteIcon />} onClick={handleNext}>
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button>
            </Stack>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default NewJourney;
