import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom'; // Import Routes and Route
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { legacy_createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from 'ReduxStore/Reducers';
import rootSaga from 'ReduxStore/Saga';
import '../src/sass/main.scss';
import "../src/assets/styles/illu-main.scss";
import RoutesComponent from './Routes';
import './utils/axiosInterceptor'; 
import logger from 'redux-logger';

const sagaMiddleware = createSagaMiddleware();
const store = legacy_createStore(
  rootReducer,
  compose(applyMiddleware(sagaMiddleware)) // Production
  // compose(applyMiddleware(sagaMiddleware, logger)) // Development
);
sagaMiddleware.run(rootSaga);

// Use createRoot for React 18
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<RoutesComponent />} /> {/* Wrap RoutesComponent in a Route */}
      </Routes>
    </BrowserRouter>
  </Provider> // Only pass the component tree, no second argument
);

// Service worker
serviceWorker.unregister();
