
import { ThemeProvider } from '@mui/material';
import { theme } from '../../../src/theme/theme';
// import '../../../sass/main.scss';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import DashboardLayout from 'Layouts/DashboardLayout';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Home from './Home';
import Videos from './Videos';
import Docs from './Docs';
import FolderContent from './FolderContent';
const FileUpload = (props) => { 

  const getDefaultIndex = () => {
    const tabs = ['home', 'images', 'docs', 'videos'];
    const seg = window.location.href.split('/');
    const mid = seg[seg.length - 1] 
    return tabs.indexOf(mid)
  }
  return (
    <DashboardLayout >
      <ThemeProvider theme={theme}>
        <div className="dashboardMiddleArea">
          <Box component={'div'} p={0} mb={3} className="header-common">
            <Grid >
              <h1 className="heading"> Drive </h1>
            </Grid>
          </Box>
          <Box className="container-with-sidebar" px={0}>
            <Tabs defaultIndex={getDefaultIndex()}>
              <Box
                className="project-sidebar"
                sx={{
                  background: 'transparent !important',
                  marginTop: '0 !important',
                  marginBottom: '0 !important',
                }}
                my={0}
              >
                <TabList>
                  <Tab value="1">
                    {' '}
                    <Link to={'/xp/files/home'}>Home</Link>
                  </Tab>
                  <Tab value="2">
                    {' '}
                    <Link to={'/xp/files/images'}>Images</Link>
                  </Tab>
                  <Tab value="3">
                    {' '}
                    <Link to={'/xp/files/docs'}>Documents</Link>
                  </Tab>
                  <Tab value="4">
                    {' '}
                    <Link to={'/xp/files/videos'}>Videos</Link>
                  </Tab>
                </TabList>
              </Box>

              <Box className="container-sidebar-right">
                {/* all files--- */}
                <TabPanel value="1">
                  <Home />
                </TabPanel>
                {/* images--- */}
                <TabPanel value="2">
                  <FolderContent />
                </TabPanel>
                {/* documents--- */}
                <TabPanel value="3">
                  <Docs />
                </TabPanel>
                {/* videos--- */}
                <TabPanel value="4">
                  <Videos />
                </TabPanel>
              </Box>
            </Tabs>
          </Box>
        </div>
      </ThemeProvider>
    </DashboardLayout>

  );

};


export default FileUpload;
