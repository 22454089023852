import React, { useEffect, useRef, useState } from 'react';
import { Badge, Box, Stack } from '@mui/material';
import Grid from '@mui/material/Grid2';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TablePagination,
  Checkbox,
} from '@mui/material';
import { GmailData } from '.';
import IconButton from '@mui/material/IconButton';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import RestoreFromTrashOutlinedIcon from '@mui/icons-material/RestoreFromTrashOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import MarkEmailUnreadOutlinedIcon from '@mui/icons-material/MarkEmailUnreadOutlined';
import DraftsOutlinedIcon from '@mui/icons-material/DraftsOutlined';

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
//@ts-ignore
import SearchIcon from '../../../src/assets/images/icons/search.svg';
//@ts-ignore
import SearchIconBar from '../../../src/assets/images/searchIco.png';
//@ts-ignore
import MoreIcon from '../../../src/assets/images/icons/more.svg';
import moment from 'moment';
import PaginationFunctional, {
  handleEndCountForPagination,
} from 'Pages/ProjectDashboard/Integration/AppIntegration/googleWorkspaceComponents/PaginationFunctional';
import {
  xpAccountIntegrationGmailArchiveEmail,
  xpAccountIntegrationGmailArchiveMultipleThreads,
  xpAccountIntegrationGmailDeleteEmail,
  xpAccountIntegrationGmailDeleteMultipleThreads,
  xpAccountIntegrationGmailGetGmailId,
  xpAccountIntegrationGmailGetList,
  xpAccountIntegrationGmailMarkEmailAsBookmarked,
  xpAccountIntegrationGmailMarkEmailAsRead,
  xpAccountIntegrationGmailMarkEmailAsUnread,
  xpAccountIntegrationGmailMarkMultipleThreadsAsRead,
  xpAccountIntegrationGmailMarkMultipleThreadsAsUnread,
  xpAccountIntegrationGmailPermanentDeleteMultipleThreads,
  xpAccountIntegrationGmailUnBookmarkMultipleEmails,
} from 'ReduxStore/API';
import ConfirmationAlertPopUp from 'Common/ConfirmationAlertPopUp';
import { createNotification } from 'helpers';
import Loader from 'Components/Loader/loader';
import AttachmentOutlinedIcon from '@mui/icons-material/AttachmentOutlined';
import CustomHTMLTooltip from '../../Components/CustomHTMLTooltip';
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import StarRateRounded from '@mui/icons-material/StarRateRounded';
import { XpUsersWebSocket_State } from 'ReduxStore/Reducers/xpUsersWebSocket.reducer';
import { useSelector } from 'react-redux';
import GmailView, { Message } from './gmailView';
import { useLocation, useNavigate } from 'react-router';
import Compose from './Compose';
import EmailsLayout from './index';
import { Link } from 'react-router-dom';
import { generateUrl } from 'helpers/common';

type Props = {
  // gmailData: GmailData;
  // getGmailData: (limit?: number, page_no?: number, showLoader?: false) => void;
  // viewGmailContent: (gmail: any, i: number) => void;
  // limit: number;
  // setLimit: React.Dispatch<React.SetStateAction<number>>;
  // page_no: number;
  // setPage_no: React.Dispatch<React.SetStateAction<number>>;
  // gmailId: string;
  // mailType: string;
  // viewDraftMailsInCompose: () => void;
  // setGmailDataAfterUnbookmarkingMultipleEmails: (messageIds: any) => void
  // setGmailDataAfterBookmarkingEmail: (messageId: any) => void
};

function EmailList({
  // gmailData,
  // getGmailData,
  // viewGmailContent,
  // limit,
  // setLimit,
  // page_no,
  // setPage_no,
  // gmailId,
}: // mailType,
// viewDraftMailsInCompose,
// setGmailDataAfterBookmarkingEmail,
// setGmailDataAfterUnbookmarkingMultipleEmails
Props) {
  const location = useLocation();
  const navigate = useNavigate()
  const { message: xpUsersWebSocketMessage } = useSelector(
    (state: any) => state.XpUsersWebSocket,
  ) as XpUsersWebSocket_State;

  // variables for pagination started
  const [startCount, setStartCount] = useState<number>(1);
  const [endCount, setEndCount] = useState<number>(10);
  const [labelIdsArray, setLabelIdsArray] = useState<string[]>([]);
  const initialRender = useRef(true);
  const [openDeleteConfirmationModel, setOpenDeleteConfirmationModel] =
    useState<boolean>(false);
  const [openPermanentDeleteConfirmationModel, setOpenPermanentDeleteConfirmationModel] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<string[]>([]);

  // variables for pagination started
  const [limit, setLimit] = useState<number>(10);
  const [page_no, setPage_no] = useState<number>(1);
  const [mailType, setMailType] = useState<
    'INBOX' | 'DRAFT' | 'SENT' | 'TRASH' | 'STARRED' | ''
  >('');

  const [payloadForDraft, setPayloadForDraft] = useState({});
  const [messageIdForDraft, setMessageIdForDraft] = useState({});

  const [draftId, setDraftId] = useState('');
  const [subject, setSubject] = useState('');
  const [recipients, setRecipients] = useState('');
  const [editorText, setEditorText] = useState('');
  const [gmailData, setGmailData] = useState<GmailData>({});

  const [gmailId, setGmailId] = useState("");

  useEffect(() => {
    handleEndCountForPagination(
      gmailData?.totalMailCount,
      limit,
      page_no,
      setEndCount,
    );
    setStartCount((page_no - 1) * limit + 1);
    getGmailDetails()
    // setGmailData(gmailDataProp)
  }, []);

  useEffect(() => {
    setMailTypeFromUrl();
  }, [location.pathname]);

  useEffect(() => {
    if (mailType) {
      getGmailData();
    }
  }, [mailType]);

  useEffect(() => {
    console.log('xpUsersWebSocketMessage', xpUsersWebSocketMessage);
    if (xpUsersWebSocketMessage.message == 'Gmail inbox list is updated') {
      if (page_no === 1) {
        getGmailData(limit, page_no, false);
      }
    }
  }, [xpUsersWebSocketMessage]);

  useEffect(() => {
    setSelectedCheckboxes([]);
  }, [page_no]);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      handleEndCountForPagination(
        gmailData?.totalMailCount,
        limit,
        page_no,
        setEndCount,
      );
    }
  }, [gmailData]);

  const setMailTypeFromUrl = () => {
    const currentPath = location.pathname.split('/')[3];
    let mailType: 'INBOX' | 'DRAFT' | 'SENT' | 'TRASH' | 'STARRED' = 'INBOX';
    switch (currentPath) {
      case 'inbox':
        mailType = 'INBOX';
        break;
      case 'drafts':
        mailType = 'DRAFT';
        break;
      case 'sent':
        mailType = 'SENT';
        break;
      case 'trash':
        mailType = 'TRASH';
        break;
      case 'bookmark':
        mailType = 'STARRED';
        break;
      default:
        break;
    }
    setMailType(mailType);
  };



  const getGmailData = async (limit = 10, page_no = 1, showLoader = true) => {
    if (showLoader) {
      setIsLoading(true);
    }
    const queryParams = {
      page_no: page_no,
      limit: limit,
      type: mailType,
    };
    const gmailDataResult: any = await xpAccountIntegrationGmailGetList(
      queryParams,
    );
    setIsLoading(false);
    if (gmailDataResult?.data) {
      setGmailData(gmailDataResult.data);
    }
  };



  const getGmailDetails = async () => {
    try {
      const result: any = await xpAccountIntegrationGmailGetGmailId();
      if (result.status === 200 && result.data.success) {
        setGmailId(result.data.details.appData.user.email);
      }
    } catch (error) {
      console.log("error");
      if (error instanceof Error) {
        createNotification("error", error.message);
      } else {
        createNotification("error", "An unexpected error occurred.");
      }
    }
  };

  const setGmailDataAfterBookmarkingEmail = (messageId) => {
    setGmailData((prev) => {
      let modifiedData = prev.data.map((obj) => {
        // Iterate through the messages array to find the message with the given messageId
        let updatedMessages = obj.messages.map((message) => {
          if (
            message.id === messageId &&
            !message.labelIds.includes('STARRED')
          ) {
            // Create a new message object with the updated labelIds
            return {
              ...message,
              labelIds: [...message.labelIds, 'STARRED'],
            };
          }
          return message; // Return the original message if no changes are needed
        });

        // Return a new object with the updated messages array
        return {
          ...obj,
          messages: updatedMessages,
        };
      });

      // Return the updated state
      return {
        ...prev,
        data: modifiedData,
      };
    });
  };

  const redirectToGmailView = (threadId: string, index) => {
    navigate(generateUrl(`/emails/inbox/gmailView/${threadId}?pageNo=${page_no}&limit=${limit}&mailNo=${index + 1}&totalCount=${gmailData?.totalMailCount}`), {state: {gmailData: gmailData}})
  }

  // const viewDraftMailsInCompose = (gmail: any) => {
  //   setViewCompose(true);
  //   const subjectDetail = gmail?.payload?.headers.find(
  //     (detail) => detail.name === 'Subject',
  //   );
  //   const sendTo = gmail?.payload?.headers.find(
  //     (detail) => detail.name === 'To',
  //   );
  //   const { payload } = gmail;
  //   setPayloadForDraft(payload);
  //   setMessageIdForDraft(gmail.id);
  //   setDraftId(gmail.draftId);
  //   setSubject(subjectDetail?.value);
  //   setRecipients(sendTo?.value);
  //   setEditorText(gmail?.snippet);
  // };

  const setGmailDataAfterUnbookmarkingMultipleEmails = (messageIds) => {
    setGmailData((prev) => {
      let modifiedData = prev.data.map((obj) => {
        // Iterate through the messages array to find the message with the given messageId
        let updatedMessages = obj.messages.map((message) => {
          if (
            messageIds.includes(message.id) &&
            message.labelIds.includes('STARRED')
          ) {
            // Create a new message object with the updated labelIds (removing "STARRED")
            return {
              ...message,
              labelIds: message.labelIds.filter((label) => label !== 'STARRED'),
            };
          }
          return message; // Return the original message if no changes are needed
        });

        // Return a new object with the updated messages array
        return {
          ...obj,
          messages: updatedMessages,
        };
      });

      // Return the updated state
      return {
        ...prev,
        data: modifiedData,
      };
    });
  };

  const handleSelectCheckBox = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    mailId: string,
    labelArray: string[],
  ) => {
    const target = e.target as HTMLInputElement;

    if (target.checked) {
      setLabelIdsArray((prev) => [...prev, ...labelArray]);
      setSelectedCheckboxes((prev) => [...prev, mailId]);
    } else {
      setLabelIdsArray((prev) => filterOnce(prev, labelArray));
      setSelectedCheckboxes((prev) => prev.filter((id) => id !== mailId));
    }
  };

  const filterOnce = (source, toRemove) => {
    const toRemoveCount = {};
    toRemove.forEach((item) => {
      toRemoveCount[item] = (toRemoveCount[item] || 0) + 1;
    });
    return source.filter((item) => {
      if (toRemoveCount[item]) {
        toRemoveCount[item]--;
        return false; // Remove this occurrence
      }
      return true; // Keep this item
    });
  };

  const handleSelectAllCheckboxes = (
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    let target = e.target as HTMLInputElement;
    if (target.checked) {
      let arr: string[] = [];
      gmailData?.data?.forEach((mail) => arr.push(mail._id));
      setSelectedCheckboxes(arr);
    } else {
      setSelectedCheckboxes([]);
    }
  };

  const handleDeleteMultiple = async () => {
    try {
      setIsLoading(true);
      const data = {
        ids: selectedCheckboxes,
        gmailId: gmailId,
      };
      const res: any = await xpAccountIntegrationGmailDeleteMultipleThreads(
        data,
      );
      if (res.data.success) {
        getGmailData();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setOpenDeleteConfirmationModel(false);
      setSelectedCheckboxes([]);
      setIsLoading(false);
      setLabelIdsArray([]);
    }
  };

  const handlePermanentDeleteMultiple = async () => {
    try {
      setIsLoading(true);
      const data = {
        threadIds: selectedCheckboxes,
        gmailId: gmailId
      };
      const res: any = await xpAccountIntegrationGmailPermanentDeleteMultipleThreads(data);
      if (res.status==200) {
        getGmailData();
      }else if(res.status==207){
        createNotification('info', res.data.message);
        getGmailData();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setOpenPermanentDeleteConfirmationModel(false);
      setSelectedCheckboxes([]);
      setIsLoading(false);
      setLabelIdsArray([]);
    }
  };

  const handleDeleteSingle = async (messageId: string) => {
    try {
      setIsLoading(true);
      const data = {
        messageId: messageId,
        gmailId: gmailId,
      };
      const res: any = await xpAccountIntegrationGmailDeleteEmail(data);
      if (res.data.success) {
        getGmailData();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setOpenDeleteConfirmationModel(false);
      setSelectedCheckboxes([]);
      setIsLoading(false);
    }
  };
  const handleArchiveEmail = async (messageId: string) => {
    try {
      setIsLoading(true);
      const data = {
        messageId: messageId,
        gmailId: gmailId,
      };
      const res: any = await xpAccountIntegrationGmailArchiveEmail(data);
      if (res.data.success) {
        getGmailData();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setOpenDeleteConfirmationModel(false);
      setSelectedCheckboxes([]);
      setIsLoading(false);
    }
  };

  const handleMarkEmailAsRead = async (messageId: string) => {
    try {
      setIsLoading(true);
      const data = {
        messageId: messageId,
        gmailId: gmailId,
      };
      const res: any = await xpAccountIntegrationGmailMarkEmailAsRead(data);
      if (res.data.success) {
        getGmailData();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setOpenDeleteConfirmationModel(false);
      setSelectedCheckboxes([]);
      setIsLoading(false);
    }
  };
  const handleMarkEmailAsUnread = async (messageId: string) => {
    try {
      setIsLoading(true);
      const data = {
        messageId: messageId,
        gmailId: gmailId,
      };
      const res: any = await xpAccountIntegrationGmailMarkEmailAsUnread(data);
      if (res.data.success) {
        getGmailData();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setOpenDeleteConfirmationModel(false);
      setSelectedCheckboxes([]);
      setIsLoading(false);
    }
  };

  const closeDeleteConfirmationModel = () => {
    setOpenDeleteConfirmationModel(false);
  };

  const closePermanentDeleteConfirmationModel = () => {
    setOpenPermanentDeleteConfirmationModel(false);
  };

  const markMultipleThreadsAsRead = async () => {
    try {
      setIsLoading(true);
      const data = {
        threadIds: selectedCheckboxes,
        gmailId: gmailId,
      };
      const res = await xpAccountIntegrationGmailMarkMultipleThreadsAsRead(
        data,
      );
      if (res.data.success) {
        getGmailData();
      }
    } catch (error) {
      console.log('error', error);
      createNotification('error', error.message);
    } finally {
      setSelectedCheckboxes([]);
      setLabelIdsArray([]);
      setIsLoading(false);
    }
  };

  const markMultipleThreadsAsUnread = async () => {
    try {
      setIsLoading(true);
      const data = {
        threadIds: selectedCheckboxes,
        gmailId: gmailId,
      };
      const res = await xpAccountIntegrationGmailMarkMultipleThreadsAsUnread(
        data,
      );
      if (res.data.success) {
        getGmailData();
      }
    } catch (error) {
      console.log('error', error);
      createNotification('error', error.message);
    } finally {
      setSelectedCheckboxes([]);
      setLabelIdsArray([]);
      setIsLoading(false);
    }
  };

  const archiveMultipleThreads = async () => {
    try {
      setIsLoading(true);
      const data = {
        threadIds: selectedCheckboxes,
        gmailId: gmailId,
      };
      const res = await xpAccountIntegrationGmailArchiveMultipleThreads(data);
      if (res.data.success) {
        getGmailData();
      }
    } catch (error) {
      console.log('error', error);
      createNotification('error', error.message);
    } finally {
      setSelectedCheckboxes([]);
      setLabelIdsArray([]);
      setIsLoading(false);
    }
  };

  const handleArchiveSingle = async (messageId: string) => {
    try {
      const data = {
        messageId: messageId,
        gmailId: gmailId,
      };
      const res: any = await xpAccountIntegrationGmailArchiveEmail(data);
      if (res.data.success) {
        getGmailData();
      }
    } catch (error) {
      console.log(error);
      createNotification('error', error.message);
    }
  };

  const renderSendersName = (gmail: { messages: Message[] }) => {
    let existingSendersNames = [];
    let existingSendersEmails = [];
    gmail.messages.forEach((message: any) => {
      const fromHeader = message.payload.headers.find(
        (header: any) => header.name === 'From',
      );

      if (!fromHeader?.value) return null;

      // Extract name and email
      const nameOnly = fromHeader.value.replace(/<.*?>/g, '').trim();
      !existingSendersNames.includes(nameOnly) &&
        existingSendersNames.push(nameOnly);

      const emailOnly = fromHeader.value.match(/<([^>]+)>/)?.[1] || '';
      !existingSendersEmails.includes(emailOnly) &&
        existingSendersEmails.push(emailOnly);
    });

    return (
      <CustomHTMLTooltip
        placement="bottom-start"
        interactive
        arrow
        title={
          <Box className="tooltip-popup" width={200}>
            {existingSendersEmails
              .map((email) => (email === '' ? gmailId : email))
              .join(', ')}
          </Box>
        }
      >
        {existingSendersNames
          .map((name) => (name === gmailId ? 'me' : name))
          .join(', ')}
      </CustomHTMLTooltip>
    );
  };

  const unBookmarkMultipleEmails = async (messages) => {
    try {
      const messageIds = messages
        .filter((message) => message.labelIds.includes('STARRED'))
        .map((message) => message.id);

      setGmailDataAfterUnbookmarkingMultipleEmails(messageIds);
      const data = {
        messageIds: messageIds,
        gmailId: gmailId,
      };

      const res = await xpAccountIntegrationGmailUnBookmarkMultipleEmails(data);

      if (res.data.success) {
        getGmailData(undefined, undefined, false);
      } else {
        createNotification('error', res.data.message);
      }
    } catch (error) {
      console.log('error', error);
      createNotification('error', error.message);
    }
  };

  const markLastEmailAsBookmarked = async (messageId: string) => {
    try {
      setGmailDataAfterBookmarkingEmail(messageId);
      const data = {
        messageId: messageId,
        gmailId: gmailId,
      };

      const res = await xpAccountIntegrationGmailMarkEmailAsBookmarked(data);

      if (res.data.success) {
        getGmailData(undefined, undefined, false);
      } else {
        createNotification('error', res.data.message);
      }
    } catch (error) {
      console.log('error', error);
      createNotification('error', error.message);
    }
  };

  const renderAttachmentCount = (gmail) => {
    let count = 0;
    gmail.messages.forEach((message) => {
      if (
        message?.payload.mimeType === 'multipart/mixed' &&
        message?.payload.parts &&
        Array.isArray(message?.payload.parts) &&
        message?.payload?.parts?.length > 1
      ) {
        count += message?.payload?.parts?.length - 1;
      }
    });
    if (count > 0) {
      return (
        <Badge color="primary" badgeContent={count}>
          <AttachmentOutlinedIcon />
        </Badge>
      );
    } else {
      return null;
    }
  };

  const redirectToCompose = (mail: Message) => {
    const subjectDetail = mail?.payload?.headers.find(
      (detail) => detail.name === 'Subject',
    );
    const sendTo = mail?.payload?.headers.find(
      (detail) => detail.name === 'To',
    );
    const { payload } = mail;
    let payloadForDraft = payload;
    let messageIdForDraft = mail.id;
    let draftId = mail.draftId;
    let subject = subjectDetail?.value;
    let recipients = sendTo?.value;
    let editorText = mail?.snippet;

    const composeState = {
      payloadForDraft,
      messageIdForDraft,
      draftId,
      subject,
      recipients,
      editorText
    }

    navigate(generateUrl(`/emails/drafts/compose/${mail.draftId}`), {state: composeState})
  }

  return (
    <EmailsLayout>
        {/* {(!isGmailInView) ? ( */}
          <div>
            <Box>
              <Box
                pb={1}
                mt={-5}
                mb={2}
                sx={{ borderBottom: '1px solid #EDECF5' }}
                className="top-filter-area"
              >
                <Grid container spacing={1} width={'100%'}>
                  <Grid size={{ md: 'grow' }}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid>
                        <Box pl={'15px'}>
                          <Checkbox
                            color="primary"
                            onClick={handleSelectAllCheckboxes}
                            checked={
                              gmailData?.data?.length
                                ? selectedCheckboxes.length ===
                                  gmailData?.data?.length
                                : false
                            }
                          />
                        </Box>
                      </Grid>
                      <Grid>
                        {selectedCheckboxes.length >= 1 &&
                        mailType !== 'TRASH' ? (
                          <IconButton
                            title="Archive"
                            onClick={() => archiveMultipleThreads()}
                          >
                            <ArchiveOutlinedIcon />
                          </IconButton>
                        ) : null}
                      </Grid>
                      <Grid>
                        {selectedCheckboxes.length >= 1 && (
                          <IconButton title="Delete">
                            <DeleteOutlineOutlinedIcon
                              onClick={() =>
                                setOpenDeleteConfirmationModel(true)
                              }
                            />
                          </IconButton>
                        )}
                      </Grid>
                      {/* <Grid>
                  <IconButton title="Refresh">
                    <RefreshOutlinedIcon />
                  </IconButton>
                </Grid> */}
                      <Grid>
                        {labelIdsArray.includes('UNREAD') ? (
                          <IconButton
                            title="Mark as read"
                            onClick={() => markMultipleThreadsAsRead()}
                          >
                            <DraftsOutlinedIcon />
                          </IconButton>
                        ) : (
                          selectedCheckboxes.length >= 1 && (
                            <IconButton
                              title="Mark as unread"
                              onClick={() => markMultipleThreadsAsUnread()}
                            >
                              <MarkEmailUnreadOutlinedIcon />
                            </IconButton>
                          )
                        )}
                      </Grid>
                      {/* <Grid>
                  <Box
                    className="options"
                    style={{ minWidth: '50px', maxWidth: '50px' }}
                  >
                    {' '}
                    <div className="options-button">
                      <IconButton title="More Options">
                        <MoreVertOutlinedIcon />
                      </IconButton>{' '}
                    </div>
                    <div className="options-list" style={{ right: '10px' }}>
                      <div>Option 1</div>
                      <hr />
                      <div>Option 2</div>
                    </div>
                  </Box>
                </Grid> */}
                    </Grid>
                  </Grid>
                  <Grid size={{ xs: 3 }}>
                    <Grid container spacing={1.25} className="team-dash-right">
                      <Grid size={{ xs: 'grow' }} className="">
                        <form>
                          <TextField
                            name="searchText"
                            placeholder={'Search'}
                            className="search"
                            size="small"
                            // value={searchText}
                            // onChange={this.handleChange}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img alt="" src={SearchIcon} />
                                </InputAdornment>
                              ),
                            }}
                            style={{ width: '100%' }}
                            variant="standard"
                          />
                          <button className="d-none" type="submit">
                            <img src={SearchIconBar} alt="" />
                          </button>
                        </form>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <TableContainer
                className="list-table email-listing"
                style={{ maxHeight: 'calc(100vh - 200px)' }}
              >
                <Table className="table">
                  <TableBody>
                    {gmailData?.data ? (
                      gmailData?.data.map((gmail: {
                        _id: string;
                        email: string;
                        messages: Message[];
                    }, index: number) => {
                        return (
                          <TableRow
                            style={{
                              cursor: 'pointer',
                              backgroundColor: gmail.messages.some((message) =>
                                message.labelIds.includes('UNREAD'),
                              )
                                ? '#edecf5'
                                : '#fff',
                            }}
                          >
                            <TableCell className="checkbox ">
                              <Checkbox
                                color="primary"
                                onClick={(e) => {
                                  handleSelectCheckBox(
                                    e,
                                    gmail.messages[0]?.threadId,
                                    gmail.messages[0]?.labelIds,
                                  );
                                }}
                                checked={selectedCheckboxes.includes(
                                  gmail.messages[0]?.threadId,
                                )}
                              />
                            </TableCell>
                            {mailType !== 'TRASH' && (
                              <TableCell className="checkbox ">
                                <IconButton
                                  title="Bookmark"
                                  onClick={() =>
                                    gmail.messages.some((message) =>
                                      message.labelIds.includes('STARRED'),
                                    )
                                      ? unBookmarkMultipleEmails(gmail.messages)
                                      : markLastEmailAsBookmarked(
                                          gmail.messages.at(-1).id,
                                        )
                                  }
                                >
                                  {gmail.messages.some((message) =>
                                    message.labelIds.includes('STARRED'),
                                  ) ? (
                                    <StarRateRounded />
                                  ) : (
                                    <StarBorderRoundedIcon />
                                  )}
                                </IconButton>
                              </TableCell>
                            )}
                            <TableCell
                              className={`long-text ${
                                gmail.messages[0]?.labelIds?.includes('UNREAD')
                                  ? 'bold'
                                  : ''
                              }`}
                              onClick={() =>
                                mailType === 'DRAFT'
                                  ? redirectToCompose(gmail.messages[0])
                                  //  viewDraftMailsInCompose(gmail.messages[0])
                                  :
                                  redirectToGmailView(gmail.messages[0].threadId, index) 
                                  // viewGmailContent(gmail.messages, index)
                              }
                            >
                              {mailType === 'SENT' ? (
                                <span>
                                  {
                                    gmail?.messages[0]?.payload.headers.find(
                                      (header: any) => header.name === 'To',
                                    )?.value
                                  }
                                </span>
                              ) : mailType !== 'DRAFT' ? (
                                renderSendersName(gmail)
                              ) : (
                                <span>
                                  {
                                    gmail?.messages[0]?.payload.headers.find(
                                      (header: any) => header.name === 'From',
                                    )?.value
                                  }
                                </span>
                              )}
                            </TableCell>

                            <TableCell
                              className="long-text"
                              style={{ maxWidth: 'unset' }}
                              onClick={() =>
                                mailType === 'DRAFT'
                                  ? 
                                  redirectToCompose(gmail.messages[0])
                                  // viewDraftMailsInCompose(gmail.messages[0])
                                  : 
                                  redirectToGmailView(gmail.messages[0].threadId, index)
                                  // viewGmailContent(gmail.messages, index)
                              }
                            >
                              <Stack
                                direction={'row'}
                                className="email-title"
                                spacing={1}
                              >
                                <Box
                                  className="long-text"
                                  style={{ maxWidth: '100%' }}
                                  onClick={() =>
                                    mailType === 'DRAFT'
                                      ? 
                                      redirectToCompose(gmail.messages[0])
                                      // viewDraftMailsInCompose(gmail.messages[0])
                                      : 
                                      redirectToGmailView(gmail.messages[0].threadId, index)
                                      // viewGmailContent(gmail.messages, index)
                                  }
                                >
                                  <span
                                    className={`${
                                      gmail.messages[0]?.labelIds.includes(
                                        'UNREAD',
                                      )
                                        ? 'bold'
                                        : ''
                                    }`}
                                  >
                                    {
                                      gmail.messages[0]?.payload.headers.find(
                                        (g: any) => g.name === 'Subject',
                                      ).value
                                    }{' '}
                                  </span>{' '}
                                  -{' '}
                                  {gmail.messages[gmail.messages.length - 1]
                                    ?.snippet.length > 80
                                    ? `${gmail.messages[
                                        gmail.messages.length - 1
                                      ]?.snippet.slice(0, 80)}...`
                                    : gmail.messages[gmail.messages.length - 1]
                                        ?.snippet}
                                </Box>
                                {renderAttachmentCount(gmail)}
                                {/* {gmail.messages[0]?.payload.mimeType ===
                            'multipart/mixed' &&
                            gmail.messages[0]?.payload.parts &&
                            Array.isArray(gmail.messages[0]?.payload.parts) &&
                            gmail.messages[0]?.payload?.parts?.length > 1 && (
                              <Badge
                                color="primary"
                                badgeContent={
                                  gmail.messages[0]?.payload?.parts?.length - 1
                                }
                              >
                                <AttachmentOutlinedIcon />
                              </Badge>
                            )
                          } */}
                              </Stack>
                            </TableCell>
                            <TableCell
                              className=""
                              style={{ minWidth: '90px', maxWidth: '90px' }}
                            >
                              {moment(
                                gmail.messages[0]?.payload.headers.find(
                                  (g: any) => g.name === 'Date',
                                ).value,
                              ).format('MMM, DD')}
                            </TableCell>
                            <TableCell
                              className="options"
                              style={{ minWidth: '50px', maxWidth: '50px' }}
                            >
                              {' '}
                              <div className="options-button">
                                <img alt="" src={MoreIcon} />{' '}
                              </div>
                              <div
                                className="options-list"
                                style={{ right: '10px' }}
                              >
                                {mailType !== 'TRASH' && (
                                  <div
                                    onClick={() =>
                                      handleArchiveEmail(
                                        gmail.messages[0]?.threadId,
                                      )
                                    }
                                  >
                                    Archive
                                  </div>
                                )}
                                <div
                                  onClick={() =>
                                    gmail.messages[0]?.labelIds.includes(
                                      'UNREAD',
                                    )
                                      ? handleMarkEmailAsRead(
                                          gmail.messages[0]?.threadId,
                                        )
                                      : handleMarkEmailAsUnread(
                                          gmail.messages[0]?.threadId,
                                        )
                                  }
                                >{`Mark as ${
                                  gmail.messages[0]?.labelIds.includes('UNREAD')
                                    ? 'read'
                                    : 'unread'
                                }`}</div>
                                <hr />
                                <div
                                  style={{ color: '#ff4170' }}
                                  onClick={() =>
                                    handleDeleteSingle(
                                      gmail.messages[0]?.threadId,
                                    )
                                  }
                                >
                                  Delete
                                </div>
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow style={{ cursor: 'pointer' }}>
                        <TableCell sx={{ textAlign: 'center' }} colSpan={5}>
                          No records found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {gmailData?.data?.length !== 0 && (
                <TableContainer>
                  <Table>
                    <TableFooter>
                      <TableRow>
                        <PaginationFunctional
                          startCount={startCount}
                          setStartCount={setStartCount}
                          endCount={endCount}
                          limit={limit}
                          setLimit={setLimit}
                          page_no={page_no}
                          setPage_no={setPage_no}
                          listItemsFn={getGmailData}
                          totalItemsCount={gmailData?.totalMailCount}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              )}
            </Box>
            <ConfirmationAlertPopUp
              openModal={openDeleteConfirmationModel}
              closeModalFunc={closeDeleteConfirmationModel}
              title={'Delete Confirmation!'}
              text={'Are you sure you want to delete it?'}
              confirmationButtonText={'Delete'}
              confirmationButtonColor="secondary"
              closeButtonText={'Cancel'}
              functionality={() => handleDeleteMultiple()}
            />
          </div>
        {isLoading && <Loader />}
    </EmailsLayout>
  );
}

export default EmailList;
