import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Actions } from 'ReduxStore/Actions';
import Loader from '../../Components/Loader/loader';
import { createNotification } from '../../helpers';
import { NotificationContainer } from 'react-notifications';
import { formatISO } from 'date-fns';
// import '../../sass/main.scss';
import Pagination from 'react-js-pagination';
import DashboardLayout from '../../Layouts/DashboardLayout';
import { Button, IconButton } from '@mui/material';
import ListViewOn from '../../assets/images/icons/List-view-on.svg';
import ListViewOff from '../../assets/images/icons/List-view-off.svg';
import GridViewOn from '../../assets/images/icons/grid-view-on.svg';
import GridViewOff from '../../assets/images/icons/grid-view-off.svg';
import SearchIconBar from '../../assets/images/searchIco.png';
import { redirectTo } from '../../helpers';
import moment from 'moment';
import { ExportToCsv } from 'export-to-csv';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import DataTable from 'react-data-table-component';
import { ThemeProvider } from '@mui/material';
import { theme } from '../../theme/theme';
import Grid from '@mui/material/Grid2';
import MoreIcon from '../../assets/images/icons/more.svg';
import LiveIcon from '../../assets/images/live-icon.svg';
import CalenderIcon from '../../assets/images/icons/calender.svg';
import TimeZoneIcon from '../../assets/images/icons/TimeZone_blue.svg';
import LinkIcon from '../../assets/images/icons/link.svg';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '../../assets/images/icons/search.svg';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { NightsStay } from '@mui/icons-material';
import { eventColorType, eventListColorType } from './config';
import Skeleton from '@mui/material/Skeleton';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import {
  generateUrl,
  getEventURL,
  getHostName,
  getProjectIdFromUrl,
} from '../../helpers/common';
import ConfirmationAlertPopUp from '../../Common/ConfirmationAlertPopUp';
import AddIcon from '@mui/icons-material/Add';
import tease from 'tease';
import SuccessAlertPopUp from 'Common/SuccessAlertPopUp';
import { Url } from 'constants/constants';
import axios from 'axios';
import NumberFormatter from 'Components/NumberFormatter';
import { useNavigate, useLocation, useParams, Link } from 'react-router-dom';
import { xpAccountIntegrationGmailGetGmailId } from 'ReduxStore/API';

const URL = process.env.REACT_APP_API_URL;


// Define the withRouter HOC in the same file
function withRouter(Component) {
  return function ComponentWithRouterProps(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    return <Component {...props} navigate={navigate} location={location} params={params} />;
  };
}

class EventList extends Component {
  constructor(props) {
    super(props);
    this.permData = {};
    const userData = localStorage.getItem('user_details');
    const user = JSON.parse(userData);
    this.user = user; 
    this.state = {
      isFilterVisible: false,
      isFilterImports: false,
      title: 'My Projects',
      pageTitle: 'My Projects',
      events: [],
      loading: false,
      page_no: '',
      searchText: '',
      filterText: '',
      pageView: 'grid',
      // allEvents: [],
      createProject: true,
      deleteProject: true,
      replicateProject: true,
      perms: user.permissions,
      activeTab: 'All Projects',
      itemCount: 10,
      startCount: 1,
      sort_by: '_id',
      sortOrder: 'desc',
      totalResults: 0,
      openDeleteModal: false,
      openReplicateModal: false,
      eventIdToDelete: '',
      eventIdToReplicate: '',
      openNoAccessModal: false,
      acceptSt: false,
      roleId: false,
      disabledOrDeleted: "",
      filterByEvent: [],
      eventFilterOptionsList: [
        'In-Person Project',
        'Hybrid Project',
        'Virtual Event',
        'Sale Enablement Project',
        'Registration',
        'Email-Campaign'
      ],
    };

  }

  findIfAllowedFromSuperadminRequest = () => {
    let userDetails = JSON.parse(localStorage.getItem('user_details')); 
    let servicePlanId = userDetails.servicePlanId;
    const projectId = getProjectIdFromUrl();
    let reqData = {
      servicePlanId,
      projectId,
    };
    this.props.findIfAllowedFromSuperadminOuterRequest(reqData);
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.events.success === true &&
      this.props.events.message !== prevProps.events.message
    ) {
      this.findIfAllowedFromSuperadminRequest();
      this.setState({
        loading: false,
        filterByEvent: this.props.events.filterByEvent[0]?.filterByEvent
      });
      this.updatePaginationDropDownState();
    }

    if (
      this.props.events.updateSuccess === true &&
      this.props.events.message !== prevProps.events.message
    ) {
      let st = prevState.loading ? this.setState({ loading: false }) : null;
      createNotification('success', this.props.events.message);
      this.listEvents();
    }

    if (
      this.props.events.error &&
      !prevProps.events.error &&
      this.props.events.message !== prevProps.events.message
    ) {
      createNotification('error', this.props.events.message, '');
      this.setState({
        openDeleteModal: false,
        openReplicateModal: false,
        loading: false
      });
    }

    if (
      this.props.events.deleteProjectSuccess &&
      !prevProps.events.deleteProjectSuccess
    ) {
      this.setState(
        {
          openDeleteModal: false,
          loading: false
        },
        () => {
          this.listEvents();
          createNotification('success', this.props.events.message);
        },
      );
    }

    if (
      this.props.events.replicateProjectSuccess &&
      !prevProps.events.replicateProjectSuccess
    ) {
      this.setState(
        {
          openReplicateModal: false,
          loading: false
        },
        () => {
          this.listEvents();
          createNotification('success', this.props.events.message);
        },
      );
    }
  }

  updatePaginationDropDownState() {
    if (this.state.itemCount >= this.props.events.totalResults) {
      this.setState({
        endCount: this.props.events.totalResults,
      });
    } else {
      this.setState({
        endCount:
          this.props.events.totalResults >
            this.state.itemCount * this.state.page_no
            ? this.state.itemCount * this.state.page_no
            : this.props.events.totalResults,
      });
    }
  }

  handleLoading = () => {
    let loading = this.state.loading;
    this.setState({
      loading: !loading,
    });
  };

  /* updated function according to react-router-dome v6.27.o */
  projectPage = (data) => {
    const { navigate, location } = this.props; // Get navigate from props (via withRouter) 
    navigate(generateUrl(`/project-dashboard/overview/${data}`), {
      state: {
        page_no: this.state.page_no,
        pageView: this.state.pageView,
        previousPath: location.pathname // Keep track of the previous path if needed
      },
    });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  findIfMemberIsActive = async () => {
    try {

      const userData = localStorage.getItem('user_details');
      const user = JSON.parse(userData);

      const res = await axios.get(`${URL}/getUserAcceptStById?userId=${user._id}`, {
        headers: {
          token: `bearer ${user.token}`,
          'Content-Type': 'application/json',
        },
      });
      if (res.data.status === 200) {
        if (res.data.data.isDeleted) {
          this.setState({
            openNoAccessModal: true,
            disabledOrDeleted: 'deleted'
          });
        } else if (res.data.data.role !== 1 && res.data.data.acceptSt !== 2) {  // if user not Active (not for admin account)
          this.setState({
            openNoAccessModal: true,
            disabledOrDeleted: 'disabled'
          });
        }
        else if (res.data.data.role === 1 || res.data.data.acceptSt === 2) {  // Admin and Active
          this.listEvents();
        } else {
          this.setState({
            openNoAccessModal: true,
            disabledOrDeleted: 'disabled'
          });
        }
      }

    } catch (err) {
      return err;
    }
  };

  componentDidMount() {
    this.findIfMemberIsActive();
    this.checkAndGetGmailIntegrationData();
    if (this.props.location.query) {
      const queryData = this.props.location.query.pageData;
      this.setState({ page_no: queryData });
    } else {
      this.setState({ page_no: 1 });
    }
    if (this.props.location.query) {
      const data = this.props.location.query.prevPageView
        ? this.props.location.query.prevPageView
        : 'grid';
      this.setState({ pageView: data });
    }

    this.permData = this.getPreparePermission(this.state.perms);
    let createProject = false;
    let deleteProject = false;
    let replicateProject = false;


    if (this.permData) {
      Object.keys(this.permData).forEach((data) => {
        if (['create project'].indexOf(data.toLowerCase()) !== -1) {
          createProject = true;
        }
        if (['delete project'].indexOf(data.toLowerCase()) !== -1) {
          deleteProject = true;
        }
        if (['replicate project'].indexOf(data.toLowerCase()) !== -1) {
          replicateProject = true;
        }
      }); 
       
      if (this.user.role == 1) {
        this.setState({ createProject: true, deleteProject: true, replicateProject: true });
      } else { 
        this.setState({ createProject, deleteProject, replicateProject });
      }  
    }
  }

  checkAndGetGmailIntegrationData = async () => {
    try {
      const response = await xpAccountIntegrationGmailGetGmailId();
      if (response?.data?.success) {
        this.props.setGmailConnectionState(true, response.data.details);
      } else {
        this.props.setGmailConnectionState(false, {});
      }
    } catch (error) {
      console.log(error);
      this.props.setGmailConnectionState(false, {});
      createNotification("error", error.message);
    }
  };

  listEvents = () => {
    const { searchText, sort_by, sortOrder, filterBy, page_no, itemCount } =
      this.state;
    const requestbody = {
      searchText: searchText.trim(),
      limit: itemCount,
      sort_by: sort_by,
      order: sortOrder,
      projectType: filterBy,
      page_no: page_no
        ? page_no || 1
        : this.props.location.query
          ? this.props.location.query.pageData
          : 1,
    };
    this.props.eventsRequest(requestbody);
    this.handleLoading();
  };

  getPreparePermission(state) {
    let perms = {};
    if (Array.isArray(state)) {
      state.forEach((data) => {
        perms[data.permission_name] = true;
      });
    }
    return perms;
  }

  deleteEvent = (id) => {
    this.props.updateRequest({ id: id, isDeleted: true });
    this.handleLoading();
  };

  handlePageChange = (pageNumber) => {
    const { itemCount, totalResults } = this.state;
    this.setState(
      (prevState, props) => ({
        startCount: pageNumber !== 1 ? itemCount * (pageNumber - 1) + 1 : 1,
        endCount:
          totalResults > itemCount * pageNumber
            ? itemCount * pageNumber
            : totalResults,
        page_no: pageNumber,
        totalResults: this.props.events.totalResults,
      }),
      () => {
        this.listEvents();
      },
    );
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ page_no: 1, startCount: 1 }, () => this.listEvents(true));
  };

  sorting = (value) => {
    if (this.state.sort_by === value) {
      if (this.state.sortOrder === 'asc') {
        this.setState(
          {
            sortOrder: 'desc',
          },
          () => this.listEvents(),
        );
      } else {
        this.setState(
          {
            sortOrder: 'asc',
          },
          () => this.listEvents(),
        );
      }
    } else {
      this.setState(
        {
          sort_by: value,
          sortOrder: 'asc',
        },
        () => this.listEvents(),
      );
    }
  };
  hanndleFilter(data) {
    this.setState(
      {
        filterBy: data !== undefined ? data.id : '',
        page_no: 1,
        limit: this.state.itemCount,
      },
      () => this.listEvents(),
    );
  }

  ChangeView = (pageView) => {
    this.setState({ pageView: pageView ? pageView : 'grid' });
  };

  selectItemCount = (e) => {
    const { totalResults } = this.state;
    this.setState(
      {
        page_no: 1,
        itemCount: e.target.value,
        endCount: parseInt(totalResults),
        startCount: 1,
      },
      () => this.listEvents(),
    );
  };

  openDeleteModal = (eventIdToDelete) => {
    this.setState({
      openDeleteModal: true,
      eventIdToDelete: eventIdToDelete,
    });
  };
  openReplicateModal = (eventIdToReplicate) => {
    this.setState({
      openReplicateModal: true,
      eventIdToReplicate: eventIdToReplicate,
    });
  };

  closeDeleteModal = () => {
    this.setState({
      openDeleteModal: false,
      eventIdToDelete: '',
    });
  };

  closeReplicateModal = () => {
    this.setState({
      openReplicateModal: false,
      eventIdToReplicate: '',
    });
  };

  deleteProject = () => {
    this.props.deleteProject({ projectId: this.state.eventIdToDelete, lngCode: this.props.users.newLangState });
    this.handleLoading();
  };

  replicateProject = () => {
    this.props.replicateProject({ projectId: this.state.eventIdToReplicate, lngCode: this.props.users.newLangState });
    this.handleLoading();
  };

  openCreateProject = () => {
    if (this.props?.servicePlan?.projectLimitExceeded) {
      this.setState({
        showNotAllowedPopUp: true,
      });
    } else {
      this.props.navigate(generateUrl('/create-project'));
    }
  };

  closeNotAllowedModal = () => {
    this.setState({
      showNotAllowedPopUp: false,
    });
  };

  closeNoAccessModal = () => {
    this.setState({
      openNoAccessModal: false,
      disabledOrDeleted: ""
    });
    this.redirectToLoginPage();
  };

  redirectToLoginPage = () => {
    localStorage.clear();
    const wixUrl = Url.wixUrl;
    window.location.href = wixUrl;
  };

  capitalizeFirstLetter = (string) => {
    return string?.charAt(0).toUpperCase() + string?.slice(1);
  };

  optionsList = (val) => {
    let temp;
    switch (val) {
      case 'Sale Enablement Project': temp = this.props.users.languageStateForRedux?.projectTypes?.sales;
        return temp;

      case 'Sale enablement project': temp = this.props.users.languageStateForRedux?.projectTypes?.sales;
        return temp;

      case 'Registration': temp = this.props.users.languageStateForRedux?.projectTypes?.registration;
        return temp;

      case 'Hybrid Project': temp = this.props.users.languageStateForRedux?.projectTypes?.hybrid;
        return temp;

      case 'Email-Campaign': temp = this.props.users.languageStateForRedux?.projectTypes?.email;
        return temp;

      case 'In-Person Project': temp = this.props.users.languageStateForRedux?.projectTypes?.inPerson;
        return temp;

      case 'Virtual Event': temp = this.props.users.languageStateForRedux?.projectTypes?.virtual;
        return temp;

      default: temp = val;
        break;
    }
  };

  render() {
    let columns = [
      {
        name: 'Date',
        selector: 'Date',
        sortable: true,
      },
      {
        name: 'Name',
        selector: 'Name',
        sortable: true,
      },
      {
        name: 'Project Type',
        selector: 'Project Type',
        sortable: true,
      },
      {
        name: 'Project URL',
        selector: 'Project URL',
        sortable: true,
      },
      {
        name: 'Location',
        selector: 'Location',
        sortable: true,
      },
      {
        name: 'Contacts',
        selector: 'Contacts',
        sortable: true,
      },
      {
        name: 'Conversion Rate',
        selector: 'Conversion Rate',
        sortable: true,
      },
      {
        name: 'Attendies',
        selector: 'Attendies',
      },
      {
        name: 'Checked-In Attendies',
        selector: 'Checked-In Attendies',
      },
      {
        name: '',
        selector: '',
      },
    ];
    let tableData = [];
    const { isFilterVisible } = this.state;
    const {
      isFilterImports,
      title,
      pageTitle,
      loading,
      // totalResults,
      page_no,
      searchText,
      pageView,
      createProject,
      deleteProject,
      replicateProject,
      activeTab,
      sort_by,
      sortOrder,
      startCount,
      endCount,
      itemCount,
      disabledOrDeleted,
      filterByEvent,
      eventFilterOptionsList
    } = this.state;

    return (
      <ThemeProvider theme={theme}>
        <DashboardLayout title={title} pageTitle={pageTitle}>
          <Box pb={1} className="header-common">
            <h1 className="heading"> {this.props.users.languageStateForRedux?.eventList?.heading}</h1>

            {createProject && (
              <>
                <Box
                  display={{ xs: 'none', sm: 'block' }}
                  component={Button}
                  variant="contained"
                  onClick={this.openCreateProject}
                  sx={{
                    backgroundColor: '#5141E7 !important',
                    color: '#ffffff !important',
                  }}
                  disableElevation
                >
                  {this.props.users.languageStateForRedux?.eventList?.button}
                </Box>
                <Box
                  display={{ xs: 'block', sm: 'none' }}
                  component={IconButton}
                  size="small"
                  variant="contained"
                  href="/create-project"
                  sx={{
                    backgroundColor: '#5141E7 !important',
                    color: '#ffffff !important',
                  }}
                  disableElevation
                  title="Add New Project"
                >
                  <AddIcon />
                </Box>
              </>
            )}
          </Box>
          <Box className="dashboardMiddleArea">
            <>
              <div className="filterSearchBar">
                <div className="filterControler buttonGroups">
                  <div className="project-main-tabs">
                    <List>
                      <ListItem
                        className={activeTab == 'All Projects' ? 'active' : ''}
                        onClick={() => {
                          this.hanndleFilter();
                          this.setState({ activeTab: 'All Projects' });
                        }}
                      >
                        <ListItemText>
                          {' '}
                          {this.props.users.languageStateForRedux?.eventList?.allProjects} (
                          {this.props.events && this.props.events.filterByEvent && this.props.events.filterByEvent[0] ? this.props.events.filterByEvent[0].allEvent : '0'}
                          ){' '}
                        </ListItemText>
                      </ListItem>
                      {this.props.events &&
                        this.props.events.filterByEvent &&
                        this.state.eventFilterOptionsList?.map((type, index) => {
                          const item = filterByEvent?.find(item => item.type === type);
                          if (item) {
                            return (
                              <ListItem
                                className={activeTab === type ? 'active' : ''}
                                key={'projectTypes' + index}
                                onClick={() => {
                                  this.hanndleFilter(item);
                                  this.setState({ activeTab: item.type });
                                }}

                              >
                                <ListItemText>
                                  {this.optionsList(item.type)} ({item.count})
                                </ListItemText>
                              </ListItem>
                            );
                          }
                          return null;
                        })}
                    </List>
                  </div>
                </div>
                <div className="filterControler SearchGrid">
                  <div className="eventControlSearch mr-2">
                    <div class="filterSerchSubmit">
                      <form onSubmit={this.handleSubmit}>
                        <TextField
                          name="searchText"
                          variant='standard'
                          placeholder={this.props.users.languageStateForRedux?.common?.search}
                          className="search"
                          size="small"
                          value={searchText}
                          onChange={this.handleChange}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <img alt="" src={SearchIcon} />
                              </InputAdornment>
                            ),
                          }}
                        />
                        <button className="d-none" type="submit">
                          <img src={SearchIconBar} alt="" />
                        </button>
                      </form>
                    </div>
                  </div>

                  <div className="eventControlGrid mr-2 mobile-None">
                    <button
                      className={
                        pageView === 'grid' ||
                          pageView === '' ||
                          pageView === undefined
                          ? 'eventControlBtn active'
                          : 'eventControlBtn'
                      }
                      onClick={() => this.ChangeView('grid')}
                    >
                      {pageView === 'grid' ? (
                        <img src={GridViewOn} alt="" className="" />
                      ) : (
                        <img src={GridViewOff} alt="" className="" />
                      )}
                    </button>
                  </div>
                  <div className="eventControlList mr-2 mobile-None">
                    <button
                      className={
                        pageView === 'list'
                          ? 'eventControlBtn active'
                          : 'eventControlBtn'
                      }
                      onClick={() => this.ChangeView('list')}
                    >
                      {pageView === 'list' ? (
                        <img src={ListViewOn} alt="" className="" />
                      ) : (
                        <img src={ListViewOff} alt="" className="" />
                      )}
                    </button>
                  </div>
                  <div className="eventControlSearch mr-2"></div>
                </div>
              </div>

              {this.props.events.event &&
                this.props.events.event.length == 0 ? (
                <Box component={'div'} px={0} mb={4} mt={10}>
                  <Grid container spacing={3}>
                    <Grid size={{ xs: 12, }}>
                      <Box className="heading" textAlign={'center'}>
                        {' '}
                        {this.props.users.languageStateForRedux?.common?.noRecords}{' '}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              ) : pageView === 'grid' ? (
                <>
                  <div className="myEventTable eventGridBoxes">
                    <Grid
                      container
                      spacing={3}
                      className="project-block-listing"
                    >
                      {this.props.events.event &&
                        Array.isArray(this.props.events.event) &&
                        this.props.events.event.map((event, index) => {
                          return (
                            <Grid size={{ xs: 12, lg: 6, xl: 4 }} >
                              <div className="project-block">
                                <div className="live-padding">
                                  <div className="live-outer">
                                    <div className="live-outer-left">
                                      <span
                                        className={
                                          eventColorType[event.projectTypeId]
                                        }
                                      >
                                        {' '}
                                        {event?.eventType ?
                                          this.optionsList(event.eventType)
                                            ?.toUpperCase()
                                            ?.replace('PROJECT', '')
                                          : null}{' '}
                                      </span>

                                      {event.isPublished == true && (
                                        <p>
                                          {' '}
                                          <img
                                            alt="LinkIcon"
                                            src={LiveIcon}
                                          />{' '}
                                          {this.props.users.languageStateForRedux?.eventList?.liveNow} {' '}
                                        </p>
                                      )}
                                    </div>

                                    <div className="live-outer-right options">
                                      <MoreHorizIcon />
                                      <div className="options-list">
                                        {deleteProject && (
                                          <div
                                            onClick={() =>
                                              this.openDeleteModal(event._id)
                                            }
                                            style={{
                                              color:
                                                theme.palette.secondary.main,
                                            }}
                                          >
                                            {this.props.users.languageStateForRedux?.common?.delete}
                                          </div>
                                        )}
                                        <div
                                          onClick={() =>
                                            this.openReplicateModal(event._id)
                                          }
                                        >
                                          {this.props.users.languageStateForRedux?.common?.replicate}
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    className="live-heading"
                                    onClick={() => this.projectPage(event._id)}
                                  >
                                    <h2 className="secondary">
                                      {event.eventName}
                                    </h2>
                                  </div>

                                  <div className="live-link long-url  ">
                                    <img alt="LinkIcon" src={LinkIcon} />
                                    <a
                                      className="primary-text"
                                      href={getEventURL(
                                        event.eventDomain,
                                        event.vanityDomain,
                                        event.isVanityDomainActive,
                                        event.isVanityDomainVerified,
                                      )}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {getEventURL(
                                        event.eventDomain,
                                        event.vanityDomain,
                                        event.isVanityDomainActive,
                                        event.isVanityDomainVerified,
                                      )}
                                    </a>
                                  </div>
                                  <div className="live-link">
                                    <img
                                      alt="CalenderIcon"
                                      src={CalenderIcon}
                                    />
                                    <p className="primary-text">
                                      {moment
                                        .tz(event.startDate, event.timezone)
                                        .format('MMMM Do YYYY, h:mm:ss a')}
                                    </p>
                                  </div>
                                  <div className="live-link">
                                    <img
                                      alt="TimeZoneIcon"
                                      src={TimeZoneIcon}
                                    />
                                    <span>
                                      {' '}
                                      {tease.dst(event?.timezone) !== undefined
                                        ? `(UTC${tease.dst(event?.timezone)})`
                                        : ''}{' '}
                                      {event?.timezone} (
                                      {moment.tz(event?.timezone).format('z')})
                                    </span>
                                  </div>
                                  <div className="live-location">
                                    <span className="primary-text">
                                      {event.agendaCount
                                        ? event.agendaCount
                                        : 0}{' '}
                                      {this.props.users.languageStateForRedux?.settingsSidebar?.yourPlan?.sessions} {' '}
                                    </span>
                                    {event.location && (
                                      <>
                                        <FiberManualRecordIcon />
                                        <span
                                          className="location"
                                          title={
                                            event.location ? event.location : ''
                                          }
                                        >
                                          {' '}
                                          {this.props.users.languageStateForRedux?.overviewTab?.loc}{' '}
                                          {event.location
                                            ? event.location
                                            : ''}{' '}
                                        </span>
                                      </>
                                    )}
                                  </div>
                                </div>

                                <div className="live-records">
                                  <List>
                                    <ListItem>
                                      <ListItemText>
                                        <h2 className="secondary orange-color">
                                          {' '}
                                          {event.campaignContactsCount}{' '}
                                        </h2>
                                        <p>
                                          {this.props.users.languageStateForRedux?.eventList?.contacts} ({event.campaignsCount}{' '}
                                          {this.props.users.languageStateForRedux?.overviewTab?.campaignsCard})
                                        </p>
                                      </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                      <ListItemText>
                                        <h2 className="secondary blue-color">
                                          {' '}
                                          {Number.isInteger(
                                            event.conversionRate,
                                          )
                                            ? event.conversionRate
                                            : event.conversionRate.toFixed(2)}
                                          %{' '}
                                        </h2>
                                        <p> {this.props.users.languageStateForRedux?.overviewTab?.convRate} </p>
                                      </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                      <ListItemText>
                                        <h2 className="secondary purple-color">
                                          <NumberFormatter number={event.attendeesCount} />
                                        </h2>
                                        <p> {this.props.users.languageStateForRedux?.overviewTab?.attendees}</p>
                                      </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                      <ListItemText>
                                        <h2 className="secondary green-color">
                                          <NumberFormatter number={event.checkInAttendeesCount} />
                                        </h2>
                                        <p> {this.props.users.languageStateForRedux?.overviewTab?.checkAttendee} </p>
                                      </ListItemText>
                                    </ListItem>
                                  </List>
                                </div>
                                {event?.eventTags?.length > 0 &&
                                  <div className="live-tags">
                                    <div className="project-main-tabs">
                                      <List>
                                        {event.eventTags &&
                                          Array.isArray(event.eventTags) &&
                                          event.eventTags.map((tag, k) => {
                                            return (
                                              <ListItem>
                                                <ListItemText>
                                                  {' '}
                                                  #{tag}
                                                </ListItemText>
                                              </ListItem>
                                            );
                                          })}
                                      </List>
                                    </div>
                                  </div>
                                }
                              </div>
                            </Grid>
                          );
                        })}
                    </Grid>
                  </div>
                </>
              ) : (
                <>
                  <div className="myEventTable eventListBoxes">
                    <div
                      className="eventTableInner table-responsive eventOnlyList list-table"
                      style={{
                        height: 'calc(100vh - 340px)',
                        paddingBottom: 0,
                      }}
                    >
                      {!pageView == 'list' && (
                        <DataTable columns={columns} data={[1]} />
                      )}
                      <table className="table">
                        <thead
                          style={{
                            position: 'sticky',
                            top: 0,
                            backgroundColor: '#fff',
                            zIndex: '9',
                          }}
                        >
                          <tr style={{ borderBottom: '1px solid #EDECF5' }}>
                            <td className={"project-info " + (sort_by === 'eventNameForSorting' ? sortOrder === 'asc' ? 'sort-asc' : 'sort-des' : '')} onClick={() => this.sorting("eventNameForSorting")}>{this.props.users.languageStateForRedux?.eventList?.projectInfo}</td>
                            <td className={"project-type " + (sort_by === 'projectTypeId' ? sortOrder === 'asc' ? 'sort-asc' : 'sort-des' : '')} onClick={() => this.sorting("projectTypeId")}>{this.props.users.languageStateForRedux?.eventList?.projectType}</td>
                            <td className={"project-url " + (sort_by === 'eventDomain' ? sortOrder === 'asc' ? 'sort-asc' : 'sort-des' : '')} onClick={() => this.sorting("eventDomain")}>{this.props.users.languageStateForRedux?.eventList?.projectUrl}</td>
                            <td className={"project-number " + (sort_by === 'campaignContactsCount' ? sortOrder === 'asc' ? 'sort-asc' : 'sort-des' : '')} onClick={() => this.sorting("campaignContactsCount")}>{this.props.users.languageStateForRedux?.eventList?.contacts}</td>
                            <td className={"project-number " + (sort_by === 'conversionRate' ? sortOrder === 'asc' ? 'sort-asc' : 'sort-des' : '')} onClick={() => this.sorting("conversionRate")}>{this.props.users.languageStateForRedux?.overviewTab?.convRate}</td>
                            <td className={"project-number " + (sort_by === 'attendeesCount' ? sortOrder === 'asc' ? 'sort-asc' : 'sort-des' : '')} onClick={() => this.sorting("attendeesCount")}>{this.props.users.languageStateForRedux?.overviewTab?.attendees}</td>
                            <td className={"project-number " + (sort_by === 'checkInAttendeesCount' ? sortOrder === 'asc' ? 'sort-asc' : 'sort-des' : '')} onClick={() => this.sorting("checkInAttendeesCount")}>
                              {this.props.users.languageStateForRedux?.overviewTab?.checkAttendee}
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {this.props.events.event &&
                            Array.isArray(this.props.events.event) &&
                            this.props.events.event.map((event, index) => {
                              let show = false;
                              const startDate1 = moment(event.startDate).format(
                                'MM/DD/YYYY',
                              );
                              const startD = startDate1 + ' ' + event.time;
                              tableData.push(event);
                              return (
                                <tr
                                  key={index}
                                  class={eventColorType[event.projectTypeId]}
                                >
                                  <td className="project-info">
                                    <div className="list-date-outer">
                                      <div className="list-date">
                                        <h2 className="date subtitle">
                                          {' '}
                                          {moment
                                            .tz(event.startDate, event.timezone)
                                            .format('MMM D')}
                                        </h2>
                                        <p className="time">
                                          {moment
                                            .tz(event.startDate, event.timezone)
                                            .format('LT')}{' '}
                                        </p>
                                      </div>
                                      <div className="list-location">
                                        {event.isPublished == true && (
                                          <div>
                                            {' '}
                                            <img
                                              style={{
                                                height: '22px',
                                                width: '22px',
                                              }}
                                              alt="LinkIcon"
                                              src={LiveIcon}
                                            />{' '}
                                            Live Now{' '}
                                          </div>
                                        )}

                                        <span
                                          className="eventName paragraph"
                                          onClick={() =>
                                            this.projectPage(event._id)
                                          }
                                        >
                                          {event.eventName.trim()}
                                        </span>
                                        <div className="live-link">
                                          <span>
                                            {' '}
                                            {tease.dst(event?.timezone) !==
                                              undefined
                                              ? `(UTC${tease.dst(
                                                event?.timezone,
                                              )})`
                                              : ''}{' '}
                                            {event?.timezone} (
                                            {moment
                                              .tz(event?.timezone)
                                              .format('z')}
                                            )
                                          </span>
                                        </div>
                                        <div className="live-location">
                                          <span className="primary-text">
                                            {' '}
                                            {event.agendaCount
                                              ? event.agendaCount
                                              : 0}{' '}
                                            {this.props.users.languageStateForRedux?.settingsSidebar?.yourPlan?.sessions} {' '}
                                          </span>
                                          {event.location && (
                                            <>
                                              <FiberManualRecordIcon />
                                              <span>
                                                {' '}
                                                Location: {event.location}{' '}
                                              </span>
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td className="project-type">
                                    {event.projectTypeId &&
                                      event.projectTypeId.length > 0 && (
                                        <span
                                          className="Tlabel"
                                          style={{
                                            backgroundColor:
                                              eventListColorType[
                                              event.projectTypeId
                                              ],
                                          }}
                                        >
                                          {event.eventType
                                            ? this.optionsList(event.eventType)
                                              ?.toUpperCase()
                                              ?.replace('PROJECT', '')
                                            : null}
                                        </span>
                                      )}
                                  </td>
                                  <td className="project-url">
                                    {' '}
                                    <a
                                      className="domainLinkurl"
                                      href={getEventURL(
                                        event.eventDomain,
                                        event.vanityDomain,
                                        event.isVanityDomainActive,
                                        event.isVanityDomainVerified,
                                      )}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {getEventURL(
                                        event.eventDomain,
                                        event.vanityDomain,
                                        event.isVanityDomainActive,
                                        event.isVanityDomainVerified,
                                      )}
                                    </a>
                                  </td>

                                  <td className="project-number">
                                    <h2 className="secondary orange-color">
                                      {event.campaignContactsCount}
                                    </h2>
                                  </td>

                                  <td className="project-number">
                                    <h2 className="secondary blue-color">
                                      {Number.isInteger(event.conversionRate)
                                        ? event.conversionRate
                                        : event.conversionRate.toFixed(2)}
                                      %
                                    </h2>
                                  </td>

                                  <td className="project-number">
                                    <h2 className="secondary purple-color">
                                      <NumberFormatter number={event.attendeesCount} />
                                    </h2>
                                  </td>

                                  <td className="project-number">
                                    <h2 className="secondary green-color">
                                      <NumberFormatter number={event.checkInAttendeesCount} />
                                    </h2>
                                  </td>

                                  {deleteProject && (
                                    <td
                                      className={`tableDotMore options ${show ? 'Open' : ''
                                        }`}
                                    >
                                      {/* <div className="options"> */}
                                      <img src={MoreIcon} />
                                      <div className="options-list">
                                        {deleteProject && (
                                          <div
                                            onClick={() =>
                                              this.openDeleteModal(event._id)
                                            }
                                            style={{
                                              color:
                                                theme.palette.secondary.main,
                                            }}
                                          >
                                            {this.props.users.languageStateForRedux?.common?.delete}
                                          </div>
                                        )}
                                        <div
                                          onClick={() =>
                                            this.openReplicateModal(event._id)
                                          }
                                        >
                                          {this.props.users.languageStateForRedux?.common?.replicate}
                                        </div>
                                      </div>
                                      {/* </div> */}
                                    </td>
                                  )}

                                  <td className="grid-show">
                                    <div className="live-records">
                                      <List>
                                        <ListItem>
                                          <ListItemText>
                                            <h2 className="secondary orange-color">
                                              {' '}
                                              ---{' '}
                                            </h2>
                                            <p> Contacts (2 Campaigns) </p>
                                          </ListItemText>
                                        </ListItem>
                                        <ListItem>
                                          <ListItemText>
                                            <h2 className="secondary blue-color">
                                              {' '}
                                              --{' '}
                                            </h2>
                                            <p> Conversion Rate </p>
                                          </ListItemText>
                                        </ListItem>
                                        <ListItem>
                                          <ListItemText>
                                            <h2 className="secondary purple-color">
                                              {' '}
                                              ---{' '}
                                            </h2>
                                            <p> Attendees </p>
                                          </ListItemText>
                                        </ListItem>
                                        <ListItem>
                                          <ListItemText>
                                            <h2 className="secondary green-color">
                                              {' '}
                                              ---{' '}
                                            </h2>
                                            <p> Checked-in Attendees </p>
                                          </ListItemText>
                                        </ListItem>
                                      </List>
                                    </div>
                                  </td>
                                  <td className="grid-show">
                                    <div className="live-tags">
                                      <div className="project-main-tabs">
                                        <List>
                                          <ListItem>
                                            <ListItemText>
                                              {' '}
                                              #Marketing{' '}
                                            </ListItemText>
                                          </ListItem>
                                          <ListItem>
                                            <ListItemText>
                                              {' '}
                                              #Launch{' '}
                                            </ListItemText>
                                          </ListItem>
                                        </List>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </>
              )}

              <Box component={"div"} mb={8} className="tablePagination">
                {this.props.events.event &&
                  this.props.events.event.length > 0 ? (
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    className="tablePagination"
                  >
                    <Grid >
                      <Box component="span" className="small-subtitle">
                        {this.props.users.languageStateForRedux?.pagination?.showing} {startCount} {this.props.users.languageStateForRedux?.pagination?.to}  {endCount} {this.props.users.languageStateForRedux?.pagination?.of} {' '}
                        {this.props.events.totalResults}
                      </Box>
                    </Grid>
                    <Grid >
                      <Grid container alignItems="center">
                        <Grid >
                          <span className="small-subtitle">{this.props.users.languageStateForRedux?.pagination?.shows}</span>
                          <select
                            value={itemCount}
                            onChange={this.selectItemCount}
                          >
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                          </select>
                          <span className="small-subtitle">{this.props.users.languageStateForRedux?.pagination?.entries}</span>
                        </Grid>
                        <Grid >
                          <Pagination
                            activePage={page_no}
                            itemsCountPerPage={itemCount}
                            totalItemsCount={this.props.events.totalResults}
                            pageRangeDisplayed={5}
                            onChange={this.handlePageChange}
                            prevPageText={
                              <NavigateBeforeIcon style={{ fontSize: 18 }} />
                            }
                            nextPageText={
                              <NavigateNextIcon style={{ fontSize: 18 }} />
                            }
                            itemClassFirst="first d-none"
                            itemClassLast="last d-none"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : null}
              </Box>
            </>
            <ConfirmationAlertPopUp
              openModal={this.state.openDeleteModal}
              closeModalFunc={this.closeDeleteModal}
              title={this.props.users.languageStateForRedux?.common?.confirmDelete}
              text={this.props.users.languageStateForRedux?.common?.deleteMsg}
              confirmationButtonText={this.props.users.languageStateForRedux?.common?.delete}
              confirmationButtonColor="secondary"
              closeButtonText={this.props.users.languageStateForRedux?.common?.cancel}
              functionality={this.deleteProject}
            />

            <ConfirmationAlertPopUp
              openModal={this.state.openReplicateModal}
              closeModalFunc={this.closeReplicateModal}
              title={this.props.users.languageStateForRedux?.common?.confirmReplicate}
              text={this.props.users.languageStateForRedux?.eventList?.replicateMsg}
              confirmationButtonText={this.props.users.languageStateForRedux?.common?.replicate}
              confirmationButtonColor="primary"
              closeButtonText={this.props.users.languageStateForRedux?.common?.cancel}
              functionality={this.replicateProject}
            />

            <ConfirmationAlertPopUp
              openModal={this.state.showNotAllowedPopUp}
              closeModalFunc={this.closeNotAllowedModal}
              title="Upgrade Plan"
              text={`Your current pricing plan does not allow to perform this operation, please contact support team.`}
              confirmationButtonText="Upgrade"
              confirmationButtonColor="primary"
              closeButtonText="Cancel"
              functionality={() => {
                window.open('https://www.illumeetxp.com/upgrade', '_blank');
              }}
              showFunctionButton={false}
            />
          </Box>
          <NotificationContainer />
          {loading || this.props.users.isLoading ? <Loader /> : null}
        </DashboardLayout>
        <SuccessAlertPopUp
          openModal={this.state.openNoAccessModal}
          closeModalFunc={this.closeNoAccessModal}
          title={`Account ${this.capitalizeFirstLetter(disabledOrDeleted)}`}
          description={`<span style="font-weight: 500; font-family: 'Roboto', sans-serif;color: #000056">Your account has been ${disabledOrDeleted}, please contact your account administrator.</span>`}
          buttonColor="primary"
          buttonFunc={this.redirectToLoginPage}
          buttonText="Logout"
          notSuccess={true}
        />
      </ThemeProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  events: state.Events,
  project: state.Projects,
  servicePlan: state.ServicePlan,
  users: state.Users,
});

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      eventsRequest: Actions.listEventsRequest,
      updateRequest: Actions.updateEventsRequest,
      searchRequest: Actions.searchEventsRequest,
      csvRequest: Actions.csvEventsRequest,
      deleteProject: Actions.deleteProjectRequest,
      replicateProject: Actions.replicateProjectRequest,
      findIfAllowedFromSuperadminOuterRequest:
        Actions.findIfAllowedFromSuperadminOuterRequest,
      setGmailConnectionState: Actions.setGmailConnectionState
    },
    dispatch,
  );
export default connect(mapStateToProps, mapActionsToProps)(withRouter(EventList));
