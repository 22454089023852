import { Stack, Box } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ErrorBoundary from 'eijent/components/ErrorBoundary';

type Message = {
  id: string;
  labelIds: string[];
  payload: {
    body: any;
    filename: string;
    headers: { name: string; value: string }[];
    mimeType: string;
    partId: string;
    parts: any[];
  };
  sizeEstimate: number;
  snippet: string;
  threadId: string;
  draftId?: string;
};

export default function EmailBody({ mail }: { mail: Message }) {
  const [showMore, setShowMore] = useState(false);
  
  const toggleShowMore = () => setShowMore(!showMore);

  const EmailContent = ({ getHtmlOfContent }) => {
    const shadowRef = useRef(null);

    useEffect(() => {
      if (!shadowRef.current) return;

      // Attach Shadow DOM
      const shadowRoot = shadowRef.current.attachShadow({ mode: 'open' });

      // Create a container for the email content
      const contentContainer = document.createElement('div');
      contentContainer.style.all = 'initial'; // Reset styles to avoid conflicts

      // Insert the email content (use getHtmlOfContent to fetch your email HTML)
      contentContainer.innerHTML = getHtmlOfContent() || '';

      // Append the container to the Shadow DOM
      shadowRoot.appendChild(contentContainer);

      // Clean up if necessary when the component unmounts
      return () => {
        shadowRoot.innerHTML = '';
      };
    }, [getHtmlOfContent]);

    return (
      // Ref to hold the Shadow DOM root
      <div
        ref={shadowRef}
        style={{ width: '100%', height: 'auto', border: 'none' }}
      />
    );
  };

  const splitHtmlAtGmailQuote = (htmlString) => {
    if (htmlString) {
      // Regular expression to find the first occurrence of a div with the class "gmail_quote"
      const regex = /<div[^>]*class=["'][^"']*gmail_quote[^"']*["'][^>]*>/;

      // Find the position of the first match
      const match = htmlString?.match(regex);

      if (match && match.index !== undefined) {
        // Get the starting index of the matched <div> tag
        const splitIndex = match.index;

        // Split the string into two parts
        const part1 = htmlString.slice(0, splitIndex);
        const part2 = htmlString.slice(splitIndex);

        return { part1, part2 };
      } else {
        // If no match is found, return the original string as part1 and an empty string as part2
        return { part1: htmlString, part2: '' };
      }
    } else {
      return { part1: '', part2: '' };
    }
  };

  const getHtmlOfContent = (mail) => {
    if (
      mail.payload.mimeType === 'multipart/mixed' ||
      mail.payload.mimeType === 'multipart/alternative'
    ) {
      if (mail?.payload?.parts.length > 1) {
        if (mail.payload.parts[0]?.mimeType === 'multipart/alternative') {
          if (mail?.payload?.parts[0]?.parts?.length > 1) {
            if (mail?.payload?.parts[0]?.parts[0]?.mimeType === 'text/html') {
              return Buffer.from(
                mail?.payload?.parts[0]?.parts[0]?.body?.data,
                'base64',
              ).toString('ascii');
            } else if (
              mail?.payload?.parts[0]?.parts[1]?.mimeType === 'text/html'
            ) {
              return Buffer.from(
                mail?.payload?.parts[0]?.parts[1]?.body?.data,
                'base64',
              ).toString('ascii');
            }
          }
        } else {
          if (mail.payload?.parts[0]?.mimeType === 'text/html') {
            return Buffer.from(
              mail.payload?.parts[0]?.body?.data,
              'base64',
            ).toString('ascii');
          } else if (mail?.payload?.parts[1]?.mimeType === 'text/html') {
            return Buffer.from(
              mail?.payload?.parts[1]?.body?.data,
              'base64',
            ).toString('utf-8');
          }
        }
      } else if (mail?.payload?.parts.length) {
        return Buffer.from(
          mail?.payload?.parts[0]?.body?.data,
          'base64',
        ).toString('ascii');
      } else {
        return '';
      }
    } else if (mail.payload.mimeType === 'text/html') {
      return Buffer.from(mail.payload?.body?.data, 'base64').toString('ascii');
    }
  };
  
  return (
    <ErrorBoundary>
      <Stack
        py={2}
        borderBottom={'1px solid #F4F6F7'}
        flexGrow={1}
        overflow={'auto'}
        position={'relative'}
        // maxHeight={450}
    >
      <Box className="email-body" pb={0} mb={2}>
        <EmailContent
          getHtmlOfContent={() =>
            splitHtmlAtGmailQuote(getHtmlOfContent(mail))?.part1
          }
        />
        {splitHtmlAtGmailQuote(getHtmlOfContent(mail))?.part2?.length !== 0 && (
          <button
            onClick={toggleShowMore}
            style={{
              backgroundColor: '#edecf5',
              border: 'none',
              borderRadius: '10px',
              cursor: 'pointer',
              marginBottom: '10px',
            }}
          >
            <MoreHorizIcon style={{ fontSize: '16px' }} />
          </button>
        )}
        {showMore && (
          <EmailContent
            getHtmlOfContent={() =>
              splitHtmlAtGmailQuote(getHtmlOfContent(mail))?.part2
            }
          />
          )}
        </Box>
      </Stack>
    </ErrorBoundary>
  );
}
