//@ts-nocheck
import React, { Component } from 'react';
import { Button, CircularProgress, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Stack, Box } from '@mui/material';
import Grid from '@mui/material/Grid2';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { SyntheticEvent, useEffect, useState } from 'react';
import {
  CustomField,
  CustomFields,
  CustomFields_State,
} from 'ReduxStore/Reducers/opportunity/opportunityCustomFields.reducer';
import { useSelector } from 'react-redux';
import { rgbToHex2, separateByComma } from 'helpers/common';
import OpportunityContactsAPI from 'ReduxStore/API/opportunity/opportunityContactsAPI';
import { Contact } from 'ReduxStore/Reducers/opportunity/contacts.reducer';
import OpportunityAccountsCustomFieldsAPI from 'ReduxStore/API/opportunity/opportunityCustomFieldsAPI';
import Loader from 'Components/Loader/loader';
import {
  AccountCf,
  ContactCf,
  Opportunity,
  OpportunityState,
} from 'ReduxStore/Reducers/opportunity/opportunities.reducer';
import ContactOrAccountDetailsSideModal from '../../Accounts/ContactOrAccountDetailsSideModal';
import OpportunityAccountsAPI from 'ReduxStore/API/opportunity/opportunityAccountsAPI';
import ChatHistory from '../ChatHistory';
import ReadMore from '../../../../assets/images/icons/informationIcon.svg';
import AIChatbotAPI from 'ReduxStore/API/aiChatbotAPI';
import { Message } from 'Pages/AIChatbot/Tabs/ChatWidget/MessageWindow';
import { getSystemLevelPermissions } from '../../../../helpers/common';
import moment from 'moment';
import { formatDateField } from 'helpers/common';
import ChatHelpers from 'Components/ChatWidget/helpers';
import {
  OpportunityStates as OpportunityDynamicStates,
  OpportunityStates_State
} from 'ReduxStore/Reducers/opportunity/opportunityStates.reducer';
import { authPermissionValidation } from 'helpers/permissionsHelper';
import { permissionsConstants } from 'constants/permissionsConstant';


type Props = {
  closeViewModal: () => void;
  opportunityToBeEdited: Opportunity | undefined;
  setShowAddNew: React.Dispatch<React.SetStateAction<boolean>>;
  setShowViewModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleTabChange: (
    e: SyntheticEvent<Element, Event>,
    newValue: string,
    searchText: string,
  ) => void;
  commentSectionOpened: boolean;
};

const currencyMap = {
  "en-US": "USD", "en-CA": "CAD", "fr-CA": "CAD", "es-MX": "MXN", "es-ES": "EUR",
  "fr-FR": "EUR", "de-DE": "EUR", "it-IT": "EUR", "nl-NL": "EUR", "pt-PT": "EUR",
  "en-GB": "GBP", "en-AU": "AUD", "en-NZ": "NZD", "en-ZA": "ZAR", "ja-JP": "JPY",
  "zh-CN": "CNY", "zh-HK": "HKD", "zh-TW": "TWD", "ko-KR": "KRW", "ru-RU": "RUB",
  "ar-SA": "SAR", "he-IL": "ILS", "hi-IN": "INR", "bn-BD": "BDT", "id-ID": "IDR",
  "vi-VN": "VND", "th-TH": "THB", "ms-MY": "MYR", "en-IN": "INR", "en-SG": "SGD",
  "en-PH": "PHP", "en-IE": "EUR", "en-NG": "NGN", "en-KE": "KES", "en-JM": "JMD",
  "fr-BE": "EUR", "nl-BE": "EUR", "pt-BR": "BRL", "es-AR": "ARS", "es-CL": "CLP",
  "es-CO": "COP", "es-PE": "PEN", "es-VE": "VES", "es-UY": "UYU", "en-PK": "PKR",
  "en-AE": "AED", "en-EG": "EGP", "en-IL": "ILS", "en-SA": "SAR", "en-QA": "QAR",
  "tr-TR": "TRY", "pl-PL": "PLN", "hu-HU": "HUF", "cs-CZ": "CZK", "sk-SK": "EUR",
  "bg-BG": "BGN", "ro-RO": "RON", "el-GR": "EUR", "hr-HR": "EUR", "sv-SE": "SEK",
  "fi-FI": "EUR", "da-DK": "DKK", "no-NO": "NOK", "is-IS": "ISK", "et-EE": "EUR",
  "lv-LV": "EUR", "lt-LT": "EUR", "uk-UA": "UAH", "kk-KZ": "KZT", "mn-MN": "MNT",
  "sr-RS": "RSD", "bs-BA": "BAM", "mk-MK": "MKD", "en-GH": "GHS", "en-TZ": "TZS",
  "en-UG": "UGX", "en-ZW": "ZWL", "my-MM": "MMK", "km-KH": "KHR", "lo-LA": "LAK",
  "fa-IR": "IRR", "ur-PK": "PKR", "ps-AF": "AFN", "ne-NP": "NPR", "si-LK": "LKR",
  "bn-IN": "INR", "ta-IN": "INR", "pa-IN": "INR", "gu-IN": "INR", "mr-IN": "INR",
  "kn-IN": "INR", "ml-IN": "INR", "te-IN": "INR", "ur-IN": "INR", "id-PH": "PHP",
  "es-EC": "USD", "es-BO": "BOB", "es-CR": "CRC", "es-PY": "PYG", "es-SV": "USD",
  "es-HN": "HNL", "es-NI": "NIO", "es-PR": "USD", "en-BD": "BDT", "en-BZ": "BZD",
  "en-LR": "LRD", "en-FJ": "FJD", "en-MT": "EUR", "en-CY": "EUR", "en-IE": "EUR"
};

const locale = navigator.language; // e.g., "en-US" or "fr-FR"
 
// const currencyMap = {     "en-US": "USD",     "fr-FR": "EUR",     "en-GB": "GBP",    } // Add more mappings as needed;
const currency = currencyMap[locale] || "USD"; // Default to USD if locale not mappedconsole.log(currency);

// const locale = navigator.language;
// const currency = new Intl.NumberFormat().resolvedOptions().timeZone;
 
// const currency = new Intl.NumberFormat(locale, { style: 'currency', currency: 'INR' })
// .resolvedOptions().currency;

function OpportunityView({
  closeViewModal,
  opportunityToBeEdited,
  setShowAddNew,
  setShowViewModal,
  handleTabChange,
  commentSectionOpened
}: Props) {
  const {
    contactsObj,
    accountsObj,
    isLoading: isOpportunitiesLoading,
  } = useSelector(
    (state: any) => state.OpportunityOpportunities,
  ) as OpportunityState;

  const { opportunityStates: opportunityDynamicStates, fetchAllOpportunityStateSuccess } = useSelector(
    (state: any) => state.OpportunityStates,
  ) as OpportunityStates_State;

  const { customFields, isLoading: isCustomFieldsLoading } = useSelector(
    (state: any) => state.OpportunityCustomFields,
  ) as CustomFields_State;
  const [detailsForToolTip, setDetailsForToolTip] = useState<Contact>(
    {} as Contact,
  );
  const [contactsCustomFields, setContactsCustomFields] =
    useState<CustomFields>({} as CustomFields);
  const [accountsCustomFields, setAccountsCustomFields] =
    useState<CustomFields>({} as CustomFields);
  const [openSideModal, setOpenSideModal] = useState(false);
  const [customFieldsForSideModal, setCustomFieldsForSideModal] =
    useState<any>(null);
  const [modalUsedFor, setModalUsedFor] = useState<'contact' | 'account'>(
    'contact',
  );
  const [titleForSideModal, setTitleForSideModal] = useState<string>('');
  const [summary, setSummary] = useState<string>('');
  const [messages, setMessages] = useState<Message[]>([]);
  const [openChatHistory, setOpenChatHistory] = useState<boolean>(false);
  const [isloading, setIsloading] = useState<boolean>(false);
  

  useEffect(() => { 
    fetchContactsCustomField();
    fetchAccountsCustomField();
    opportunityToBeEdited?.sourceRef && fetchChatHistoryAndSummary();
  }, []);

  const fetchChatHistoryAndSummary = async () => {
    try {
      setIsloading(true);
      const aIChatbotAPI = new AIChatbotAPI();
      let data = {
        chatId: opportunityToBeEdited?.sourceRef,
      };
      const res = await aIChatbotAPI.getChatSummary(data);

      if (res.data.chatSummary?.summary) {
        console.log("AI Summary:", res.data.chatSummary.summary);  // Add this log
        setSummary(res.data.chatSummary.summary);
      }
      if (res.data.history) {
        setIsloading(false);
        const newMessages = ChatHelpers.splitMessagesIntoParagrphs(res.data.history);
        setMessages(newMessages);
      }
    } catch (err) {
      setIsloading(false);
    }
  };


  const fetchContactsCustomField = async () => {
    try {
      const opportunityAccountsCustomFieldsAPI =
        new OpportunityAccountsCustomFieldsAPI();
      const data = {
        customFieldType: 'contacts',
        from: 'viewOpportunity',
      };
      const res =
        await opportunityAccountsCustomFieldsAPI.fetchAllOpportunityCustomField(
          data,
        );
      setContactsCustomFields(res.data.customFields);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAccountsCustomField = async () => {
    try {
      const opportunityAccountsCustomFieldsAPI =
        new OpportunityAccountsCustomFieldsAPI();
      const data = {
        customFieldType: 'accounts',
        from: 'viewOpportunity',
      };
      const res =
        await opportunityAccountsCustomFieldsAPI.fetchAllOpportunityCustomField(
          data,
        );
      setAccountsCustomFields(res.data.customFields);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchContactById = async (contactId: string) => {
    const opportunityContactsAPI = new OpportunityContactsAPI();
    const res = await opportunityContactsAPI.fetchOpportunityContact({
      id: contactId,
    });
    if (res.data.contact) {
      setDetailsForToolTip(res.data.contact);
    }
  };

  const fetchAccountById = async (accountId: string) => {
    const opportunityAccountsAPI = new OpportunityAccountsAPI();
    const res =
      await opportunityAccountsAPI.fetchAccountWithProperCustomFieldsValue({
        id: accountId,
      });
    if (res.data?.account) {
      setDetailsForToolTip(res.data.account);
    }
  };

  const handleShowSideModal = (
    viewDetailData: any,
    fieldType: string,
    title: string,
  ) => {
    if (viewDetailData._id) {
      setTitleForSideModal(title);
      if (fieldType === 'contact') {
        setModalUsedFor('contact');
        openContactDetailsModal(viewDetailData._id);
      } else if (fieldType === 'account') {
        setModalUsedFor('account');
        openAccountDetailsModal(viewDetailData._id);
      }
    }
  };

  const openContactDetailsModal = (id: string) => {
    setOpenSideModal(true);
    setCustomFieldsForSideModal(contactsCustomFields);
    fetchContactById(id);
  };

  const openAccountDetailsModal = (id: string) => {
    setOpenSideModal(true);
    setCustomFieldsForSideModal(accountsCustomFields);
    fetchAccountById(id);
  };

  const handleCloseSideModal = () => {
    setOpenSideModal(false);
    setDetailsForToolTip({} as Contact);
  };

  const handleCloseChatHistory = () => {
    setOpenChatHistory(false);
  };

  const showRestOfPages = (cfs: (string | { email: string; })[]) => {
    return cfs?.slice(1, cfs.length);
  };

  const getStateColor = (opportunity: Opportunity | undefined) => {
    // Ensure opportunity and state exist and have at least one element
    if (opportunity?.state?.length > 0) {
      const defaultState = opportunityDynamicStates.find(
        (state) => state.name === opportunity.state[0].name
      );
      // Return textColor and backgroundColor if found, else return default values
      if (defaultState) {
        return {
          textColor: defaultState.textColor ? rgbToHex2(defaultState.textColor) : "#000056", // Default text color
          backgroundColor: rgbToHex2(defaultState.backgroundColor) || "#ffffff00", // Default background color
        };
      }
    }

    // If state length is 0, return no color for both
    return {
      textColor: "",
      backgroundColor: "",
    };
  };

  const customFieldValue = (
    cf: CustomField,
    opportunityToBeEdited: Opportunity | undefined,
  ) => {
    const value1 = opportunityToBeEdited?.customFields?.[cf._id as string] as any | any[];

    if (cf.fieldData.fieldType === 'contact') {
      return opportunityToBeEdited?.customFields?.[cf._id as string]?.fullName || '-';
    } else if (cf.fieldData.fieldType === 'account') {
      return opportunityToBeEdited?.customFields?.[cf._id as string]?.name || '-';
    } else if (cf.fieldData.fieldType === 'boolean') {
      const boolValue = Array.isArray(value1) ? value1[0] : value1; // Get the first element if it's an array
      if(typeof boolValue !=="boolean"){
        return ""
      }
      return (boolValue && boolValue.toString().toUpperCase() === "TRUE") ? "TRUE" : "FALSE";
    } else if (cf.fieldData.fieldType === 'user' && Array.isArray(value1) && value1.length > 0) {
      // Handle 'user' field type with detail-popup
      return (
        <>
          <span
            className={`long-text ${cf.fieldData.fieldType === 'user' && 'link'}`}
            style={{ maxWidth: '80%' }}
            onClick={() => {
              if (cf.fieldData.fieldType === 'user') {
                window.open(`mailto:${value1[0].email || value1[0]}`);
              }
            }}
          >
            {value1[0].email || String(value1[0])} {/* Ensure it's a string */}
          </span>

          {value1.length > 1 && (
            <div className="detail-popup">
              <span className="count">+{value1.length - 1}</span>
              <div className="info">
                {value1.slice(1).map((cfValue: string | { email: string; }, i: number) => {
                  const displayValue = typeof cfValue === 'object' ? cfValue.email : cfValue;
                  return (
                    <div
                      key={i}
                      className={cf.fieldData.fieldType === 'user' ? 'link' : ''}
                      onClick={() => {
                        if (cf.fieldData.fieldType === 'user' && typeof cfValue === 'object') {
                          window.open(`mailto:${displayValue}`);
                        }
                      }}
                    >
                      <p>{displayValue}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </>
      );
    } else if (Array.isArray(value1) && value1.length > 0) {
      // Handle other field types similarly with detail-popup
      return (
        <>
          <span className="long-text" style={{ maxWidth: '80%' }}>
            {String(value1[0])} {/* Ensure it's a string */}
          </span>

          {value1.length > 1 && (
            <div className="detail-popup">
              <span className="count">+{value1.length - 1}</span>
              <div className="info">
                {value1.slice(1).map((cfValue: string, i: number) => (
                  <div key={i} style={{ zIndex: 999999 }}>
                    <p>{cfValue}</p>
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      );
    } else {
      // Handle other single-value fields like 'date'
      return cf.fieldData.fieldType === 'date'
        ? formatDateField(opportunityToBeEdited, cf, customFields)
        : typeof opportunityToBeEdited?.customFields?.[cf._id as string] === 'object'
          ? JSON.stringify(opportunityToBeEdited?.customFields?.[cf._id as string])
          : opportunityToBeEdited?.customFields?.[cf._id as string] || '-';
    }
  };


  return (
    <>
      <Box className="Opportunity-Account">
        {/* @ts-ignore */}
        <Grid container spacing={2} mb={3} className="top-filter-area">
          <Grid >
            <Stack direction="row" alignItems="center" spacing={1}>
              <IconButton size="small" onClick={closeViewModal}>
                <KeyboardBackspaceIcon color="primary" />
              </IconButton>
              <Box className="paragraph bold">Opportunity view</Box>
            </Stack>
          </Grid>
          <Grid >
            {/* @ts-ignore */}
            <Grid container spacing={1.25} className="team-dash-right">
              <Grid >
                {authPermissionValidation(permissionsConstants.editOpportunity) && (
                  <Button
                    onClick={() => {
                      setShowAddNew(true);
                      setShowViewModal(false);
                    }}
                    variant="contained"
                    color="primary"
                    disableElevation
                  >
                    Edit
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {summary && (
          <Stack
            spacing={1}
            className="account-details"
            mb={3}
            sx={{ minWidth: '600px' }}
          >
            <Box className="subtitle">AI Summary</Box>
            <Box className="small-subtitle">{summary}</Box>
            <Box
              className="small-subtitle link"
              onClick={() => setOpenChatHistory(true)}
            >
              View Chat History
            </Box>
          </Stack>
        )}
        <Box className="account-details" sx={{ minWidth: '600px' }}>
          <Grid container spacing={3}>
            <Grid
              size={{
                xs: 12,
                sm: commentSectionOpened ? 12 : 6,
                lg: commentSectionOpened ? 6 : 4,
              }}
            >
              <Grid container>
                <Grid size={{ xs: 12 }}>
                  <Box className="small-subtitle bold primary-text">
                    Eijent Id:
                  </Box>
                </Grid>
                <Grid size={{ xs: 12 }} md>
                  <Box className="small-subtitle primary-text ">
                    {opportunityToBeEdited?.uniqueEijentId}
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              size={{
                xs: 12,
                sm: commentSectionOpened ? 12 : 6,
                lg: commentSectionOpened ? 6 : 4,
              }}
            >
              <Grid container>
                <Grid size={{ xs: 12 }}>
                  <Box className="small-subtitle bold primary-text">
                    Opportunity Name <span className="required">*</span>:
                  </Box>
                </Grid>
                <Grid size={{ xs: 12 }} md>
                  <Box className="small-subtitle primary-text ">
                    {opportunityToBeEdited?.name}
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              size={{
                xs: 12,
                sm: commentSectionOpened ? 12 : 6,
                lg: commentSectionOpened ? 6 : 4,
              }}
            >
              <Grid container>
                <Grid size={{ xs: 12 }}>
                  <Box className="small-subtitle bold primary-text">
                    Project:
                  </Box>
                </Grid>
                <Grid size={{ xs: 12 }}>
                  <Stack direction="row" alignItems={'center'} spacing={1}>
                    <span className="small-subtitle primary-text">
                      {opportunityToBeEdited?.projectIds?.length > 0 &&
                      opportunityToBeEdited?.projectIds[0]?.eventName
                        ? opportunityToBeEdited.projectIds[0].eventName
                        : '-'}
                    </span>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              size={{
                xs: 12,
                sm: commentSectionOpened ? 12 : 6,
                lg: commentSectionOpened ? 6 : 4,
              }}
            >
              <Grid container>
                <Grid size={{ xs: 12 }}>
                  <Box className="small-subtitle bold primary-text">
                    Assigned To:
                  </Box>
                </Grid>
                <Grid size={{ xs: 12 }}>
                  <Stack direction="row" alignItems={'center'} spacing={1}>
                    <span
                      className={`small-subtitle primary-text ${
                        opportunityToBeEdited?.assignedToIds?.[0]?.email
                          ? 'link'
                          : ''
                      }`}
                      onClick={() => {
                        if (opportunityToBeEdited?.assignedToIds?.[0]?.email) {
                          window.open(
                            `mailto:${opportunityToBeEdited.assignedToIds[0].email}`,
                          );
                        }
                      }}
                    >
                      {opportunityToBeEdited?.assignedToIds?.[0]?.email || ''}
                    </span>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              size={{
                xs: 12,
                sm: commentSectionOpened ? 12 : 6,
                lg: commentSectionOpened ? 6 : 4,
              }}
            >
              <Grid container>
                <Grid size={{ xs: 12 }}>
                  <Box className="small-subtitle bold primary-text">State:</Box>
                </Grid>
                <Grid size={{ xs: 12 }}>
                  <Stack direction="row" alignItems={'center'} spacing={1}>
                    {opportunityToBeEdited?.state?.length > 0 ? (
                      <Box
                        component="span"
                        className="table-status "
                        sx={{
                          color: `${
                            getStateColor(opportunityToBeEdited).textColor
                          } !important`,
                          bgcolor: `${
                            getStateColor(opportunityToBeEdited).backgroundColor
                          }`,
                        }}
                      >
                        {opportunityToBeEdited &&
                        opportunityToBeEdited.state &&
                        Array.isArray(opportunityToBeEdited.state)
                          ? opportunityToBeEdited.state[0]?.name || ''
                          : ''}
                      </Box>
                    ) : (
                      '-'
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              size={{
                xs: 12,
                sm: commentSectionOpened ? 12 : 6,
                lg: commentSectionOpened ? 6 : 4,
              }}
            >
              <Grid container>
                <Grid size={{ xs: 12 }}>
                  <Box className="small-subtitle bold primary-text">
                    Source:
                  </Box>
                </Grid>
                <Grid size={{ xs: 12 }}>
                  <Box className="small-subtitle primary-text">
                    {opportunityToBeEdited?.source === 'aiAgent'
                      ? 'AI Agent'
                      : opportunityToBeEdited?.source === 'imported'
                      ? 'Imported'
                      : 'Manually Added'}
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            {customFields.map((cf: CustomField) => {
              return (
                <Grid
                  size={{
                    xs: 12,
                    sm:
                      cf.fieldData.fieldType === 'longText'
                        ? 12
                        : commentSectionOpened
                        ? 12
                        : 6,
                    lg:
                      cf.fieldData.fieldType === 'longText'
                        ? 12
                        : commentSectionOpened
                        ? 6
                        : 4,
                  }}
                  key={cf._id}
                >
                  <Grid container>
                    <Grid size={{ xs: 12 }}>
                      <Box className="small-subtitle bold primary-text">
                        {cf.fieldData.fieldName}:
                      </Box>
                    </Grid>
                    <Grid size={{ xs: 12 }}>
                      <Stack direction={'row'} spacing={1}>
                        <Box
                          className={`small-subtitle primary-text  ${
                            [
                              'account',
                              'contact',
                              'url',
                              'email',
                              'facebook',
                              'instagram',
                              'x',
                              'pinterest',
                              'linkedin',
                              'phone',
                            ].includes(cf.fieldData.fieldType)
                              ? 'link'
                              : ''
                          }
                          ${cf.fieldData.fieldType === 'longText' ? '' : ''}`}
                          onClick={(e: SyntheticEvent<Element, Event>) => {
                            if (cf.fieldData.fieldType === 'contact') {
                              //   handleTabChange(
                              //   e,
                              //   '3',
                              //   opportunityToBeEdited?.customFields?.[cf._id as string].fullName
                              // );
                              opportunityToBeEdited?.customFields?.[
                                cf._id as string
                              ]?.fullName &&
                                // cf.fieldData.fieldName &&
                                handleShowSideModal(
                                  opportunityToBeEdited?.customFields?.[
                                    cf._id as string
                                  ] as
                                    | ContactCf
                                    | AccountCf as unknown as string,
                                  cf.fieldData.fieldType,
                                  cf.fieldData.fieldName,
                                );
                            } else if (cf.fieldData.fieldType === 'account') {
                              //   handleTabChange(
                              //   e,
                              //   '2',
                              //   opportunityToBeEdited?.customFields?.[cf._id as string].name
                              // );
                              opportunityToBeEdited?.customFields?.[
                                cf._id as string
                              ]?.name &&
                                handleShowSideModal(
                                  opportunityToBeEdited?.customFields?.[
                                    cf._id as string
                                  ] as
                                    | ContactCf
                                    | AccountCf as unknown as string,
                                  cf.fieldData.fieldType,
                                  cf.fieldData.fieldName,
                                );
                            }
                            if (
                              [
                                'url',
                                'facebook',
                                'instagram',
                                'x',
                                'pinterest',
                                'linkedin',
                              ].includes(cf.fieldData.fieldType)
                            ) {
                              let url = opportunityToBeEdited?.customFields?.[
                                cf._id as string
                              ] as string;
                              if (!/^https?:\/\//i.test(url)) {
                                // If not, prepend 'https://'
                                url = 'https://' + url;
                              }
                              window.open(url, '_blank');
                            }
                            if (cf.fieldData.fieldType === 'email') {
                              window.open(
                                `mailto:${
                                  opportunityToBeEdited?.customFields?.[
                                    cf._id as string
                                  ] as string
                                }`,
                              );
                            } else if (cf.fieldData.fieldType === 'phone') {
                              window.open(
                                `tel:${
                                  opportunityToBeEdited?.customFields?.[
                                    cf._id as string
                                  ] as string
                                }`,
                              );
                            }
                          }}
                        >
                          {/* @ts-ignore */}
                          {customFieldValue(cf, opportunityToBeEdited)}
                        </Box>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
          {openSideModal && (
            <ContactOrAccountDetailsSideModal
              open={openSideModal}
              handleClose={handleCloseSideModal}
              details={detailsForToolTip}
              customFields={customFieldsForSideModal}
              usedFor={modalUsedFor}
              title={titleForSideModal}
            />
          )}
        </Box>
        {opportunityToBeEdited?.items?.length > 0 && (
          <Box className="account-details" mt={2} sx={{ minWidth: '600px' }}>
            <Box className="subtitle" mb={1}>
              Items
            </Box>
            <TableContainer
              className="list-table"
              style={{ minHeight: 'unset' }}
            >
              <Table className="table">
                <TableHead
                  style={{
                    position: 'sticky',
                    top: '0',
                    backgroundColor: '#f6f6fc',
                    zIndex: 2,
                  }}
                >
                  <TableRow>
                    <TableCell className={'long-text'}>Name</TableCell>
                    <TableCell className={'text '}>Quantity</TableCell>
                    <TableCell className={'text '}>Price Per Item</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {opportunityToBeEdited?.items?.map((item, i) => {
                    return (
                      <TableRow>
                        <TableCell className="long-text">{item.name}</TableCell>
                        <TableCell className="text">{item.quantity}</TableCell>
                        <TableCell className="text">
                          {new Intl.NumberFormat(locale, {
                            style: 'currency',
                            currency,
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }).format(item.price)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </Box>
      <ChatHistory
        open={openChatHistory}
        handleClose={handleCloseChatHistory}
        messages={messages}
      />
      ;
      {(isCustomFieldsLoading || isOpportunitiesLoading || isloading) && (
        <Loader />
      )}
    </>
  );
}

export default OpportunityView;
