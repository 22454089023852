import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Avatar, IconButton, InputAdornment, Stack, TextField } from "@mui/material";

import {
    ArrowDownIcon,
    AddNewIcon,
    HomeIconActive,
    InboxIconActive,
    TasksIconActive,
    OpportunitiesIconActive,
    AccountsIconActive,
    ContactsIconActive,
    ReportingIconActive,
    CancelIcon,
    AttachmentIcon,
    SentWhiteIcon,
    SuggetionIcon,
} from "../components/CustomIcons";

// @ts-ignore
import EijentButtonLogo from "../assets/images/eijent-logo-compressed.gif";

const style: React.CSSProperties = {
    height: "100%",
    maxHeight: "100%",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
};

const userMessage: React.CSSProperties = {
    padding: '14px 24px',
    borderRadius: '8px',
    backgroundColor: '#FFF7EE',
    border: '1px solid #BCBCBC',
    display: 'flex',
    gap: 2,
    alignItems:'center',
};

const EijentCopilot: React.FC = () => {
    return (
        <Box sx={style}>
            <Stack flexGrow={1} gap={2} overflow={'auto'} py={1.5} display="flex" alignItems="center" justifyContent="start">
                <Box display="flex" mt={4} gap={1} justifyContent="center" alignItems="center">
                    <img
                        src={EijentButtonLogo}
                        width={36}
                        height={36}
                        alt="Eijent logo"
                        style={{
                            mixBlendMode: "multiply",
                            filter: "contrast(1)",
                        }}
                    />
                    <Typography>Eijent</Typography>
                </Box>

                <Typography variant="body2" align="center">
                    What <strong>type of Journey</strong> would <br /> you like to create?
                </Typography>

                <Typography variant="body2" align="center">
                    Here’s some suggestions:
                </Typography>

                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    flexWrap="wrap"
                    mt={1}
                    gap={0.75}
                    sx={{ maxWidth: "800px" }}
                >
                    {[
                        "Journey for an Account",
                        "Journey for an Opportunity",
                        "Journey for a Team",
                    ].map((text, index) => (
                        <Button key={index}
                            //@ts-ignore
                            variant="dotted"
                            color="primary" size="medium" startIcon={<SuggetionIcon />}>
                            {text}
                        </Button>
                    ))}
                </Stack>

                
                {/* --chat started-- */}
                <Stack spacing={2} maxWidth={'500px'}>

                    {/* --user message-- */}
                    <Box sx={userMessage}>
                        <Avatar
                            sx={{ width: 31, height: 31, fontSize: '11px', fontWeight: 400, }}
                        > AS</Avatar>
                        <Typography variant="body2" align="center">
                            Eijent, I’d like to import all the leads from Start.Se.
                        </Typography>
                    </Box>

                    {/* --Ai response-- */}
                    <Typography variant="body2" align="center">
                    Okay, <strong>I’ve imported the leads from all editions of Start.Se. </strong>
                    There are a total of 2,376 contacts, of which 1,237 were already distributed across 
                    other pre-selected categories (Businessmen and Entrepreneurs).
                    </Typography>
                </Stack>
            </Stack>

            {/* --Eijent Input-- */}
            <Box
                sx={{
                    backgroundColor: "#F8F8F8",
                    p: 2.25,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <TextField
                    name="BCC"
                    placeholder="Type your message here…"
                    variant="outlined"
                    fullWidth
                    sx={{
                        maxWidth: "500px",
                        "& .MuiOutlinedInput-root": {
                            pr: "4px !important",
                            borderColor: "#D9D9D9 !important",
                        },
                    }}
                    //@ts-ignore
                    size="large"
                    slotProps={{
                        input: {
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Stack direction={'row'} spacing={1.5}>
                                        <IconButton
                                            size='small'
                                            variant="contained"
                                            //@ts-ignore
                                            color="transparent"
                                        >
                                            <AttachmentIcon />
                                        </IconButton>

                                        {/* @ts-ignore */}
                                        <IconButton
                                            size="medium"
                                            variant="contained"
                                            color="primary"
                                        >
                                            <SentWhiteIcon />
                                        </IconButton>
                                    </Stack>
                                </InputAdornment>
                            ),
                        },
                    }}
                />
            </Box>
        </Box>
    );
};

export default EijentCopilot;