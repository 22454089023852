import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Typography,
  Stack,
  Checkbox,
  IconButton,
  Tab,
  TabProps,
  TableFooter,
  TableRow,
  TableContainer,
  Table,
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { styled } from '@mui/material/styles';
import { useLocation, useNavigate, useParams } from 'react-router';
import EmailList from './List';
import NoData from './NoData';
import {
  UnCheckAllIcon,
  CheckAllIcon,
  DeleteIcon,
} from '../../components/CustomIcons';
import SideTabs from './SideTabs';
import PageTabs from '../Tabs';
import EijentDashboard from 'eijent';
import {
  xpAccountIntegrationGmailArchiveMultipleThreads,
  xpAccountIntegrationGmailDeleteMultipleThreads,
  xpAccountIntegrationGmailEmptyTrash,
  xpAccountIntegrationGmailGetList,
  xpAccountIntegrationGmailMarkMultipleThreadsAsRead,
  xpAccountIntegrationGmailMarkMultipleThreadsAsUnread,
  xpAccountIntegrationGmailPermanentDeleteMultipleThreads,
  xpAccountIntegrationGmailRestoreTrashMails,
} from 'ReduxStore/API';
import { createNotification } from 'helpers';
import { useSelector } from 'react-redux';
import { XpUsersWebSocket_State } from 'ReduxStore/Reducers/xpUsersWebSocket.reducer';
import ConfirmationAlertPopUp from 'Common/ConfirmationAlertPopUp';
import Loader from 'Components/Loader/loader';
import MarkEmailUnreadOutlinedIcon from '@mui/icons-material/MarkEmailUnreadOutlined';
import DraftsOutlinedIcon from '@mui/icons-material/DraftsOutlined';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import PaginationFunctional, {
  handleEndCountForPagination,
} from 'Pages/ProjectDashboard/Integration/AppIntegration/googleWorkspaceComponents/PaginationFunctional';
import ErrorBoundary from '../../components/ErrorBoundary';
import { useGmail } from './context/GmailContext';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import { MailType, setMailTypeFromUrl, GmailData } from './common';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

interface EmailCategory {
  name: string; // Ensure this is explicitly a string
  path: string;
}


const emailCategory: EmailCategory[] = [
  {
    name: 'All',
    path: 'all',
  },
  {
    name: 'Pinned',
    path: 'pinned',
  },
  { name: 'Priority', path: 'priority' },
  // {
  //   name: 'New Opportunity',
  //   path: 'new-opportunity',
  // },
  // {
  //   name: 'At-Risk Opportunity',
  //   path: 'at-risk-opportunity',
  // },
  // {
  //   name: 'Creating a using AI',
  //   path: 'ai-creation',
  // },
];

const mainRoute = '/inbox/my-inbox/';

export default function EmailsListContainer() {
  const location = useLocation();
  const navigate = useNavigate();
  const initialRender = useRef(true);
  const initialRender2 = useRef(true);

  const { gmailId } = useGmail();
  const [value, setValue] = useState('all');
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [mailType, setMailType] = useState<MailType>('');
  const [sideBarType, setSideBarType] = useState<string>('');
  const [internalTab, setInternalTab] = useState<'all' | 'pinned' | 'priority' | 'new-opportunity' | 'at-risk-opportunity' | 'ai-creation' | ''>('');
  const [gmailData, setGmailData] = useState<GmailData>({});
  const [openDeleteConfirmationModel, setOpenDeleteConfirmationModel] =
    useState<boolean>(false);
  const [labelIdsArray, setLabelIdsArray] = useState<string[]>([]);
  const [
    openPermanentDeleteConfirmationModel,
    setOpenPermanentDeleteConfirmationModel,
  ] = useState<boolean>(false);
  // variables for pagination started
  const [startCount, setStartCount] = useState<number>(1);
  const [endCount, setEndCount] = useState<number>(10);
  const [limit, setLimit] = useState<number>(10);
  const [page_no, setPage_no] = useState<number>(1);
  // variables for pagination ends
  const [openRestoreConfirmationModel, setOpenRestoreConfirmationModel] = useState<boolean>(false);
  const [openEmptyTrashConfirmationModel, setOpenEmptyTrashConfirmationModel] = useState<boolean>(false);

  const { message: xpUsersWebSocketMessage } = useSelector(
    (state: any) => state.XpUsersWebSocket,
  ) as XpUsersWebSocket_State;

  // StyledTab customization
  const StyledTab = styled((props: TabProps) => <Tab {...props} />)(
    ({ theme }) => ({
      '&.MuiTab-root': {
        height: 52,
        marginBottom: 0,
        borderBottom: 0,
        paddingLeft: '30px',
        paddingRight: '30px',
        fontSize: '12px',
        transitionDuration: '200ms',
        '&:hover': {
          backgroundColor: '#ffffff',
          borderColor: '#ffffff',
        },
      },
      '&.Mui-selected': {
        fontWeight: '700',
      },
    }),
  );



  useEffect(() => {
    handleEndCountForPagination(
      gmailData?.totalMailCount,
      limit,
      page_no,
      setEndCount,
    );
    setStartCount((page_no - 1) * limit + 1);
  }, []);

  useEffect(() => {
    setSelectedCheckboxes([]);
  }, [page_no]);

  useEffect(() => {
    resetPaginationStates();
  }, [location.pathname]);

  // Get the current path from the URL and set the active tab
  useEffect(() => {
    const currentPath = location.pathname.split('/').pop(); // Get the last part of the URL
    const currentTabIndex = emailCategory.findIndex(
      (category) => category.path === currentPath,
    );
    if (currentTabIndex !== -1) {
      setValue((currentTabIndex + 1).toString());
    }
    const {mailType, currentPath: currentPathFromFn, internalTab} = setMailTypeFromUrl();
    setMailType(mailType);
    setSideBarType(currentPathFromFn);
    setInternalTab(internalTab || '');
  }, [location.pathname, emailCategory]);

  useEffect(() => {
    console.log('xpUsersWebSocketMessage', xpUsersWebSocketMessage);
    if (xpUsersWebSocketMessage.message == 'Gmail inbox list is updated') {
      // if (page_no === 1) {
      getGmailData(undefined, undefined, false);
      // }
    }
  }, [xpUsersWebSocketMessage]);

  useEffect(() => {
    if(initialRender2.current){
      initialRender2.current = false;
    } else {
      getGmailData();
    }
  }, [mailType, internalTab]);

  const resetPaginationStates = () => {
    setStartCount(1);
    setLimit(10);
    setPage_no(1);
  };

  const getGmailData = async (limit = 10, page_no = 1, showLoader = true) => {
    if (showLoader) {
      setIsLoading(true);
    }
    const queryParams = {
      page_no: page_no,
      limit: limit,
      type: setMailTypeFromUrl().mailType,
      filterBy: (setMailTypeFromUrl().internalTab || ''),
    };
    const gmailDataResult: any = await xpAccountIntegrationGmailGetList(
      queryParams,
    );
    handleEndCountForPagination(
      gmailDataResult?.data?.totalMailCount,
      limit,
      page_no,
      setEndCount,
    );
    
    setIsLoading(false);
    if (gmailDataResult?.data) {
      setGmailData(gmailDataResult.data);
    }
  };


  // Handle tab change
  const handleChange = (event, newValue) => {
    setValue(newValue);
    const newTabPath = emailCategory[parseInt(newValue) - 1].path;
    if (sideBarType === 'my-inbox') {
      navigate(`/inbox/${sideBarType}/${newTabPath}`);
    }
  };

  const handleDeleteMultiple = async () => {
    const previousGmailData = { ...gmailData };  // Declare at the start of try block
    try {
      
      // Immediately update UI by filtering out selected emails
      setGmailData(prev => ({
        ...prev,
        data: prev.data.filter(mail => !selectedCheckboxes.includes(mail._id)),
        totalMailCount: prev.totalMailCount - selectedCheckboxes.length
      }));

      setIsLoading(true);
      let data: {
        threadIds?: string[];
        ids?: string[];
        gmailId: string;
      } = {
        gmailId: gmailId,
      };

      let res: any;

      if (mailType === 'TRASH') {
        data.threadIds = selectedCheckboxes;
        res = await xpAccountIntegrationGmailPermanentDeleteMultipleThreads(data);
      } else {
        data.ids = selectedCheckboxes;
        res = await xpAccountIntegrationGmailDeleteMultipleThreads(data);
      }
      
      if (res.data.success) {
        createNotification('success', `Email${selectedCheckboxes.length > 1 ? 's' : ''} ${mailType === 'TRASH' ? 'permanently deleted' : 'deleted'} successfully`);
      } else {
        // If API fails, restore the previous data
        setGmailData(previousGmailData);
        createNotification('error', 'Failed to delete emails');
      }
    } catch (error) {
      console.log(error);
      // Restore the previous data on error
      setGmailData(previousGmailData);
      createNotification('error', 'Failed to delete emails');
    } finally {
      setOpenDeleteConfirmationModel(false);
      setSelectedCheckboxes([]);
      setIsLoading(false);
      setLabelIdsArray([]);
    }
  };

  const closeDeleteConfirmationModel = () => {
    setOpenDeleteConfirmationModel(false);
  };

  const handleSelectAllCheckboxes = (
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    let target = e.target as HTMLInputElement;
    if (target.checked) {
      let arr: string[] = [];
      gmailData?.data?.forEach((mail) => arr.push(mail._id));
      setSelectedCheckboxes(arr);
    } else {
      setSelectedCheckboxes([]);
    }
  };

  const markMultipleThreadsAsRead = async () => {
    try {
      setIsLoading(true);
      const data = {
        threadIds: selectedCheckboxes,
        gmailId: gmailId,
      };
      const res = await xpAccountIntegrationGmailMarkMultipleThreadsAsRead(
        data,
      );
      if (res.data.success) {
        getGmailData();
      }
    } catch (error) {
      console.log('error', error);
      createNotification('error', error.message);
    } finally {
      setSelectedCheckboxes([]);
      setLabelIdsArray([]);
      setIsLoading(false);
    }
  };

  const markMultipleThreadsAsUnread = async () => {
    try {
      setIsLoading(true);
      const data = {
        threadIds: selectedCheckboxes,
        gmailId: gmailId,
      };
      const res = await xpAccountIntegrationGmailMarkMultipleThreadsAsUnread(
        data,
      );
      if (res.data.success) {
        getGmailData();
      }
    } catch (error) {
      console.log('error', error);
      createNotification('error', error.message);
    } finally {
      setSelectedCheckboxes([]);
      setLabelIdsArray([]);
      setIsLoading(false);
    }
  };

  const archiveMultipleThreads = async () => {
    const previousGmailData = { ...gmailData };  // Store current state
    try {
      setIsLoading(true);
      // Immediately update UI by filtering out selected emails
      setGmailData(prev => ({
        ...prev,
        data: prev.data.filter(mail => !selectedCheckboxes.includes(mail._id)),
        totalMailCount: prev.totalMailCount - selectedCheckboxes.length
      }));

      const data = {
        threadIds: selectedCheckboxes,
        gmailId: gmailId,
      };
      const res = await xpAccountIntegrationGmailArchiveMultipleThreads(data);
      if (res.data.success) {
        getGmailData();
        createNotification('success', `Email${selectedCheckboxes.length > 1 ? 's' : ''} archived successfully`);
      } else {
        // If API fails, restore the previous data
        setGmailData(previousGmailData);
        createNotification('error', 'Failed to archive emails');
      }
    } catch (error) {
      console.log('error', error);
      // Restore the previous data on error
      setGmailData(previousGmailData);
      createNotification('error', error.message);
    } finally {
      setSelectedCheckboxes([]);
      setLabelIdsArray([]);
      setIsLoading(false);
    }
  };

  const restoreMultipleThreads = async () => {
    const previousGmailData = { ...gmailData };  // Store current state
    try {
      // Immediately update UI by filtering out selected emails
      setGmailData(prev => ({
        ...prev,
        data: prev.data.filter(mail => !selectedCheckboxes.includes(mail._id)),
        totalMailCount: prev.totalMailCount - selectedCheckboxes.length
      }));

      setIsLoading(true);
      const data = {
        threadIds: selectedCheckboxes,
        gmailId: gmailId,
      };
      const res = await xpAccountIntegrationGmailRestoreTrashMails(data);
      if (res.data.success) {
        createNotification('success', `Email${selectedCheckboxes.length > 1 ? 's' : ''} restored successfully`);
      } else {
        // If API fails, restore the previous data
        setGmailData(previousGmailData);
        createNotification('error', 'Failed to restore emails');
      }
    } catch (error) {
      console.log('error', error);
      // Restore the previous data on error
      setGmailData(previousGmailData);
      createNotification('error', error.message);
    } finally {
      setOpenRestoreConfirmationModel(false);
      setSelectedCheckboxes([]);
      setLabelIdsArray([]);
      setIsLoading(false);
    }
  };

  const redirectToGmailView = (threadId: string, index) => {
    if (sideBarType === 'my-inbox') {
      navigate(
        `/inbox/${sideBarType}/${internalTab}/view/${threadId}?pageNo=${page_no}&limit=${limit}&mailNo=${
          index + 1
        }&totalCount=${gmailData?.totalMailCount}`,
        { state: { gmailData: gmailData } },
      );
    } else {
      navigate(
        `/inbox/${sideBarType}/view/${threadId}?pageNo=${page_no}&limit=${limit}&mailNo=${
          index + 1
        }&totalCount=${gmailData?.totalMailCount}`,
        { state: { gmailData: gmailData } },
      );
    }
  };

  const closeRestoreConfirmationModel = () => {
    setOpenRestoreConfirmationModel(false);
  };

  const handleEmptyTrash = async () => {
    const previousGmailData = { ...gmailData };
    try {
      setIsLoading(true);
      // Immediately update UI by clearing all emails
      setGmailData(prev => ({
        ...prev,
        data: [],
        totalMailCount: 0
      }));

      const res = await xpAccountIntegrationGmailEmptyTrash({
        gmailId: gmailId
      });
      
      if (res.data.success) {
        createNotification('success', 'Trash emptied successfully');
      } else {
        // If API fails, restore the previous data
        setGmailData(previousGmailData);
        createNotification('error', 'Failed to empty trash');
      }
    } catch (error) {
      console.log('error', error);
      // Restore the previous data on error
      setGmailData(previousGmailData);
      createNotification('error', error.message);
    } finally {
      setOpenEmptyTrashConfirmationModel(false);
      setIsLoading(false);
    }
  };

  const closeEmptyTrashConfirmationModel = () => {
    setOpenEmptyTrashConfirmationModel(false);
  };

  return (
    <EijentDashboard>
      <PageTabs>
        <SideTabs>
          <ErrorBoundary>
            <Stack>
              {/* --Select and menu-- */}
              {selectedCheckboxes.length >= 1 && (
                <Stack
                  px={1.5}
                  borderBottom={'1px solid #C1C1C1'}
                  direction={'row'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                >
                  <Stack
                    spacing={2.75}
                    py={1.5}
                    px={2}
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                  >
                    <Box display={'flex'} gap={1} alignItems={'center'}>
                      <Checkbox
                        onClick={handleSelectAllCheckboxes}
                        checked={
                          gmailData?.data?.length
                            ? selectedCheckboxes.length ===
                              gmailData?.data?.length
                            : false
                        }
                        icon={<UnCheckAllIcon />}
                        checkedIcon={<CheckAllIcon />}
                      />
                      <Typography>{selectedCheckboxes.length}</Typography>
                    </Box>

                    <IconButton
                      size="small"
                      // @ts-ignore
                      color="transparent"
                      variant="contained"
                      title="Delete"

                      onClick={() => setOpenDeleteConfirmationModel(true)}
                    >
                      <DeleteIcon />
                    </IconButton>

                    {mailType === 'TRASH' && (
                      <IconButton
                        size="small"
                        // @ts-ignore
                        color="transparent"
                        variant="contained"
                        title="Empty Trash Permanently"
                        onClick={() => setOpenEmptyTrashConfirmationModel(true)}
                      >
                        <DeleteForeverIcon />
                      </IconButton>
                    )}
                    
                    {mailType !== 'TRASH' && (
                      <IconButton
                        size="small"
                        // @ts-ignore
                        color="transparent"
                        variant="contained"
                        onClick={() => archiveMultipleThreads()}
                        title="Archive"
                      >
                        <ArchiveOutlinedIcon
                          sx={{ fontSize: '22px !important' }}
                        />
                      </IconButton>
                    )}

                    {mailType === 'TRASH' && (
                      <IconButton
                        size="small"
                        // @ts-ignore
                        color="transparent"
                        variant="contained"
                        onClick={() => setOpenRestoreConfirmationModel(true)}
                        title="Restore"
                      >
                        <RestoreFromTrashIcon sx={{ fontSize: '22px !important' }} />
                      </IconButton>
                    )}

                    {labelIdsArray.includes('UNREAD') ? (
                      <IconButton
                        size="small"
                        // @ts-ignore
                        color="transparent"
                        variant="contained"
                        onClick={() => markMultipleThreadsAsRead()}
                        title="Mark as Read"
                      >
                        <DraftsOutlinedIcon />
                      </IconButton>
                    ) : (
                      <IconButton
                        size="small"
                        // @ts-ignore
                        color="transparent"
                        variant="contained"
                        onClick={() => markMultipleThreadsAsUnread()}
                        title="Mark as Unread"
                      >
                        <MarkEmailUnreadOutlinedIcon />
                      </IconButton>
                    )}
                  </Stack>
                </Stack>
              )}

              <TabContext value={value}>
                {(mailType === 'INBOX' || mailType === '') && (
                  <TabList
                    TabIndicatorProps={{ sx: { height: 3 } }}
                    variant="standard"
                    onChange={handleChange}
                    aria-label="Page tabs"
                    sx={{ borderBottom: '1px solid' }}
                  >
                    {emailCategory.map((item, index) => (
                      <StyledTab
                        key={index}
                        label={item.name}
                        value={`${index + 1}`}
                        // onClick={() => handleNavigation(item.path)}
                      />
                    ))}
                  </TabList>
                )}
              </TabContext>
              <EmailList
                selectedCheckboxes={selectedCheckboxes}
                setSelectedCheckboxes={setSelectedCheckboxes}
                gmailData={gmailData}
                getGmailData={getGmailData}
                gmailId={gmailId}
                setGmailData={setGmailData}
                mailType={mailType}
                setLabelIdsArray={setLabelIdsArray}
                redirectToGmailView={redirectToGmailView}
                sideBarType={sideBarType}
                internalTab={internalTab}
                limit={limit}
                page_no={page_no}
                setPage_no={setPage_no}
                setEndCount={setEndCount}
                setStartCount={setStartCount}
              />

              {gmailData?.data?.length !== 0 && (
                <TableContainer>
                  <Table>
                    <TableFooter>
                      <TableRow>
                        <PaginationFunctional
                          startCount={startCount}
                          setStartCount={setStartCount}
                          endCount={endCount}
                          limit={limit}
                          setLimit={setLimit}
                          page_no={page_no}
                          setPage_no={setPage_no}
                          listItemsFn={getGmailData}
                          totalItemsCount={gmailData?.totalMailCount}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              )}

              <ConfirmationAlertPopUp
                openModal={openDeleteConfirmationModel}
                closeModalFunc={closeDeleteConfirmationModel}
                title={'Delete Confirmation!'}
                text={'Are you sure you want to delete it?'}
                confirmationButtonText={mailType === 'TRASH' ? 'Delete Permanently' : 'Delete'}
                confirmationButtonColor="secondary"
                closeButtonText={'Cancel'}
                functionality={() => handleDeleteMultiple()}
              />
              <ConfirmationAlertPopUp
                openModal={openRestoreConfirmationModel}
                closeModalFunc={closeRestoreConfirmationModel}
                title={'Restore Mails Confirmation!'}
                text={'Are you sure you want to restore it?'}
                confirmationButtonText={'Restore'}
                confirmationButtonColor="secondary"
                closeButtonText={'Cancel'}
                functionality={() => restoreMultipleThreads()}
              />
              <ConfirmationAlertPopUp
                openModal={openEmptyTrashConfirmationModel}
                closeModalFunc={closeEmptyTrashConfirmationModel}
                title={'Empty Trash Confirmation!'}
                text={'Are you sure you want to permanently delete all emails in Trash?'}
                confirmationButtonText={'Empty Trash'}
                confirmationButtonColor="secondary"
                closeButtonText={'Cancel'}
                functionality={() => handleEmptyTrash()}
              />
            </Stack>
            {isLoading && <Loader />}
          </ErrorBoundary>
        </SideTabs>
      </PageTabs>
    </EijentDashboard>
  );
}
