import React from 'react';
import {
  Stack,
  IconButton,
  Typography,
} from '@mui/material';
import {
  PaginationArrowRightIcon,
  PaginationArrowLeftIcon,
  DeleteIcon,
} from '../../../components/CustomIcons';
import MarkEmailUnreadOutlinedIcon from '@mui/icons-material/MarkEmailUnreadOutlined';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';

interface EmailViewThreadActionsButtonsProps {
  mailType: string;
  archiveThread: () => void;
  markThreadAsUnread: () => void;
  setOpenDeleteConfirmationModel: (value: boolean) => void;
  tempStart: number;
  totalCount: number;
  handlePreviousMail: () => void;
  handleNextMail: () => void;
}

const EmailViewThreadActionsButtons: React.FC<EmailViewThreadActionsButtonsProps> = ({
  mailType,
  archiveThread,
  markThreadAsUnread,
  setOpenDeleteConfirmationModel,
  tempStart,
  totalCount,
  handlePreviousMail,
  handleNextMail,
}) => {
  return (
    <Stack
      py={1.5}
      borderBottom={'1px solid #F4F6F7'}
      direction={'row'}
      alignItems={'center'}
      justifyContent={'space-between'}
    >
      <Stack direction={'row'} alignItems={'center'} spacing={1.5}>
        {mailType !== 'TRASH' && (
          <IconButton
            size="small"
            // @ts-ignore
            color="transparent"
            variant="contained"
            title="Archive"
            onClick={archiveThread}
          >
            <ArchiveOutlinedIcon sx={{ fontSize: '22px !important' }} />
          </IconButton>
        )}
        <IconButton
          size="small"
          // @ts-ignore
          color="transparent"
          variant="contained"
          title="Mark as unread"
          onClick={markThreadAsUnread}
        >
          <MarkEmailUnreadOutlinedIcon />
        </IconButton>
        <IconButton
          size="small"
          // @ts-ignore
          color="transparent"
          variant="contained"
          title="Delete"
          onClick={() => setOpenDeleteConfirmationModel(true)}
        >
          <DeleteIcon />
        </IconButton>
      </Stack>

      {/* --Pagination--- */}
      <Stack
        spacing={2}
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Typography variant="caption" color="#C1C1C1">
          SHOWING {tempStart} OF {totalCount}
        </Typography>

        <IconButton
          size="small"
          // @ts-ignore
          color="transparent"
          variant="contained"
          onClick={handlePreviousMail}
          sx={{
            opacity: tempStart === 1 ? 0.5 : 1,
          }}
          disabled={tempStart === 1}
        >
          <PaginationArrowLeftIcon />
        </IconButton>
        <IconButton
          size="small"
          // @ts-ignore
          color="transparent"
          variant="contained"
          onClick={handleNextMail}
          sx={{
            opacity: tempStart === totalCount ? 0.5 : 1,
          }}
          disabled={tempStart === totalCount}
        >
          <PaginationArrowRightIcon />
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default EmailViewThreadActionsButtons;
