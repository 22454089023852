import React from 'react';
import {
  Route,
  Routes,
  Navigate,
  useNavigate,
  useLocation,
  useParams,
} from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Actions } from 'ReduxStore/Actions';
import PrivateUserRoute from './privateUserRoutes';
import PrivateMemberRoute from './privateMemberRoutes';
import PageNotFound from '../Components/PageNotFound';
import Login from '../Pages/Login';
import Eventlist from '../Pages/EventList';
import RegistrationEditing from '../Pages/RegisterEditing';
import Contact from '../Pages/Contact';
import ContactGroups from '../Pages/ContactGroups';
import ListAudience from '../Pages/ContactGroups/listAudience';
import Analytics from '../Pages/Analytics';
import RegistrationAnalytics from '../Pages/Analytics/registrationAnalytics';
import UserProfile from '../Pages/Profile';
import ForgotPassword from '../Pages/ForgotPassword';
import ResetPassword from '../Pages/ResetPassword';
import EmailVerified from '../Pages/EmailVerified';
import CompleteRegistration from '../Pages/CompleteRegistration';
import CreateEvent from '../Pages/CreateEvent';
import CreateYEvent from '../Pages/CreateEvent/index2';
import UpdateEvent from '../Pages/UpdateEvent';
import ProjectType from '../Pages/ProjectType/view';
import ProjectTypeEdit from '../Pages/ProjectType/edit';
import EventHomePage from '../Pages/EventHomePage';
import EventRegisteredUser from '../Pages/EventRegisteredUser';
import EditLibTemplate from '../Pages/PageDesign/editLibTemplate';
import Settings from '../Pages/Settings';
import Projects from '../Pages/Projects';
import CreateProject from '../Pages/Projects/CreateProject';
import Dashboard from '../Pages/Dashboard';
import ProjectDashboard from '../Pages/ProjectDashboard';
import Agenda from '../Pages/Agenda';
import Report from '../Pages/Report';
import Register from '../Pages/Register';

// Meeting Page Components
import PageDesignList from '../Pages/PageDesign/PageDesignList';
import ChoosePageDesign from '../Pages/PageDesign/ChoosePageDesign';
import PageDesign from '../Pages/PageDesign';
//import TransactionalDesign from '../Pages/TransactionalDesign';

import LoginFormDesign from '../Pages/LoginFormDesign';
import EmailDashboard from '../Pages/CompleteEmail';
import ChooseEmailTemplate from '../Pages/CompleteEmail/ChooseEmailTemplate';
import EditTemplate from '../Pages/CompleteEmail/EditTemplate';
import Reviews from '../Pages/CompleteEmail/Reviews';
import Resipients from '../Pages/CompleteEmail/Resipients';
import EditTemplateDesign from '../Pages/CompleteEmail/EditTemplateDesign';
import Invitation from '../Pages/Invitation';
import Notifications from '../Pages/Notifications';
import EmailPerformance from '../Pages/Analytics/emailPerformance';
import CreateEmailCampaign from '../Pages/EmailCampaign';
import ChooseEmailtemplate from '../Pages/EmailCampaign/ChooseEmailtemplate';
import Reschedule from '../Pages/EmailCampaign/reschedule';
import ImportLogs from '../Pages/Contact/rejectContacts';
import ChooseTemplate from '../Pages/EventPage/chooseTemplate';

import CreateEventPage from '../Pages/EventPage/createEventPage';
import ProjectEditortPage from '../Pages/ProjectDashboard/ManagePage/projectEditorPage';
import TransactionEmailEditor from '../Pages/ProjectDashboard/ManagePage/transactionEmailEditor';
import BadgesTemplateEditor from '../Pages/ProjectDashboard/ManagePage/badgesTemplateEditor';
import ManageBadgeSettings from '../Pages/ProjectDashboard/ManagePage/ManageBadgeSettings';
import EventPageListing from '../Pages/EventPage/eventPageListing';
import Impersonate from '../Pages/AutoLogin/impersonate';
import WixLogin from '../Pages/AutoLogin/wixLogin';
import EditProject from '../Pages/Projects/EditProject';
import AddNotification from '../Pages/Dashboard/Notications/addNotification';
import NotificationsDashboard from '../Pages/Dashboard/Notications/NotificationsDashboard';
import AddNewPage from '../Pages/ProjectDashboard/ManagePage/AddNewPage';
import SelectPageTemplate from '../Pages/ProjectDashboard/ManagePage/SelectPageTemplate';
import ManagePageSettings from '../Pages/ProjectDashboard/ManagePage/ManagePageSettings';

import CreateCampaign from '../Pages/ProjectDashboard/Campaigns/CreateCampaign';
import SelectCampaignTemplate from '../Pages/ProjectDashboard/Campaigns/SelectCampaignTemplate';
import CampaignsEditor from '../Pages/ProjectDashboard/Campaigns/CampaignsEditor';
import Editor from '../Pages/ProjectDashboard/Meetings/Editor';
import CreateMeeting from '../Pages/ProjectDashboard/Meetings/CreateMeeting';

import EditCampaign from '../Pages/ProjectDashboard/Campaigns/EditCampaign';
import PagePreview from '../Pages/ProjectDashboard/ManagePage/PagePreview';
import CampaignPreview from '../Pages/ProjectDashboard/Campaigns/campaignPreview';
import { Url, showLogin } from '../constants/constants';
import CampaignPreviewWithExit from '../Pages/ProjectDashboard/Campaigns/campaignPreviewWithExit';
import { TransactionalEmailPreview } from '../Pages/ProjectDashboard/Campaigns/transactionalEmailPreview';
import { NotificationContainer } from 'react-notifications';
import ImportModal from '../Pages/ContactGroups/importModal';
import DashboardProfile from 'Pages/DashboardProfile.js/Index';
import GoogleSignIn from 'Pages/GoogleSignIn/GoogleSignIn';
import MeetingEmailPreviewForReport from 'Pages/ProjectDashboard/Meetings/MeetingEmailPreviewForReport';
import { UserData } from 'helpers/common';
import AiChatbot from 'Pages/AIChatbot/';
import Integrations from 'Pages/Integrations';
import Emails from 'Pages/EmailIntegration';
import OpportunityLayout from 'Pages/Opportunity/OpportunityLayout';
import FileUpload from '../Pages/Files/FileUpload';
import OpportunityTransactionEmailEditor from 'Pages/Opportunity/Opportunity/OpportunityTransactionEmailEditor';
import ContactDetails from 'Pages/Opportunity/Contacts/ContactsView/ContactDetails';
import AccountView from 'Pages/Opportunity/Accounts/AccountView';

import openApiAuth from 'Pages/openApiAuth';
import ImportWizardContextProvider from 'Contexts/ImportWizardContextProvider';
import OpportunityContactsContextProvider from 'Contexts/OpportunityContactsProvider';
import { OpportunityAccountsContextProvider } from 'Contexts/OpportunityAccountsProvider';
import OpportunityOpportunitiesContextProvider from 'Contexts/OpportunityOpportunitiesProvider';
import withCustomRouter from '../Common/withCustomRouter';
import { createNotification } from 'helpers';
import { xpAccountIntegrationGmailGetGmailId } from 'ReduxStore/API';
import ConfirmAccount from '../Pages/ConfirmAccount';
import EmailList from 'Pages/EmailIntegration/EmailList';
import Compose from 'Pages/EmailIntegration/Compose';
import GmailView from 'Pages/EmailIntegration/gmailView';
class ApplicationRoutes extends React.Component {
  constructor(props) {
    super(props);
    this.changeXpUsersWebSocketConnectionStatus =
      this.changeXpUsersWebSocketConnectionStatus.bind(this);
    this.handleWebSocketMessage = this.handleWebSocketMessage.bind(this);

    this.signalInterval = null;
    this.state = {
      xpUsersWebSocketConnectionCalled: false,
    };
  }

  componentDidMount() {
    const favId = document.getElementById('favicon');
    favId.href = '/favicon1.ico';
    this.props.prouteRquest('/my-events');
    this.checkAndGetGmailIntegrationData();
  }

  componentDidUpdate(prevProps) {
    // if (!this.state.xpUsersWebSocketConnectionCalled) {
    //   if (UserData()) {
    //     const user = UserData();

    //     this.connectWS(user.idToken);
    //     this.setState({ xpUsersWebSocketConnectionCalled: true });
    //   }
    // }

    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.props.prouteRquest(prevProps.location.pathname);
    }
  }

  checkAndGetGmailIntegrationData = async () => {
    try {
      const response = await xpAccountIntegrationGmailGetGmailId();
      if (response?.data?.success) {
        this.props.setGmailConnectionState(true, response.data.details);
      } else {
        this.props.setGmailConnectionState(false, {});
      }
    } catch (error) {
      this.props.setGmailConnectionState(false, {});
      createNotification('error', error.message);
    }
  };

  connectWS(token) {
    let webSocketUrl = process.env.REACT_APP_AWS_USER_WEBSOCKET_URL;
    if (token) {
      webSocketUrl += `?xpToken=bearer ${token}`;
    }
    let socket = new WebSocket(webSocketUrl);
    socket.onopen = (e) => this.handleWebSocketOpen(e, socket);

    socket.onmessage = this.handleWebSocketMessage;

    socket.onclose = (e) => this.handleWebSocketClose(e, token);

    socket.onerror = function (error) {
      console.error('xp users WebSocket error:', error);
    };
  }

  handleWebSocketOpen(event, socket) {
    console.log('xp users WebSocket connection established.');
    this.changeXpUsersWebSocketConnectionStatus(true);
    this.signalInterval = setInterval(() => {
      console.log('after 9 minutes');
      socket.send(
        JSON.stringify({ action: 'ping', message: 'hi, from client' }),
      );
    }, 1000 * 60 * 9); //60*9
  }

  handleWebSocketMessage(event) {
    this.props.setXpUsersWebSocketConnectionMessage(JSON.parse(event.data));
  }

  handleWebSocketClose(event, token) {
    if (this.signalInterval) {
      clearInterval(this.signalInterval);
    }
    this.changeXpUsersWebSocketConnectionStatus(false);
    if (event.wasClean) {
      console.log(
        `xp users WebSocket - [close] Connection closed cleanly, code=${event.code} reason=${event.reason}`,
      );
    } else {
      this.connectWS(token);
    }
  }

  changeXpUsersWebSocketConnectionStatus(status) {
    this.props.changeXpUsersWebSocketConnectionStatus(status);
  }

  render() {
    return (
      <>
        <Routes>
          {/* Regular Routes */}
          <Route
            path="/"
            element={
              UserData() ? (
                <Navigate to="/my-events" />
              ) : showLogin ? (
                <Login
                  navigate={this.props.navigate}
                  location={this.props.location}
                  params={this.props.params}
                />
              ) : (
                <Navigate to={Url.wixUrl} />
              )
            }
          />

          <Route
            path="/register"
            element={
              <Register
                navigate={this.props.navigate}
                location={this.props.location}
                params={this.props.params}
              />
            }
          />
          <Route
            path="/user/confirm-account"
            element={
              <ConfirmAccount
                navigate={this.props.navigate}
                location={this.props.location}
                params={this.props.params}
              />
            }
          />

          <Route
            path="/auto-login/:hash"
            element={
              <Impersonate
                navigate={this.props.navigate}
                location={this.props.location}
                params={this.props.params}
              />
            }
          />

          <Route
            path="/app/:hash"
            element={
              <WixLogin
                navigate={this.props.navigate}
                location={this.props.location}
                params={this.props.params}
              />
            }
          />

          <Route
            path="/forgotPassword"
            element={
              <ForgotPassword
                navigate={this.props.navigate}
                location={this.props.location}
                params={this.props.params}
              />
            }
          />

          <Route
            path="/resetPassword/:token"
            element={
              <ResetPassword
                navigate={this.props.navigate}
                location={this.props.location}
                params={this.props.params}
              />
            }
          />

          <Route
            path="/emailVerified/:token"
            element={
              <EmailVerified
                navigate={this.props.navigate}
                location={this.props.location}
                params={this.props.params}
              />
            }
          />

          <Route
            path="/openApi/auth"
            element={
              <openApiAuth
                navigate={this.props.navigate}
                location={this.props.location}
                params={this.props.params}
              />
            }
          />

          <Route
            path="/notification-dashboard/:ProjectId"
            element={
              <PrivateUserRoute
                element={
                  <NotificationsDashboard
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />

          <Route
            path="/project-page/update-badges-template-page/pid/:pid/tid/:tid/pn/:pn/purl/:purl"
            element={
              <PrivateUserRoute
                element={
                  <BadgesTemplateEditor
                    navigate={this.props.navigate}
                location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />

          <Route
            path="/project-badge/update-badge/settings/pid/:pid/tid/:tid/pn/:pn/purl/:purl"
            element={
              <PrivateUserRoute
                element={
                  <ManageBadgeSettings
                    navigate={this.props.navigate}
                    location={this.props.location}
                  />
                }
              />
            }
          />

          <Route
            path="/ImportContact"
            element={
              <PrivateUserRoute
                element={
                  <ImportModal
                    navigate={this.props.navigate}
                    location={this.props.location}
                  />
                }
              />
            }
          />

          <Route
            path="/Settings"
            element={
              <PrivateUserRoute
                element={
                  <Settings
                    navigate={this.props.navigate}
                    location={this.props.location}
                  />
                }
              />
            }
          />

          <Route
            path="/Projects"
            element={
              <PrivateUserRoute
                element={
                  <Projects
                    navigate={this.props.navigate}
                    location={this.props.location}
                  />
                }
              />
            }
          />

          <Route
            path="/CreateProject"
            element={
              <PrivateUserRoute
                element={
                  <CreateProject
                    navigate={this.props.navigate}
                    location={this.props.location}
                  />
                }
              />
            }
          />

          <Route
            path="/Dashboard"
            element={
              <PrivateUserRoute
                element={
                  <Dashboard
                    navigate={this.props.navigate}
                    location={this.props.location}
                  />
                }
              />
            }
          />

          <Route
            path="/project-page/update-transactional-email-templates-page/pid/:pid/tid/:tid/pn/:pn/purl/:purl"
            element={
              <PrivateUserRoute
                element={
                  <TransactionEmailEditor
                    navigate={this.props.navigate}
                    location={this.props.location}
                  />
                }
              />
            }
          />

          <Route
            path="/project-page/update-badges-template-page/pid/:pid/tid/:tid/pn/:pn/purl/:purl"
            element={
              <PrivateUserRoute
                element={
                  <BadgesTemplateEditor
                    navigate={this.props.navigate}
                    location={this.props.location}
                  />
                }
              />
            }
          />

          <Route
            path="/roles"
            element={
              <PrivateUserRoute
                element={
                  <Settings
                    navigate={this.props.navigate}
                    location={this.props.location}
                  />
                }
              />
            }
          />

          <Route
            path="/update-notifications/:notiId/:projectId"
            element={
              <PrivateUserRoute
                element={
                  <AddNotification
                    navigate={this.props.navigate}
                    location={this.props.location}
                  />
                }
              />
            }
          />

          <Route
            path="/billing"
            element={
              <PrivateUserRoute
                element={
                  <Settings
                    navigate={this.props.navigate}
                    location={this.props.location}
                  />
                }
              />
            }
          />

          <Route
            path="/users"
            element={
              <PrivateUserRoute
                element={
                  <Settings
                    navigate={this.props.navigate}
                    location={this.props.location}
                  />
                }
              />
            }
          />

          <Route
            path="/general"
            element={
              <PrivateUserRoute
                element={
                  <Settings
                    navigate={this.props.navigate}
                    location={this.props.location}
                  />
                }
              />
            }
          />

          <Route
            path="/yourPlan"  
            element={
              <PrivateUserRoute
                element={
                  <Settings
                    navigate={this.props.navigate}
                    location={this.props.location}
                  />
                }
              />
            }
          />

          <Route
            path="/api-key"
            element={
              <PrivateUserRoute
                element={
                  <Settings
                    navigate={this.props.navigate}
                    location={this.props.location}
                  />
                }
              />
            }
          />

          <Route
            path="/customization"
            element={
              <PrivateUserRoute
                element={
                  <Settings
                    navigate={this.props.navigate}
                    location={this.props.location}
                  />
                }
              />
            }
          />

          <Route
            path="/project-dashboard/google-sign-in/:eventId"
            element={
              <PrivateUserRoute
                element={
                  <GoogleSignIn
                    navigate={this.props.navigate}
                    location={this.props.location}
                  />
                }
              />
            }
          />

          <Route
            path="/report"
            element={
              <PrivateUserRoute
                element={
                  <Report
                    navigate={this.props.navigate}
                    location={this.props.location}
                  />
                }
              />
            }
          />

          <Route
            path="/aichatbot"
            element={
              <PrivateUserRoute
                element={
                  <AiChatbot
                    navigate={this.props.navigate}
                    location={this.props.location}
                  />
                }
              />
            }
          />

          <Route
            path="/integrations" 
            element={
              <PrivateUserRoute
                element={
                  <Integrations
                    navigate={this.props.navigate}
                    location={this.props.location}
                  />
                }
              />
            }
          />

          {/* <Route
            path="/emails"
            element={<Emails navigate={this.props.navigate} location={this.props.location} />}
          /> */}
          <Route
            path="/emails/drafts/compose/:draftId"
            element={
              <PrivateUserRoute
                element={
                  <Compose
                    navigate={this.props.navigate}
                    location={this.props.location}
                  />
                }
              />
            }
          />
          <Route
            path="/emails/compose"
            element={
              <PrivateUserRoute
                element={
                  <Compose
                    navigate={this.props.navigate}
                    location={this.props.location}
                  />
                }
              />
            }
          />
          <Route
            path="/emails/inbox"
            element={
              <PrivateUserRoute
                element={
                  <EmailList
                    navigate={this.props.navigate}
                    location={this.props.location}
                  />
                }
              />
            }
          />
          <Route
            path="/emails/inbox/gmailView/:threadId"
            element={
              <PrivateUserRoute
                element={
                  <GmailView
                    navigate={this.props.navigate}
                    location={this.props.location}
                  />
                }
              />
            }
          />
          <Route
            path="/emails/drafts"
            element={
              <PrivateUserRoute
                element={
                  <EmailList
                    navigate={this.props.navigate}
                    location={this.props.location}
                  />
                }
              />
            }
          />
          <Route
            path="/emails/sent"
            element={
              <PrivateUserRoute
                element={
                  <EmailList
                    navigate={this.props.navigate}
                    location={this.props.location}
                  />
                }
              />
            }
          />
          <Route
            path="/emails/trash"
            element={
              <PrivateUserRoute
                element={
                  <EmailList
                    navigate={this.props.navigate}
                    location={this.props.location}
                  />
                }
              />
            }
          />
          <Route
            path="/emails/bookmark"
            element={
              <PrivateUserRoute
                element={
                  <EmailList
                    navigate={this.props.navigate}
                    location={this.props.location}
                  />
                }
              />
            }
          />

          <Route
            path="/aichatbot/general"
            element={
              <PrivateUserRoute
                element={
                  <AiChatbot
                    navigate={this.props.navigate}
                    location={this.props.location}
                  />
                }
              />
            }
          />

          <Route
            path="/aichatbot/general/:id"
            element={
              <PrivateUserRoute
                element={
                  <AiChatbot
                    navigate={this.props.navigate}
                    location={this.props.location}
                  />
                }
              />
            }
          />

          <Route
            path="/aichatbot/chatwidget/:id"
            element={
              <PrivateUserRoute
                element={
                  <AiChatbot
                    navigate={this.props.navigate}
                    location={this.props.location}
                  />
                }
              />
            }
          />
          <Route
            path="/aichatbot/settings/:id"
            element={
              <PrivateUserRoute
                element={
                  <AiChatbot
                    navigate={this.props.navigate}
                    location={this.props.location}
                  />
                }
              />
            }
          />
          <Route
            path="/aichatbot/dashboard/:id"
            element={
              <PrivateUserRoute
                element={
                  <AiChatbot
                    navigate={this.props.navigate}
                    location={this.props.location}
                  />
                }
              />
            }
          />
          <Route
            path="/aichatbot/integrations/:id"
            element={
              <PrivateUserRoute
                element={
                  <AiChatbot
                    navigate={this.props.navigate}
                    location={this.props.location}
                  />
                }
              />
            }
          />
          <Route
            path="/member/invitation/:status/:token"
            element={
              <Invitation
                navigate={this.props.navigate}
                location={this.props.location}
              />
            }
          />

          

          <Route
            path="*"
            element={
              <PageNotFound
                navigate={this.props.navigate}
                location={this.props.location}
              />
            }
          />
               {/* PrivateUserRoute */}
          <Route
            path="/opportunity/accounts"
            element={
              <PrivateUserRoute
                element={
                  <OpportunityOpportunitiesContextProvider>
                    <OpportunityAccountsContextProvider>
                      <OpportunityContactsContextProvider>
                        <ImportWizardContextProvider>
                          <OpportunityLayout
                            navigate={this.props.navigate}
                            location={this.props.location}
                          />
                        </ImportWizardContextProvider>
                      </OpportunityContactsContextProvider>
                    </OpportunityAccountsContextProvider>
                  </OpportunityOpportunitiesContextProvider>
                }
              />
            }
          />

          <Route
            path="/opportunity/opportunity"
            element={
              <PrivateUserRoute
                element={
                  <OpportunityOpportunitiesContextProvider>
                    <OpportunityAccountsContextProvider>
                      <OpportunityContactsContextProvider>
                        <ImportWizardContextProvider>
                          <OpportunityLayout
                            navigate={this.props.navigate}
                            location={this.props.location}
                          />
                        </ImportWizardContextProvider>
                      </OpportunityContactsContextProvider>
                    </OpportunityAccountsContextProvider>
                  </OpportunityOpportunitiesContextProvider>
                }
              />
            }
          />

          <Route
            path="/opportunity/contacts"
            element={
              <PrivateUserRoute
                element={
                  <OpportunityOpportunitiesContextProvider>
                    <OpportunityAccountsContextProvider>
                      <OpportunityContactsContextProvider>
                        <ImportWizardContextProvider>
                          <OpportunityLayout
                            navigate={this.props.navigate}
                            location={this.props.location}
                          />
                        </ImportWizardContextProvider>
                      </OpportunityContactsContextProvider>
                    </OpportunityAccountsContextProvider>
                  </OpportunityOpportunitiesContextProvider>
                }
              />
            }
          />
          <Route
            path="/opportunity/contactdetail/:id"
            element={
              <PrivateUserRoute
                element={
                  <OpportunityOpportunitiesContextProvider>
                    <OpportunityAccountsContextProvider>
                      <OpportunityContactsContextProvider>
                        <ImportWizardContextProvider>
                          <OpportunityLayout
                            navigate={this.props.navigate}
                            location={this.props.location}
                          />
                        </ImportWizardContextProvider>
                      </OpportunityContactsContextProvider>
                    </OpportunityAccountsContextProvider>
                  </OpportunityOpportunitiesContextProvider>
                }
              />
            }
          />
          <Route
            path="/opportunity/opportunityview/:id"
            element={
              <PrivateUserRoute
                element={
                  <OpportunityOpportunitiesContextProvider>
                    <OpportunityAccountsContextProvider>
                      <OpportunityContactsContextProvider>
                        <ImportWizardContextProvider>
                          <OpportunityLayout
                            navigate={this.props.navigate}
                            location={this.props.location}
                          />
                        </ImportWizardContextProvider>
                      </OpportunityContactsContextProvider>
                    </OpportunityAccountsContextProvider>
                  </OpportunityOpportunitiesContextProvider>
                }
              />
            }
          />

          <Route
            path="/opportunity/accountview/:id"
            element={
              <PrivateUserRoute
                element={
                  <OpportunityOpportunitiesContextProvider>
                    <OpportunityAccountsContextProvider>
                      <OpportunityContactsContextProvider>
                        <ImportWizardContextProvider>
                          <OpportunityLayout
                            navigate={this.props.navigate}
                            location={this.props.location}
                          />
                        </ImportWizardContextProvider>
                      </OpportunityContactsContextProvider>
                    </OpportunityAccountsContextProvider>
                  </OpportunityOpportunitiesContextProvider>
                }
              />
            }
          />

          <Route
            path="/opportunity/audience"
            element={
              <PrivateUserRoute
                element={
                  <OpportunityOpportunitiesContextProvider>
                    <OpportunityAccountsContextProvider>
                      <OpportunityContactsContextProvider>
                        <ImportWizardContextProvider>
                          <OpportunityLayout
                            navigate={this.props.navigate}
                            location={this.props.location}
                          />
                        </ImportWizardContextProvider>
                      </OpportunityContactsContextProvider>
                    </OpportunityAccountsContextProvider>
                  </OpportunityOpportunitiesContextProvider>
                }
              />
            }
          />

          <Route
            path="/opportunity/contactGroups"
            element={
              <PrivateUserRoute
                element={
                  <OpportunityOpportunitiesContextProvider>
                    <OpportunityAccountsContextProvider>
                      <OpportunityContactsContextProvider>
                        <ImportWizardContextProvider>
                          <OpportunityLayout
                            navigate={this.props.navigate}
                            location={this.props.location}
                          />
                        </ImportWizardContextProvider>
                      </OpportunityContactsContextProvider>
                    </OpportunityAccountsContextProvider>
                  </OpportunityOpportunitiesContextProvider>
                }
              />
            }
          />
          <Route
            path="/opportunity/opportunity/update-transactional-email-templates"
            element={
              <PrivateUserRoute
                element={
                  <OpportunityTransactionEmailEditor
                    navigate={this.props.navigate}
                    location={this.props.location}
                  />
                }
              />
            }
          />

  
          <Route
            path="/add-new-page/:eventId"
            element={
              <PrivateUserRoute
                element={
                  <AddNewPage
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/my-events"
            element={
              <PrivateUserRoute
                element={
                  <Eventlist
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/project-page/update-page/pid/:pid/tid/:tid/pn/:pn/purl/:purl"
            element={
              <PrivateUserRoute
                element={
                  <ProjectEditortPage
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/project-dashboard/overview/:eventId"
            element={<PrivateUserRoute element={<ProjectDashboard />} />}
          />
          <Route
            path="/project-dashboard/manage-pages/:eventId"
            element={
              <PrivateUserRoute
                element={
                  <ProjectDashboard
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/project-dashboard/preview-page/:eventId"
            element={
              <PrivateUserRoute
                element={
                  <PagePreview
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/project-dashboard/campaigns/create-campaign/:ei"
            element={
              <PrivateUserRoute
                element={
                  <CreateCampaign
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/update-notification/:notificationId"
            element={
              <PrivateUserRoute
                element={
                  <AddNotification
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/project-dashboard/campaigns/select-template/ei/:ei/cn/:cn"
            element={
              <PrivateUserRoute
                element={
                  <SelectCampaignTemplate
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/project-dashboard/campaign-template-editor/tid/:tid/cn/:cn/eid/:eid"
            element={
              <PrivateUserRoute
                element={
                  <CampaignsEditor
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/project-dashboard/meeting-editor/:eventId"
            element={
              <PrivateUserRoute
                element={
                  <Editor
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/meetings/create-meeting"
            element={
              <PrivateUserRoute
                element={
                  <CreateMeeting
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/project-dashboard/edit-campaign/cn/:cn/eid/:eid"
            element={
              <PrivateUserRoute
                element={
                  <EditCampaign
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/project-dashboard/preview-campaign/:eventId"
            element={
              <PrivateUserRoute
                element={
                  <CampaignPreview
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/project-dashboard/preview-campaign-with-exit/:eventId"
            element={
              <PrivateUserRoute
                element={
                  <CampaignPreviewWithExit
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/project-dashboard/preview-transactional/:eventId"
            element={
              <PrivateUserRoute
                element={
                  <TransactionalEmailPreview
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/project-dashboard/preview-meeting/:eventId/:email"
            element={
              <PrivateUserRoute
                element={
                  <MeetingEmailPreviewForReport
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/project-dashboard/campaigns/:eventId"
            element={
              <PrivateUserRoute
                element={
                  <ProjectDashboard
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/project-dashboard/campaigns/edit/:eventId"
            element={
              <PrivateUserRoute
                element={
                  <ProjectDashboard
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/project-dashboard/campaigns/create-campaign/:ei/"
            element={
              <PrivateUserRoute
                element={
                  <CreateCampaign
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/project-dashboard/meeting-edior/:eventId"
            element={
              <PrivateUserRoute
                element={
                  <Editor
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/project-dashboard/analytics/:eventId"
            element={
              <PrivateUserRoute
                element={
                  <ProjectDashboard
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/project-dashboard/agenda/:eventId"
            element={
              <PrivateUserRoute
                element={
                  <ProjectDashboard
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/project-dashboard/notifications/:eventId"
            element={
              <PrivateUserRoute
                element={
                  <ProjectDashboard
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/project-dashboard/access-code/:eventId"
            element={
              <PrivateUserRoute
                element={
                  <ProjectDashboard
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/project-dashboard/team/:eventId"
            element={
              <PrivateUserRoute
                element={
                  <ProjectDashboard
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/project-dashboard/attendees/:eventId"
            element={
              <PrivateUserRoute
                element={
                  <ProjectDashboard
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/project-dashboard/integration/:eventId"
            element={
              <PrivateUserRoute
                element={
                  <ProjectDashboard
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/project-dashboard/meetings/settings/:eventId"
            element={
              <PrivateUserRoute
                element={
                  <ProjectDashboard
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/project-dashboard/meetings/:eventId"
            element={
              <PrivateUserRoute
                element={
                  <ProjectDashboard
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/project-dashboard/gamification/challenges/:eventId"
            element={
              <PrivateUserRoute
                element={
                  <ProjectDashboard
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/project-dashboard/gamification/configuration/:eventId"
            element={
              <PrivateUserRoute
                element={
                  <ProjectDashboard
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/project-dashboard/gamification/leaderboard/:eventId"
            element={
              <PrivateUserRoute
                element={
                  <ProjectDashboard
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/events-list"
            element={
              <PrivateUserRoute
                element={
                  <Eventlist
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/event-home"
            element={
              <PrivateUserRoute
                element={
                  <EventHomePage
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/audience-contacts/:id?"
            element={
              <PrivateUserRoute
                element={
                  <ContactGroups
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/audience/:id?"
            element={
              <PrivateUserRoute
                element={
                  <ListAudience
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/analytics/:eventId?/:templateId?"
            element={
              <PrivateUserRoute
                element={
                  <Analytics
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/registration-analytics/:eventId?/:templateId?"
            element={
              <PrivateUserRoute
                element={
                  <RegistrationAnalytics
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/user-profile"
            element={
              <PrivateUserRoute
                element={
                  <UserProfile
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/profile"
            element={
              <PrivateUserRoute
                element={
                  <DashboardProfile
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/complete-registration"
            element={
              <PrivateUserRoute
                element={
                  <CompleteRegistration
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/create-event"
            element={
              <PrivateUserRoute
                element={
                  <CreateEvent
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/create-project"
            element={
              <PrivateUserRoute
                element={
                  <CreateProject
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/create-your-event"
            element={
              <PrivateUserRoute
                element={
                  <CreateYEvent
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/agenda"
            element={
              <PrivateUserRoute
                element={
                  <Agenda
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/email"
            element={
              <PrivateUserRoute
                element={
                  <EmailDashboard
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/choose-template"
            element={
              <PrivateUserRoute
                element={
                  <ChooseEmailTemplate
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/edit-template"
            element={
              <PrivateUserRoute
                element={
                  <EditTemplate
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/email-campaigns/:eventId"
            element={
              <PrivateUserRoute
                element={
                  <CreateEmailCampaign
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/choose-email-template/:eventId"
            element={
              <PrivateUserRoute
                element={
                  <ChooseEmailtemplate
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/reschedule/:eventId/:templateId"
            element={
              <PrivateUserRoute
                element={
                  <Reschedule
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/choose-event-template/:eventId"
            element={
              <PrivateUserRoute
                element={
                  <ChooseTemplate
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/edit-lib-template/eid/:eventId/tid/:templateId"
            element={
              <PrivateUserRoute
                element={
                  <EditLibTemplate
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/email-performance/:eventId?/:templateId?"
            element={
              <PrivateUserRoute
                element={
                  <EmailPerformance
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/review/:eventId"
            element={
              <PrivateUserRoute
                element={
                  <Reviews
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/recipients/:eventId/:templateId"
            element={
              <PrivateUserRoute
                element={
                  <Resipients
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/project-type/:eventId"
            element={
              <PrivateUserRoute
                element={
                  <ProjectType
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/project-type-edit/:eventId"
            element={
              <PrivateUserRoute
                element={
                  <ProjectTypeEdit
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/login-form-design/:eventId"
            element={
              <PrivateUserRoute
                element={
                  <LoginFormDesign
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/event-page-list/:eventId"
            element={
              <PrivateUserRoute
                element={
                  <EventPageListing
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/event-registered-user/"
            element={
              <PrivateUserRoute
                element={
                  <EventRegisteredUser
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/choose-page-design/:eventId"
            element={
              <PrivateUserRoute
                element={
                  <ChoosePageDesign
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/page-design/:eventId"
            element={
              <PrivateUserRoute
                element={
                  <PageDesign
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/edit-meeting-page/eid/:eid/tid/:tid"
            element={
              <PrivateUserRoute
                element={
                  <PageDesign
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/edit-login-page/eid/:eid/tid/:tid"
            element={
              <PrivateUserRoute
                element={
                  <PageDesign
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/edit-event-form/eid/:eid/tid/:tid"
            element={
              <PrivateUserRoute
                element={
                  <PageDesign
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/registration-editing/:eventId"
            element={
              <PrivateUserRoute
                element={
                  <RegistrationEditing
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/edit-template-design/:eventId/:mode/:templateId"
            element={
              <PrivateUserRoute
                element={
                  <EditTemplateDesign
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/update-event/:eventId"
            element={
              <PrivateUserRoute
                element={
                  <UpdateEvent
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/edit-project/:projectId"
            element={
              <PrivateUserRoute
                element={
                  <EditProject
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/notifications"
            element={
              <PrivateUserRoute
                element={
                  <Notifications
                    navigate={this.props.navigate}
                    location={this.props.location}
                  />
                }
              />
            }
          />
          <Route
            path="/importlogs"
            element={
              <PrivateUserRoute
                element={
                  <ImportLogs
                    navigate={this.props.navigate}
                    location={this.props.location}
                  />
                }
              />
            }
          />
          <Route
            path="/choose-page-design/:eventId"
            element={
              <PrivateUserRoute
                element={
                  <ChoosePageDesign
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/choose-meeting-page/:eventId"
            element={
              <PrivateUserRoute
                element={(props) => (
                  <ChoosePageDesign
                    {...props}
                    type="Meeting"
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                )}
              />
            }
          />
          <Route
            path="/choose-form-design/:eventId"
            element={
              <PrivateUserRoute
                element={(props) => (
                  <ChoosePageDesign
                    {...props}
                    type="registrationForm"
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                )}
              />
            }
          />
          <Route
            path="/choose-login-page/:eventId"
            element={
              <PrivateUserRoute
                element={(props) => (
                  <ChoosePageDesign
                    {...props}
                    type="Login"
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                )}
              />
            }
          />
          <Route
            path="/select-page-template/pid/:eventId/pn/:pageName/purl/:pageNameUrl"
            element={
              <PrivateUserRoute
                element={
                  <SelectPageTemplate
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/project-page/pid/:pid/tid/:tid/pn/:pn/purl/:purl"
            element={
              <PrivateUserRoute
                element={
                  <ProjectEditortPage
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/project-dashboard/campaign-template-edior/tid/:tid/cn/:cn/eid/:eid"
            element={
              <PrivateUserRoute
                element={
                  <CampaignsEditor
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/files/home"
            element={
              <PrivateUserRoute
                element={
                  <FileUpload
                    navigate={this.props.navigate}
                    location={this.props.location}
                  />
                }
              />
            }
          />
          <Route
            path="/files/images"
            element={
              <PrivateUserRoute
                element={
                  <FileUpload
                    navigate={this.props.navigate}
                    location={this.props.location}
                  />
                }
              />
            }
          />
          <Route
            path="/files/docs"
            element={
              <PrivateUserRoute
                element={
                  <FileUpload
                    navigate={this.props.navigate}
                    location={this.props.location}
                  />
                }
              />
            }
          />
          <Route
            path="/files/videos"
            element={
              <PrivateUserRoute
                element={
                  <FileUpload
                    navigate={this.props.navigate}
                    location={this.props.location}
                  />
                }
              />
            }
          />

          <Route
            path="/files/images/:folderId*"
            element={
              <PrivateUserRoute
                element={
                  <FileUpload
                    navigate={this.props.navigate}
                    location={this.props.location}
                  />
                }
              />
            }
          />

          <Route
            path="/files/home/:folderId*"
            element={
              <PrivateUserRoute
                element={
                  <FileUpload
                    navigate={this.props.navigate}
                    location={this.props.location}
                  />
                }
              />
            }
          />

          <Route
            path="/files/videos/:folderId*"
            element={
              <PrivateUserRoute
                element={
                  <FileUpload
                    navigate={this.props.navigate}
                    location={this.props.location}
                  />
                }
              />
            }
          />

          <Route
            path="/files/docs/:folderId*"
            element={
              <PrivateUserRoute
                element={
                  <FileUpload
                    navigate={this.props.navigate}
                    location={this.props.location}
                  />
                }
              />
            }
          />

          <Route
            path="/project-page/update-page/settings/pid/:pid/tid/:tid/pn/:pn/purl/:purl"
            element={
              <PrivateUserRoute
                element={
                  <ManagePageSettings
                    navigate={this.props.navigate}
                    location={this.props.location}
                  />
                }
              />
            }
          />

          <Route
            path="/project-page/update-page/settings/pid/:pid/tid/:tid/pn/:pn/purl/:purl"
            element={
              <PrivateUserRoute
                element={
                  <ManagePageSettings
                    navigate={this.props.navigate}
                    location={this.props.location}
                  />
                }
              />
            }
          />
          {/* PrivateMemberRoute */}
          <Route
            path="/member/my-events"
            element={
              <PrivateMemberRoute
                element={
                  <Eventlist
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/member/events-list"
            element={
              <PrivateMemberRoute
                element={
                  <Eventlist
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/member/event-home"
            element={
              <PrivateMemberRoute
                element={
                  <EventHomePage
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/member/contact"
            element={
              <PrivateMemberRoute
                element={
                  <Contact
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/member/audience-contacts/:id?"
            element={
              <PrivateMemberRoute
                element={
                  <ContactGroups
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/member/analytics/:eventId?/:templateId?"
            element={
              <PrivateMemberRoute
                element={
                  <Analytics
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/member/registration-analytics/:eventId?/:templateId?"
            element={
              <PrivateMemberRoute
                element={
                  <RegistrationAnalytics
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/member/profile"
            element={
              <PrivateMemberRoute
                element={
                  <UserProfile
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/member/complete-registration"
            element={
              <PrivateMemberRoute
                element={
                  <CompleteRegistration
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/member/create-event"
            element={
              <PrivateMemberRoute
                element={
                  <CreateEvent
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/member/update-event/eventId"
            element={
              <PrivateMemberRoute
                element={
                  <UpdateEvent
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/member/notifications"
            element={
              <PrivateMemberRoute
                element={
                  <Notifications
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/member/email"
            element={
              <PrivateMemberRoute
                element={
                  <EmailDashboard
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/member/choose-template"
            element={
              <PrivateMemberRoute
                element={
                  <ChooseEmailTemplate
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/member/edit-template"
            element={
              <PrivateMemberRoute
                element={
                  <EditTemplate
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/member/email-campaigns/:eventId"
            element={
              <PrivateMemberRoute
                element={
                  <CreateEmailCampaign
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/member/choose-email-template/:eventId"
            element={
              <PrivateMemberRoute
                element={
                  <ChooseEmailtemplate
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/member/choose-event-template/:eventId"
            element={
              <PrivateMemberRoute
                element={
                  <ChooseEmailtemplate
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/member/email-performance/:eventId?/:templateId?"
            element={
              <PrivateMemberRoute
                element={
                  <EmailPerformance
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/member/review/:eventId"
            element={
              <PrivateMemberRoute
                element={
                  <Reviews
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/member/reschedule/:eventId/:templateId"
            element={
              <PrivateMemberRoute
                element={
                  <Reschedule
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/member/resipients/:eventId/:templateId"
            element={
              <PrivateMemberRoute
                element={
                  <Resipients
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/member/project-type/:eventId"
            element={
              <PrivateMemberRoute
                element={
                  <ProjectType
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/member/project-type-edit/:eventId"
            element={
              <PrivateMemberRoute
                element={
                  <ProjectTypeEdit
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/member/page-design/:eventId"
            element={
              <PrivateMemberRoute
                element={
                  <PageDesign
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/member/registration-editing/:eventId"
            element={
              <PrivateMemberRoute
                element={
                  <RegistrationEditing
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/member/importlogs"
            element={
              <PrivateMemberRoute
                element={
                  <ImportLogs
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          <Route
            path="/member/edit-template-design/:eventId/:mode/:templateId"
            element={
              <PrivateMemberRoute
                element={
                  <EditTemplateDesign
                    navigate={this.props.navigate}
                    location={this.props.location}
                    params={this.props.params}
                  />
                }
              />
            }
          />
          
        </Routes>
        <NotificationContainer />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  proutes: state.Proutes,
});

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      prouteRquest: Actions.prouteRequest,
      changeXpUsersWebSocketConnectionStatus:
        Actions.changeXpUsersWebSocketConnectionStatus,
      setXpUsersWebSocketConnectionMessage:
        Actions.setXpUsersWebSocketConnectionMessage,
      setGmailConnectionState: Actions.setGmailConnectionState,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(withCustomRouter(ApplicationRoutes));
