 
/* Common helper function to check permissions for user */
/* export const authPermissionValidation = (pids = []) => {
    const userDataLocalStorage = localStorage.getItem('user_details');
    const userData = userDataLocalStorage;
    const user = JSON.parse(userData);
    console.log("~~~systemLevelPermissions authpermission function~~",user.systemLevelPermissions)
    console.log("~~~permissions authpermission function~~",user.permissions)
    if (user?.role === 1 || user?.role === 3) {
        return true;
    } else {
        if (!user || !Array.isArray(user?.permissions)) {
            console.warn("User data or permissions are missing!");
            return false;
        }
        // Ensure `pids` is always treated as an array
        const permissionArray = Array.isArray(pids) ? pids : [pids];
        // Convert `user.permissions` to a set for faster lookup
        const userPermissionsSet = new Set(user.permissions.map(p => p.pid));
        // Check if any of the provided PIDs exist in the user's permissions
        const hasPermission = permissionArray.some(pid => userPermissionsSet.has(pid));
        console.log(`Checking permissions for ${pids}:===> ${hasPermission}`); 
        return hasPermission;
    } 
};
 */

export const authPermissionValidation = (pids = []) => {
    const userDataLocalStorage = localStorage.getItem('user_details');
    if (!userDataLocalStorage) {
        console.warn("User data is missing!");
        return false;
    }

    const user = JSON.parse(userDataLocalStorage); 
    // Allow full access for roles 1 (Admin) & 3 (Co-Admin)
    if (user?.role === 1 || user?.role === 3) {
        return true;
    }

    //  Ensure `pids` is always treated as an array
    const permissionArray = Array.isArray(pids) ? pids : [pids];

    //  Validate `permissions` and `systemLevelPermissions`
    const userPermissions = Array.isArray(user?.permissions) ? user.permissions.map(p => p.pid) : [];
    const systemLevelPermissions = Array.isArray(user?.systemLevelPermissions) ? user.systemLevelPermissions : [];

    //  Convert permissions to a Set for fast lookup
    const userPermissionsSet = new Set([...userPermissions, ...systemLevelPermissions]);

    console.log("userPermissionsSet~~~~~~~",userPermissionsSet)

    //  Check if any of the provided PIDs exist in either permissions array
    const hasPermission = permissionArray.some(pid => userPermissionsSet.has(pid));

    console.log(`Checking permissions for ${pids}: ===> ${hasPermission}`); 
    return hasPermission;
};



