import React from "react";
import { Popover,Stack } from "@mui/material";
import NotificationList from "./NotificationList";
import { useSelector } from "react-redux";
import { NotificationState } from "ReduxStore/Reducers/eijentNotification.reducer";

interface NotificationPopoverProps {
  open: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
}

export default function NotificationPopover({
  open,
  anchorEl,
  onClose,
}: NotificationPopoverProps): JSX.Element { 
   return (
    <>
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      slotProps={{
        paper: {
          sx: {
            marginTop: '6px',
            backgroundColor: '#F4F6F7',
            background: '#F4F6F7 0% 0% no-repeat padding-box',
            boxShadow: '0px 3px 6px #00000029',
            py: 0.5,

          },
        },
      }}
    >
      <NotificationList
       open={open}
       />
    </Popover>
     </>
  );
}
