import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { Actions } from 'ReduxStore/Actions'
import { connect } from 'react-redux' 
import { getProjectIdFromUrl } from '../../../helpers/common'
import Loader from '../../../Components/Loader/loader'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid2'
import { theme } from '../../../theme/theme'
import Avatar from '@mui/material/Avatar'
import BadgeGold from '../../../assets/images/icons/Badge_Gold.svg'
import BadgeSilver from '../../../assets/images/icons/Badge_Silver.svg'
import BadgeBronze from '../../../assets/images/icons/Badge_Bronze.svg'
import moment from 'moment'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { createNotification } from '../../../helpers'
import { NotificationContainer } from 'react-notifications'
import Tooltip from '@mui/material/Tooltip'

import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
} from '@mui/material'
import TextField from '@mui/material/TextField'
import ExportIcon from '../../../assets/images/icons/export.svg'
import InputAdornment from '@mui/material/InputAdornment'

import Rank_1 from '../../../assets/images/icons/Rank_1.svg'
import Rank_2 from '../../../assets/images/icons/Rank_2.svg'
import Rank_3 from '../../../assets/images/icons/Rank_3.svg'
import Rank_4 from '../../../assets/images/icons/Rank_4.svg'
import Rank_5 from '../../../assets/images/icons/Rank_5.svg'
import Rank_6 from '../../../assets/images/icons/Rank_6.svg'
import Rank_7 from '../../../assets/images/icons/Rank_7.svg'
import SearchIcon from '../../../assets/images/icons/search.svg'
import InformationIcon from '../../../assets/images/icons/informationIcon.svg'
import RightArrowIcon from '../../../assets/images/icons/right_arrow_icon.svg'
import SubIcon from '../../../assets/images/icons/sub-icon.svg'
import AddIcon from '../../../assets/images/icons/add-icon.svg'
import Pagination from 'react-js-pagination'
import { Modal } from 'react-responsive-modal'
import CloseIcon from '@mui/icons-material/Close'
import { usePreviousProps } from '@mui/utils'

import CustomTooltip from '../../../Components/CustomHTMLTooltip'
import infoFilledIcon from '../../../assets/images/icons/information-filled.svg'
import emailIcon from '../../../assets/images/icons/email.svg'
import Exporting from '../../../assets/images/export.gif';
import { permissionsConstants } from 'constants/permissionsConstant';


const styles = {
  avatar: {
    backgroundColor: '#EDECF5',
    width: '30px',
    height: '30px',
    fontSize: 12,
    fontWeight: '900',
    color: '#726F8E',
  },
}
export class GamificationLeaderboard extends Component {
  constructor(props) {
    super(props)
    this.isTotalRecords = true
    this.state = {
      projectId: '',
      page_no: 1,
      pageNo: 1,
      limit: 10,
      endCount: 0,
      endCountHistory: 0,
      itemCount: 10,
      itemCountHistory: 10,
      startCount: 1,
      startCountHistory: 1,
      sortOrder: 'asc',
      sort_by: 'rank',
      searchText: '',
      searchTextHistory: '',
      openPoint: false,
      addManualPoints: false,
      editGamificationGrade: false,
      openEngagedUser: false,
      editChallengesList: false,
      emailOpenPoints: '',
      achievement: ['Gold', 'Silver', 'Bronze'],
      leaderBoardList: [],
      noteForAddPoints: '',
      idForEmail: '',
      pointsForAddPoints: '',
      challengeName: '',
      errors: {},
      loadMoreNumber: 0,
      pointsHistoryArr: [],
      disableLoadMorePoints: true,
      itemCount: 10,
      attendeeId: '',
      leaderboardUserHistory: [],
      attendeeEmail: '',
      userHistory: [],
      loading: false,
      isExport: true,
      // dateTime: new Date(),
      orderForUserHistory: 'asc',
      sortBy: 'challengeName',
      downloadExport:false
    }
  }

  componentDidMount() {
    const projectId = getProjectIdFromUrl()
    this.setState(
      {
        projectId,
      },
      () => {
        this.listGamificationLeaderBoards()
      },
    )
    const userData = JSON.parse(localStorage.getItem('user_details'))
    this.setState({
      profileName: `${userData._id}`,
    })
    this.props?.location?.state?.attendeeEmail &&
      this.setState({
        searchText: this.props?.location?.state?.attendeeEmail,
      })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.itemCount !== this.state.itemCount) {
      if (this.state.itemCount < this.state.totalResults) {
        this.setState({
          endCount: parseInt(this.state.itemCount),
        })
      } else {
        this.setState({
          endCount: parseInt(this.props.gamification?.totalResults),
        })
      }
    }

    if (prevState.itemCountHistory !== this.state.itemCountHistory) {
      if (this.state.itemCountHistory < this.state.totalResults) {
        this.setState({
          endCountHistory: parseInt(this.state.itemCountHistory),
        })
      } else {
        this.setState({
          endCountHistory: parseInt(this.props.gamification?.totalResults),
        })
      }
    }

    if (
      this.props.gamification?.listGamificationLeaderBoardsSuccess &&
      !prevProps.gamification?.listGamificationLeaderBoardsSuccess
    ) {
      this.setState(
        {
          leaderBoardList: this.props.gamification.leaderBoardList,
          filterByAchievement: this.props.gamification.filterByAchievement,
          totalResults: this.props.gamification.totalResults,
          loading: false,
          downloadExport:false
        },
        () => {
          if (this.state.itemCount >= this.state.totalResults) {
            this.setState({
              endCount: parseInt(this.state.totalResults),
            })
          } else if (
            this.state.itemCount < this.state.totalResults &&
            this.state.page_no === 1
          ) {
            this.setState({
              endCount: parseInt(this.state.itemCount),
            })
          }
        },
      )
    }

    if (
      this.props.gamification.addPointsGamificationLeaderBoardsSuccess &&
      !prevProps.gamification.addPointsGamificationLeaderBoardsSuccess
    ) {
      this.setState({
        addManualPoints: false,
        challengeName: '',
        pointsForAddPoints: '',
        noteForAddPoints: '',
        loading: false,
      })
      createNotification('success', this.props.gamification.message)
      this.leaderboardEngagedUsers()
    }

    if (
      this.props.gamification.configrationChallengesSuccess &&
      !prevProps.gamification.configrationChallengesSuccess
    ) {
      this.setState({
        challengesList: this.props.gamification.challengesList,
        loading: false,
      })
    }

    if (
      this.props.gamification.leaderBoardEngagedUserSuccess === true &&
      this.props.gamification.leaderboardUserHistory !==
        prevProps.gamification.leaderboardUserHistory
    ) {
      this.setState(
        {
          leaderboardUserHistory:
            this.props.gamification.leaderboardUserHistory,
          totatlHistory: this.props.gamification.totatlHistory,
          loading: false,
        },
        () => {
          if (this.state.itemCountHistory >= this.state.totatlHistory) {
            this.setState({
              endCountHistory: parseInt(this.state.totatlHistory),
            })
          } else if (
            this.state.itemCountHistory < this.state.totatlHistory &&
            this.state.pageNo === 1
          ) {
            this.setState({
              endCountHistory: parseInt(this.state.itemCountHistory),
            })
          }
        },
      )
    }

    if (
      this.props.gamification.error === true &&
      this.props.gamification.error !== prevProps.gamification.error
    ) {
      createNotification('error', this.props.gamification.message)
      this.setState({
        loading: false,
      })
    }
  }

  configrationChallenges = () => {
    const { projectId } = this.state
    const body = {
      page_no: 1,
      searchText: '',
      projectId,
      sort_by: '_id',
      order: 'asc',
    }
    this.props.configrationChallenges(body)
    this.handleLoading()
  }

  handleOpenPoint = (row) => {
    this.setState(
      {
        attendeeId: row._id,
        openPoint: true,
        attendeeEmail: row.attendeeEmail,
      },
      () => {
        this.leaderboardEngagedUsers()
        this.handleLoading()
      },
    )
  }

  listGamificationLeaderBoards = () => {
    try {
      const {
        projectId,
        page_no,
        sortOrder,
        sort_by,
        searchText,
        achievement,
        itemCount,
      } = this.state
      const requestBody = {
        projectId,
        page_no,
        limit: itemCount,
        order: sortOrder,
        sort_by,
        searchText: searchText.trim(),
        achievement,
        permission: 'View Gamification',
      }
      this.props.listGamificationLeaderBoards(requestBody)
      this.handleLoading()
    } catch (error) {}
  }

  exportLeaderboard = () => {
    try {
      const {
        projectId,
        page_no,
        sortOrder,
        sort_by,
        searchText,
        achievement,
        itemCount,
        isExport,
      } = this.state
      const requestBody = {
        projectId,
        page_no,
        limit: itemCount,
        order: sortOrder,
        sort_by,
        searchText: searchText.trim(),
        achievement,
        isExport,
        permission: 'Export Gamification',
      }
      this.props.listGamificationLeaderBoards(requestBody)
      this.setState({
         downloadExport:this.props.gamification.totalResults <=1000  ?  false:true,
         loading :this.props.gamification.totalResults <=1000? true:false,
      })
    } catch (error) {}
  }

  leaderboardEngagedUsers = () => {
    try {
      const {
        projectId,
        searchTextHistory,
        attendeeId,
        itemCountHistory,
        orderForUserHistory,
        sortBy,
        pageNo,
      } = this.state
      const requestBody = {
        projectId,
        searchText: searchTextHistory.trim(),
        attendeeId,
        limit: itemCountHistory,
        order: orderForUserHistory,
        sort_by: sortBy,
        page_no: pageNo,
      }
      this.props.leaderboardEngagedUsers(requestBody)
      this.handleLoading()
    } catch (error) {}
  }
  handleAddManualPoints = (attendeeId) => {
    this.setState(
      {
        attendeeId,
        addManualPoints: true,
      },
      () => this.configrationChallenges(),
    )
  }

  handleClose = (event) => {
    this.setState({
      editGamificationGrade: false,
      openPoint: false,
      openEngagedUser: false,
      editChallengesList: false,
      addManualPoints: false,
      idForEmail: '',
      challengeName: '',
      pointsForAddPoints: '',
      noteForAddPoints: '',
      loadMoreNumber: 0,
      pageNo: 1,
      startCountHistory: 1,
      endCountHistory: 0,
      itemCountHistory: 10,
      searchTextHistory: '',
    })
  }

  handleLoading = () => {
    let loading = this.state.loading
    this.setState({
      loading: !loading,
    })
  }

  handleCloseAddPoints = () => {
    this.setState({
      addManualPoints: false,
      challengeName: '',
      pointsForAddPoints: '',
      noteForAddPoints: '',
    })
  }

  returnBadge = (achievement) => {
    switch (achievement) {
      case 'Gold':
        return BadgeGold
        break
      case 'Silver':
        return BadgeSilver
        break
      case 'Bronze':
        return BadgeBronze
        break
      default:
        break
    }
  }

  sorting = (value) => {
    if (this.state.sort_by === value) {
      if (this.state.sortOrder === 'asc') {
        this.setState(
          {
            sortOrder: 'desc',
          },
          () => this.listGamificationLeaderBoards(),
        )
      } else {
        this.setState(
          {
            sortOrder: 'asc',
          },
          () => this.listGamificationLeaderBoards(),
        )
      }
    } else {
      this.setState(
        {
          sort_by: value,
          sortOrder: 'asc',
        },
        () => this.listGamificationLeaderBoards(),
      )
    }
  }

  historySorting = (value) => {
    if (this.state.sortBy === value) {
      if (this.state.orderForUserHistory === 'asc') {
        this.setState(
          {
            orderForUserHistory: 'desc',
          },
          () => this.leaderboardEngagedUsers(),
        )
      } else {
        this.setState(
          {
            orderForUserHistory: 'asc',
          },
          () => this.leaderboardEngagedUsers(),
        )
      }
    } else {
      this.setState(
        {
          sortBy: value,
          orderForUserHistory: 'asc',
        },
        () => this.leaderboardEngagedUsers(),
      )
    }
  }

  filterByAchievement = (...achievement) => {
    this.setState(
      {
        achievement: achievement,
        page_no: 1,
        searchText: '',
      },
      () => this.listGamificationLeaderBoards(),
    )
  }

  handleChange = (e) => {
    e.preventDefault()
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleSearchSubmit = (e) => {
    e.preventDefault()
    this.isTotalRecords = true
    this.props.gamification.listGamificationLeaderBoardsSuccess = false
    this.setState(
      {
        page_no: 1,
        startCount: 1,
      },
      () => this.listGamificationLeaderBoards(),
    )
  }

  searchUserHistory = (e) => {
    e.preventDefault()
    this.setState(
      {
        pageNo: 1,
        startCountHistory: 1,
      },
      () => this.leaderboardEngagedUsers(),
    )
  }

  getFilterCount = (countOf) => {
    const { filterByAchievement } = this.state
    if (filterByAchievement && Object.keys(filterByAchievement).length) {
      return filterByAchievement[0]['filterByAchievement'][countOf] > 0
        ? filterByAchievement[0]['filterByAchievement'][countOf]
        : 0
    }
  }
  handlePageChangeHistory = (pageNumber) => {
    try {
      const { itemCountHistory } = this.state
      let totatlHistory = this.props.gamification.totatlHistory
      this.setState(
        (prevState, props) => ({
          startCountHistory:
            pageNumber !== 1 ? itemCountHistory * (pageNumber - 1) + 1 : 1,
          endCountHistory:
            totatlHistory > itemCountHistory * pageNumber
              ? itemCountHistory * pageNumber
              : totatlHistory,
          pageNo: pageNumber,
          pageHasChanged: true,
        }),
        () => {
          this.leaderboardEngagedUsers()
        },
      )
    } catch (error) {
      createNotification('error', 'something went wrong')
    }
  }

  handlePageChange = (pageNumber) => {
    try {
      const { itemCount } = this.state
      let totalResults = this.props.gamification.totalResults
      this.setState(
        (prevState, props) => ({
          startCount: pageNumber !== 1 ? itemCount * (pageNumber - 1) + 1 : 1,
          endCount:
            totalResults > itemCount * pageNumber
              ? itemCount * pageNumber
              : totalResults,
          page_no: pageNumber,
          pageHasChanged: true,
        }),
        () => {
          this.listGamificationLeaderBoards()
        },
      )
    } catch (error) {
      createNotification('error', 'something went wrong')
    }
  }

  selectItemCount = (e) => {
    const { totalResults } = this.state
    this.setState(
      {
        itemCount: e.target.value,
        page_no: 1,
        startCount: 1,
      },
      () => this.listGamificationLeaderBoards(),
    )
  }

  selectItemCountHistory = (e) => {
    this.setState(
      {
        itemCountHistory: e.target.value,
        pageNo: 1,
        startCountHistory: 1,
      },
      () => this.leaderboardEngagedUsers(),
    )
  }

  submitAddPoints = (e) => {
    e.preventDefault()
    const {
      projectId,
      attendeeId,
      noteForAddPoints,
      profileName,
      challengeName,
      dateTime,
    } = this.state
    let error = {}
    if (challengeName === '' || challengeName === undefined) {
      error['challengeName'] = 'Please select a challenge'
      this.setState({
        errors: error,
      })
      return
    }

    const requestBody = {
      projectId,
      attendeeId,
      updatedAt: dateTime,
      challengeId: challengeName,
      note: noteForAddPoints,
      userId: profileName,
    }
    this.props.addPoints(requestBody)
  }

  selectChallenge = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        const { challengesList, challengeName } = this.state
        let requiredChallenge = []
        requiredChallenge = challengesList?.filter(
          (challenge) => challenge._id === challengeName,
        )
        this.setState({
          pointsForAddPoints:
            requiredChallenge?.length && requiredChallenge[0]?.points,
        })
      },
    )
  }

  getActiveClass = (metal) => {
    const { achievement } = this.state
    return achievement.length === 1 && achievement.indexOf(metal) > -1
  }

  render() {
    const {
      itemCountHistory,
      startCountHistory,
      leaderBoardList,
      attendeeEmail,
      leaderboardUserHistory,
      attendeeId,
      sort_by,
      sortBy,
      sortOrder,
      achievement,
      searchText,
      orderForUserHistory,
      searchTextHistory,
      userHistory,
      startCount,
      endCount,
      endCountHistory,
      itemCount,
      page_no,
      pageNo,
      noteForAddPoints,
      pointsForAddPoints,
      challengesList,
      challengeName,
      errors,
      filterByAchievement,
    } = this.state

    let all = ['Gold', 'Silver', 'Bronze']
    return (
      <div>
        <Box className="subtitle" mb={3} mt={2}>
          {/* Ranking List*/}
          <Box>
            <Box className="subtitle" mb={3} mt={2}>
            {this.props.users.languageStateForRedux?.column_names?.gamification?.rankingList}
            </Box>
            <Grid container spacing={2} mb={3} className="top-filter-area">
              <Grid >
                <Grid container spacing={1}>
                  <Grid >
                    <Box
                      component={Button}
                      variant="contained"
                      sx={{
                        backgroundColor: theme.palette.background.light,
                        color: theme.palette.common.black,
                        textTransform: 'capitalize',
                        fontWeight: '400',

                        '&:hover, &.active': {
                          backgroundColor: theme.palette.primary.dark,
                          color: theme.palette.primary.contrastText,
                        },
                      }}
                      className={achievement.length === 3 ? 'active' : null}
                      size="small"
                      onClick={() =>
                        this.filterByAchievement('Gold', 'Silver', 'Bronze')
                      }
                    >
                      {this.props.users.languageStateForRedux?.common?.all} (
                      {filterByAchievement &&
                      Object.keys(filterByAchievement).length
                        ? this.getFilterCount('totalCount')
                        : '0'}
                      )
                    </Box>
                  </Grid>
                  <Grid >
                    <Box
                      component={Button}
                      variant="contained"
                      sx={{
                        backgroundColor: theme.palette.background.light,
                        color: theme.palette.common.black,
                        textTransform: 'capitalize',
                        fontWeight: '400',

                        '&:hover, &.active': {
                          backgroundColor: theme.palette.primary.dark,
                          color: theme.palette.primary.contrastText,
                        },
                      }}
                      className={this.getActiveClass('Gold') ? 'active' : null}
                      size="small"
                      onClick={() => this.filterByAchievement('Gold')}
                    >
                      {this.props.users.languageStateForRedux?.buttons?.gamification?.gold} (
                      {filterByAchievement &&
                      Object.keys(filterByAchievement).length
                        ? this.getFilterCount('Gold')
                        : '0'}
                      )
                    </Box>
                  </Grid>
                  <Grid >
                    <Box
                      component={Button}
                      variant="contained"
                      sx={{
                        backgroundColor: theme.palette.background.light,
                        color: theme.palette.common.black,
                        textTransform: 'capitalize',
                        fontWeight: '400',

                        '&:hover, &.active': {
                          backgroundColor: theme.palette.primary.dark,
                          color: theme.palette.primary.contrastText,
                        },
                      }}
                      className={
                        this.getActiveClass('Silver') ? 'active' : null
                      }
                      size="small"
                      onClick={() => this.filterByAchievement('Silver')}
                    >
                      {this.props.users.languageStateForRedux?.buttons?.gamification?.silver} (
                      {filterByAchievement &&
                      Object.keys(filterByAchievement).length
                        ? this.getFilterCount('Silver')
                        : '0'}
                      )
                    </Box>
                  </Grid>
                  <Grid >
                    <Box
                      component={Button}
                      variant="contained"
                      sx={{
                        backgroundColor: theme.palette.background.light,
                        color: theme.palette.common.black,
                        textTransform: 'capitalize',
                        fontWeight: '400',

                        '&:hover, &.active': {
                          backgroundColor: theme.palette.primary.dark,
                          color: theme.palette.primary.contrastText,
                        },
                      }}
                      className={
                        this.getActiveClass('Bronze') ? 'active' : null
                      }
                      size="small"
                      onClick={() => this.filterByAchievement('Bronze')}
                    >
                      {this.props.users.languageStateForRedux?.buttons?.gamification?.bronze}(
                      {filterByAchievement &&
                      Object.keys(filterByAchievement).length
                        ? this.getFilterCount('Bronze')
                        : '0'}
                      )
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid >
                <Grid container spacing={1.25} className="team-dash-right">
                  <Grid className="">
                    <form onSubmit={this.handleSearchSubmit}>
                      <TextField
                        placeholder={this.props.users.languageStateForRedux?.common?.search}
                        className="search"
                        size="small"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <img alt="" src={SearchIcon} />
                            </InputAdornment>
                          ),
                        }}
                        name="searchText"
                        variant='standard'
                        value={searchText}
                        onChange={this.handleChange}
                      />
                    </form>
                  </Grid>
                  { authPermissionValidation(permissionsConstants.exportGamification) && (
                    <Grid >
                      <Button
                        size="small"
                        variant="outlined"
                        color="primary"
                        endIcon={<img alt="" src={ExportIcon} />}
                        disableElevation
                        disabled={
                          Array.isArray(leaderBoardList) &&
                          leaderBoardList.length > 0
                            ? false
                            : true
                        }
                        onClick={() => this.exportLeaderboard()}
                      >
                        {this.props.users.languageStateForRedux?.common?.export}
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <TableContainer
              className="list-table"
              style={{ maxHeight: 'calc(100vh - 450px)' }}
            >
              <Table className="table">
                <TableHead
                  style={{
                    position: 'sticky',
                    top: '0',
                    backgroundColor: '#f6f6fc',
                    zIndex: 2,
                  }}
                >
                  <TableRow>
                    <TableCell
                      style={{ maxWidth: '120px', minWidth: '70px' }}
                      className={
                        'text sticky-cell ' +
                        (sort_by === 'rank'
                          ? sortOrder === 'asc'
                            ? 'sort-asc'
                            : 'sort-des'
                          : '')
                      }
                      onClick={() => this.sorting('rank')}
                    >
                      {this.props.users.languageStateForRedux?.column_names?.gamification?.rank}
                    </TableCell>

                    <TableCell
                      className={
                        'email sticky-cell ' +
                        (sort_by === 'email'
                          ? sortOrder === 'asc'
                            ? 'sort-asc'
                            : 'sort-des'
                          : '')
                      }
                      style={{ left: 70 }}
                      onClick={() => this.sorting('attendeeEmail')}
                    >
                      {this.props.users.languageStateForRedux?.column_names?.gamification?.emailAddress}
                    </TableCell>
                    <TableCell
                      className={
                        'text ' +
                        (sort_by === 'totalPoints'
                          ? sortOrder === 'asc'
                            ? 'sort-asc'
                            : 'sort-des'
                          : '')
                      }
                      onClick={() => this.sorting('totalPoints')}
                    >
                      {this.props.users.languageStateForRedux?.column_names?.gamification?.points}
                    </TableCell>
                    <TableCell
                      className={
                        'text ' +
                        (sort_by === 'totalPoints'
                          ? sortOrder === 'asc'
                            ? 'sort-asc'
                            : 'sort-des'
                          : '')
                      }
                      onClick={() => this.sorting('totalPoints')}
                    >
                      {this.props.users.languageStateForRedux?.column_names?.gamification?.achievement}
                    </TableCell>
                    <TableCell className="text" align="right"></TableCell>
                  </TableRow>
                </TableHead>
                {Array.isArray(leaderBoardList) &&
                leaderBoardList.length > 0 ? (
                  <TableBody>
                    {Array.isArray(leaderBoardList) &&
                      leaderBoardList.length &&
                      leaderBoardList.map((row) => {
                        return (
                          <TableRow key={row._id}>
                            <TableCell
                              className="text sticky-cell"
                              style={{
                                maxWidth: '120px',
                                minWidth: '70px',
                              }}
                            >
                              <Avatar style={styles.avatar}>{row.rank}</Avatar>
                            </TableCell>

                            <TableCell className="email sticky-cell" style={{ left: 70 }}>
                              {row.attendeeEmail}
                            </TableCell>
                            <TableCell className="text">
                              {row.totalPoints}
                            </TableCell>
                            <TableCell className="text">
                              <Box
                                style={{
                                  maxWidth: '32px',
                                  minWidth: '32px',
                                }}
                                component={'img'}
                                mt={0}
                                mr={1}
                                alt="icon"
                                src={this.returnBadge(row.achievement)}
                                title={row.achievement}
                              />
                              {row.achievement}
                            </TableCell>
                            <TableCell className="text" align="right">
                              <IconButton
                                variant="text"
                                onClick={() => this.handleOpenPoint(row)}
                              >
                                <img alt="arrow" src={RightArrowIcon} />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        )
                      })}
                  </TableBody>
                ) : ( !this.state.loader && leaderBoardList.length === 0 && this.props.gamification?.listGamificationLeaderBoardsSuccess &&
                  (<TableBody>
                    <TableRow>
                      <TableCell style={{ textAlign: 'center' }}>
                      {this.props.users.languageStateForRedux?.common?.noRecords}
                      </TableCell>
                    </TableRow>
                  </TableBody>)
                )}
              </Table>
            </TableContainer>
            {Array.isArray(leaderBoardList) && leaderBoardList.length > 0 && (
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                className="tablePagination"
              >
                <Grid >
                  <Box component="span" className="small-subtitle">
                    Showing {startCount} to {endCount} of{' '}
                    {this.props.gamification.totalResults}
                  </Box>
                </Grid>
                <Grid >
                  <Grid container alignItems="center">
                    <Grid >
                      <span className="small-subtitle">Shows</span>
                      <select value={itemCount} onChange={this.selectItemCount}>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                      <span className="small-subtitle">Entries</span>
                    </Grid>
                    <Grid >
                      <Pagination
                        activePage={page_no}
                        itemsCountPerPage={this.state.itemCount}
                        totalItemsCount={this.props.gamification.totalResults}
                        pageRangeDisplayed={5}
                        onChange={this.handlePageChange}
                        prevPageText={
                          <NavigateBeforeIcon style={{ fontSize: 18 }} />
                        }
                        nextPageText={
                          <NavigateNextIcon style={{ fontSize: 18 }} />
                        }
                        itemClassFirst="first d-none"
                        itemClassLast="last d-none"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Box>

          {/* Add points*/}
          <Modal
            open={this.state.openPoint}
            onClose={this.handleClose}
            classNames={{
              overlay: '',
              modal: 'info-modal tab-modal',
            }}
            center
          >
            <Box>
              <Box
                px={3}
                pt={3}
                pb={3}
                sx={{ color: '#ffffff', background: '#000056' }}
                className="paragraph"
              >
                Point History
              </Box>

              <Box px={3}>
                <Box className="paragraph" pl={2} mb={0} mt={2}>
                  Attendee
                </Box>
                <Grid
                  container
                  alignItems={'center'}
                  spacing={2}
                  mb={2}
                  className="top-filter-area"
                >
                  <Grid >
                    <Stack pl={2} direction={'row'}>
                      <Box mr={1}>
                        <img alt="" src={emailIcon} />
                      </Box>
                      <Box className="small-subtitle primary-text">
                        {attendeeEmail} ({this.props.gamification.totatlHistory}
                        )
                      </Box>
                    </Stack>
                  </Grid>
                  <Grid >
                    <Grid container spacing={1.25} className="team-dash-right">
                      <Grid className="">
                        <form onSubmit={this.searchUserHistory}>
                          <TextField
                            placeholder="Search..."
                            className="search"
                            fullWidth
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img alt="" src={SearchIcon} />
                                </InputAdornment>
                              ),
                            }}
                            name="searchTextHistory"
                            value={searchTextHistory}
                            onChange={this.handleChange}
                          />
                        </form>
                      </Grid>
                      { authPermissionValidation(permissionsConstants.addManualPoints) && (
                        <Grid >
                          <Button
                            // size="small"
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              this.handleAddManualPoints(attendeeId)
                            }
                          >
                            Add Points
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <TableContainer
                  className="list-table"
                  style={{ maxHeight: '450px' }}
                >
                  <Table className="table">
                    <TableHead
                      style={{
                        position: 'sticky',
                        top: '0',
                        backgroundColor: '#ffffff',
                        zIndex: 1,
                      }}
                    >
                      <TableRow>
                        <TableCell
                          className={
                            'long-text ' +
                            (sortBy === 'challengeName'
                              ? orderForUserHistory === 'asc'
                                ? 'sort-asc'
                                : 'sort-des'
                              : '')
                          }
                          onClick={() => this.historySorting('challengeName')}
                        >
                          Challenges
                        </TableCell>
                        <TableCell
                          className={
                            'date-time ' +
                            (sortBy === 'createdAt'
                              ? orderForUserHistory === 'asc'
                                ? 'sort-asc'
                                : 'sort-des'
                              : '')
                          }
                          onClick={() => this.historySorting('createdAt')}
                        >
                          Date & time
                        </TableCell>

                        <TableCell
                          className={
                            'text ' +
                            (sortBy === 'source'
                              ? orderForUserHistory === 'asc'
                                ? 'sort-asc'
                                : 'sort-des'
                              : '')
                          }
                          onClick={() => this.historySorting('source')}
                        >
                          {this.props.users.languageStateForRedux?.common?.source}
                        </TableCell>
                        <TableCell
                          className={
                            'text ' +
                            (sortBy === 'points'
                              ? orderForUserHistory === 'asc'
                                ? 'sort-asc'
                                : 'sort-des'
                              : '')
                          }
                          style={{ maxWidth: '120px', minWidth: '120px' }}
                          onClick={() => this.historySorting('points')}
                        >
                          Points
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {Array.isArray(leaderboardUserHistory) &&
                    leaderboardUserHistory?.length > 0 ? (
                      <>
                        <TableBody>
                          {Array.isArray(leaderboardUserHistory) &&
                            leaderboardUserHistory.length &&
                            leaderboardUserHistory.map((userHistory) => {
                              return (
                                <TableRow>
                                  <TableCell className="long-text">
                                    {userHistory?.challengeName
                                      ? userHistory.challengeName
                                      : '--'}
                                  </TableCell>

                                  <TableCell className="date-time">
                                    {userHistory.createdAt
                                      ? moment
                                          .tz(
                                            userHistory.createdAt,
                                            this.props.project?.project
                                              ?.timezone,
                                          )
                                          .format('MM/DD/YYYY h:mm a')
                                      : '--'}
                                  </TableCell>
                                  <TableCell className="text">
                                    <Stack direction={'row'}>
                                      <Box mr={1.25}>
                                        {userHistory?.source === 'Page'
                                          ? userHistory?.pageTitle
                                          : userHistory.source}
                                      </Box>
                                      {userHistory.source === 'Manual' && (
                                        <Box>
                                          <CustomTooltip
                                            placement={'bottom-start'}
                                            title={
                                              <Box className="manual-note">
                                                {userHistory.note && (
                                                  <Box className="note">
                                                    <Box className="small-subtitle primary-text bold">
                                                      Note:
                                                    </Box>
                                                    <Box className="small-subtitle primary-text">
                                                      {userHistory.note}
                                                    </Box>
                                                  </Box>
                                                )}
                                                <Box className="small-subtitle added-by">
                                                  Added By: &nbsp;
                                                  <span
                                                    style={{ color: '#000000' }}
                                                  >
                                                    {userHistory.userName
                                                      ? userHistory.userName
                                                      : '--'}
                                                  </span>
                                                </Box>
                                              </Box>
                                            }
                                          >
                                            <img alt="" src={infoFilledIcon} />
                                          </CustomTooltip>
                                        </Box>
                                      )}
                                    </Stack>
                                  </TableCell>
                                  <TableCell
                                    className="text"
                                    style={{
                                      maxWidth: '120px',
                                      minWidth: '120px',
                                    }}
                                  >
                                    <Box
                                      className="subtitle"
                                      sx={{
                                        width: 'max-content',
                                        background: '#ECEAFF',
                                        borderRadius: '4px',
                                        padding: '5px 9px',
                                        textAlign: 'center',
                                      }}
                                    >
                                      {userHistory.points
                                        ? userHistory.points
                                        : '--'}
                                    </Box>
                                  </TableCell>
                                </TableRow>
                              )
                            })}
                        </TableBody>
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="space-between"
                          className="tablePagination"
                        >
                          <Grid >
                            <Box component="span" className="small-subtitle">
                              Showing {startCountHistory} to {endCountHistory}{' '}
                              of {this.props.gamification.totatlHistory}
                            </Box>
                          </Grid>
                          <Grid >
                            <Grid container alignItems="center">
                              <Grid >
                                <span className="small-subtitle">Shows</span>
                                <select
                                  value={itemCountHistory}
                                  onChange={this.selectItemCountHistory}
                                >
                                  <option value={10}>10</option>
                                  <option value={20}>20</option>
                                  <option value={50}>50</option>
                                  <option value={100}>100</option>
                                </select>
                                <span className="small-subtitle">Entries</span>
                              </Grid>
                              <Grid >
                                <Pagination
                                  activePage={pageNo}
                                  itemsCountPerPage={
                                    this.state.itemCountHistory
                                  }
                                  totalItemsCount={
                                    this.props.gamification.totatlHistory
                                  }
                                  pageRangeDisplayed={5}
                                  onChange={this.handlePageChangeHistory}
                                  prevPageText={
                                    <NavigateBeforeIcon
                                      style={{ fontSize: 18 }}
                                    />
                                  }
                                  nextPageText={
                                    <NavigateNextIcon
                                      style={{ fontSize: 18 }}
                                    />
                                  }
                                  itemClassFirst="first d-none"
                                  itemClassLast="last d-none"
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    ) : (
                      <TableBody>
                        <TableRow>
                          <TableCell style={{ textAlign: 'center' }}>
                            There are no records to display
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          </Modal>
          {/* Add points end*/}

          {/* Add Manual Points */}
          <Modal
            open={this.state.addManualPoints}
            onClose={this.handleCloseAddPoints}
            classNames={{
              overlay: '',
              modal: 'Invite-User-Modal wide',
            }}
            center
          >
            <Grid component="form" container className="modal-wrapper">
              <Grid size={{xs: 12,}} pb={4}>
                <Box component="p" mb={1} className="subtitle">
                  Add Manual Points
                </Box>
                <Box component="span" mb={1} className="small-subtitle">
                  This is some help text about adding the manual points
                </Box>
              </Grid>
              <Grid size={{xs: 12,}} pb={4}>
                <Box component="div" className="inputField">
                  <label>Email</label>
                  <input
                    disabled={true}
                    type="email"
                    class="form-control small"
                    placeholder=""
                    name="emailOpenPoints"
                    value={attendeeEmail}
                  />
                </Box>
              </Grid>
              <Grid size={{xs: 12,}} pb={4}>
                <Grid container spacing={2.5}>
                  <Grid size={{xs: 8,}} className="inputField">
                    <label>
                      Select the Challenge <span className="required">*</span>
                    </label>
                    <select
                      type="text"
                      class="form-control small"
                      name="challengeName"
                      value={challengeName}
                      onChange={this.selectChallenge}
                    >
                      <option value="">Select a challenge</option>
                      {Array.isArray(challengesList) &&
                        challengesList.length &&
                        challengesList.map((challenge, i) => {
                          return (
                            <option value={challenge._id}>
                              {challenge.challengeName}
                            </option>
                          )
                        })}
                    </select>
                    <p className="required small-subtitle">
                      {' '}
                      {errors['challengeName']}{' '}
                    </p>
                  </Grid>
                  <Grid size={{xs: 4,}} className="inputField" pb={1}>
                    <label>Points</label>
                    <div className="sub-add-outer">
                      <div className="sub-add-outer-inner">
                        <input
                          disabled={true}
                          type="number"
                          class="form-control text-center small"
                          name="pointsForAddPoints"
                          value={pointsForAddPoints}
                        />
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid size={{xs: 12,}} pb={4}>
                <Box component="div" className="inputField">
                  <label>Note</label>
                  <textarea
                    rows={'4'}
                    class="form-control"
                    placeholder=""
                    name="noteForAddPoints"
                    value={noteForAddPoints}
                    onChange={this.handleChange}
                  />
                </Box>
              </Grid>
              <Grid size={{xs: 12,}}>
                <Grid container justifyContent="end">
                  <Stack spacing={1} direction="row" fullWidth>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.submitAddPoints}
                    >
                      Add Points
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={this.handleCloseAddPoints}
                    >
                      {this.props.users.languageStateForRedux?.common?.cancel}
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Modal>

          <Modal
            open={this.state.downloadExport}
            classNames={{
              overlay: '',
              modal: 'Invite-User-Modal modal-default-close-none',
              
            }}
            center
          >
            <Grid
              component="form"
              container
              alignItems="center"
              justifyContent="center"
            >
              <Grid size={{xs: 12,}} pb={2.6}>
                <Box textAlign="center" mt={1.6} mb={3}>
                  <img alt="" src={Exporting} width="92" height="92" />
                </Box>
                <Box
                  component="p"
                  textAlign="center"
                  mb={3}
                  className="subsecondary bold"
                >
                  Please wait while we securely <br/>
                       export your data!
                </Box>
                <Box component="p" textAlign="center" className="paragraph">
                  Your information is being processed and will be ready for<br/>
                      download shortly. Thank you for your patience!
                </Box>
              </Grid>
            </Grid>
          </Modal>

          {/* Add Manual Points end*/}

          {/* Ranking List end*/}
        </Box>
        {this.state.loading ? <Loader /> : null}
        <NotificationContainer />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  gamification: state.Gamification,
  project: state.Projects,
  events: state.Events,
  users: state.Users,
})

const mapActionsToProps = (dispatch) =>
  bindActionCreators(
    {
      listGamificationLeaderBoards: Actions.listGamificationLeaderBoardsRequest,
      addPoints: Actions.addPointsGamificationLeaderBoardsRequest,
      configrationChallenges: Actions.configrationChallengesRequest,
      leaderboardEngagedUsers: Actions.leaderboardUserHistoryRequest,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(GamificationLeaderboard)
