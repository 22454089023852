import axios from 'axios';
import { jwtDecode } from "jwt-decode";

const URL = process.env.REACT_APP_API_URL;
let isRefreshing = false;
let requestQueue = [];


/**
 * Function to detect whether the token is from Cognito or Google SSO.
 * @param {string} token - JWT access token
 * @returns {"cognito" | "google" | "unknown"}
 */ 
function detectAuthProvider(token) {
  if (!token) return "unknown";

  try {
    const decoded = jwtDecode(token);

    if (decoded.iss.includes("cognito-idp")) {
      return "cognito";
    } else if (
      decoded.iss.includes("accounts.google.com") ||
      decoded.iss.includes("https://accounts.google.com")
    ) {
      return "google";
    } else {
      return "unknown"; // Could be another provider
    }
  } catch (error) {
    console.error("Error decoding token:", error);
    return "unknown";
  }
}

// Function to check if JWT token is expired
function isTokenExpired(token) {
  if (!token) return true;

  try {
    const { exp } = jwtDecode(token);
    return exp < Math.floor(Date.now() / 1000);
  } catch (error) {
    console.error("Invalid Token:", error);
    return true;
  }
}

// Axios request interceptor
axios.interceptors.request.use(
  async (config) => {
    const hostname = window.location.hostname;

    // Skip token logic for certain routes
    if (
      hostname === "localhost" ||
      config.url.includes("/api/users/login") ||
      config.url.includes("/register") ||
      config.url.includes("/refreshToken") ||
      config.url.includes("/confirmYourEmailOnCognito") ||
      config.url.includes("/forgotPassword") ||
      config.url.includes("/resetPassword") ||
      config.url.includes("/getInvitedUserDetails")
    ) {
      return config;
    }

    // Get user details from localStorage
    const rawUserDetails = localStorage.getItem("user_details");
    if (!rawUserDetails) {
      window.location.href = "/";
      throw new Error("User not logged in");
    }

    const users = JSON.parse(rawUserDetails);
    if (!users.token) {
      throw new Error("Access token not found in user details");
    }

    let accessToken = users.token;

    // Detect authentication provider (Cognito or Google SSO)
    const authProvider = detectAuthProvider(accessToken);

    // Validate token expiration
    const isExpired = isTokenExpired(accessToken);

    if (isExpired) {
      if (!isRefreshing) {
        console.log(`Access token expired. Refreshing via ${authProvider}...`);
        isRefreshing = true;

        try {
          const newAccessToken =
            authProvider === "cognito"
              ? await refreshTokenForCognito(users)
              : authProvider === "google"
              ? await refreshTokenForGoogle(users)
              : null;

          isRefreshing = false;
          processQueue(null, newAccessToken);
        } catch (err) {
          isRefreshing = false;
          processQueue(err, null);
          throw err;
        }
      }

      // Queue current request
      return new Promise((resolve, reject) => {
        requestQueue.push({
          resolve: (token) => {
            config.headers.Authorization = `Bearer ${token}`;
            config.headers.token = `Bearer ${token}`;
            resolve(config);
          },
          reject: (err) => reject(err),
        });
      });
    } else {
      // Attach valid token to headers
      config.headers.Authorization = `Bearer ${accessToken}`;
      config.headers.token = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor to handle 401 errors
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      console.log("Unauthorized. Redirecting to login...");
      localStorage.removeItem("user_details");
      window.location.href = "/"; // Redirect to login page
    }
    return Promise.reject(error);
  }
);

function processQueue(error, token = null) {
  requestQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  requestQueue = [];
}

const refreshTokenForCognito = async (rawUserDetails) => {
  try { 
    if (!rawUserDetails) {
      throw new Error('User not logged in');
    }

    const users = JSON.parse(rawUserDetails);
    const storedRefreshToken = users.refreshToken;

    if (!storedRefreshToken) {
      throw new Error('Refresh token missing');
    }

    const response = await axios.post(`${URL}/users/refreshToken`, { refreshToken: storedRefreshToken });
    const { accessToken, idToken } = response.data;

    // Update user details in localStorage
    users.token = accessToken;
    users.idToken = idToken;
    localStorage.setItem('user_details', JSON.stringify(users));

    console.log('Token refreshed successfully:', users);
    return accessToken;
  } catch (err) {
    console.error('Error refreshing token:', err);
    localStorage.removeItem('user_details');
    window.location.href = '/'; // Redirect to login
    throw err;
  }
};

// **Function to refresh Google SSO token**
const refreshTokenForGoogle = async (users) => {
  try {
    const storedRefreshToken = users.refreshToken;
    if (!storedRefreshToken) throw new Error("Google refresh token missing");

    const response = await axios.post(`${URL}/users/refreshGoogleToken`, {
      refreshToken: storedRefreshToken,
    });

    const { idToken } = response.data;

    // Update user details in localStorage
    users.token = idToken;
    users.idToken = idToken;
    localStorage.setItem("user_details", JSON.stringify(users));

    console.log("Google SSO Token refreshed successfully");
    return idToken;
  } catch (err) {
    console.error("Error refreshing Google SSO token:", err);
    localStorage.removeItem("user_details");
    window.location.href = "/";
    throw err;
  }
};

export default axios;
