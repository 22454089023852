import React, { useEffect, useState } from "react";
import { Box, Stack, Typography, Avatar, Button, AvatarGroup, Checkbox, Tooltip } from "@mui/material";
import moment from 'moment';
import { useNavigate, useLocation } from 'react-router-dom';
import { NotificationItems } from "ReduxStore/Reducers/eijentNotification.reducer";
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useDispatch } from "react-redux";
import { Actions } from "ReduxStore/Actions";
import { generateUrl } from "helpers/common";

interface NotificationItemProps {
  notification: NotificationItems;
}

export default function NotificationItem({ notification }: NotificationItemProps): JSX.Element {
  const dispatch = useDispatch();
  const { name, title, updatedAt, profile,_id,message,link,isRead: initialIsRead  } = notification;
  const [isRead, setIsRead] = useState<boolean>(initialIsRead);

  const navigate = useNavigate();
  const handleNavigation = (): void => {
    if (!link) {
      console.error("Navigation failed: link is undefined or empty.");
      return;
    }
    const firstSegment = link.startsWith("/") ? link.split("/")[1] : "";
    if (firstSegment === "opportunity") {
      markNotificationRequest(true);
      navigate(generateUrl(link));
    } else {
      markNotificationRequest(true);
      navigate(link);
    }
  };
  
  const handleUnreadChange = (): void => {
    const newIsRead = !isRead
    markNotificationRequest( newIsRead);
  };

  const markNotificationRequest = (isRead: boolean) => {
    setIsRead(!isRead);
    dispatch(Actions.markNotificationRequest({ id:_id, isRead }));
    dispatch(Actions.eijentNotificationListReset({ 
      resetType: 'update', 
      notification: { id: notification._id, isRead: isRead } 
    }));
  };

  return (
    <Box
      // key={index}
      sx={{
        width: 537,
        minWidth: 300,
        // borderRadius:'4px',
        borderBottom: '1px solid #c2cccc',
        // backgroundColor: !isRead ? '#DCE6F7' : 'transparent',
        opacity:isRead ? "0.4":"1",
        '&:hover ': {
          opacity: '1',
          background:"#e6fdf4 "
        },

        '&:hover .read-checkbox': {
          visibility: 'visible',
        },

      }}
      py={2}
      px={2}
      className={'eijent-notification'}
    >
      <Stack
        direction="row"
        sx={{
          gap: 1,
          alignItems: 'center',
        }}
      >
        <AvatarGroup max={2}>
          <Avatar
            sizes="small"
            alt="Riley Carter"
            sx={{ width: 32, height: 32, fontSize: '10px', fontWeight: 400, border: 'none !important', }}
          >
            {profile ? (
              <img src={profile} alt="profile" style={{ width: '100%', height: '100%', borderRadius: '50%' }} />
            ) : (
              name
                ? name
                  .split(' ')
                  .slice(0, 2)
                  .map((n) => n.charAt(0).toUpperCase())
                  .join(' ')
                : ''
            )}
          </Avatar>
        </AvatarGroup>
        <Stack flexGrow={1} direction="row" justifyContent="space-between">
          <Typography variant="body2">
            <Box component="span" sx={{ fontWeight: 700  }}>
              {name ? name : ""}{" "}
            </Box>
          </Typography>
          <Box
            display="flex"
            gap={1}
          >
            <Typography variant="caption">{moment(updatedAt).format("DD MMM YYYY")}</Typography>
            <Tooltip arrow placement="left"
              title={!isRead ? 'Mark as read' : 'Mark as Unread'}
            >
              <Checkbox
                className="read-checkbox"
                sx={{
                  visibility: 'hidden',
                }}
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<CheckCircleIcon />}
                checked={isRead}
                onChange={handleUnreadChange}
              />
            </Tooltip>
          </Box>
        </Stack>
      </Stack>
      <Stack spacing={1.4} alignItems={'start'} sx={{ pl: 5, }}>
        <Typography variant="body2" sx={{ fontWeight: 700, lineHeight: '16px',  textDecoration: 'underline', cursor: 'pointer' }}
          onClick={handleNavigation}
         >
          {title ?title :"" }
        </Typography>
        <Typography sx={{ fontSize: '13px', fontWeight: 400, }} >
        {message ? message:'' }
        </Typography>
      </Stack>
    </Box>
  );
}
