import  React,{ useEffect,useState } from 'react';
import { IconButton, Badge } from "@mui/material";
import { NotificationIcon } from "../CustomIcons";
import { useSelector } from 'react-redux';
import { NotificationState } from 'ReduxStore/Reducers/eijentNotification.reducer';

interface NotificationButtonProps {
  open: boolean;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
}

export default function NotificationButton({
  open,
  onClick,
}: NotificationButtonProps): JSX.Element {
  const {
    totalResults,
    notificationList,
    loading,
  } = useSelector((state: any) => state.eijentNotification) as NotificationState;
  const unreadNotifications = notificationList.filter((item) => !item.isRead);
  return (
    <>
    <IconButton
      onClick={onClick}
      // @ts-ignore
      color="white"
      variant="contained"
      aria-label="open notifications"
      size="large"
      sx={{
        backgroundColor: open && '#F4F6F7',
        borderBottomLeftRadius: open ? "0px" : 8,
        borderBottomRightRadius: open ? "0px" : 8,
        transitionDuration: '200ms',
        "&::before": {
          content: '""',
          position: "absolute",
          bottom: open ? "-6px" : 0,
          left: "0",
          right: '0',
          height: open ? "6px" : 0,
          transitionDuration: '200ms',
          backgroundColor: "#F4F6F7",
          borderTopLeftRadius: "0px",
          borderTopRightRadius: "0px",
        },
      }}
    >
       <Badge
      color="error"
      variant={unreadNotifications.length > 0 ? "standard" : null}
      badgeContent={unreadNotifications.length > 0 ? unreadNotifications.length : null}
      sx={{ padding: "10px" }}
    >
        <NotificationIcon />
      </Badge>
    </IconButton>
    </>
  );
}
