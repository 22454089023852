import { createReducer } from 'reduxsauce'
import { Constants } from '../Actions'


// Define types for NotificationItem and State
export interface NotificationItems {
    _id: string;
    title?: string;
    message: string;
    name: string;
    updatedAt?:string;
    profile?:string;
    isRead?:boolean;
    link?:string;
  }

  export interface smartNudgesItems {
    _id: string;
    title?: string;
    message: string;
    name: string;
    updatedAt?:string;
    createdAt?:string;
    profile?:string;
    // isRead?:boolean;
    link?:string;
    usersReadedThisMessage:object;
    priority:string;
  }
  
  export interface NotificationState {
    error: boolean;
    message: string | null;
    eijentNotificationListSuccess: boolean;
    smartNudgesNotificationListSuccess:boolean;
    markNotificationSuccess:boolean,
    notificationList: NotificationItems[];
    smartNudgesNotification: smartNudgesItems[];
    loading: boolean,
    totalResults:number,
    totalSmartNudgesNotification:number,
  }

// Initial state with proper type
const INITIAL_STATE: NotificationState = {
    error: false,
    message: null,
    eijentNotificationListSuccess: false,
    smartNudgesNotificationListSuccess:false,
    markNotificationSuccess:false,
    notificationList: [],
    smartNudgesNotification:[],
    loading: false,
    totalResults :0,
    totalSmartNudgesNotification :0,
  };


// Reducer handlers with type annotations
const eijentNotificationListRequest = (
    state: NotificationState = INITIAL_STATE,
    action: any
  ): NotificationState => {
    return {
      ...state,
      loading: true,
      eijentNotificationListSuccess: false,
      error: false,
      message: null,
      totalResults:0
    };
  };

const eijentNotificationListSuccess = (
  state: NotificationState = INITIAL_STATE,
  action: any
): NotificationState => {
  if (action && action.notificationList.type && action.notificationList.type === 'eijentNotification') {
    const newNotificationList = [
      action?.notificationList.data,
      ...state.notificationList,
    ];
    newNotificationList.sort((a, b) => Number(b.isRead) - Number(a.isRead));
    return {
      ...state,
      loading: false,
      eijentNotificationListSuccess: true,
      error: false,
      notificationList: newNotificationList,
      totalResults: state.totalResults + 1
    };
  } else {
    let previousData = [
      ...state.notificationList,
      ...action?.notificationList,
    ];

    const mergedArray = [...previousData, ...action?.notificationList];

    // Use Set to track unique _id values
    const uniqueArray = mergedArray.filter((item, index, self) =>
      index === self.findIndex(obj => obj._id === item._id)
    );
    uniqueArray.sort((a, b) => Number(a.isRead) - Number(b.isRead));
    return {
      ...state,
      loading: false,
      eijentNotificationListSuccess: true,
      error: false,
      notificationList: uniqueArray,
      totalResults: action?.totalResults
    };
  }
};

const eijentNotificationListReset = (state: NotificationState = INITIAL_STATE, action: any) => {
  const { resetType, notification } = action.action || {};
    if (resetType == 'reset') {
      return {
        ...state,
        notificationList: [], // Reset notification list to empty
        totalResults: 0, // Reset totalResults to 0
      };
    }

    if (resetType === 'update' && notification) {
      const updatedNotificationList = state.notificationList.map((item) =>
        item._id === notification.id ? { ...item, isRead: notification.isRead } : item
      );

      return {
        ...state,
        notificationList: updatedNotificationList, // Apply the updated notification
      };
    }
  return state;
};

const eijentNotificationListError = (
  state: NotificationState = INITIAL_STATE,
  action: any
): NotificationState => {
  return {
    ...state,
    loading: false,
    eijentNotificationListSuccess: false,
    error: true,
    message: action.error,
    notificationList: [],
    totalResults:0,
  };
};

// Reducer handlers with type annotations
const markNotificationRequest = (
  state: NotificationState = INITIAL_STATE,
  action: any
): NotificationState => {
  return {
    ...state,
    markNotificationSuccess: false,
    error: false,
  };
};

const markNotificationSuccess = (
state: NotificationState = INITIAL_STATE,
action: any
): NotificationState => {
  return {
    ...state,
    loading: false,
    markNotificationSuccess: true,
    error: false,
  };
};

const markNotificationError = (
  state: NotificationState = INITIAL_STATE,
  action: any
): NotificationState => {
  return {
    ...state,
    markNotificationSuccess: false,             
    error: true,
    message: action.error,
  };
}; 


// Reducer handlers with type annotations
const smartNudgesNotificationListRequest = (
  state: NotificationState = INITIAL_STATE,
  action: any
): NotificationState => {
  return {
    ...state,
    loading: true,
    smartNudgesNotificationListSuccess: false,
    error: false,
    message: null,
    totalSmartNudgesNotification:0
  };
};

const smartNudgesNotificationListSuccess = (
state: NotificationState = INITIAL_STATE,
action: any
): NotificationState => {
if (action && action.smartNudgesNotification.type && action.smartNudgesNotification.type === 'smartNudges') {
  const newNotificationList = [
    action?.smartNudgesNotification.data,
    ...state.smartNudgesNotification,
  ];

  const priorityOrder = { high: 3, normal: 2, low: 1 };
    newNotificationList.sort((a, b) => {
      const priorityDiff = priorityOrder[b.priority] - priorityOrder[a.priority];
      if (priorityDiff !== 0) return priorityDiff;
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    });
  return {
    ...state,
    loading: false,
    smartNudgesNotificationListSuccess: true,
    error: false,
    smartNudgesNotification: newNotificationList,
    // totalSmartNudgesNotification: state.totalResults + 1
  };
} else {
  const priorityOrder = { high: 3, normal: 2, low: 1 };
  const sortedNotificationList = action?.smartNudgesNotification.sort((a, b) => {
    const priorityDiff = priorityOrder[b.priority] - priorityOrder[a.priority];
    if (priorityDiff !== 0) return priorityDiff;
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  });
  return {
    ...state,
    loading: false,
    smartNudgesNotificationListSuccess: true,
    error: false,
    smartNudgesNotification: sortedNotificationList,
    // totalSmartNudgesNotification: action?.totalResults
  };
}
};

const smartNudgesNotificationListError = (
  state: NotificationState = INITIAL_STATE,
  action: any
): NotificationState => {
  return {
    ...state,
    loading: false,
    smartNudgesNotificationListSuccess: false,
    error: true,
    message: action.error,
    smartNudgesNotification: [],
    totalSmartNudgesNotification:0,
  };
};
  

const HANDLERS = {
    [Constants.SMART_NUDGES_NOTIFICATION_LIST_REQUEST]: smartNudgesNotificationListRequest,
    [Constants.SMART_NUDGES_NOTIFICATION_LIST_SUCCESS]: smartNudgesNotificationListSuccess,
    [Constants.SMART_NUDGES_NOTIFICATION_LIST_ERROR]: smartNudgesNotificationListError,
    [Constants.EIJENT_NOTIFICATION_LIST_REQUEST]: eijentNotificationListRequest,
    [Constants.EIJENT_NOTIFICATION_LIST_SUCCESS]: eijentNotificationListSuccess,
    [Constants.EIJENT_NOTIFICATION_LIST_ERROR]: eijentNotificationListError,
    [Constants.EIJENT_NOTIFICATION_LIST_RESET]: eijentNotificationListReset, // Handle reset action here
    [Constants.MARK_NOTIFICATION_REQUEST]: markNotificationRequest,
    [Constants.MARK_NOTIFICATION_SUCCESS]: markNotificationSuccess,
    [Constants.MARK_NOTIFICATION_ERROR]: markNotificationError,
}

export default createReducer(INITIAL_STATE, HANDLERS)