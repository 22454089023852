// Google Analytics
import ReactGA from 'react-ga4';
import { permissionForCognitoIdToken, permissions, trackingId, Url, userDataLocalStorage } from '../constants/constants';
import moment from 'moment';
import { createNotification } from '.';
import { ADMIN_COADMIN_ROLES } from '../config/config';
import { checkJWTAuthAPI } from 'ReduxStore/API';
import englishData from '../Common/Languages/en.json';
import spanishData from '../Common/Languages/es.json';
import romanData from '../Common/Languages/ro.json';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import { permissionsConstants } from 'constants/permissionsConstant';

ReactGA.initialize(trackingId);
ReactGA.send('pageview'); 

/**
 * Converts a file to a data URL using FileReader.
 * @param {File} file - The file to be converted.
 * @returns {Promise<string>} - A promise that resolves to the data URL.
 */
export const fileReader = async (file) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      resolve(e.target.result);
    };
    reader.readAsDataURL(file);
  });
};

/**
 * Converts a data URI to a Blob.
 * @param {string} dataURI - The data URI to be converted.
 * @returns {Blob} - The Blob representation of the data URI.
 */
export const dataURItoBlob = (dataURI) => {
  const byteString = atob(dataURI.split(',')[1]);
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mimeString });
};

/**
 * Determines whether to show end-user event routes based on the current URL.
 * @returns {boolean} - True if end-user event routes should be shown, false otherwise.
 */
/* export const showEndUserEventRoutesFn = () => {
  const splitURL = window.location.hostname.split('.');
  const splitURLLength = splitURL.length;

  if (window.location.hostname === 'live.illumeetxp.com') {
    return false;
  }
  if (window.location.hostname === 'web.development.illumeetxp.com') {
    return false;
  }
  if (window.location.hostname === 'web.development.eijent.com') {
    return false;
  }
  if (window.location.hostname === 'development.eijent.com') {
    return false;
  }
  if (window.location.hostname === 'development.illumeetxp.com') {
    return false;
  }
  if (splitURL.includes('development') && splitURLLength > 3) {
    return true;
  }

  if (splitURL.includes('localhost') && splitURL.length > 1) {
    return true;
  }
  if (!splitURL.includes('illumeetxp') && splitURL.length >= 2) {
    return true;
  }
  if (!splitURL.includes('eijent') && splitURL.length >= 2) {
    return true;
  }
  if (splitURL.length > 2) {
    return true;
  }

  return false;
  // return (
  //     splitURL.includes('dev') && splitURLLength > 3 ||
  //     !splitURL.includes('dev') && splitURL.includes('illumeetxp') && splitURL.length > 2
  //     || splitURL.includes('localhost')  && splitURL.length > 1
  //     || !splitURL.includes('live') && splitURL.includes('illumeetxp') && splitURLLength > 1
  // );
};
 */

export const showEndUserEventRoutesFn = () => {
  const splitURL = window.location.hostname.split('.');
  const splitURLLength = splitURL.length; 

  // Extract primary domain and subdomain dynamically
  const primaryDomain = splitURL.slice(-2).join('.'); // Extracts "eijent.com" from "softobiz.eijent.com"
  const subdomain = splitURLLength > 2 ? splitURL[0] : null;

  // Excluded domains (return false for these)
  const excludedDomains = [
    'eijent.com',
    'live.illumeetxp.com',
    'development.eijent.com',
    'development.illumeetxp.com'
  ];

  if (excludedDomains.includes(window.location.hostname)) {
    return false;
  }

  // Handle dynamic subdomain with 'development' (e.g., 'web.development.eijent.com' → 'development.eijent.com')
  if (splitURL.includes('development') && splitURLLength > 2) {
    return false;
  }

  if (primaryDomain === 'eijent.com' && subdomain) {
    return false;
  }

  // Allow routes for local development
  if (splitURL.includes('localhost') && splitURL.length > 1) {
    return true;
  }

  // Allow routes if not from 'illumeetxp.com' or 'eijent.com' and has at least two subdomains
  if ((!splitURL.includes('eijent')) && splitURL.length >= 2) {
    return false;
  }

  // If URL has more than two parts (e.g., dynamic.web.domain.com)
  if (splitURLLength > 2) {
    return true;
  } 
  return false;
}; 


/**
 * Gets the hostname based on the current window location.
 * @returns {string} - The hostname.
 */
const getHostName = () => {
  const [subdomain, domain, tdomian, tcom] = window.location.host.split('.');
  if (subdomain === 'live') {
    return domain + '.' + tdomian;
  }
  if (subdomain === 'web') {
    //dev site
    return domain + '.' + tdomian + '.' + tcom;
  }
  if (subdomain === 'localhost:3000') {
    return window.location.host;
  } else {
    return tdomian + '.' + tcom;
  }
};
export { getHostName };

/**
 * Extracts and prepares permissions from the given state.
 * @param {Array} state - The state containing permission data.
 * @returns {Object} - An object representing permissions.
 */
export const getPreparePermission = (state) => {
  let perms = {};
  if (Array.isArray(state)) {
    state.forEach((data) => {
      perms[data.permission_name] = true;
    });
  }
  console.log("perms---->", perms);
  return perms;
};

/**
 * Gets the project ID from the current URL.
 * @returns {string} - The project ID.
 */
export const getProjectIdFromUrl = () => {
  const arr = window.location.pathname.split('/');
  let projectId = arr[arr.length - 1];
  if (projectId.indexOf('?') !== -1) {
    projectId = projectId.split('?')[0];
  }
  return projectId;
};

/**
 * Sends a Google Analytics event with the provided category and action.
 * @param {string} category - The event category.
 * @param {string} action - The event action.
 */
export const addEventGA = (category, action) => {
  let actionWithCategory = `${category}-${action}`;
  ReactGA.event({
    category: category,
    action: actionWithCategory,
  });
};

/**
 * Sends a Google Analytics event with category, action, and value.
 * @param {string} category - The event category.
 * @param {string} action - The event action.
 * @param {number} value - The event value.
 */
export const addGamificationEventGA = (category, action, value) => {
  let actionWithCategory = `${category}-${action}`;
  ReactGA.event({
    category: category,
    action: actionWithCategory,
    value: value,
  });
};

/**
 * Converts a date to the specified timezone.
 * @param {Date} date - The date to be converted.
 * @param {string} tzString - The target timezone.
 * @returns {string} - The converted date in ISO format.
 */
export const convertTZ = (date, tzString) => {
  try {
    var t = parseFloat(moment.tz(tzString).format('Z').replace(':', '.'));
    var st = parseFloat(moment().format('Z').replace(':', '.'));
    var diffTArr = ('' + t).split('.');
    var diffSTArr = ('' + st).split('.');
    var d = new Date(date);
    if (diffTArr.length > 1) {
      d.setMinutes(d.getMinutes() - parseInt(diffTArr[1]) * 10);
    }
    d.setHours(d.getHours() - parseInt(diffTArr[0]));

    if (diffSTArr.length > 1) {
      d.setMinutes(d.getMinutes() + parseInt(diffSTArr[1]) * 10);
    }
    d.setHours(d.getHours() + parseInt(diffSTArr[0]));
    return new Date(d).toISOString();
  } catch (err) {
    return new Date(date).toISOString();
  }
};

/**
 * Retrieves user data from localStorage.
 * @returns {Object|null} - User data or null if not found.
 */
export const UserData = () => {
  return JSON.parse(localStorage.getItem('user_details'));
};

/**
 * Redirects to the login page based on the API result.
 * @param {Object} result - The API result object.
 */
export const redirectToLogin = (result) => {
  if (result.data.isPublished) {
    if (result.data.rdirect === 'login_page') {
      createNotification(
        'error',
        result.data ? result.data.message : 'Something went wrong!',
      );
      localStorage.clear();
      if (result.data.path) {
        window.location.href =
          '/' + result.data.path + '?LoginError=' + result.data.message;
      }
    }
  }
};

/**
 * Removes user-related data from localStorage and redirects to a specified path.
 */
export const removeStorage = async () => {
  const userData = JSON.parse(localStorage.getItem('user_details'));

  let path = `${Url.wixUrl}/account/my-account?logout=true`;
  if (userData) {
    // API call for checking the JWT expiry
    const response = await checkJWTAuthAPI();
    if (response.status === 200) {
      if (response.data.status === 200) {
        path = '/my-events';
      } else {
        let store = ['user_role', 'user_details'];
        store.forEach((item) => localStorage.removeItem(item));
      }
    }
  }
  setTimeout(() => {
    window.location.href = path;
  }, 1500);
};

/**
 * Gets system-level permissions based on the user's role and permissions.
 * @returns {Object} - An object representing system-level permissions.
 */
export const getSystemLevelPermissions = () => {
  const userData = userDataLocalStorage;
  const user = JSON.parse(userData);
  const {
    createProject,
    viewProject,
    editProject,
    deleteProject,
    publishUnpublishProject,
    createPage,
    viewPages,
    editPage,
    deletePage,
    editPageSettings,
    createCampaign,
    viewCampaigns,
    editCampaign,
    deleteCampaign,
    createSession,
    viewSessions,
    editSession,
    deleteSession,
    createNotification,
    viewNotifications,
    editNotification,
    deleteNotification,
    editMembers,
    viewMembers,
    createAttendee,
    viewAttendees,
    editAttendee,
    deleteAttendee,
    checkInCheckOutAttendee,
    importAttendee,
    exportAttendees,
    viewOutboundEmailsReports,
    editCustomFields,
    editGroups,
    editTransactionalEmailTemplates,
    resetPassword,
    webhooks,
    appIntegrations,
    viewGamification,
    editGamification,
    exportGamification,
    addManualPoints,
    viewLiveDashboard,
    createAudience,
    viewAudience,
    editAudience,
    deleteAudience,
    rolesPermission,
    users,
    createAccessCodes,
    viewAccessCodes,
    editAccessCodes,
    deleteAccessCodes,
    helpdeskAdmin,
    helpdeskAgent,
    addKioskUser,
    viewBadge,
    createMeetingForAll,
    createMeetingForMyTeam,
    createMeetingForMyOwn,
    viewAllMeetings,
    viewMyTeamMeetings,
    viewMyOwnMeetings,
    editAllMeetings,
    editMyTeamMeetings,
    editMyOwnMeeting,
    deleteAllMeeting,
    deleteMyTeamMeetings,
    deleteMyOwnMeeting,
    meetingSetting,
    createChatWidget,
    viewChatWidget,
    editChatWidget,
    deleteChatWidget,
    embedOnSide,
    createAccount,
    viewAccount,
    editAccount,
    deleteAccount,
    accountCustomFields,
    createOpportunity,
    viewOpportunity,
    editOpportunity,
    deleteOpportunity,
    assignOpportunity,
    changeOpportunityStatus,
    opportunityCustomFields,
    createContacts,
    viewContacts,
    editContacts,
    deleteContacts,
    contactsCustomFields,
    createDrive,
    editDrive,
    deleteDrive,
    viewDrive
  } = permissionsConstants;

 
  //let permData = user?.permissions; 
  let permData = user?.permissions; 
  let allowed = {};
  if (ADMIN_COADMIN_ROLES.indexOf(user?.role) > -1) {
    console.log("------- allowed if commonjs ----")
    allowed[createAudience] = true;
    allowed[viewAudience] = true;
    allowed[editAudience] = true;
    allowed[deleteAudience] = true;
    allowed[deleteAccount] = true;
    allowed[viewAccount] = true;
    allowed[editAccount] = true;
    allowed[createAccount] = true;
    allowed[accountCustomFields] = true;
    allowed[editOpportunity] = true;
    allowed[deleteOpportunity] = true;
    allowed[createOpportunity] = true;
    allowed[viewOpportunity] = true;
    allowed[assignOpportunity] = true;
    allowed[changeOpportunityStatus] = true;
    allowed[opportunityCustomFields] = true;
    allowed[deleteContacts] = true;
    allowed[editContacts] = true;
    allowed[viewContacts] = true;
    allowed[createContacts] = true;
    allowed[contactsCustomFields] = true;
    allowed[createChatWidget] = true;
    allowed[viewChatWidget] = true;
    allowed[editChatWidget] = true;
    allowed[deleteChatWidget] = true;
    allowed[embedOnSide] = true;
  } else {
    console.log("------- allowed else commonjs ----")
    if (permData && permData.length > 0) {
      // Check if `permData` contains any matching `pid` from `permissionForCognitoIdToken` keys
      permData.forEach(item => {
        const matchedPids = permData.filter(item => Object.values(permissionsConstants)
          .includes(item?.pid))
          .map(item => item.pid);

        if (matchedPids) { 
          /* Opportunity permissions*/
          if (matchedPids.includes(permissionsConstants.createOpportunity)) {
            allowed[createOpportunity] = true;
          }
          if (matchedPids.includes(permissionsConstants.editOpportunity)) {
            allowed[editOpportunity] = true;
          }
          if (matchedPids.includes(permissionsConstants.viewOpportunity)) {
            allowed[viewOpportunity] = true;
          }
          if (matchedPids.includes(permissionsConstants.deleteOpportunity)) {
            allowed[deleteOpportunity] = true;
          }
          if (matchedPids.includes(permissionsConstants.changeOpportunityStatus)) {
            allowed[changeOpportunityStatus] = true;
          }
          if (matchedPids.includes(permissionsConstants.changeOpportunityStatus)) {
            allowed[changeOpportunityStatus] = true;
          }
          if (matchedPids.includes(permissionsConstants.assignOpportunity)) {
            allowed[assignOpportunity] = true;
          }
          if (matchedPids.includes(permissionsConstants.opportunityCustomFields)) {
            allowed[opportunityCustomFields] = true;
          }

          /* Account permissions*/
          if (matchedPids.includes(permissionsConstants.createAccount)) {
            allowed[createAccount] = true;
          }
          if (matchedPids.includes(permissionsConstants.editAccount)) {
            allowed[editAccount] = true;
          }
          if (matchedPids.includes(permissionsConstants.viewAccount)) {
            allowed[viewAccount] = true;
          }
          if (matchedPids.includes(permissionsConstants.deleteAccount)) {
            allowed[deleteAccount] = true;
          }
          if (matchedPids.includes(permissionsConstants.accountCustomFields)) {
            allowed[accountCustomFields] = true;
          }

          /* Contacts permissions*/
          if (matchedPids.includes(permissionsConstants.createContacts)) {
            allowed[createContacts] = true;
          }
          if (matchedPids.includes(permissionsConstants.editContacts)) {
            allowed[editContacts] = true;
          }
          if (matchedPids.includes(permissionsConstants.viewContacts)) {
            allowed[viewContacts] = true;
          }
          if (matchedPids.includes(permissionsConstants.deleteContacts)) {
            allowed[deleteContacts] = true;
          }
          if (matchedPids.includes(permissionsConstants.contactsCustomFields)) {
            allowed[contactsCustomFields] = true;
          }

          /* Audience permissions*/
          if (matchedPids.includes(permissionsConstants.createAudience)) {
            allowed[createAudience] = true;
          }
          if (matchedPids.includes(permissionsConstants.viewAudience)) {
            allowed[viewAudience] = true;
          }
          if (matchedPids.includes(permissionsConstants.editAudience)) {
            allowed[editAudience] = true;
          }
          if (matchedPids.includes(permissionsConstants.deleteAudience)) {
            allowed[deleteAudience] = true;
          }

          /* Chat widget permissions */
          if (matchedPids.includes(permissionsConstants.createChatWidget)) {
            allowed[createChatWidget] = true;
          }
          if (matchedPids.includes(permissionsConstants.editChatWidget)) {
            allowed[editChatWidget] = true;
          }
          if (matchedPids.includes(permissionsConstants.embedOnSide)) {
            allowed[embedOnSide] = true;
          }
          if (matchedPids.includes(permissionsConstants.viewChatWidget)) {
            allowed[viewChatWidget] = true;
          }
          if (matchedPids.includes(permissionsConstants.deleteChatWidget)) {
            allowed[deleteChatWidget] = true;
          }
          if (matchedPids.includes(permissionsConstants.deleteChatWidget)) {
            allowed[deleteChatWidget] = true;
          }

          /* Drive permisssions */
          if (matchedPids.includes(permissionsConstants.viewDrive)) {
            allowed[viewDrive] = true;
          }
          if (matchedPids.includes(permissionsConstants.createDrive)) {
            allowed[createDrive] = true;
          }
          if (matchedPids.includes(permissionsConstants.editDrive)) {
            allowed[editDrive] = true;
          }
          if (matchedPids.includes(permissionsConstants.deleteDrive)) {
            allowed[deleteDrive] = true;
          }  
          if (matchedPids.includes(permissionsConstants.users)) {
            allowed[users] = true;
          } 
          if (matchedPids.includes(permissionsConstants.rolesPermission)) {
            allowed[rolesPermission] = true;
          }  
          if (matchedPids.includes(permissionsConstants.createAccessCodes)) {
            allowed[createAccessCodes] = true;
          } 
          if (matchedPids.includes(permissionsConstants.viewAccessCodes)) {
            allowed[viewAccessCodes] = true;
          }
          if (matchedPids.includes(permissionsConstants.editAccessCodes)) {
            allowed[editAccessCodes] = true;
          }
          if (matchedPids.includes(permissionsConstants.deleteAccessCodes)) {
            allowed[deleteAccessCodes] = true;
          }

          /* Other permissions which were not here previously */
          if (matchedPids.includes(permissionsConstants.helpdeskAdmin)) {
            allowed[helpdeskAdmin] = true;
          }
          if (matchedPids.includes(permissionsConstants.helpdeskAgent)) {
            allowed[helpdeskAgent] = true;
          } 
          if (matchedPids.includes(permissionsConstants.addKioskUser)) {
            allowed[addKioskUser] = true;
          }
          if (matchedPids.includes(permissionsConstants.viewBadge)) {
            allowed[viewBadge] = true;
          }
          if (matchedPids.includes(permissionsConstants.createMeetingForAll)) {
            allowed[createMeetingForAll] = true;
          }
          if (matchedPids.includes(permissionsConstants.createMeetingForMyTeam)) {
            allowed[createMeetingForMyTeam] = true;
          }
          if (matchedPids.includes(permissionsConstants.createMeetingForMyOwn)) {
            allowed[createMeetingForMyOwn] = true;
          }
          if (matchedPids.includes(permissionsConstants.viewAllMeetings)) {
            allowed[viewAllMeetings] = true;
          }
          if (matchedPids.includes(permissionsConstants.viewMyTeamMeetings)) {
            allowed[viewMyTeamMeetings] = true;
          }
          if (matchedPids.includes(permissionsConstants.viewMyTeamMeetings)) {
            allowed[viewMyTeamMeetings] = true;
          }
          if (matchedPids.includes(permissionsConstants.viewMyOwnMeetings)) {
            allowed[viewMyOwnMeetings] = true;
          }
          if (matchedPids.includes(permissionsConstants.editAllMeetings)) {
            allowed[editAllMeetings] = true;
          }
          if (matchedPids.includes(permissionsConstants.editMyTeamMeetings)) {
            allowed[editMyTeamMeetings] = true;
          }
          if (matchedPids.includes(permissionsConstants.editMyOwnMeeting)) {
            allowed[editMyOwnMeeting] = true;
          }
          if (matchedPids.includes(permissionsConstants.editMyOwnMeeting)) {
            allowed[editMyOwnMeeting] = true;
          }
          if (matchedPids.includes(permissionsConstants.deleteAllMeeting)) {
            allowed[deleteAllMeeting] = true;
          }
          if (matchedPids.includes(permissionsConstants.deleteMyTeamMeetings)) {
            allowed[deleteMyTeamMeetings] = true;
          }
          if (matchedPids.includes(permissionsConstants.meetingSetting)) {
            allowed[meetingSetting] = true;
          }
          if (matchedPids.includes(permissionsConstants.deleteMyOwnMeeting)) {
            allowed[deleteMyOwnMeeting] = true;
          } 
          if (matchedPids.includes(permissionsConstants.createProject)) {
            allowed[createProject] = true;
          }
          if (matchedPids.includes(permissionsConstants.viewProject)) {
            allowed[viewProject] = true;
          }
          if (matchedPids.includes(permissionsConstants.editProject)) {
            allowed[editProject] = true;
          }
          if (matchedPids.includes(permissionsConstants.deleteProject)) {
            allowed[deleteProject] = true;
          }
          if (matchedPids.includes(permissionsConstants.publishUnpublishProject)) {
            allowed[publishUnpublishProject] = true;
          }
          if (matchedPids.includes(permissionsConstants.createPage)) {
            allowed[createPage] = true;
          }
          if (matchedPids.includes(permissionsConstants.viewPages)) {
            allowed[viewPages] = true;
          }
          if (matchedPids.includes(permissionsConstants.editPage)) {
            allowed[editPage] = true;
          }
          if (matchedPids.includes(permissionsConstants.deletePage)) {
            allowed[deletePage] = true;
          }
          if (matchedPids.includes(permissionsConstants.editPageSettings)) {
            allowed[editPageSettings] = true;
          }
          if (matchedPids.includes(permissionsConstants.createCampaign)) {
            allowed[createCampaign] = true;
          }

          if (matchedPids.includes(permissionsConstants.viewCampaigns)) {
            allowed[viewCampaigns] = true;
          }
          if (matchedPids.includes(permissionsConstants.editCampaign)) {
            allowed[editCampaign] = true;
          }
          if (matchedPids.includes(permissionsConstants.deleteCampaign)) {
            allowed[deleteCampaign] = true;
          }
          if (matchedPids.includes(permissionsConstants.createSession)) {
            allowed[createSession] = true;
          }
          if (matchedPids.includes(permissionsConstants.viewSessions)) {
            allowed[viewSessions] = true;
          }
          if (matchedPids.includes(permissionsConstants.editSession)) {
            allowed[editSession] = true;
          } 
          if (matchedPids.includes(permissionsConstants.deleteSession)) {
            allowed[deleteSession] = true;
          }
          if (matchedPids.includes(permissionsConstants.createNotification)) {
            allowed[createNotification] = true;
          } 
          if (matchedPids.includes(permissionsConstants.viewNotifications)) {
            allowed[viewNotifications] = true;
          } 
          if (matchedPids.includes(permissionsConstants.editNotification)) {
            allowed[editNotification] = true;
          } 
          if (matchedPids.includes(permissionsConstants.deleteNotification)) {
            allowed[deleteNotification] = true;
          } 
          if (matchedPids.includes(permissionsConstants.viewMembers)) {
            allowed[viewMembers] = true;
          }
          if (matchedPids.includes(permissionsConstants.createAttendee)) {
            allowed[createAttendee] = true;
          } 
          if (matchedPids.includes(permissionsConstants.viewAttendees)) {
            allowed[viewAttendees] = true;
          }
          if (matchedPids.includes(permissionsConstants.editAttendee)) {
            allowed[editAttendee] = true;
          }
          if (matchedPids.includes(permissionsConstants.deleteAttendee)) {
            allowed[deleteAttendee] = true;
          } 
          if (matchedPids.includes(permissionsConstants.checkInCheckOutAttendee)) {
            allowed[checkInCheckOutAttendee] = true;
          }
          if (matchedPids.includes(permissionsConstants.importAttendee)) {
            allowed[importAttendee] = true;
          }
          if (matchedPids.includes(permissionsConstants.exportAttendees)) {
            allowed[exportAttendees] = true;
          }
          if (matchedPids.includes(permissionsConstants.viewOutboundEmailsReports)) {
            allowed[viewOutboundEmailsReports] = true;
          }
          if (matchedPids.includes(permissionsConstants.editCustomFields)) {
            allowed[editCustomFields] = true;
          } 
          if (matchedPids.includes(permissionsConstants.editGroups)) {
            allowed[editGroups] = true;
          }
          if (matchedPids.includes(permissionsConstants.editTransactionalEmailTemplates)) {
            allowed[editTransactionalEmailTemplates] = true;
          }
          if (matchedPids.includes(permissionsConstants.resetPassword)) {
            allowed[resetPassword] = true;
          }
          if (matchedPids.includes(permissionsConstants.webhooks)) {
            allowed[webhooks] = true;
          }
          if (matchedPids.includes(permissionsConstants.appIntegrations)) {
            allowed[appIntegrations] = true;
          }
          if (matchedPids.includes(permissionsConstants.viewGamification)) {
            allowed[viewGamification] = true;
          }
          if (matchedPids.includes(permissionsConstants.editGamification)) {
            allowed[editGamification] = true;
          }
          if (matchedPids.includes(permissionsConstants.exportGamification)) {
            allowed[exportGamification] = true;
          }
          if (matchedPids.includes(permissionsConstants.addManualPoints)) {
            allowed[addManualPoints] = true;
          }
          if (matchedPids.includes(permissionsConstants.viewLiveDashboard)) {
            allowed[viewLiveDashboard] = true;
          }
        }
      });
    }
  } 
  return allowed;
};

/**
 * Generates a random string of the specified length.
 * @param {number} length - The length of the random string.
 * @returns {string} - The generated random string.
 */
export const generateRandomString = (length) => {
  var result = '';
  var characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

/**
 * Gets the event URL based on the provided parameters.
 * @param {string} eventDamainName - The event domain name.
 * @param {string} vanityDomainName - The vanity domain name.
 * @param {boolean} isVanityDomainActive - Flag indicating if the vanity domain is active.
 * @param {boolean} isVanityDomainVerified - Flag indicating if the vanity domain is verified.
 * @returns {string} - The generated event URL.
 */
export const getEventURL = (
  eventDamainName,
  vanityDomainName,
  isVanityDomainActive = false,
  isVanityDomainVerified = false,
) => {
  let url = '';
  if (isVanityDomainActive && isVanityDomainVerified) {
    url = `${window.location.protocol}//${vanityDomainName}`;
  } else if (eventDamainName) {
    url = `${window.location.protocol
      }//${eventDamainName.toLowerCase()}.${getHostName()}`;
  }
  return url;
};

/**
 * Checks if the given email is a valid email address.
 * @param {string} email - The email address to be validated.
 * @returns {boolean} - True if the email is valid, false otherwise.
 */
export const isValidEmail = (email) => {
  let isValid = true;
  const regexTest = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  if (email && regexTest.test(email) === false) {
    isValid = false;
  }
  return isValid;
};

export const isValidURL = (str) => {
  const urlPattern =
    /^(https?:\/\/)?([a-z0-9-]+\.)*[a-z0-9-]+\.[a-z]{2,6}(:[0-9]+)?(\/.*)?$/i;
  return urlPattern.test(str);
};

/**
 * Returns language data based on the provided language code.
 * @param {string} data - The language code.
 * @returns {Object} - Language data.
 */
export const languageCode = (data) => {
  switch (data) {
    case 'en': ;
      return englishData.engData;

    case 'es':
      return spanishData.spanData;

    case 'ro':
      return romanData.romData;

    default: return englishData.engData;
  }
};

export const separateByComma = (arr) => {
  if (Array.isArray(arr) && arr.length) {
    const arrLength = arr.length;
    return arr
      .map((el, i) => {
        if (typeof el === "object") {
          return el.email; // Exclude objects from the result
        }
        return (arrLength - 1) > i ? `${el}, ` : `${el}`; // Add commas for all but the last element
      })
      .filter(Boolean) // Remove any null values (like objects)
      .join(''); // Join the array elements into a single string
  }
  return ''; // Return an empty string if input is not valid or is an empty array
};

export function camelCaseToTitleCase(camelCaseString) {
  let spacedString = camelCaseString.replace(/([A-Z])/g, ' $1');
  // Capitalize the first letter of each word
  let titleCaseString = spacedString.replace(/\b\w/g, (char) => char.toUpperCase());
  return titleCaseString;
}

export function formatDateField(listValue, cf, cid) {
  if (cf?.fieldData?.fieldType === 'date') {
    const dateValue = listValue?.customFields?.[cf._id]; 
    if (dateValue) {
      // Check if the date is valid
      const newDate = moment(new Date(dateValue)); 
      if (!newDate.isValid()) {
        // Return null if the date is invalid
        return null;
      } 
      // Format the date if valid
      return newDate.format("YYYY/MM/DD");
    }
  } 
  return null;
}

export function validateSocialMediaUrl(url, platform) {
  // Define regex patterns for each social media platform
  const patterns = {
    linkedin: /^(https?:\/\/)?(www\.)?linkedin\.com\/.+$/,
    facebook: /^(https?:\/\/)?(www\.)?facebook\.com\/.+$/,
    instagram: /^(https?:\/\/)?(www\.)?instagram\.com\/.+$/,
    x: /^(https?:\/\/)?(www\.)?twitter\.com\/.+$/, // Assuming 'x' refers to Twitter
    pinterest: /^(https?:\/\/)?(www\.)?pinterest\.com\/.+$/
  }; 
  // Check if the platform is supported
  if (!patterns[platform]) {
    throw new Error("Unsupported social media platform");
  } 
  // Check if the URL matches the pattern for the given platform
  return patterns[platform].test(url);
}

export const bytesToSize = (bytes) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
};

export const downloadFile = (fileUrl, fileName) => {
  const a = document.createElement('a');
  a.href = fileUrl;
  a.download = fileName; // Set the file name
  document.body.appendChild(a);
  a.click();
  a.remove();
};

export const getContactFields = [
  { field: 'firstName', label: 'First Name', disabled: false },
  { field: 'lastName', label: 'Last Name', disabled: false },
  { field: 'middle', label: 'Middle', disabled: false },
  { field: 'email', label: 'Email', disabled: false },
  { field: 'audienceIds', label: 'Contact Groups', disabled: false }
];

export const textFieldConditions = [
  { label: 'Exact Match', condition: 'exactMatch' },
  { label: 'Contains', condition: 'contains' },
  { label: 'Does Not Contain', condition: 'doesNotContain' },
];

export const numberFieldConditions = [
  { label: 'Exact Match', condition: 'exactMatchNumber' },
  { label: 'Less Than', condition: 'lessThan' },
  { label: 'Greater Than', condition: 'greaterThan' },
  { label: 'Less Than or Equal To', condition: 'lessThanOrEqualTo' },
  { label: 'Greater Than or Equal To', condition: 'greaterThanOrEqualTo' },
  { label: 'Not Equal To', condition: 'notEqualTo' },
];

export const inAndNotInConditionsForUser = [
  { label: 'In', condition: 'uIn' },
  { label: 'Not In', condition: 'uNotIn' },

];

export const inAndNotInConditions = [
  { label: 'In', condition: 'in' },
  { label: 'Not In', condition: 'notIn' },

];

export const booleanFieldConditions = [
  { label: 'Equal To', condition: 'equalTo' },
];

export const dateFieldConditions = [
  { label: 'Before', condition: 'beforeDate' },
  { label: 'After', condition: 'afterDate' },
  { label: 'Custom Range', condition: 'fromToDate' },
];

export const rgbToHex2 = (color) => {
  if (color?.r !== undefined && color?.g !== undefined && color?.b !== undefined) {
    let { r, g, b } = color;
    // Convert each RGB component to hexadecimal
    const redHex = r.toString(16).padStart(2, '0');
    const greenHex = g.toString(16).padStart(2, '0');
    const blueHex = b.toString(16).padStart(2, '0');

    // Concatenate the hex values
    const hexColor = '#' + redHex + greenHex + blueHex;

    return hexColor.toUpperCase(); // Convert to uppercase for consistency
  }
  return undefined; // Return undefined if the color is invalid
};


// HOC to provide navigate and location props
export const withCustomRouter = (WrappedComponent) => {
  return (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    return <WrappedComponent {...props} navigate={navigate} location={location} params={params} />;
  };
};

export const generateUrl = (basePath) => {
  // Automatically determine if we're in a 'member' context based on the current location
  const isMember = location?.pathname.includes('member');
  return isMember ? `/member${basePath}` : `/xp${basePath}`;
};

export const decodeBase64Content = (base64String) => {
  try {
    const base64 = base64String.replace(/-/g, '+').replace(/_/g, '/');
    const padding = '='.repeat((4 - (base64.length % 4)) % 4);
    return atob(base64 + padding);
  } catch (e) {
    console.error('Base64 decode failed:', e);
    return '';
  }
};