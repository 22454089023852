import { alpha } from '@mui/material/styles';
import { gray, orange } from '../themePrimitives';
import { AlertSystemIcon, AlertInfoIcon, AlertSuccessIcon, AlertErrorIcon } from '../../eijent/components/CustomIcons'

export const feedbackCustomizations = {
  MuiAlert: {
    defaultProps: {
      iconMapping: {
        system: <AlertSystemIcon />, // Your custom success icon
        success: <AlertSuccessIcon />, // Your custom success icon
        info: <AlertInfoIcon />,       // Your custom info icon
        warning: <AlertSystemIcon />, // Your custom warning icon
        error: <AlertErrorIcon />,     // Your custom error icon
      },
    },
    styleOverrides: {
      root: ({ theme }) => ({
        borderRadius: 10,
        backgroundColor: orange[100],
        color: (theme.vars || theme).palette.text.primary,

        boxShadow: '0px 3px 6px #00000029',
        '& .MuiAlert-action': {
          padding: '8px 4px 0 16px',
        },
        '& .MuiAlert-message': {
          marginTop: '30px',
          marginLeft: '-30px',
        },
        ...theme.applyStyles('dark', {
          backgroundColor: `${alpha(orange[900], 0.5)}`,
          border: `1px solid ${alpha(orange[800], 0.5)}`,
        }),
        variants: [
          {
            props: {
              severity: "system"
            },
            style: {
              backgroundColor: '#F4F6F7',
            },
          },
          {
            props: {
              severity: "success"
            },
            style: {
              backgroundColor: '#E6FDF3',
            },
          },
          {
            props: {
              severity: "info"
            },
            style: {
              backgroundColor: '#DBF7FF',
            },
          },
          {
            props: {
              severity: "warning"
            },
            style: {
              backgroundColor: '#FFFAE3',
            },
          },
          {
            props: {
              severity: "error"
            },
            style: {
              backgroundColor: '#FFD8D8',
            },
          },
          {
            props: {
              severity: "error"
            },
            style: {
              backgroundColor: '#FFD8D8',
            },
          },
        ]
      }),
    },
  },
  MuiDialog: {
    styleOverrides: {
      root: ({ theme }) => ({
        '& .MuiDialog-paper': {
          borderRadius: '10px',
          border: '1px solid',
          borderColor: (theme.vars || theme).palette.divider,
        },
      }),
    },
  },
  MuiLinearProgress: {
    styleOverrides: {
      root: ({ theme }) => ({
        height: 8,
        borderRadius: 8,
        backgroundColor: gray[200],
        ...theme.applyStyles('dark', {
          backgroundColor: gray[800],
        }),
      }),
    },
  },
};
