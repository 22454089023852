import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Stack, SxProps, Theme } from "@mui/material";
import EijentCopilot from "../EijentCopilot/index";
import Grid from '@mui/material/Grid2';


const aiDividerStyle: SxProps<Theme> = {
  position: 'relative',
  transformStyle: 'preserve-3d',
  '&::after': {
    content: '""',
    position: 'absolute',
    width: '5px',
    right: '0',
    top: '0',
    bottom: '0',
    transform: 'translate3d(1px, 0px, -1px)',
    background: 'transparent linear-gradient(114deg, #5578FA 0%, #CBFFE3 100%) 0% 0% no-repeat padding-box',
    borderRadius: '26px',
    filter: 'blur(10px)',
  },
};

const JourneyWorkflow: React.FC = () => {
  return (
    <Grid container
      height="100%"
      width="100%">
      {/* Left Panel - Journey Summary */}
      <Grid size={{ xs: 6, }} sx={aiDividerStyle}>
        <Stack sx={{ backgroundColor: "#fff", height: "100%" }}>
          {/* Header */}
          <Box sx={{ borderBottom: "1px solid #D9D9D9", padding: 1.5 }}>
            <Typography variant="h4" align="center" color="primary">
              F1 – Las Vegas
            </Typography>
          </Box>

          {/* Body */}
          <Stack p={4} alignItems="center" justifyContent="space-between" flexGrow={1} overflow={'auto'}>
            <Typography variant="body2" align="center">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, <br />

              sed do eiusmod tempor incididunt ut labore et dolore magna. <br />
              Hub for all the opportunities and operational tasks related <br />
              to events from Formula 1 in Las Vegas.
            </Typography>

            <Button variant="contained" size="medium" color="primary">
              Switch to Chat View
            </Button>
          </Stack>
        </Stack>
      </Grid>

      {/* Right Panel - Copilot */}
      <Grid size={{ xs: 6, }} maxHeight={'100%'}>
        <EijentCopilot />
      </Grid>
    </Grid>
  );
};

export default JourneyWorkflow;