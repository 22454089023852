//@ts-nocheck
import React, {
  SyntheticEvent,
  useState,
  useEffect,
  useRef,
  useContext,
} from 'react';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import AddIcon from '@mui/icons-material/Add';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Fade from '@mui/material/Fade';
import Popper from '@mui/material/Popper';
import MenuList from '@mui/material/MenuList';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Stack, Box } from '@mui/material';
import { Modal } from 'react-responsive-modal';
import MenuItem from '@mui/material/MenuItem';
import { theme } from '../../../theme/theme';
import MoreIcon from '../../../../src/assets/images/icons/more.svg';
import SearchIcon from '../../../../src/assets/images/icons/search.svg';
// import '../../../sass/main.scss';
import { ThemeProvider } from '@mui/material';
import ManageCustomFields from '../CustomFields/ManageCustomFields';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Actions } from 'ReduxStore/Actions';
import {
  CustomField,
  CustomFields_State,
} from 'ReduxStore/Reducers/opportunity/opportunityCustomFields.reducer';
import {
  Opportunity,
  OpportunityState,
  Source,
  Status,
} from 'ReduxStore/Reducers/opportunity/opportunities.reducer';
import moment from 'moment';
import FilterDropDown from 'Common/FilterDropDown';
import { AccountsState } from 'ReduxStore/Reducers/opportunity/accounts.reducer';
import { createNotification, turnCamelCaseToNormalText } from 'helpers';
import PaginationFunctional, {
  handleEndCountForPagination,
} from 'Pages/ProjectDashboard/Integration/AppIntegration/googleWorkspaceComponents/PaginationFunctional';
import Loader from 'Components/Loader/loader';
import DropdownSelectionPopUp from 'Common/DropdownSelectionPopUp';
import {
  AudienceObject,
  Contact,
  ContactState,
} from 'ReduxStore/Reducers/opportunity/contacts.reducer';
import AddOrUpdateContact from './AddOrUpdateContact';
import ConfirmationAlertPopUp from 'Common/ConfirmationAlertPopUp';
import { generateUrl, getSystemLevelPermissions, rgbToHex2 } from '../../../helpers/common';
import OpportunityContactsAPI from 'ReduxStore/API/opportunity/opportunityContactsAPI';
import ImportModal from '../CommonComponents/ImportModal';
import AddOrEditContact from './AddOrEditContact';
import ContactDetails from './ContactsView/ContactDetails';
import ContactsViewLayout from './ContactsView/ContactsViewLayout';
import EditIcon from '../../../../src/assets/images/icons/edit.svg';
import {
  AudienceParent,
  AudienceState,
} from 'ReduxStore/Reducers/opportunity/audience.reducer';
import {
  AudienceValidationError,
  FetchAudienceQueryParams,
} from '../Audience/Audience';
import Tooltip from '@mui/material/Tooltip';
import CustomHTMLTooltip from '../../../Components/CustomHTMLTooltip';
import { MultiValue, default as ReactSelect } from 'react-select';
import { components } from 'react-select';
import removeIcon from '../../../assets/images/icons/close.svg';
import CustomFieldFilterDropDown from 'Common/CustomFieldFilterDropDown';
import FilterByDraft from '../Common/FilterByDraft';
import SmartGroupIcon from '../../../assets/images/icons/smart-group-icon.svg';
import GroupIcon from '../../../assets/images/icons/contact-group-icon.svg';
import ContactsIcon from '../../../assets/images/icons/contacts-nav-icon.svg';
import AddOrEditAudience from '../Audience/AddOrEditAudience';
import SmartGroup from '../Audience/SmartGroup';
import FilterByLastImported from '../Common/FilterByLastImported';
import ViewLayout from '../Common/ViewLayout';
import { OpportunityContactsContext } from 'Contexts/OpportunityContactsProvider';
import ManageStates from '../Opportunity/States/ManageStates';
import {
  OpportunityStates as OpportunityDynamicStates,
  OpportunityStates_State
} from 'ReduxStore/Reducers/opportunity/opportunityStates.reducer';
import Grid from '@mui/material/Grid2';
import { authPermissionValidation } from 'helpers/permissionsHelper';
import { permissionsConstants } from 'constants/permissionsConstant';

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 250,
      marginTop: 4,
    },
  },
};

type SourceFilterArr = {
  _id: Source;
  name: string;
}[];

type StatusFilterArr = {
  _id: Status;
  name: string;
}[];

const sourceFilterArr: SourceFilterArr = [
  { _id: 'aiAgent', name: 'AI Agent' },
  { _id: 'manuallyAdded', name: 'Manually Added' },
  { _id: 'imported', name: 'Imported' },
];

const statusFilterArr: StatusFilterArr = [{ _id: 'draft', name: 'Draft' }];

const styles = {
  avatar: {
    backgroundColor: '#FF4170',
    width: '48px',
    height: '48px',
    borderRadius: 8,
  },
  customTabList: {
    minHeight: 42,
  },
  customTab: {
    minHeight: 42,
  },
};

export type FetchOpportunityQueryParams = {
  pageNo: number;
  limit: number;
  sortBy: string;
  sortOrder: string;
  searchText: string;
};

type Props = {
  handleTabChange: (
    e: SyntheticEvent<Element, Event>,
    newValue: string,
    searchText: string,
  ) => void;
  groupIdForAdd: string;
  groupClickObject: any;
  showAddNewfromGroupTree: boolean;
  setShowAddNewfromGroupTree: React.Dispatch<React.SetStateAction<boolean>>;
};

function formatDateField(listValue: any, cf: any, cid: any) {
  if (cf.fieldData.fieldType === 'date') {
    const dateValue = listValue?.customFields?.[cf._id];
    if (dateValue) {
      const customField = cid.find(
        (format: { _id: any; }) => format._id === cf._id,
      );
      if (customField) {
        const dateFormat =
          customField && customField.fieldData.dateFormat
            ? customField.fieldData.dateFormat
            : 'MM/DD/YYYY';
        return moment(dateValue).format(dateFormat);
      }
    }
  }
  return null;
}

const Option = (props: any) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{' '}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const customStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#EDECF5' : 'none',
    position: 'relative',
    borderRadius: 4,
    marginBottom: 5,
  }),
  control: (provided: any) => ({
    ...provided,
    minHeight: 48,
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: 'none',
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    paddingTop: '8px',
    paddingBottom: '8px',
  }),
  menu: (provided: any) => ({
    ...provided,
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 4,
    paddingBottom: 4,
    top: '-235px',
    overflow: 'hidden',
    maxHeight: 220,
    minHeight: 220,
  }),
  menuList: (provided: any) => ({
    ...provided,
    paddingBottom: 90,
  }),
  singleValue: (provided: any, state: any) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    return { ...provided, opacity, transition };
  },
};

export type FetchContactsQueryParams = {
  pageNo: number;
  limit: number;
  sortBy: string;
  sortOrder: string;
  from: string;
  status?: string;
};

export default function Contacts({
  handleTabChange,
  groupClickObject,
  groupIdForAdd,
  showAddNewfromGroupTree,
  setShowAddNewfromGroupTree,
}: Props) {
  const { opportunityStates: opportunityDynamicStates, fetchAllOpportunityStateSuccess } = useSelector(
    (state: any) => state.OpportunityStates,
  ) as OpportunityStates_State;

  const opportunityContactsContext = useContext(OpportunityContactsContext);
  if (!opportunityContactsContext) {
    throw new Error(
      'Contacts must be used within an opportunityContactsContextProvider',
    );
  }

  const {
    sortOrder,
    setSortOrder,
    sortBy,
    setSortBy,
    pageNo,
    setPageNo,
    limit,
    setLimit,
    filterByStatus,
    setFilterByStatus,
    filterBySource,
    setFilterBySource,
    filterByAudience,
    setFilterByAudience,
    filterByLastImported,
    setFilterByLastImported,
    searchText,
    setSearchText,
    filterByState,
    setFilterByState
  } = opportunityContactsContext; 
 
  const location = useLocation<any>();
  const navigate = useNavigate<any>();
  const dispatch = useDispatch();
  const initialRender = useRef(true);
  const [assign, setAssign] = useState<boolean>(false);
  const [openAddSmartGroup, setOpenAddSmartGroup] = useState<boolean>(false);
  const [showManageStates, setShowManageStates] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);


  const { accounts } = useSelector(
    (state: any) => state.OpportunityAccounts,
  ) as AccountsState;

  const { groups } = useSelector(
    (state: any) => state.OpportunityAudience,
  ) as AudienceState;

  const { customFields } = useSelector(
    (state: any) => state.OpportunityCustomFields,
  ) as CustomFields_State;

  const { editOpportunityAudienceSuccess } = useSelector(
    (state: any) => state.OpportunityAudience,
  ) as AudienceState;

  const {
    contacts,
    filterCount,
    totalCount,
    loading,
    updateMultipleOpportunityContactsSuccess,
    deleteOpportunityContactSuccess,
    createOpportunityContactSuccess,
    assignOrUnassignOpportunityContactsSuccess,
  } = useSelector((state: any) => state.OpportunityContacts) as ContactState;
  const uniqueEijentId = location.pathname.split('/').at(-1);
  const currentPath = location.pathname.split('/').at(3);
  const [openUpdateModel, setOpenUpdateModel] = useState<boolean>(false);
  const [openSettings, setOpenSettings] = React.useState(false);
  const [openAction, setOpenAction] = React.useState(false);
  const anchorRef = React.useRef(null);
  const anchorRefAction = React.useRef(null);
  const anchorRefSettings = React.useRef(null);
  const [showManageCustomFields, setShowManageCustomFields] =
    useState<boolean>(false);
  const [searchTextTemp, setSearchTextTemp] = useState<string>('');
  const [startCount, setStartCount] = useState<number>(1);
  const [endCount, setEndCount] = useState<number>(10);
  const [contactToBeEdited, setContactToBeEdited] = useState<
    Contact | undefined
  >({} as Contact);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<string[]>([]);
  const [openUnassignPopup, setOpenUnassignPopup] = useState<boolean>(false);
  const [openDeleteConfirmationModel, setOpenDeleteConfirmationModel] =
    useState<boolean>(false);
  const [openImportModal, setOpenImportModal] = useState<boolean>(false);
  const [showAddNew, setShowAddNew] = useState<boolean>(false);
  const [showViewModal, setShowViewModal] = useState<boolean>(false);
  const [audienceIds, setAudienceIds] = useState<string[]>([]);
  const [audienceIdsTemp, setAudienceIdsTemp] = useState<{ audienceName: string; _id: string; }[]>([]);
  const [openUnassignAudience, setOpenUnassignAudience] =
    useState<boolean>(false);
  const [action, setAction] = useState<string>('');
  const [radioButtonCheck, setRadioButtonCheck] = useState<string>('assign');
  const [audienceName, setAudienceName] = useState<string>('');
  const [errors, setErrors] = useState<AudienceValidationError>({});
  const [filterByCustomField, setFilterByCustomField] = useState<string[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [addOrEditAudience, setAddOrEditAudience] = useState<boolean>(false);
  const [editData, setEditData] = useState<AudienceParent | undefined>(
    {} as AudienceParent,
  );
  const [dynamicStates, setDynamicStates] = useState<OpportunityDynamicStates>([]);
  useEffect(() => {
    if (showAddNewfromGroupTree) {
      handleAddOrUpdate();
    }
  }, [showAddNewfromGroupTree]);

  useEffect(() => { 
    fetchAllOpportunityCustomField();
    fetchAllOpportunityAudience();
    getAllOpportunityGroups();
    fetchAllOpportunityStates();
  }, []);

  useEffect(() => {
    if (deleteOpportunityContactSuccess) {
      setSelectedCheckboxes([]);
    }
    if (createOpportunityContactSuccess) {
      setSelectedCheckboxes([]);
    }
  }, [createOpportunityContactSuccess, deleteOpportunityContactSuccess]);

  useEffect(() => {
    if (assignOrUnassignOpportunityContactsSuccess) {
      setSelectedCheckboxes([]);
      setAudienceIds([]);
      setAudienceIdsTemp([]);
      setAssign(false);
      setAction('');
      setOpenUnassignAudience(false);
      setRadioButtonCheck('assign');
      setAudienceName('');
      setErrors({});
      setFilterByAudience([]);
    }
  }, [assignOrUnassignOpportunityContactsSuccess]);

  useEffect(() => {
    if (currentPath === "contacts") {
      if (groupClickObject.groupClick && groupClickObject.isSmartGroup == true) {
        getSmartGroupsContacts();
      } else {
        fetchAllContacts();
      }
      setShowAddNew(false);
      setShowViewModal(false);
    }
  }, [
    pageNo,
    limit,
    sortBy,
    sortOrder,
    searchText,
    filterByStatus,
    filterBySource,
    filterByAudience,
    filterByCustomField,
    filterByLastImported,
    filterByState,
    currentPath
  ]);


  useEffect(() => {
    if (groupClickObject.groupClick && groupClickObject.isSmartGroup === true && editOpportunityAudienceSuccess) {
      getSmartGroupsContacts();
    }
  }, [
    editOpportunityAudienceSuccess
  ]);

  useEffect(() => {
    if (fetchAllOpportunityStateSuccess) {
      setDynamicStates(opportunityDynamicStates);
    }
  }, [fetchAllOpportunityStateSuccess]);

  useEffect(() => {
    if (groupClickObject.groupId !== '') {
      setPageNo(1);
      setLimit(10);
      setSortBy('updatedAt');
      setSortOrder('desc');
      setSearchTextTemp('');
      setSearchText('');
      setSelectedCheckboxes([]);
      setFilterByAudience([groupClickObject.groupId]);
    } else {
      setPageNo(1);
      setLimit(10);
      setSelectedCheckboxes([]);
    }
  }, [groupClickObject.groupId]);

  const queryParamsForAudience: FetchAudienceQueryParams = {
    pageNo: 1,
    limit: 10,
    sortBy: 'updatedAt',
    order: 'desc',
    searchText: '',
  };

  const fetchAllOpportunityAudience = () => {
    const data = queryParamsForAudience;
    dispatch(Actions.fetchAllOpportunityAudienceRequest(data));
  };

  const getAllOpportunityGroups = () => {
    dispatch(Actions.getAllOpportunityGroupsRequest());
  };

  useEffect(() => {
    setSelectedCheckboxes([]);
  }, [pageNo]);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      handleEndCountForPagination(totalCount, limit, pageNo, setEndCount);
    }
  }, [totalCount]);

  useEffect(() => {
    if (updateMultipleOpportunityContactsSuccess) {
      setSelectedCheckboxes([]);
    }
  }, [updateMultipleOpportunityContactsSuccess]);

  useEffect(() => {
    if (currentPath === "contactdetail") {
      fetchContactById();
    }
  }, [uniqueEijentId, showAddNew]);

  const fetchContactById = async () => {
    try {
      const opportunityContactsAPI = new OpportunityContactsAPI();
      const res = await opportunityContactsAPI.fetchOpportunityContact({
        uniqueEijentId: uniqueEijentId
      });
      if (res.data?.contact) {
        setContactToBeEdited(res.data?.contact);
        setShowViewModal(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (location.state) {
      if (location.state.comingFromOtherTab === true) {
        handleAddOrUpdate(location.state.contact, true);
      } else {
        setSearchText(location.state as string);
        setSearchTextTemp(location.state as string);
      }
    }
  }, [location.state]);

  useEffect(() => {
    if (action === 'assign' && audienceIds.length > 0) {
      assignOrUnassignOpportunityContacts();
    } else if (action === 'unassign') {
      assignOrUnassignOpportunityContacts();
    } else if (action === 'add') {
      let error: AudienceValidationError = {};

      if (audienceName === '' || audienceName.trim().length === 0) {
        error['audienceName'] = 'Please enter audience name';
        setErrors(error);
      } else {
        assignOrUnassignOpportunityContacts();
      }
    } else if (action === 'remove' && audienceIds.length > 0) {
      assignOrUnassignOpportunityContacts();
    }
  }, [action]);
  const bodyParamsForfiltering = {
    filterBySource,
    filterByAudience,
    filterByCustomField,
    filterByStatus,
    filterByLastImported,
    filterByState
  };

  const fetchAllContacts = () => {
    const data = {
      queryParams,
      bodyParams: { filterArray: bodyParamsForfiltering, searchText },
    };
    dispatch(Actions.fetchAllOpportunityContactsRequest(data));
  };

  useEffect(() => {
    if (groupClickObject.isSmartGroup && groupClickObject.groupClick) {
      getSmartGroupsContacts();
    }
    if (!showAddNewfromGroupTree) {
      // setShowAddNew(false);
    }
  }, [groupClickObject]);

  const getSmartGroupsContacts = () => {
    const data = {
      queryParams: {
        ...queryParams,
        groupId: groupClickObject.groupId, // Add groupId here
      },
      bodyParams: {
        filterArray: bodyParamsForfiltering,
        searchText,
      },
    };

    dispatch(Actions.getSmartGroupsContactsRequest(data));
  };

  const fetchAllOpportunityStates = () => {
    const currentTab = location.pathname.split('/').at(-1);
    const data = {
      opportunityStateType: "contacts"
    };
    dispatch(Actions.fetchAllOpportunityStateRequest(data));
  };

  const queryParams: FetchContactsQueryParams = {
    pageNo,
    limit,
    sortBy,
    sortOrder,
    from: 'viewContact',
  };

  const fetchAllOpportunityCustomField = () => {
    const currentTab = location.pathname.split('/').at(-1);
    const data = {
      customFieldType: "contacts",
      from: 'viewContact',
    };
    dispatch(Actions.fetchAllOpportunityCustomFieldRequest(data));
  };

  const handleToggleSettings = () => {
    setOpenSettings((prevOpen) => !prevOpen);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleToggleAction = () => {
    setOpenAction((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpenSettings(false);
    setOpenAction(false);
    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent<HTMLUListElement>) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenSettings(false);
      setOpen(false);
    }
  }

  const handleOpenManageCustomFields = () => {
    setShowManageCustomFields(true);
  };

  const handleCloseManageCustomFields = () => {
    setShowManageCustomFields(false);
  };

  const handleOpenManageStates = () => {
    setShowManageStates(true);
  };

  const handleCloseManageStates = () => {
    setShowManageStates(false);
  };

  const resetAddNewModal = () => {
    setOpen(false);
    setContactToBeEdited({} as Contact);
  };

  const handleAddOrUpdate = (
    contact?: Contact,
    isBeingEdited: boolean = false,
  ) => { 
    if (isBeingEdited) {
      setContactToBeEdited(contact);
      location.state?.comingFromOtherTab
        ? setShowAddNew(true)
        : setShowViewModal(true);
      navigate(generateUrl(`/opportunity/contactdetail/${contact?.uniqueEijentId}`));
    } else if (!isBeingEdited) {
      setContactToBeEdited(contact);
      setShowAddNew(true);
    }
  };

  const handleSorting = (sort_by: string) => {
    setSortBy(sort_by);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    setSelectedCheckboxes([]);
  };

  const handleSelectCheckBox = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    accountId: string,
  ) => {
    let target = e.target as HTMLInputElement;
    if (target.checked) {
      setSelectedCheckboxes([...selectedCheckboxes, accountId]);
    } else {
      setSelectedCheckboxes(
        selectedCheckboxes.filter((id) => id !== accountId),
      );
    }
  };

  const handleSelectAllCheckboxes = (
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    let target = e.target as HTMLInputElement;
    if (target.checked) {
      let arr: string[] = [];
      contacts.forEach((contact) => arr.push(contact._id));
      setSelectedCheckboxes(arr);
    } else {
      setSelectedCheckboxes([]);
    }
  };

  const handleCloseDropdowns = () => {
    setOpenSettings(false);
    setOpenAction(false);
    setOpen(false);
  };

  const handleSearchText = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setSearchTextTemp(e.target.value);
  };

  const handleSearchTextSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSearchText(searchTextTemp);
    setPageNo(1);
    setStartCount(1); // pagination
    setSelectedCheckboxes([]);
    if (
      groupClickObject.groupClick &&
      groupClickObject.isSmartGroup == false &&
      groupClickObject.groupId
    ) {
      setFilterByAudience([groupClickObject.groupId]);
    }
  };

  const handleUpdateMultiple = (key: string, value: any, ids?: string[]) => {
    if (!ids && !selectedCheckboxes.length) {
      createNotification('error', 'Please select contacts');
    } else {
      const bodyParams = {
        ...bodyParamsForfiltering,
        ids: ids || selectedCheckboxes, // ids will come from status dropdown component
        toUpdate: {
          [key]: value,
        },
      };
      const data = {
        bodyParams,
        queryParams,
      };
      dispatch(Actions.updateMultipleOpportunityContactsRequest(data));
    }
    handleCloseDropdowns();
    handleCloseUnassignPopup();
    closeDeleteConfirmationModel();
  };

  const handleOpenDropdownSelectionPopup = (
    setOpenFn: React.Dispatch<React.SetStateAction<boolean>>,
  ) => {
    if (!selectedCheckboxes.length) {
      createNotification(
        'error',
        'Please select one or more opportunities to modify',
      );
    } else {
      setOpenFn(true);
    }
  };

  const handleCloseUnassignPopup = () => {
    setOpenUnassignPopup(false);
  };

  const handleCloseUnassignPopupAudience = () => {
    setOpenUnassignAudience(false);
    setAudienceIds([]);
    setAudienceIdsTemp([]);
    setAssign(false);
    setAction('');
  };

  const handleOpenUnassignPopup = () => {
    setOpenUnassignPopup(true);
  };

  const closeDeleteConfirmationModel = () => {
    setOpenDeleteConfirmationModel(false);
  };

  const handleOpenDeleteConfirmationModel = () => {
    setOpenDeleteConfirmationModel(true);
  };

  const showRestOfPages = (cfs: string[]) => {
    return cfs?.slice(1, cfs.length);
  };

  const handleExport = async (onlyExportSelected: boolean) => {
    type ExportData = {
      queryParams: FetchContactsQueryParams;
      bodyParams?: {
        ids?: string[];
        timezone: string;
        audienceId?: string;
      };
    };

    setIsLoading(true);
    let data: ExportData | null = null;

    if (
      groupClickObject.groupClick &&
      groupClickObject.isSmartGroup &&
      !onlyExportSelected
    ) {
      const data1 = {
        queryParams: {
          ...queryParams,
          groupId: groupClickObject.groupId, // Add groupId here
        },
        bodyParams: {
          isExport: true,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          filterArray: bodyParamsForfiltering,
          searchText,
        },
      };

      const opportunityContactsAPI = new OpportunityContactsAPI();
      // @ts-ignore
      const res1 = await opportunityContactsAPI.getSmartGroupsContacts(data1);

      if (res1.data && res1.data.csvData) {
        const url = window.URL.createObjectURL(new Blob([res1.data.csvData]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Contacts.csv'); // or any other extension
        document.body.appendChild(link);
        link.click();
      }
    } else {
      if (
        groupClickObject.groupClick &&
        !groupClickObject.isSmartGroup &&
        !onlyExportSelected
      ) {
        data = {
          queryParams,
          bodyParams: {
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            audienceId: groupClickObject?.groupId,
          },
        };
      } else {
        data = {
          queryParams,
          bodyParams: {
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        };
      }

      if (onlyExportSelected) {
        data = {
          queryParams,
          bodyParams: {
            ids: selectedCheckboxes,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        };
      }

      const opportunityContactsAPI = new OpportunityContactsAPI();
      // @ts-ignore
      const res = await opportunityContactsAPI.export(data);

      if (res.data.csvData) {
        const url = window.URL.createObjectURL(new Blob([res?.data?.csvData]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Contacts.csv'); // or any other extension
        document.body.appendChild(link);
        link.click();
      }
    }
    setIsLoading(false);
  };

  const handleClickOpenImportModal = (doOpenImportModal: boolean) => {
    setOpenImportModal(doOpenImportModal);
  };

  const handleOpenImportModal = (isOpen: boolean) => {
    setOpenImportModal(isOpen);
  };

  const closeViewModal = () => {
    setShowViewModal(false);
    navigate(generateUrl(`/opportunity/contacts`));
  };

  const openAssignAudience = () => {
    setAssign(true);
    getAllOpportunityGroups();
  };

  const openUnassignAudiencemodal = () => {
    setOpenUnassignAudience(true);
  };
  const closeAssignAudience = () => {
    setAssign(false);
    setAudienceIds([]);
    setAudienceIdsTemp([]);
    setAction('');
    setOpenUnassignAudience(false);
    setRadioButtonCheck('assign');
    setAudienceName('');
  };

  const handleChangeAudience = (
    selected: MultiValue<{ label: string; value: string; }>,
  ) => {
    const selectedValues = selected.map(
      (option) => ({
        audienceName: option.label,
        _id: option.value,
      }),
    );

    setAudienceIdsTemp(selectedValues);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAudienceName(e.target.value);
  };

  const assignOrUnassignOpportunityContacts = () => {
    let data;
    if (action === 'unassign' && groupClickObject.groupClick) {
      data = {
        queryParams,
        bodyParams: {
          audienceIds: [groupIdForAdd],
          contactIds: selectedCheckboxes,
          action: action,
          audienceName: audienceName,
        },
      };
    } else {
      data = {
        queryParams,
        bodyParams: {
          groupsDetail: audienceIdsTemp,
          audienceIds: audienceIds,
          contactIds: selectedCheckboxes,
          action: action,
          audienceName: audienceName,
        },
      };
    }

    dispatch(Actions.assignOrUnassignOpportunityContactsRequest(data));
  };

  const handleAssignAndUnassign = (
    e:
      | React.FormEvent<HTMLFormElement>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>,
    action: string,
  ) => {
    const ids = audienceIdsTemp.map(item => item._id);
    e.preventDefault();
    setAudienceIds(ids);
    setPageNo(1); // pagination
    setStartCount(1); // pagination
    setAction(action);
  };
  const selectRadioButton = (value: string) => {
    setRadioButtonCheck(value);
    setAudienceName('');
  };

  const showRestOfAudience = (audience: AudienceObject[]) => {
    let audienceLength = audience.length;
    return audience;
  };

  const removeAudience = (audience: string, contactId: string) => {
    setAudienceIds([audience._id]);
    setSelectedCheckboxes([contactId]);
    setAudienceIdsTemp([audience]);
    setAction('remove');
  };

  const closeAddOrEditAudience = () => {
    setAddOrEditAudience(false);
  };

  const addOrEditAudienceModal = () => {
    setAddOrEditAudience(true);
  };

  const handleClickOpenAddSmartGroup = () => {
    setOpenAddSmartGroup(true);
  };

  const handleCloseAddSmartGroup = () => {
    setOpenAddSmartGroup(false);
  };

  const bodyParamsForfiltering1 = {
    filterBySource,
    filterByAudience,
    filterByCustomField,
    filterByStatus,
    filterByLastImported,
    filterByState
  };

  const getStateColor = (contact: Contact) => {
    // Ensure opportunity and state exist and have at least one element
    if (contact?.state?.length > 0) {
      const defaultState = opportunityDynamicStates.find(
        (state) => state.name === contact.state[0].name
      );
      // Return textColor and backgroundColor if found, else return default values
      if (defaultState) {
        return {
          textColor: defaultState.textColor ? rgbToHex2(defaultState.textColor) : "#000056", // Default text color
          backgroundColor: rgbToHex2(defaultState.backgroundColor) || "#ffffff00", // Default background color
        };
      }
    }

    // If state length is 0, return no color for both
    return {
      textColor: "",
      backgroundColor: "",
    };
  };

  return (
    <ThemeProvider theme={theme}>
      <Box className="Opportunity-contacts-tab">
        {currentPath !== "contactdetail" && !showViewModal && !showAddNew ? (
          <Box px={1.25} py={4}>
            <Grid
              container
              spacing={0.5}
              pb={3}
              alignItems={'end'}
              className="top-filter-area"
              Opportunity-contacts-tab
            >
              <Grid >
                {!groupClickObject.groupClick &&
                  <Grid container spacing={0.5}>
                    <FilterDropDown
                      dropdownArray={dynamicStates}
                      filterBy={filterByState}
                      setFilterBy={setFilterByState}
                      filtering="state"
                      filterCount={filterCount}
                      totalCount={totalCount}
                    />
                    <FilterDropDown
                      dropdownArray={sourceFilterArr}
                      filterBy={filterBySource}
                      setFilterBy={setFilterBySource}
                      filtering="source"
                      filterCount={filterCount}
                      totalCount={totalCount}
                    />
                    <FilterDropDown
                      dropdownArray={filterCount?.audience}
                      filterBy={filterByAudience}
                      setFilterBy={setFilterByAudience}
                      filtering="audience"
                      filterCount={filterCount}
                      totalCount={totalCount}
                    />

                    <Grid >
                      {/* <FilterByDraft
                        filterByStatus={filterByStatus}
                        setFilterByStatus={setFilterByStatus}
                        filterCount={filterCount}
                      /> */}
                    </Grid>
                    <Grid >
                      <FilterByLastImported
                        filterByLastImported={filterByLastImported}
                        setFilterByLastImported={setFilterByLastImported}
                        filterCount={filterCount}
                      />
                    </Grid>
                  </Grid>
                }
              </Grid>
              <Grid >
                <Grid
                  container
                  spacing={0.5}
                  alignItems={'end'}
                  className="team-dash-right"
                >
                  <Grid className="">
                    <Stack
                      direction={'row'}
                      spacing={1}
                      alignItems={'end'}
                      style={{ maxWidth: '200px' }}
                    >
                      <CustomFieldFilterDropDown
                        dropdownArray={customFields}
                        filterBy={filterByCustomField}
                        setFilterBy={setFilterByCustomField}
                      />
                      <form onSubmit={handleSearchTextSubmit}>
                        <TextField
                          name="searchText"
                          variant='standard'
                          placeholder="Search..."
                          className="search"
                          size="small"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <img alt="" src={SearchIcon} />
                              </InputAdornment>
                            ),
                          }}
                          onChange={handleSearchText}
                          value={searchTextTemp}
                        />
                      </form>
                    </Stack>
                  </Grid>
                  <Grid >
                    <div>
                      <Button
                        variant="outlined"
                        color="primary"
                        disableElevation
                        ref={anchorRefAction}
                        aria-controls={
                          openAction ? 'menu-list-grow' : undefined
                        }
                        aria-haspopup="true"
                        onClick={handleToggleAction}
                        endIcon={<ArrowDropDownIcon />}
                        size="small"
                      >
                        Actions
                      </Button>
                      <Popper
                        open={openAction}
                        style={{ zIndex: 3 }}
                        placement="bottom-start"
                        transition
                        anchorEl={anchorRefAction.current}
                        role={undefined}
                        disablePortal
                      >
                        {({ TransitionProps, placement }) => (
                          <Fade {...TransitionProps} timeout={250}>
                            <Box
                              sx={
                                {
                                  backgroundColor: theme.palette.common.white,
                                  boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.2)',
                                  borderRadius: '4px',
                                  marginTop: '8px',
                                } as any
                              }
                            >
                              <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                  autoFocusItem={openAction}
                                  id="menu-list-grow"
                                  onKeyDown={handleListKeyDown}
                                >
                                  <MenuItem
                                    onClick={() => openAssignAudience()}
                                    disabled={!selectedCheckboxes.length}
                                  >
                                    Assign Contact Group
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() => openUnassignAudiencemodal()}
                                    disabled={!selectedCheckboxes.length}
                                  >
                                    Unassign Contact Group
                                  </MenuItem>
                                  {authPermissionValidation(permissionsConstants.deleteContacts) && (
                                    <MenuItem
                                      onClick={() =>
                                        handleOpenDeleteConfirmationModel()
                                      }
                                      disabled={!selectedCheckboxes.length}
                                      style={{
                                        color: theme.palette.secondary.main,
                                      }}
                                    >
                                      Delete
                                    </MenuItem>
                                  )}
                                  {authPermissionValidation(permissionsConstants.viewAccessCodes) && (
                                    <>
                                      <MenuItem
                                        onClick={() => handleExport(true)}
                                        disabled={!selectedCheckboxes.length}
                                      >
                                        Export
                                      </MenuItem>
                                      <MenuItem
                                        onClick={() => handleExport(false)}
                                        disabled={
                                          selectedCheckboxes.length > 0 ||
                                          !Array.isArray(contacts) ||
                                          contacts.length === 0
                                        }
                                      >
                                        Export All
                                      </MenuItem>
                                    </>
                                  )}{' '}

                                </MenuList>
                              </ClickAwayListener>
                            </Box>
                          </Fade>
                        )}
                      </Popper>
                    </div>
                  </Grid>
                  <Grid >
                    <div>
                      <Button
                        variant="outlined"
                        color="primary"
                        disableElevation
                        ref={anchorRefSettings}
                        aria-controls={
                          openSettings ? 'menu-list-grow' : undefined
                        }
                        aria-haspopup="true"
                        onClick={handleToggleSettings}
                        endIcon={<ArrowDropDownIcon />}
                        size="small"
                      >
                        Settings
                      </Button>
                      <Popper
                        open={openSettings}
                        style={{ zIndex: 3 }}
                        placement="bottom-start"
                        transition
                        anchorEl={anchorRefSettings.current}
                        role={undefined}
                        disablePortal
                      >
                        {({ TransitionProps, placement }) => (
                          <Fade {...TransitionProps} timeout={250}>
                            <Box
                              sx={
                                {
                                  backgroundColor: theme.palette.common.white,
                                  boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.2)',
                                  borderRadius: '4px',
                                  marginTop: '8px',
                                } as any
                              }
                            >
                              <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                  autoFocusItem={openSettings}
                                  id="menu-list-grow"
                                  onKeyDown={handleListKeyDown}
                                >
                                  {authPermissionValidation(permissionsConstants.contactsCustomFields) && (
                                    <MenuItem
                                      onClick={handleOpenManageCustomFields}
                                    >
                                      Custom Fields
                                    </MenuItem>
                                  )}
                                  <MenuItem
                                    onClick={handleOpenManageStates}
                                  >
                                    Custom States
                                  </MenuItem>
                                </MenuList>
                              </ClickAwayListener>
                            </Box>
                          </Fade>
                        )}
                      </Popper>
                    </div>
                  </Grid>
                  <Grid >
                    { authPermissionValidation(permissionsConstants.createContacts) &&
                      (!groupClickObject.groupClick ? (
                        <Button
                          variant="contained"
                          color="primary"
                          disableElevation
                          onClick={() => handleAddOrUpdate()}
                          size="small"
                        >
                          Add New
                        </Button>
                      ) : groupClickObject.isSmartGroup ? (
                        <Button
                          variant="contained"
                          color="primary"
                          disableElevation
                          onClick={() => handleAddOrUpdate()}
                          size="small"
                        >
                          New Contact
                        </Button>
                      ) : (
                        <Box>
                          <Button
                            variant="outlined"
                            className="button-between"
                            color="primary"
                            startIcon={
                              <Stack direction={'row'} alignItems={'center'}>
                                <AddIcon sx={{ fontSize: '16px', }} />
                                <Box ml={1} sx={{ fontSize: '13px', }}>New</Box>
                              </Stack>

                            }
                            size="small"
                            endIcon={<ArrowDropDownIcon />}
                            disableElevation
                            ref={anchorRef}
                            aria-controls={open ? 'menu-list-grow' : undefined}
                            aria-haspopup="true"
                            onClick={handleToggle}
                          ></Button>
                          <Popper
                            open={open}
                            style={{ zIndex: 999 }}
                            placement="bottom-end"
                            transition
                            anchorEl={anchorRef.current}
                            role={undefined}
                            disablePortal
                          >
                            {({ TransitionProps, placement }) => (
                              <Fade {...TransitionProps} timeout={250}>
                                <Box
                                  sx={{
                                    backgroundColor: theme.palette.common.white,
                                    boxShadow:
                                      '0px 6px 20px rgba(0, 0, 0, 0.2)',
                                    borderRadius: '4px',
                                    marginTop: '8px',
                                  }}
                                >
                                  <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList
                                      autoFocusItem={open}
                                      id="menu-list-grow"
                                      onKeyDown={handleListKeyDown}
                                    >
                                      <MenuItem
                                        onClick={() => addOrEditAudienceModal()}
                                      >
                                        <Stack direction={'row'} spacing={1}>
                                          <img src={GroupIcon} alt={'icon'} />
                                          <Box>New Group</Box>
                                        </Stack>
                                      </MenuItem>
                                      <MenuItem
                                        onClick={() => handleAddOrUpdate()}
                                      >
                                        <Stack direction={'row'} spacing={1}>
                                          <img
                                            src={ContactsIcon}
                                            alt={'icon'}
                                          />
                                          <Box>New Contact</Box>
                                        </Stack>
                                      </MenuItem>
                                      <MenuItem
                                        onClick={() =>
                                          handleClickOpenAddSmartGroup()
                                        }
                                      >
                                        <Stack direction={'row'} spacing={1}>
                                          <img
                                            src={SmartGroupIcon}
                                            alt={'icon'}
                                          />
                                          <Box>New Smart Group</Box>
                                        </Stack>
                                      </MenuItem>
                                    </MenuList>
                                  </ClickAwayListener>
                                </Box>
                              </Fade>
                            )}
                          </Popper>
                        </Box>
                      ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <TableContainer
              className="list-table"
              style={{ height: 'calc(100vh - 330px)', minHeight: '290px' }}
            >
              <Table className="table">
                <TableHead
                  style={{
                    position: 'sticky',
                    top: '0',
                    backgroundColor: '#f6f6fc',
                    zIndex: 2,
                  }}
                >
                  <TableRow>
                    <TableCell className="checkbox sticky-cell">
                      <Checkbox
                        color="primary"
                        onClick={handleSelectAllCheckboxes}
                        checked={
                          contacts?.length
                            ? selectedCheckboxes.length === contacts?.length
                            : false
                        }
                      />
                    </TableCell>
                    <TableCell
                      className={
                        'id sticky-cell ' +
                        (sortBy === 'uniqueEijentId'
                          ? sortOrder === 'asc'
                            ? 'sort-asc'
                            : 'sort-des'
                          : '')
                      }
                      style={{ left: 50 }}
                      onClick={() => handleSorting('uniqueEijentId')}
                    >
                      Eijent Id
                    </TableCell>
                    <TableCell
                      className={
                        'long-text sticky-cell ' +
                        (sortBy === 'firstName'
                          ? sortOrder === 'asc'
                            ? 'sort-asc'
                            : 'sort-des'
                          : '')
                      }
                      style={{ left: 150 }}
                      onClick={() => handleSorting('firstName')}
                    >
                      First Name
                    </TableCell>

                    <TableCell
                      className={
                        'long-text ' +
                        (sortBy === 'middleName'
                          ? sortOrder === 'asc'
                            ? 'sort-asc'
                            : 'sort-des'
                          : '')
                      }
                      onClick={() => handleSorting('middleName')}
                    >
                      Middle Name
                    </TableCell>
                    <TableCell
                      className={
                        'long-text ' +
                        (sortBy === 'lastName'
                          ? sortOrder === 'asc'
                            ? 'sort-asc'
                            : 'sort-des'
                          : '')
                      }
                      onClick={() => handleSorting('lastName')}
                    >
                      Last Name
                    </TableCell>
                    <TableCell
                      className={
                        'long-text ' +
                        (sortBy === 'email'
                          ? sortOrder === 'asc'
                            ? 'sort-asc'
                            : 'sort-des'
                          : '')
                      }
                      onClick={() => handleSorting('email')}
                    >
                      Email
                    </TableCell>
                    <TableCell
                      className={
                        'status ' +
                        (sortBy === 'state'
                          ? sortOrder === 'asc'
                            ? 'sort-asc'
                            : 'sort-des'
                          : '')
                      }
                      onClick={() => handleSorting('state')}
                    >
                      State
                    </TableCell>
                    <TableCell
                      className={
                        'text ' +
                        (sortBy === 'source'
                          ? sortOrder === 'asc'
                            ? 'sort-asc'
                            : 'sort-des'
                          : '')
                      }
                      onClick={() => handleSorting('source')}
                    >
                      Source
                    </TableCell>
                    {/* <TableCell
                      className={
                        'text ' +
                        (sortBy === 'status'
                          ? sortOrder === 'asc'
                            ? 'sort-asc'
                            : 'sort-des'
                          : '')
                      }
                      onClick={() => handleSorting('status')}
                    >
                      Status
                    </TableCell> */}
                    <TableCell className='text'>Contact Groups</TableCell>
                    {customFields.map((cf) => {
                      return (
                        <TableCell
                          key={cf._id}
                          className={
                            'custom-field ' +
                            (sortBy === `customFields.${cf._id}`
                              ? sortOrder === 'asc'
                                ? 'sort-asc'
                                : 'sort-des'
                              : '')
                          }
                          onClick={() =>
                            handleSorting(`customFields.${cf._id}`)
                          }
                        >
                          {cf.fieldData.fieldName}
                        </TableCell>
                      );
                    })}
                    <TableCell
                      className={
                        'date-time ' +
                        (sortBy === 'updatedAt'
                          ? sortOrder === 'asc'
                            ? 'sort-asc'
                            : 'sort-des'
                          : '')
                      }
                      onClick={() => handleSorting('updatedAt')}
                    >
                      Updated On
                    </TableCell>
                    <TableCell className="options">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.isArray(contacts) && contacts.length ? (
                    contacts.map((contact) => {
                      return (
                        <TableRow key={contact._id}>
                          <TableCell className="checkbox sticky-cell">
                            <Checkbox
                              color="primary"
                              onClick={(e) =>
                                handleSelectCheckBox(e, contact._id)
                              }
                              style={{ left: 0 }}
                              checked={selectedCheckboxes.includes(contact._id)}
                            />
                          </TableCell>
                          <TableCell
                            className="id link sticky-cell"
                            style={{ left: 50 }}
                            onClick={() => handleAddOrUpdate(contact, true)}
                          >
                            {contact?.uniqueEijentId || '-'}
                          </TableCell>
                          <TableCell
                            className="long-text link sticky-cell"
                            style={{ left: 150 }}
                            onClick={() => handleAddOrUpdate(contact, true)}
                          >
                            {contact?.firstName || '-'}
                          </TableCell>
                          <TableCell className="long-text">
                            {contact?.middleName || '-'}
                          </TableCell>
                          <TableCell className="long-text">
                            {contact?.lastName || '-'}
                          </TableCell>
                          <TableCell
                            className={`long-text ${contact?.email ? 'link' : ''
                              }`}
                            onClick={() => {
                              if (contact?.email) {
                                window.open(`mailto:${contact?.email}`);
                              }
                            }}
                          >
                            {contact?.email || '-'}
                          </TableCell>
                          <TableCell className="status">
                            {contact?.state?.length > 0 ? (
                              <Box
                                component={'span'}
                                className="table-status"
                                sx={{
                                  color: `${getStateColor(contact).textColor} !important`,
                                  bgcolor: `${getStateColor(contact).backgroundColor}`,
                                }}
                              >
                                {contact?.state[0]?.name || '-'}
                              </Box>
                            ) : (
                              '-'
                            )}
                          </TableCell>
                          <TableCell className="text">
                            {contact?.source === 'aiAgent'
                              ? 'AI Agent'
                              : contact?.source === 'imported'
                                ? 'Imported'
                                : 'Manually Added'}
                          </TableCell>
                          {/* <TableCell className="text">
                            {contact?.status === 'draft' ? 'Draft' : ''}
                          </TableCell> */}
                          <TableCell className="text">
                            <Stack
                              direction="row"
                              alignItems={'center'}
                              spacing={1}
                            >
                              <Box display={'flex'} className="detail-popup">
                                {contact.audience &&
                                  contact.audience.length > 0 ? (
                                  <Box
                                    sx={{ maxWidth: '140px' }}
                                    className="long-text"
                                  >
                                    {contact.audience[0]?.audienceName}
                                  </Box>
                                ) : (
                                  '--'
                                )}
                                {contact.audience &&
                                  contact.audience.length > 1 && (
                                    <CustomHTMLTooltip
                                      placement={'bottom-end'}
                                      interactive
                                      arrow
                                      title={
                                        <Box
                                          className="tooltip-popup"
                                          width={200}
                                        >
                                          {showRestOfAudience(
                                            contact.audience,
                                          ).map((data, i) => {
                                            return (
                                              <Box
                                                sx={{ display: 'flex' }}
                                                justifyContent={'space-between'}
                                              >
                                                <Box
                                                  component={'p'}
                                                  className={'long-text'}
                                                  maxWidth={'170px'}
                                                >
                                                  {data?.audienceName}
                                                </Box>
                                                <Tooltip
                                                  placement="bottom"
                                                  title="Remove"
                                                  arrow
                                                  onClick={() =>
                                                    removeAudience(
                                                      data,
                                                      contact._id,
                                                    )
                                                  }
                                                >
                                                  <Box
                                                    component={'img'}
                                                    src={removeIcon}
                                                    width={'12px'}
                                                    alt="remove"
                                                  />
                                                </Tooltip>
                                              </Box>
                                            );
                                          })}
                                        </Box>
                                      }
                                    >
                                      <Box ml={1}>
                                        <span className="count">
                                          +
                                          {contact.audience
                                            ? contact.audience.length - 1
                                            : 0}
                                        </span>
                                      </Box>
                                    </CustomHTMLTooltip>
                                  )}
                              </Box>
                            </Stack>
                          </TableCell>

                          {customFields.map((cf: CustomField) => {
                            return Array.isArray(
                              contact?.customFields?.[cf._id as string],
                            ) ? (
                              (
                                contact?.customFields?.[
                                cf._id as string
                                ] as string[]
                              ).length ? (
                                <TableCell
                                  key={cf._id as string}
                                  className="custom-field "
                                  sx={{ overflow: 'unset !important' }}
                                >
                                  <Stack
                                    direction="row"
                                    alignItems={'center'}
                                    spacing={1}
                                  >
                                    <span
                                      className={`long-text ${cf.fieldData.fieldType === 'user' &&
                                        'link'
                                        }`}
                                      style={{ maxWidth: '80%' }}
                                      onClick={() => {
                                        if (cf.fieldData.fieldType === "user" && contact?.customFields?.[
                                          cf._id as string
                                        ]) {
                                          window.open(`mailto:${(contact?.customFields?.[
                                            cf._id as string
                                          ] as string[])?.[0]}`);
                                        }
                                      }}
                                    >
                                      {' '}
                                      {cf.fieldData.fieldType === "user" ? contact?.customFields?.[cf._id as string]
                                        ? (
                                          contact?.customFields?.[
                                          cf._id as string
                                          ] as string[]
                                        )[0]?.email
                                        : '' : contact?.customFields?.[cf._id as string]
                                        ? (
                                          contact?.customFields?.[
                                          cf._id as string
                                          ] as string[]
                                        )[0]
                                        : ''}
                                    </span>
                                    {contact?.customFields?.[
                                      cf._id as string
                                    ] &&
                                      (
                                        contact?.customFields?.[
                                        cf._id as string
                                        ] as string[]
                                      )?.length > 1 && (
                                        <div className="detail-popup">
                                          <span className="count">
                                            +
                                            {(
                                              contact?.customFields?.[
                                              cf._id as string
                                              ] as string[]
                                            )?.length - 1}
                                          </span>
                                          <div className="info">
                                            {showRestOfPages(
                                              (contact?.customFields?.[
                                                cf._id as string
                                              ] as string[]).map(el => cf.fieldData.fieldType === "user" ? el.email : el)
                                            )?.map((cfValue: String, i: number) => {
                                              return (
                                                <div
                                                  key={i}
                                                  className={`${cf.fieldData.fieldType === "user" && "link"}`}
                                                  style={{ zIndex: 999999 }}
                                                  onClick={() => {
                                                    if (cf.fieldData.fieldType === "user" && contact?.customFields?.[
                                                      cf._id as string
                                                    ]) {
                                                      window.open(`mailto:${cfValue}`);
                                                    }
                                                  }}
                                                >
                                                  <p>{cfValue}</p>
                                                </div>
                                              );
                                            })}
                                          </div>
                                        </div>
                                      )}
                                  </Stack>
                                </TableCell>
                              ) : (
                                <TableCell className="custom-field" key={cf._id}>
                                  -
                                </TableCell>
                              )
                            ) : (
                              <TableCell className={`custom-field ${['url', 'email', 'facebook', 'instagram', 'x', 'pinterest', 'linkedin', 'phone'].includes(cf.fieldData.fieldType) && contact
                                ?.customFields?.[
                                cf._id as string
                              ] as string && 'link'}`} key={cf._id}
                                onClick={() => {
                                  if (contact
                                    ?.customFields?.[
                                    cf._id as string
                                  ] as string) {
                                    if (
                                      ['url', 'facebook', 'instagram', 'x', 'pinterest', 'linkedin'].includes(cf.fieldData.fieldType)
                                    ) {
                                      let url = (contact
                                        ?.customFields?.[
                                        cf._id as string
                                      ] as string);
                                      if (!/^https?:\/\//i.test(url)) {
                                        // If not, prepend 'https://'
                                        url = 'https://' + url;
                                      }
                                      window.open(url);
                                    } else if (cf.fieldData.fieldType === 'email') {
                                      window.open(`mailto:${contact
                                        ?.customFields?.[
                                        cf._id as string
                                      ] as string}`);
                                    } else if (cf.fieldData.fieldType === 'phone') {
                                      window.open(`tel:${contact
                                        ?.customFields?.[
                                        cf._id as string
                                      ] as string}`);
                                    }
                                  }
                                }}>
                                {cf.fieldData.fieldType === 'date' &&
                                  contact?.customFields?.[cf._id as string]
                                  ? formatDateField(contact, cf, customFields)
                                  : cf.fieldData.fieldType === 'boolean' &&
                                    contact?.customFields?.[cf._id as string]
                                    ? contact?.customFields?.[
                                      cf._id as string
                                    ]?.toString() === 'true'
                                      ? 'True'
                                      : (contact?.customFields?.[
                                        cf._id as string
                                      ]?.toString() == 'false' ? 'False' : '-')
                                    :
                                    cf.fieldData.fieldType === 'longText' &&
                                      contact?.customFields?.[cf._id as string] ?
                                      <CustomHTMLTooltip
                                        interactive
                                        title={
                                          <Box className="upgrade-tooltip long-description">
                                            <p>
                                              {(contact?.customFields?.[
                                                cf._id as string
                                              ] as string)}
                                            </p>
                                          </Box>}
                                        placement='bottom-start'
                                        arrow={false}
                                      >
                                        <span>
                                          {(contact?.customFields?.[
                                            cf._id as string
                                          ] as string)}
                                        </span>
                                      </CustomHTMLTooltip>
                                      :
                                      (contact?.customFields?.[
                                        cf._id as string
                                      ] as string) || '-'}

                              </TableCell>
                            );
                          })}
                          <TableCell className="date-time">
                            {moment(contact.updatedAt).format(
                              'MMM, Do YYYY, h:mm A',
                            )}
                          </TableCell>
                          <TableCell className="options" align="center">
                            { authPermissionValidation(permissionsConstants.editContacts) && (
                              <div
                                onClick={() =>
                                  handleAddOrUpdate(contact, false)
                                }
                              >
                                <img alt="Edit" src={EditIcon} />{' '}
                              </div>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={3} style={{ textAlign: 'center' }}>
                        There are no records to display
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <PaginationFunctional
              startCount={contacts?.length === 0 ? 0 : startCount}
              setStartCount={setStartCount}
              endCount={contacts?.length === 0 ? 0 : endCount}
              limit={limit}
              setLimit={setLimit}
              page_no={pageNo}
              setPage_no={setPageNo}
              totalItemsCount={totalCount}
              listItemsFn={() => { }}
            />
          </Box>
        ) : showAddNew ? (
          <Box px={1.25} py={4.5}>
            <AddOrEditContact
              setShowAddNewModal={setShowAddNew}
              queryParams={queryParams}
              contactId={contactToBeEdited?._id as string}
              resetAddNewModal={resetAddNewModal}
              groupClickObject={groupClickObject}
              setShowAddNewfromGroupTree={setShowAddNewfromGroupTree}
              bodyParamsForfiltering={bodyParamsForfiltering1}
            />
          </Box>
        ) : (
          <ViewLayout
            handleTabChangeFromMainLayout={handleTabChange}
            closeViewModal={closeViewModal}
            setShowAddNew={setShowAddNew}
            setShowViewModal={setShowViewModal}
            contactToBeEdited={contactToBeEdited}
            type="contact"
          />
        )}
        {showManageCustomFields && (
          <ManageCustomFields
            handleCloseManageCustomFields={handleCloseManageCustomFields}
            showManageCustomFields={showManageCustomFields}
            handleOpenManageCustomFields={handleOpenManageCustomFields}
          />
        )}

        {showManageStates && (
          <ManageStates
            handleCloseManageStates={handleCloseManageStates}
            showManageStates={showManageStates}
            from={"contacts"}
          />
        )}
        {loading && <Loader />}

        <DropdownSelectionPopUp
          open={openUpdateModel}
          setOpen={setOpenUpdateModel}
          dropdownArray={accounts}
          heading={'Assign Account'}
          subheading={
            'Efficiently assign opportunities by choosing an account from the list'
          }
          label={'Account Name'}
          onSaveFn={(value: any) =>
            handleUpdateMultiple('accountId', value._id)
          }
        />
        <Modal
          open={openUnassignPopup}
          onClose={handleCloseUnassignPopup}
          classNames={{
            overlay: '',
            modal: 'Invite-User-Modal',
          }}
          center
        >
          <Grid component="form" container className="modal-wrapper">
            <Grid size={{ xs: 12, }} pb={3}>
              <Box mb={1} className="subsecondary">
                Unassign Account
              </Box>
              <Box className="small-subtitle">
                Selected Contacts:{' '}
                <span className="primary-text">
                  {selectedCheckboxes?.length}
                </span>
              </Box>
            </Grid>
            <Grid size={{ xs: 12, }} pb={2.5}>
              <Box component="div" className="inputField" pb={2}>
                <label>
                  Are you sure you want to unassign the account from the
                  selected contact?
                </label>
              </Box>
            </Grid>
            <Grid size={{ xs: 12, }}>
              <Grid container justifyContent="end">
                <Stack spacing={1} direction="row">
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={() => handleUpdateMultiple('accountId', null)}
                  >
                    Yes
                  </Button>
                  <Button
                    variant="outlined"
                    disableElevation
                    onClick={handleCloseUnassignPopup}
                  >
                    Cancel
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Modal>
      </Box>
      <ConfirmationAlertPopUp
        openModal={openDeleteConfirmationModel}
        closeModalFunc={closeDeleteConfirmationModel}
        title={'Delete Confirmation!'}
        text={'Are you sure you want to delete it?'}
        confirmationButtonText={'Delete'}
        confirmationButtonColor="secondary"
        closeButtonText={'Cancel'}
        functionality={() => handleUpdateMultiple('isDeleted', true)}
      />
      <ImportModal
        open={openImportModal}
        tab={'contacts'}
        handleOpen={handleOpenImportModal}
        fetchAllOpportunityData={fetchAllContacts}
      />

      <Modal
        open={assign}
        onClose={closeAssignAudience}
        classNames={{
          overlay: '',
          modal: 'Invite-User-Modal',
        }}
        center
      >
        <div className="formSection">
          <Grid
            component="form"
            container
            className="modal-wrapper"
            sx={{ maxHeight: 'auto' }}
          >
            <Grid size={{ xs: 12, }} pb={4}>
              <Box component="p" mb={1} className="subtitle">
                Assign Contact Group
              </Box>
              <Box className="small-subtitle">
                Select an option either the selected contact(s) will add into
                the selected contact group or created the new contact group
              </Box>
            </Grid>
            <Grid size={{ xs: 12, }}>
              <Box className="inputField" pb={4}>
                <Box>
                  <label>Select Contact Group to assign</label>
                </Box>
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    aria-label="position"
                    name="position"
                    defaultValue="top"
                  >
                    <Box>
                      <FormControlLabel
                        value="top"
                        control={<Radio color="primary" />}
                        label="Assign"
                        onClick={() => selectRadioButton('assign')}
                      />
                    </Box>
                    <Box>
                      <FormControlLabel
                        value="start"
                        control={<Radio color="primary" />}
                        label="Add"
                        onClick={() => selectRadioButton('add')}
                      />
                    </Box>
                  </RadioGroup>
                </FormControl>
              </Box>
              {radioButtonCheck === 'assign' ? (
                <Box component="div" className="" pb={4}>
                  <label>Contact Group Name</label>
                  <ReactSelect
                    styles={customStyles}
                    className="custom-multi-select"
                    options={
                      groups && groups.length > 0
                        ? groups.map((o, index) => {
                          return { label: o.audienceName, value: o._id };
                        })
                        : []
                    }
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{
                      Option,
                    }}
                    onChange={handleChangeAudience}
                    placeholder={'select'}
                  />
                </Box>
              ) : (
                <Box component="div" className="inputField" pb={4}>
                  <label>Contact Group Name</label>
                  <input
                    type="text"
                    className="form-control small"
                    name="audienceName"
                    value={audienceName}
                    placeholder={'Enter audience name'}
                    onChange={handleChange}
                    maxLength={30}
                  />
                  <span className="error_mesage required">
                    {errors.audienceName}
                  </span>
                </Box>
              )}
            </Grid>
            <Grid size={{ xs: 12, }}>
              <Grid container justifyContent="end">
                <Stack spacing={1} direction="row">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={(e) =>
                      radioButtonCheck === 'assign'
                        ? handleAssignAndUnassign(e, 'assign')
                        : handleAssignAndUnassign(e, 'add')
                    }
                  >
                    {' '}
                    Assign Contact Group
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Modal>

      <Modal
        open={openUnassignAudience}
        onClose={handleCloseUnassignPopupAudience}
        classNames={{
          overlay: '',
          modal: 'Invite-User-Modal',
        }}
        center
      >
        <Grid component="form" container className="modal-wrapper">
          <Grid size={{ xs: 12, }} pb={3}>
            <Box mb={1} className="subsecondary">
              Unassign Contact Group
            </Box>
            <Box className="small-subtitle">
              Selected Contacts:{' '}
              <span className="primary-text">{selectedCheckboxes?.length}</span>
            </Box>
          </Grid>
          <Grid size={{ xs: 12, }} pb={2.5}>
            <Box component="div" className="inputField" pb={2}>
              <label>
                Are you sure you want to unassign the contact group from the
                selected contact?
              </label>
            </Box>
          </Grid>
          <Grid size={{ xs: 12, }}>
            <Grid container justifyContent="end">
              <Stack spacing={1} direction="row">
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  onClick={(e) => handleAssignAndUnassign(e, 'unassign')} // Handle onClick with a function
                >
                  Yes
                </Button>
                <Button
                  variant="outlined"
                  disableElevation
                  onClick={handleCloseUnassignPopupAudience}
                >
                  Cancel
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
      <AddOrEditAudience
        openModal={addOrEditAudience}
        closeModalFunc={closeAddOrEditAudience}
        editData={editData}
        queryParams={queryParamsForAudience}
        parentId={groupIdForAdd}
      />
      <SmartGroup
        openModal={openAddSmartGroup}
        closeModalFunc={handleCloseAddSmartGroup}
        editData={editData}
        queryParams={queryParamsForAudience}
        parentId={groupIdForAdd}
      />
      {isLoading && <Loader />}
    </ThemeProvider>
  );
}
