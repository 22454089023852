import {
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  InputAdornment,
  Chip,
  Tooltip,
  Typography,
} from '@mui/material';
import { DeleteIcon, SentWhiteIcon } from 'eijent/components/CustomIcons';
import ReactQuill from 'react-quill';
import DraftsIcon from '@mui/icons-material/Drafts';
import Grid from '@mui/material/Grid2';
//@ts-ignore
import DocIcon from '../../../../../src/assets/images/icons/Doc-icon.svg';
import ClearIcon from '@mui/icons-material/Clear';
import QuillToolbar from '../QuillToolbar/QuillToolbar';
import { useEffect, useState, useRef } from 'react';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import 'react-quill/dist/quill.snow.css';
import { createNotification } from 'helpers';
import { ChangeEvent } from 'react';
import { decodeBase64Content, isValidEmail } from 'helpers/common';
import { Message } from '../common';

// Define the modules configuration
const getModules = (id: string) => ({
  toolbar: {
    container: `#toolbar-${id}`, // Use unique ID for each instance
    handlers: {
      // Add any custom handlers here
    },
  },
});

type Errors = {
  recipients?: string;
  cc?: string;
  bcc?: string;
  emailBody?: string;
};

type Props = {
  i: number;
  mail: Message;
  handleSendOrDraft: (
    status: string,
    i: any,
    mail: Message,
    editorText: string,
    fileNames: File[],
    removedAttachments: string[],
    recipientsChips?: string[],
    ccChips?: string[],
    bccChips?: string[],
  ) => Promise<void>;
  handleDiscard: (i: any, mail: any) => Promise<void>;
  downloadAttachment: (
    messageId: any,
    filename: any,
    attachmentId: any,
  ) => Promise<void>;
};

export default function ComposeThreadReply({
  i,
  mail,
  handleSendOrDraft,
  handleDiscard,
  downloadAttachment,
}: Props) {
  const [editorText, setEditorText] = useState<string>('');
  const [removedAttachments, setRemovedAttachments] = useState<string[]>([]);
  const [fileNames, setFileNames] = useState<File[]>([]);
  const [recipients, setRecipients] = useState('');
  const [recipientsChips, setRecipientsChips] = useState<string[]>([]);
  const recipientsRef = useRef(null);
  const [isCcOpen, setIsCcOpen] = useState(false);
  const [isBccOpen, setIsBccOpen] = useState(false);
  const [cC, setCc] = useState('');
  const [bcC, setBcc] = useState('');
  const [ccChips, setCcChips] = useState<string[]>([]);
  const [bccChips, setBccChips] = useState<string[]>([]);
  const ccRef = useRef(null);
  const bccRef = useRef(null);
  const [errors, setErrors] = useState<Errors>({});
  // const [showCc, setShowCc] = useState(false);

  useEffect(() => {
    // For forwarded messages, we need to decode the content
    const decodedContent = mail.payload.parts?.[0]?.body?.data
      ? decodeBase64Content(mail.payload.parts[0].body.data)
      : decodeBase64Content(mail.payload.body?.data || '');

    setEditorText(decodedContent || mail?.snippet || '');
  }, [mail]);

  useEffect(() => {
    // Initialize recipients from the new recipients field if available
    if (mail.recipients) {
      setRecipientsChips(mail.recipients.to);
      if (mail.recipients.cc.length > 0) {
        setCcChips(mail.recipients.cc);
        setIsCcOpen(true);
      }
    }
  }, [mail]);

  const handleRecipientsChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value !== ',') {
      setRecipients(event.target.value);
    }
  };

  const handleRemoveChip = (
    chip: string,
    type: 'recipients' | 'cC' | 'bcC',
  ) => {
    if (type === 'recipients') {
      setRecipientsChips((prev) => prev.filter((el) => el !== chip));
    } else if (type === 'cC') {
      setCcChips((prev) => prev.filter((el) => el !== chip));
    } else if (type === 'bcC') {
      setBccChips((prev) => prev.filter((el) => el !== chip));
    }
  };

  const handleOpenCC = () => {
    setIsCcOpen(true);
  };

  const handleCloseCC = () => {
    setIsCcOpen(false);
    setCc('');
    setCcChips([]);
  };

  const handleOpenBCC = () => {
    setIsBccOpen(true);
  };

  const handleCloseBCC = () => {
    setIsBccOpen(false);
    setBcc('');
    setBccChips([]);
  };

  const handleCcChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value !== ',') {
      setCc(event.target.value);
    }
  };

  const handleBccChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value !== ',') {
      setBcc(event.target.value);
    }
  };

  const processInput = (handlingFor: 'recipients' | 'cC' | 'bcC') => {
    let input = '';

    if (handlingFor === 'recipients') {
      input = recipients;
    } else if (handlingFor === 'cC') {
      input = cC;
    } else if (handlingFor === 'bcC') {
      input = bcC;
    }

    const isInputValid = isValidEmail(input.trim());

    if (!isInputValid) {
      setErrors({
        ...errors,
        [handlingFor === 'cC' ? 'cc' : handlingFor === 'bcC' ? 'bcc' : 'recipients']: 'Please enter a valid email address'
      });
      return;
    }

    if (input && isInputValid) {
      setErrors(prev => {
        const obj = { ...prev };
        delete obj[handlingFor === 'cC' ? 'cc' : handlingFor === 'bcC' ? 'bcc' : 'recipients'];
        return obj;
      });
      const callBackFn = (prev) => [input.toLowerCase().trim(), ...prev];

      if (handlingFor === 'recipients') {
        setRecipientsChips(callBackFn);
        setRecipients('');
        recipientsRef?.current?.focus();
      } else if (handlingFor === 'cC') {
        setCcChips(callBackFn);
        setCc('');
        ccRef?.current?.focus();
      } else if (handlingFor === 'bcC') {
        setBccChips(callBackFn);
        setBcc('');
        bccRef?.current?.focus();
      }
    }
  };

  const handleKeyDown = (event, handlingFor: 'recipients' | 'cC' | 'bcC') => {
    if (['Tab', ',', 'Enter'].includes(event.key)) {
      let currentInput = '';
      if (handlingFor === 'recipients') currentInput = recipients;
      else if (handlingFor === 'cC') currentInput = cC;
      else if (handlingFor === 'bcC') currentInput = bcC;

      if (currentInput.trim() && isValidEmail(currentInput.trim())) {
        event.preventDefault();
        processInput(handlingFor);
      }
    }
  };

  const handleBlur = (handlingFor: 'recipients' | 'cC' | 'bcC') => {
    processInput(handlingFor);
  };

  const handleEditorText = (val: string) => {
    setEditorText(val);
  };

  const handleRemovedAttachments = (partId) => {
    setRemovedAttachments((prev) => [...prev, partId]);
  };

  const handleDelete = (fileToDelete: File, i) => {
    setFileNames((prevFileNames) =>
      prevFileNames.filter((file) => file.name !== fileToDelete.name),
    );
  };

  const handleAttachment = (file) => {
    if (file) {
      setFileNames([...fileNames, file]);
    }
  };

  const renderAttachments = (
    gmail,
    showRemoveAttachmentIcon = false,
    indexFromGmail,
  ) => {
    const { payload } = gmail;
    const { parts } = payload;

    if (
      payload.mimeType === 'multipart/mixed' &&
      Array.isArray(parts) &&
      parts.length > 1
    ) {
      return parts.map((part, i) => {
        if (i > 0 && !removedAttachments?.includes(part.partId)) {
          return (
            <Box className="attachment-file" display={'flex'} alignItems={'center'} key={part.partId}>
              <Box display={'flex'} alignItems={'center'}>
                <Box className="file-icon">
                  <img src={DocIcon} alt="file-icon" />
                </Box>
                <Box>
                  <Typography variant='body2' className="file-name">{part.filename}</Typography>
                  <Typography variant='caption' className="file-size">{part.body.size}</Typography>
                </Box>
              </Box>
              <FileDownloadIcon
                onClick={() =>
                  downloadAttachment(
                    gmail.id,
                    part.filename,
                    part.body.attachmentId,
                  )
                }
                style={{ fontSize: '18px' }}
                className="options-button"
              />
              {showRemoveAttachmentIcon && (
                <Box ml={1} className="options">
                  <ClearIcon
                    onClick={() => handleRemovedAttachments(part.partId)}
                    style={{ fontSize: '18px' }}
                  />
                </Box>
              )}
            </Box>
          );
        } else {
          return null;
        }
      });
    } else {
      return null;
    }
  };

  const isValid = (status: string) => {
    let isValid = true;
    let error: Errors = {};

    const validateEmailField = (
      field: string | undefined,
      fieldName: string,
    ): boolean => {
      if (!field) {
        return false; // Immediately return false if field is undefined or empty
      }

      const allMails = field.split(',').map((mail) => mail.trim()); // Split and trim all emails
      const invalidMail = allMails.find(
        (mail) => mail.length > 0 && !isValidEmail(mail),
      ); // Find the first invalid email

      if (invalidMail) {
        error[fieldName] = `The field is not valid. Please enter a valid email address for ${fieldName.toUpperCase()}.`;
        return false;
      }

      return true; // All emails are valid
    };

    if (status === 'send') {
      if (recipientsChips.length === 0) {
        error.recipients = 'Please add at least one recipient';
        isValid = false;
      }

      if (recipientsChips.length > 0 && !validateEmailField(recipientsChips.join(','), 'recipients')) {
        error.recipients = 'Please enter the recipients email again';
        isValid = false;
      }

      if (isCcOpen && ccChips.length === 0) {
        error.cc = 'Please add at least one cc';
        isValid = false;
      }

      if (isBccOpen && bccChips.length === 0) {
        error.bcc = 'Please add at least one bcc';
        isValid = false;
      }

      if (isCcOpen && !validateEmailField(ccChips.join(','), 'cc')) {
        error.cc = 'Please enter the cc email again';
        isValid = false;
      }

      if (isBccOpen && !validateEmailField(bccChips.join(','), 'bcc')) {
        error.bcc = 'Please enter the bcc email again';
        isValid = false;
      }

      const isBodyEmpty = !editorText || editorText === '<p><br></p>';

      if (isBodyEmpty) {
        error.emailBody = 'Please enter the email body';
        isValid = false;
      }

      if (recipients !== '') {
        error.recipients = 'Please enter the recipients email properly';
        isValid = false;
      }
      if (isCcOpen && cC !== '') {
        error.cc = 'Please enter the cc email properly';
        isValid = false;
      }
      if (isBccOpen && bcC !== '') {
        error.bcc = 'Please enter the bcc email properly';
        isValid = false;
      }

      setErrors(error);

      return isValid;
    }
  };


  return (
    <Stack mb={1} px={2.5} py={1} height={'100%'} bgcolor={'#F4F6F7'} borderRadius={'8px'} >
      {/* Recipients field for forwarded emails */}
      {/* {mail.labelIds?.includes('FORWARD') && ( */}
      {(
        <>
          <TextField
            name="Recipients"
            placeholder="Add recipients"
            variant="standard"
            fullWidth
            // @ts-ignore
            size="large"
            inputRef={recipientsRef}
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <Box gap={1} display={'flex'} alignItems={'center'}>
                      <Stack direction="row" spacing={1}>
                        {recipientsChips.map(
                          (chip, i) =>
                            i < 3 && (
                              <Chip
                                key={chip}
                                label={chip}
                                size="medium"
                                variant="outlined"
                                onDelete={() =>
                                  handleRemoveChip(chip, 'recipients')
                                }
                              />
                            ),
                        )}
                      </Stack>
                      {recipientsChips.length > 3 && (
                        <Tooltip
                          arrow
                          placement="bottom"
                          title={
                            <Stack spacing={0.5} alignItems={'start'}>
                              {recipientsChips.map(
                                (chip, i) =>
                                  i >= 3 && (
                                    <Chip
                                      key={chip}
                                      label={chip}
                                      size="medium"
                                      variant="outlined"
                                      onDelete={() =>
                                        handleRemoveChip(chip, 'recipients')
                                      }
                                    />
                                  ),
                              )}
                            </Stack>
                          }
                        >
                          <Button
                            // @ts-ignore
                            variant="tag"
                            size="small"
                            sx={{
                              backgroundColor: '#FBE5FE',
                              '&:hover': {
                                backgroundColor: '#FBE5FE',
                              },
                              '&:active': {
                                backgroundColor: '#FBE5FE',
                              },
                            }}
                          >
                            +{recipientsChips.length - 3} more
                          </Button>
                        </Tooltip>
                      )}
                    </Box>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <Box gap={0.75} display={'flex'}>
                      {!isCcOpen && (
                        <Button
                          onClick={handleOpenCC}
                          // @ts-ignore
                          variant="tag"
                          size="small"
                          sx={{
                            backgroundColor: '#B8F3F5',
                            '&:hover': {
                              backgroundColor: '#B8F3F5',
                            },
                            '&:active': {
                              backgroundColor: '#B8F3F5',
                            },
                          }}
                        >
                          CC
                        </Button>
                      )}
                      {!isBccOpen && (
                        <Button
                          onClick={handleOpenBCC}
                          // @ts-ignore
                          variant="tag"
                          size="small"
                          sx={{
                            backgroundColor: '#E6FDF3',
                            '&:hover': {
                              backgroundColor: '#E6FDF3',
                            },
                            '&:active': {
                              backgroundColor: '#E6FDF3',
                            },
                          }}
                        >
                          BCC
                        </Button>
                      )}
                    </Box>
                  </InputAdornment>
                ),
              },
            }}
            value={recipients}
            onChange={handleRecipientsChange}
            onKeyDown={(e) => handleKeyDown(e, 'recipients')}
            onBlur={() => handleBlur('recipients')}
            color={errors?.recipients ? 'error' : 'primary'}
            helperText={errors?.recipients}
            error={Object.keys(errors)?.includes('recipients')}
          />

          {/* CC Field */}
          {isCcOpen && (
            <TextField
              name="CC"
              placeholder="CC"
              variant="standard"
              fullWidth
              // @ts-ignore
              size="large"
              color={errors?.cc ? 'error' : 'primary'}
              helperText={errors?.cc}
              error={Object.keys(errors)?.includes('cc')}
              slotProps={{
                input: {
                  ref: ccRef,
                  startAdornment: (
                    <InputAdornment position="start">
                      <Box gap={1} display={'flex'} alignItems={'center'}>
                        <Stack direction="row" spacing={1}>
                          {ccChips.map(
                            (chip, i) =>
                              i < 3 && (
                                <Chip
                                  key={chip}
                                  label={chip}
                                  size="medium"
                                  variant="outlined"
                                  onDelete={() => handleRemoveChip(chip, 'cC')}
                                />
                              ),
                          )}
                        </Stack>
                        {ccChips.length > 3 && (
                          <Tooltip
                            arrow
                            placement="bottom"
                            title={
                              <Stack spacing={0.5} alignItems={'start'}>
                                {ccChips.map(
                                  (chip, i) =>
                                    i >= 3 && (
                                      <Chip
                                        key={chip}
                                        label={chip}
                                        size="medium"
                                        variant="outlined"
                                        onDelete={() =>
                                          handleRemoveChip(chip, 'cC')
                                        }
                                      />
                                    ),
                                )}
                              </Stack>
                            }
                          >
                            <Button
                              // @ts-ignore
                              variant="tag"
                              size="small"
                              sx={{
                                backgroundColor: '#FBE5FE',
                                '&:hover': {
                                  backgroundColor: '#FBE5FE',
                                },
                                '&:active': {
                                  backgroundColor: '#FBE5FE',
                                },
                              }}
                            >
                              +{ccChips.length - 3} more
                            </Button>
                          </Tooltip>
                        )}
                      </Box>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleCloseCC}
                        // @ts-ignore
                        color="transparent"
                        size="small"
                        variant="contained"
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                },
              }}
              value={cC}
              onChange={handleCcChange}
              onKeyDown={(e) => handleKeyDown(e, 'cC')}
              onBlur={() => handleBlur('cC')}
            />
          )}

          {/* BCC Field */}
          {isBccOpen && (
            <TextField
              name="BCC"
              placeholder="BCC"
              variant="standard"
              // @ts-ignore
              fullWidth
              // @ts-ignore
              size="large"
              color={errors?.bcc ? 'error' : 'primary'}
              helperText={errors?.bcc}
              error={Object.keys(errors)?.includes('bcc')}
              slotProps={{
                input: {
                  ref: bccRef,
                  startAdornment: (
                    <InputAdornment position="start">
                      <Box gap={1} display={'flex'} alignItems={'center'}>
                        <Stack direction="row" spacing={1}>
                          {bccChips.map(
                            (chip, i) =>
                              i < 3 && (
                                <Chip
                                  key={chip}
                                  label={chip}
                                  size="medium"
                                  variant="outlined"
                                  onDelete={() => handleRemoveChip(chip, 'bcC')}
                                />
                              ),
                          )}
                        </Stack>
                        {bccChips.length > 3 && (
                          <Tooltip
                            arrow
                            placement="bottom"
                            title={
                              <Stack spacing={0.5} alignItems={'start'}>
                                {bccChips.map(
                                  (chip, i) =>
                                    i >= 3 && (
                                      <Chip
                                        key={chip}
                                        label={chip}
                                        size="medium"
                                        variant="outlined"
                                        onDelete={() =>
                                          handleRemoveChip(chip, 'bcC')
                                        }
                                      />
                                    ),
                                )}
                              </Stack>
                            }
                          >
                            <Button
                              // @ts-ignore
                              variant="tag"
                              size="small"
                              sx={{
                                backgroundColor: '#FBE5FE',
                                '&:hover': {
                                  backgroundColor: '#FBE5FE',
                                },
                                '&:active': {
                                  backgroundColor: '#FBE5FE',
                                },
                              }}
                            >
                              +{bccChips.length - 3} more
                            </Button>
                          </Tooltip>
                        )}
                      </Box>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleCloseBCC}
                        // @ts-ignore
                        color="transparent"
                        size="small"
                        variant="contained"
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                },
              }}
              value={bcC}
              onChange={handleBccChange}
              onKeyDown={(e) => handleKeyDown(e, 'bcC')}
              onBlur={() => handleBlur('bcC')}
            />
          )}
        </>
      )}

      {/* Email body */}
      <Stack
        py={2}
        borderBottom={'1px solid #F4F6F7'}
        flexGrow={1}
        overflow={'auto'}
      >
        <ReactQuill
          key={mail.id}
          theme="snow"
          value={editorText}
          onChange={handleEditorText}
          modules={getModules(mail.id)}
          placeholder="Write something amazing..."
        />
      </Stack>

      {/* ---Attachment--- */}
      <Box className={'attachment-container'} mt={1}>
        <Grid container spacing={1} my={1} className="attachments">
          {renderAttachments(mail, true, i)}
        </Grid>
      </Box>

      <Grid container spacing={1} my={1} className="attachments">
        {fileNames?.map((file, i) => {
          const imageSizeInBytes = file?.size;
          let convertedFileSizeKbMb;
          if (imageSizeInBytes > 1024 * 1024) {
            // Convert to MB if file size is larger than 1024 KB
            convertedFileSizeKbMb = `${(
              imageSizeInBytes /
              (1024 * 1024)
            ).toFixed(2)} MB`;
          } else {
            convertedFileSizeKbMb = `${(imageSizeInBytes / 1024).toFixed(
              2,
            )} KB`;
          }
          return (
            <Grid size={'auto'}>
              <Box className="attachment-file" display={'flex'} alignItems={'center'}>
                <Box display={'flex'} alignItems={'center'}>
                  <Box className="file-icon">
                    <img src={DocIcon} alt="file-icon" />
                  </Box>
                  <Box>
                    <Typography variant='body2'>{file.name}</Typography>
                    <Typography variant='caption' >
                      Size: {convertedFileSizeKbMb}
                    </Typography>
                  </Box>
                </Box>
                <Box ml={1} className="options">
                  <ClearIcon
                    onClick={() => handleDelete(file, i)}
                    style={{ fontSize: '18px' }}
                  />
                </Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>
      {/* ---Buttons--- */}
      <Stack
        py={1.5}
        direction={'row'}
        alignItems={'center'}
        spacing={1}
        justifyContent={'space-between'}
      >
        <Box display={'flex'} alignItems={'center'} gap={1}>
          <Button
            sx={{ borderRadius: '6px' }}
            variant="contained"
            color="primary"
            endIcon={<SentWhiteIcon />}
            onClick={() => {
              if (isValid('send')) {
                handleSendOrDraft(
                  'send',
                  i,
                  mail,
                  editorText,
                  fileNames,
                  removedAttachments,
                  recipientsChips,
                  ccChips,
                  bccChips,
                );
                setFileNames([]);
                setRemovedAttachments([]);
              }
            }}
          >
            Send
          </Button>
          {!mail.labelIds?.includes('FORWARD') && (
            <Button
              sx={{ borderRadius: '6px' }}
              variant="contained"
              color="secondary"
              endIcon={<DraftsIcon />}
              onClick={() => {
                handleSendOrDraft(
                  'draft',
                  i,
                  mail,
                  editorText,
                  fileNames,
                  removedAttachments,
                );
                setFileNames([]);
                setRemovedAttachments([]);
              }}
            >
              Draft
            </Button>
          )}

          <QuillToolbar
            toolbarId={`toolbar-${mail.id}`}
            onUploadAttachment={handleAttachment}
          />
        </Box>

        <IconButton
          size="small"
          // @ts-ignore
          color="transparent"
          variant="contained"
          onClick={() => handleDiscard(i, mail)}
        >
          <DeleteIcon />
        </IconButton>
      </Stack>
    </Stack>
  );
}
