import React, { createContext, useContext, useState, useEffect } from 'react';
import { xpAccountIntegrationGmailGetGmailId } from 'ReduxStore/API';
import { createNotification } from 'helpers';

interface GmailContextType {
  gmailId: string;
  isLoading: boolean;
}

const GmailContext = createContext<GmailContextType>({
  gmailId: '',
  isLoading: false,
});

export const GmailProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [gmailId, setGmailId] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const getGmailDetails = async () => {
    try {
      setIsLoading(true);
      const result: any = await xpAccountIntegrationGmailGetGmailId();
      if (result.status === 200 && result.data.success) {
        const gmailId = result.data.details.appData.user.email;
        setGmailId(gmailId);
      }
    } catch (error) {
      console.log(error);
      if (error instanceof Error) {
        createNotification('error', error.message);
      } else {
        createNotification('error', 'An unexpected error occurred.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getGmailDetails();
  }, []);

  return (
    <GmailContext.Provider value={{ gmailId, isLoading }}>
      {children}
    </GmailContext.Provider>
  );
};

export const useGmail = () => {
  const context = useContext(GmailContext);
  if (context === undefined) {
    throw new Error('useGmail must be used within a GmailProvider');
  }
  return context;
}; 